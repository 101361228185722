import { IActionMeta } from '@platform/front-lib/dist/stores/_helpers';
import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { RegisterOptions } from 'react-hook-form';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, IconButton, InputAdornment } from '@material-ui/core';
import { useStyles } from './styles';

import {
  AnvIcon,
  AnvTextField,
  AnvTooltip,
  AnvUtils,
  Constants,
  ModalConfirm,
} from '@platform/front-lib';
import { envConst } from 'src/constants';
import { checkFilesDuplicates } from 'src/utils';
import { FILE_EXTENSION_BY_TYPE } from '../../../../constants/mime-types';

const { apiUrl: API_URL } = envConst;
// const MAX_FILE_SIZE = 2048 * 1024;
// const ACCEPTED_FILE_TYPES =
//   'image/png, image/jpg, image/jpeg, image/gif, application/pdf';

// const FILE_EXTENSION_BY_TYPE: Record<string, any> = {
//   'image/png': '.png',
//   'image/jpg': '.jpeg',
//   'image/jpeg': '.jpeg',
//   'image/gif': '.gif',
//   'application/pdf': '.pdf',
// };

export interface IAnvFileInputLocProps {
  accept?: string | string[];
  maxSize?: number;
  maxFiles?: number;
  multiple?: boolean;
  name: string;
  mode: 'update' | 'append';
  existsFileUrl?: string;
  error?: boolean;
  removeFile: (
    id: string,
    meta?: IActionMeta | undefined,
  ) => Promise<void> | any;
  label?: string | JSX.Element | null;
  caption?: any;
  classNames?: Record<string, any>;
  disabled?: boolean;
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  setFilePublicState: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  filePublicState: Record<string, boolean>;
  setFileTitles: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  fileTitles: any;

  labelFileName?: string | JSX.Element;

  [key: string]: any;
}

export const AnvFileInputLabLoc: React.FC<IAnvFileInputLocProps> = ({
  withFilePreview = false,
  accept = Constants.ACCEPTED_FILE_TYPES,
  multiple = false,
  maxSize = Constants.MAX_FILE_SIZE,
  maxFiles,
  name,
  mode,
  existsFileUrl = `${API_URL}/files/get`,
  error = false,
  removeFile,
  label,
  caption,
  classNames = {},
  disabled,
  rules,
  setFileTitles,
  setFilePublicState,
  filePublicState,
  fileTitles,
  register,
  unregister,
  setValue,
  watch,
  errors,
  labelFileName = 'Title',
  ...rest
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [removeState, setRemoveState] = React.useState<Record<
    string,
    any
  > | null>(null);

  const handleOpenRemoveModal = (id: string | null, index: number) => (
    ev: any,
  ) => {
    ev.stopPropagation();
    setRemoveState({ index, id });
    setIsRemoveModalOpen(true);
  };

  const handleCloseRemoveModal = () => {
    setRemoveState(null);
    setIsRemoveModalOpen(false);
  };

  /** remove modal */
  const [isRemoveModalOpen, setIsRemoveModalOpen] = React.useState<
    boolean | null
  >(null);
  const confirmAction = async () => {
    const index = removeState?.index;
    const id = removeState?.id;

    if (id) {
      await removeFile?.(id);

      handleRemovePhoto(index);

      return;
    }

    handleRemovePhoto(index);
  };

  const files: Record<string, any>[] = watch(name) || Constants.mockArr;

  const onDrop = useCallback(
    (droppedFiles) => {
      let newFiles =
        mode === 'update' ? droppedFiles : [...(files || []), ...droppedFiles];

      if (mode === 'append') {
        newFiles = checkFilesDuplicates(newFiles);
      }

      newFiles.forEach((file: any) => {
        if (!!file._id) {
          return;
        }

        setFileTitles((prev: any) => ({
          ...prev,
          [file?.path]:
            fileTitles?.[file?.path] ||
            file?.title ||
            file?.originalFileName ||
            file?.name,
        }));
      });

      setValue(name, newFiles, { shouldValidate: true, shouldDirty: true });
    },
    [mode, files, setValue, name, setFileTitles, fileTitles],
  );

  const handleRemovePhoto = (index: number) => {
    files.splice(index, 1);
    setValue(name, files, { shouldValidate: true, shouldDirty: true });
    setIsRemoveModalOpen(null);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept,
    maxSize: maxSize,
  });

  let isErrorSize = false;
  let isErrorFormat = false;

  fileRejections &&
    fileRejections.forEach((item: Record<string, any>) => {
      const { file } = item;
      isErrorSize = isErrorSize || (!!maxSize && file.size > maxSize);
      isErrorFormat =
        isErrorFormat || (!!accept && accept?.indexOf?.(file.type) < 0);
    });

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  const formHelper = (field: string) => {
    return AnvUtils.formErrorHelper(field, errors, {});
  };

  const isSomeError = isErrorSize || isErrorFormat || formHelper(name);

  const handleOnChange = ({ id, title }: any) => {
    setFileTitles((prev: any) => ({
      ...prev,
      [id]: title,
    }));
  };
  const handleOnChangePublicState = ({ id, state }: any) => {
    setFilePublicState((prev: any) => ({
      ...prev,
      [id]: !!state,
    }));
  };

  return (
    <>
      <div className={classNames.container}>
        {label && <div className={classes.filesGroupHeader}>{label}</div>}

        {caption && <div className={classes.filesGroupCaption}>{caption}</div>}

        {!disabled && (
          <Grid container style={{ marginBottom: 16 }}>
            <div className={classes.dropZoneRoot} {...getRootProps()}>
              <input
                aria-label={name}
                id={name}
                multiple={multiple}
                name={name}
                accept={Array.isArray(accept) ? accept.join(',') : accept}
                disabled={disabled}
                {...rest}
                {...getInputProps()}
              />

              <div
                className={clsx(classes.container, {
                  [classes.containerActive]: isDragActive,
                  [classes.containerFullHeight]: !isSomeError,
                })}
                style={{ height: '100%' }}
              >
                <div>
                  <AnvIcon icon={'paperClip'} size={24} />
                </div>
                <div className={classes.containerText}>
                  <FormattedMessage
                    id="STORAGE_ZONE.COMMON.UPLOAD_DROPZONE.CAPTION"
                    defaultMessage="Choose a file or drag it here"
                  />
                </div>
              </div>
            </div>

            {isErrorSize && (
              <p
                className={clsx(classes.helper, {
                  [classes.helperError]: isErrorSize,
                })}
              >
                <FormattedMessage
                  id="APP.COMMON.VALIDATION.FILE.MAX_SIZE"
                  defaultMessage="Max file size is {value}"
                  values={{
                    value: AnvUtils.formatBytes(maxSize),
                  }}
                />
              </p>
            )}

            {isErrorFormat && (
              <p className={clsx(classes.helper, classes.helperError)}>
                <FormattedMessage
                  id="FILE.DROP_ZONE.ERROR.WRONG_FORMAT"
                  defaultMessage="The format is not acceptable"
                />
              </p>
            )}
            {!!formHelper(name) && (
              <p className={clsx(classes.helper, classes.helperError)}>
                {formHelper(name)}
              </p>
            )}
          </Grid>
        )}

        <Grid container direction={'column'}>
          {Object.keys(fileTitles).length > 0 &&
            !!files?.length &&
            !!files?.map &&
            files.map((file: Record<string, any>, index: number) => {
              const name = fileTitles?.[file?._id || file?.path] || '';

              const size = file?.fileSize || file?.size || 0;
              const sizeHumanable = AnvUtils.formatBytes(size);

              if (!!file._id) {
                return null;
              }

              const publicState = filePublicState[file?._id || file?.path];

              return (
                <Grid
                  item
                  container
                  wrap={'nowrap'}
                  alignItems={'baseline'}
                  key={file?._id || file?.path}
                  className={classes.gridItem}
                >
                  <AnvTextField
                    className={classes.filesControl}
                    fullWidth
                    name={file.name}
                    helperText={
                      <div className={classes.filesHelperTextSize}>
                        {sizeHumanable}
                      </div>
                    }
                    label={labelFileName}
                    onChange={(ev: any) => {
                      handleOnChange({
                        id: file?._id || file?.path,
                        title: ev.target.value,
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          classes={{ root: classes.inputAdornmentRoot }}
                        >
                          {`.${
                            // @ts-ignore
                            FILE_EXTENSION_BY_TYPE[file?.fileType || file?.type]
                          }`}
                        </InputAdornment>
                      ),
                    }}
                    defaultValue={name?.slice(0, name.lastIndexOf('.'))}
                  />

                  <AnvTooltip
                    title={formatMessage(
                      publicState
                        ? {
                            id: 'STORAGE_ZONE.COMMON.FILE.PUBLIC',
                            defaultMessage: 'Public',
                          }
                        : {
                            id: 'STORAGE_ZONE.COMMON.FILE.PRIVATE',
                            defaultMessage: 'Private',
                          },
                    )}
                    arrow
                  >
                    <IconButton
                      onClick={() =>
                        handleOnChangePublicState({
                          id: file._id || file?.path,
                          state: !publicState,
                        })
                      }
                    >
                      <AnvIcon
                        icon={publicState ? 'unlock' : 'lock'}
                        size={24}
                        color={publicState ? 'lightIcons' : 'primary'}
                      />
                    </IconButton>
                  </AnvTooltip>

                  <IconButton onClick={handleOpenRemoveModal(file._id, index)}>
                    <AnvIcon icon={'closeCross'} size={24} color={'error'} />
                  </IconButton>
                </Grid>
              );
            })}
        </Grid>

        {isRemoveModalOpen && (
          <ModalConfirm
            isOpen={isRemoveModalOpen}
            text={
              <FormattedMessage
                id="APP.COMMON.FILE.REMOVE.WARN"
                defaultMessage="Are you sure to remove this file?"
              />
            }
            cancelAction={handleCloseRemoveModal}
            confirmAction={confirmAction}
          />
        )}
      </div>
    </>
  );
};
