import React from 'react';
import { useTheme, Theme } from '@material-ui/core';
import ContainerAuth from '../components/layout/container-auth';
import { AnvForbiddenPage } from '@platform/front-lib';
import { TOOL_CODE } from '../constants';

const ForbiddenPage = () => {
  const {
    palette: { brand },
  } = useTheme<Theme>();
  return (
    <ContainerAuth>
      <AnvForbiddenPage
        shankColor={brand.main}
        lockBodyColor={brand.light}
        numbersColor={brand.main}
        toolCode={TOOL_CODE}
      />
    </ContainerAuth>
  );
};

export default ForbiddenPage;
