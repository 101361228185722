import {
  AnvTableWrapper,
  Constants,
  IsLoadingComponent,
  useCommonOffsetsStyles,
} from '@platform/front-lib';
import clsx from 'clsx';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { AnvBreadcrumbsConnected } from '../../../../../components/connected/anv-breadcrumbs-container';
import { useStores } from '../../../../../stores';
import { createBreadcrumbsFromVirtualFolders } from '../../../../../utils/tool';

import { prepareCompliancePath } from '../../../../../utils/virtula-folders';
import { SZFoldersBreadcrumbs } from '../../../../common/folders-breadcrumbs';
import { SZFoldersVirtualView } from '../../../../common/folders-virtual';
import { IVirtualFolder } from '../../../../common/folders-virtual/types';
import { ModuleContext } from '../../../../common/module-context';
import { useStylesContentWDrawer } from '../../../personal-zone/list-education-entities/view/styles';
import { ExplorerMainTabs } from '../../../tabs';
import { WorkZoneTitle } from '../../common';

interface IPageFEComplianceWorkZone {}

const MODULE_CODE = 'REPORT';

export const PageFEAnnualReportWorkZone: React.FC<IPageFEComplianceWorkZone> = observer(
  () => {
    const classes = useStylesContentWDrawer();
    const classesOffset = useCommonOffsetsStyles();
    const module = React.useContext(ModuleContext);
    const { schoolId, annualReportId } = useParams<{
      schoolId: string;
      annualReportId: string;
    }>();

    const path = `${Constants.TOOLS.COMPLIANCE}/${MODULE_CODE}/${schoolId}/${annualReportId}`;
    const pathModify = prepareCompliancePath(MODULE_CODE, path);
    const requestId = path;
    const {
      fileExplorerStore: { setActiveFolderId, setActiveFileId },
      authStores: { isOperatorLoggedIn },
      uiStores: { tool },

      workZoneVirtualFoldersStore: {
        getDataByPath,
        getList,
        getVirtualEntities,
        isFetching,
        getListMetaDataByRequestId,
      },
    } = useStores();

    const {
      dataList: dataListCompliance,
      isFetchingList,
    } = getListMetaDataByRequestId(requestId);

    const entities = getDataByPath(pathModify);

    React.useEffect(() => {
      return () => {
        setActiveFolderId(null);
        setActiveFileId(null);
      };
    }, [setActiveFolderId, setActiveFileId]);

    React.useEffect(() => {
      if (entities.length > 0) {
        return;
      }
      getVirtualEntities({
        path,
      });
    }, [entities.length, getVirtualEntities, path]);

    React.useEffect(() => {
      getList(
        {
          path,
        },
        {
          requestId,
        },
      );
    }, [getList, path, requestId]);

    const wZFoldersBreadcrumbsItems = React.useMemo(() => {
      return createBreadcrumbsFromVirtualFolders(entities, classes);
    }, [classes, entities]);

    const preparedVirtualFolders: IVirtualFolder[] = React.useMemo(() => {
      return (
        dataListCompliance?.map?.((virtualFolder) => {
          return {
            ...virtualFolder,
            to: window.location.pathname + `/${virtualFolder.to}`,
          };
        }) || []
      );
    }, [dataListCompliance]);

    return (
      <>
        <div data-clickaway={true} className={classes.mainContent}>
          <IsLoadingComponent isLoading={isFetchingList || isFetching} />
          <AnvBreadcrumbsConnected
            tool={tool}
            moduleCode={module}
            isOperator={isOperatorLoggedIn}
          />

          <AnvTableWrapper>
            <WorkZoneTitle />

            <ExplorerMainTabs />
            <div className={clsx(classesOffset.pb_2, classesOffset.pt_1)}>
              <SZFoldersBreadcrumbs items={wZFoldersBreadcrumbsItems} />
            </div>

            <div>
              <SZFoldersVirtualView virtualFolders={preparedVirtualFolders} />
            </div>
          </AnvTableWrapper>
        </div>
      </>
    );
  },
);
