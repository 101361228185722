import React from 'react';
import clsx from 'clsx';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import mixins from '../../../styles/mixins';

interface IProps extends DialogProps {
  title?: any;
  handleClose: () => void;
  open: boolean;
}

const AnvDialog: React.FC<IProps> = ({
  handleClose,
  open = false,
  title,
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Dialog
      {...rest}
      onClose={handleClose}
      open={open}
      classes={{
        ...(rest?.classes || {}),
        paper: clsx(classes.paper, rest?.classes?.paper),
      }}
    >
      <IconButton
        size={'small'}
        onClick={handleClose}
        className={classes.closeButton}
      >
        <CloseIcon />
      </IconButton>

      {!!title && <DialogTitle>{title}</DialogTitle>}
      {children}
    </Dialog>
  );
};

export default AnvDialog;

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      ...mixins.scrollBar,

      position: 'relative',
      padding: theme.spacing(3, 4),

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(4, 5),
        minWidth: 640,
      },
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(1.5),
      right: theme.spacing(1.5),
    },
  }),
);
