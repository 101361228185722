import React from 'react';
import clsx from 'clsx';

import { FormattedMessage } from 'react-intl';

import { TextBody } from '@platform/front-lib';

import { SZFolderVirtualViewGrid } from './folder';
import { useFoldersViewStyles } from '../folders/styles';
import { IVirtualFolder } from './types';

interface ISZFoldersVirtualViewProps {
  virtualFolders: IVirtualFolder[];

  handleCreateRootFolder?: (orgId: string) => () => void;
  showToolIcon?: boolean;
}

export const SZFoldersVirtualView: React.FC<ISZFoldersVirtualViewProps> = ({
  virtualFolders,
  handleCreateRootFolder,
  showToolIcon,
}) => {
  const classes = useFoldersViewStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.caption}>
          <TextBody semiBold>
            <FormattedMessage
              id="STORAGE_ZONE.FOLDERS.LIST.TITLE"
              defaultMessage="Folders"
            />
          </TextBody>
        </div>

        <div
          className={clsx(classes.gridContainer, {
            [classes.gridContainerShort]: virtualFolders?.length <= 2,
          })}
        >
          {virtualFolders?.map((folder) => {
            return (
              <SZFolderVirtualViewGrid
                key={folder._id}
                virtualFolder={folder}
                handleCreateRootFolder={handleCreateRootFolder}
                showToolIcon={showToolIcon}
              />
            );
          })}

          {!virtualFolders?.length && (
            <div>
              <TextBody color={'textSecondary'}>
                <FormattedMessage
                  id="STORAGE_ZONE.FOLDERS.LIST.EMPTY"
                  defaultMessage="This Folder does not contain any folders"
                />
              </TextBody>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
