import { autorun } from 'mobx';
import React from 'react';
import { observer } from 'mobx-react-lite';

import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import {
  AnvPaginationMui,
  Constants,
  useCheckIsTableView,
  useCommonTable,
  AnvTable,
  IsLoadingComponent,
  NoDataText,
  useQuery,
  useModal,
} from '@platform/front-lib';
import { ROUTES, SZ_ENTITY_KIND, TOOL_MODULES } from '../../../../constants';
import { useStateMiddleware } from '../../../../hooks/use-set-selected-value-middleware';
import { ISZFolderFile } from '../../../../models';
import { useStores } from '../../../../stores';
import { IParamsFolders } from '../../../../types/storage-zone';
import useClickPreventionOnDoubleClick from '../../../../utils/hook-useDoubleClick';
import { EntityDeleteDialog } from '../../folder-files/delete-modal';
import { EditFolderFileModal } from '../../folder-files/edit-modal';
import { FolderFilesActions } from '../../folder-files/list/actions';
import { MoveModal } from '../../folder-files/move-modal';
import { useRouteMatchEntity } from '../../folders-breadcrumbs/use-route-match-entity';
import { ModuleContext } from '../../module-context';

import { commonFilesColumns } from './columns';

import { useFilesViewStyles } from './styles';

interface ISZFilesViewProps {}

type TQueryParams = {
  search?: string;
};

export const SZSearchFilesView: React.FC<ISZFilesViewProps> = observer(() => {
  const history = useHistory();
  const params = useParams<IParamsFolders>();
  const query = useQuery<TQueryParams>();

  const searchValue = query?.search;

  const { folders, rootId } = params;

  const foldersArray = folders?.split('/');
  const lastId = foldersArray?.pop?.() as string;

  const requestId = `${rootId}_${lastId}_search-page`;

  const classes = useFilesViewStyles();

  const { formatMessage, locale } = useIntl();

  const module = React.useContext(ModuleContext);

  const isView = module === TOOL_MODULES.FILE_EXPLORER;

  const { isPersonalZoneEE } = useRouteMatchEntity();

  const { fileExplorerStore, fileOrganizerStore } = useStores();

  const storageStore =
    module === TOOL_MODULES.FILE_ORGANIZER
      ? fileOrganizerStore
      : fileExplorerStore;

  const {
    activeFolderId,
    activeFileId,
    setActiveFileId,
    setActiveFolderId,
    folderStore: { getChainToEntity },
    folderFilesStore: {
      getSearchList,
      getListData,
      setPage,
      setFilter,
      setSort,
      refetch,
      setRefetch,
      dataMap,
      dropListStore,
      updatePublicState,
    },
  } = storageStore;

  const {
    fileStore: { updateFile },
    folderStore: { updateFolder },
  } = fileOrganizerStore;

  const {
    isLoadedList,
    dataListFromMap: entities,
    pagination,
    storeFilter,
    storeSorting,
    isFetchingList,
  } = getListData(requestId);

  // watch for updates in dataMap
  const autorunId = autorun(() => dataMap);

  React.useEffect(() => {
    return () => {
      dropListStore(requestId);
      autorunId();
    };

    // on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    sorting = '',
    page,
    filterState,
    filterMemo,
    memoQueryFilter = '',
    handleChangeFilterDate,
    handleChangeFilter,
    handleChangePhone,
    handleChangePage,
  } = useCommonTable({
    defaultFilterState: Constants.mockObj,
    pagination,
    setPage,
    setFilter,
    setSort,
    storeFilter,
    storeSorting,
    requestId,
  });

  const getChainToEntityCb = React.useCallback(
    ({ id }: { id: string }) => {
      getChainToEntity(
        { id, path: true },
        {
          successFunc: (data: string[]) => {
            const foldersPath = data.join('/');

            const url = generatePath(
              isPersonalZoneEE
                ? ROUTES.MODULE_FILE_EXPLORER_VIEW_EE_PREFIX
                : ROUTES.MODULE_FILE_ORGANIZER_VIEW_PREFIX,
              {
                rootId: params.rootId,
                folders: foldersPath || undefined,
              },
            );
            history.push(decodeURIComponent(url));
          },
        },
      );
    },
    [getChainToEntity, history, isPersonalZoneEE, params.rootId],
  );

  const getSearchEntities = React.useCallback(() => {
    if (!searchValue) {
      return;
    }
    if (!lastId) {
      return;
    }

    getSearchList(
      {
        id: lastId,
        page: +page,
        sorting: !!sorting ? enhanceSorting(sorting) : sorting,
        filter: filterMemo,
        limit: Constants.PAGINATION_LIMIT,
        search: searchValue,
      },
      {
        requestId,
      },
    );

    if (refetch) {
      setRefetch(false);
    }
  }, [
    searchValue,
    lastId,
    getSearchList,
    page,
    sorting,
    filterMemo,
    requestId,
    refetch,
    setRefetch,
  ]);

  React.useEffect(() => {
    getSearchEntities();
  }, [getSearchEntities]);

  const [selectedId, setSelectedIdMiddleware] = useStateMiddleware<
    string | null | undefined
  >(null);

  const {
    isOpen: isOpenDelete,
    handleOpenModal: handleOpenModalDelete,
    handleCloseModal: handleCloseModalDelete,
  } = useModal();

  const {
    isOpen: isOpenMove,
    handleOpenModal: handleOpenModalMove,
    handleCloseModal: handleCloseModalMove,
  } = useModal();

  const {
    isOpen: isOpenEdit,
    handleOpenModal: handleOpenModalEdit,
    handleCloseModal: handleCloseModalEdit,
  } = useModal();

  /** COLUMNS  */
  const columns = React.useMemo(() => {
    return commonFilesColumns(locale, formatMessage, {
      isView,
      getChainToEntityCb,
      classes,
    });
  }, [isView, classes, formatMessage, locale, getChainToEntityCb]);

  const data = React.useMemo(() => {
    return entities || [];
  }, [entities]);

  const handleChangePublicAccess = React.useCallback(
    (id: string) => {
      const entity = dataMap[id];
      const isFile = entity?.kind === SZ_ENTITY_KIND.FILE;

      const publicAccess = !entity.publicAccess;

      if (isFile) {
        updateFile(
          {
            id: entity._id,
            title: entity.name,
            publicAccess,
          },
          {
            successFunc: () => {
              updatePublicState(id, publicAccess);
              // getEntities();
            },
          },
        );

        return;
      }

      updateFolder(
        {
          id: entity._id,
          folderName: entity.name,
          publicAccess,
        },
        {
          successFunc: () => {
            updatePublicState(id, publicAccess);
            // getEntities();
          },
        },
      );
    },
    [dataMap, updateFile, updateFolder, updatePublicState],
  );

  const TableActionComponent = React.useCallback(
    ({ data }) => {
      return (
        <FolderFilesActions
          data={data}
          handleChangePublicAccess={handleChangePublicAccess}
          handleOpenDeleteModal={setSelectedIdMiddleware(handleOpenModalDelete)}
          handleOpenMoveModal={setSelectedIdMiddleware(handleOpenModalMove)}
          handleEditFolder={setSelectedIdMiddleware(handleOpenModalEdit)}
          handleEditFile={setSelectedIdMiddleware(handleOpenModalEdit)}
          setActiveFileId={setActiveFileId}
          setActiveFolderId={setActiveFolderId}
        />
      );
    },
    [
      setActiveFileId,
      setActiveFolderId,
      setSelectedIdMiddleware,
      handleChangePublicAccess,
      handleOpenModalDelete,
      handleOpenModalEdit,
      handleOpenModalMove,
    ],
  );

  const { isShowTable } = useCheckIsTableView({
    tableDataIsLoaded: isLoadedList,
    tableDataLength: entities?.length || 0,
    memoQueryFilter,
  });

  const handleFileClick = (entity: ISZFolderFile) => {
    if (entity.kind === SZ_ENTITY_KIND.FOLDER) {
      setActiveFolderId(entity._id);
      return;
    }
    setActiveFileId(entity._id);
  };
  const handleFileDoubleClick = (entity: ISZFolderFile) => {
    if (entity.kind === SZ_ENTITY_KIND.FOLDER) {
      getChainToEntityCb({ id: entity._id });
      return;
    }

    setActiveFileId(entity._id);
  };

  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
    handleFileClick,
    handleFileDoubleClick,
  );

  return (
    <>
      {isFetchingList && <IsLoadingComponent isLoading={isFetchingList} />}

      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <AnvTable
            size={'small'}
            requestId={requestId}
            filterMemo={filterMemo}
            setFilter={setFilter}
            isShowTable={isShowTable}
            filterState={filterState}
            handleChangeFilterDate={handleChangeFilterDate}
            handleChangeFilter={handleChangeFilter}
            handleChangePhone={handleChangePhone}
            isLoadedList={isLoadedList}
            //common
            data={data}
            columns={columns}
            querySort={sorting}
            setSort={setSort}
            TableActionComponent={TableActionComponent}
            onRowClick={handleClick}
            onRowDoubleClick={handleDoubleClick}
            selectedRow={activeFileId || activeFolderId}
            noDataMessage={
              <FormattedMessage
                id="STORAGE_ZONE.ORGANIZER.SEARCH.LIST.EMPTY_LIST"
                defaultMessage="Files and folders not found for the given search criteria"
              />
            }
          />

          {!isLoadedList && (
            <NoDataText
              topOffset={24}
              justify={'flex-start'}
              message={
                <FormattedMessage
                  id="STORAGE_ZONE.ORGANIZER.SEARCH.LIST.WARN_MESSAGE"
                  defaultMessage="Enter at least one character into search input to start search documents"
                />
              }
            />
          )}

          <AnvPaginationMui
            pagination={pagination}
            totalPages={pagination?.totalPages}
            handleChangePage={handleChangePage}
            page={+page}
          />
        </div>
      </div>

      {isOpenEdit && selectedId && (
        <EditFolderFileModal
          id={selectedId}
          open={isOpenEdit}
          handleClose={setSelectedIdMiddleware(handleCloseModalEdit)}
        />
      )}

      {isOpenMove && selectedId && (
        <MoveModal
          open={isOpenMove}
          id={selectedId}
          handleClose={setSelectedIdMiddleware(handleCloseModalMove)}
        />
      )}

      {isOpenDelete && selectedId && (
        <EntityDeleteDialog
          handleCloseModal={setSelectedIdMiddleware(handleCloseModalDelete)}
          id={selectedId}
          open={isOpenDelete}
        />
      )}
    </>
  );
});

const enhanceSorting = (sorting: string) => {
  return '-kind ' + sorting + ' _id';
};
