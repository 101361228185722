import { observer } from 'mobx-react-lite';
import React from 'react';
import { generatePath } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';

import { AnvTableWrapper, useModuleAccessHistory } from '@platform/front-lib';

import { SZFoldersVirtualView } from '../../common/folders-virtual';
import {
  PZ_ROOT_VIRTUAL_FOLDERS,
  pzRootVirtualFolders,
  ROUTES,
} from '../../../constants';
import { ModuleContext } from '../../common/module-context';
import { SZBreadcrumbs } from '../../common/SZBreadcrumbs';
import { useStores } from '../../../stores';
import { ExplorerMainTabs } from '../tabs';
import { MyStorageZoneTitle } from '../common/title';

interface IPersonalMainRootProps {
  children?: React.ReactNode;
}

const ADDITIONAL_INFO_BY_VIRTUAL_FOLDER_ID = {
  [PZ_ROOT_VIRTUAL_FOLDERS.EDU_ENTITIES]: {
    folderName: (
      <FormattedMessage
        id={'STORAGE_ZONE.FOLDERS.LIST.EDUCATION_ENTITIES'}
        defaultMessage={'Education Entities'}
      />
    ),
    to: generatePath(ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_EDUCATION, {}),
  },
  [PZ_ROOT_VIRTUAL_FOLDERS.FAMILY_CIRCLE]: {
    to: generatePath(
      ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_FAMILY_CIRCLE,
      {},
    ),
  },
  [PZ_ROOT_VIRTUAL_FOLDERS.GOVERNING_BOARDS]: {
    folderName: (
      <FormattedMessage
        id={'STORAGE_ZONE.COMMON.VIRTUAL_FOLDER.GOVERNANCE_TOOL.NAME'}
        defaultMessage={'Governing Boards'}
      />
    ),
    to: generatePath(
      ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_GOVERNING_BOARDS,
      {},
    ),
  },
};

const requestId = pzRootVirtualFolders;

export const PersonalMainRoot: React.FC<IPersonalMainRootProps> = observer(
  () => {
    const module = React.useContext(ModuleContext);

    useModuleAccessHistory({
      moduleCodeFallback: module,
    });

    const {
      fileExplorerStore: {
        setActiveFolderId,
        setActiveFileId,
        personalZoneVirtualFoldersStore: { getList, getListData },
      },
      metaStores: {
        toolsStore: { getTools, isLoaded: isToolsLoaded },
      },
    } = useStores();

    React.useEffect(() => {
      return () => {
        setActiveFileId(null);
        setActiveFolderId(null);
      };
    }, [setActiveFolderId, setActiveFileId]);

    React.useEffect(() => {
      if (!isToolsLoaded) {
        getTools();
      }
    }, [isToolsLoaded, getTools]);

    const { dataListFromMap: dataList } = getListData(requestId);

    React.useEffect(() => {
      return () => {
        setActiveFolderId(null);
        setActiveFileId(null);
      };
    }, [setActiveFolderId, setActiveFileId]);

    React.useEffect(() => {
      getList(
        {
          path: '',
        },
        {
          requestId: requestId,
        },
      );
    }, [getList]);

    const preparedVirtualFolders = () => {
      return (
        dataList?.map?.((virtualFolder) => {
          const additionalInfo =
            //@ts-ignore
            ADDITIONAL_INFO_BY_VIRTUAL_FOLDER_ID[virtualFolder._id];

          return {
            ...virtualFolder,
            ...additionalInfo,
          };
        }) || []
      );
    };

    return (
      <>
        <SZBreadcrumbs />

        <AnvTableWrapper>
          <MyStorageZoneTitle />

          <ExplorerMainTabs />

          <SZFoldersVirtualView
            virtualFolders={preparedVirtualFolders()}
            showToolIcon
          />
        </AnvTableWrapper>
      </>
    );
  },
);
