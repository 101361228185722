import { createStyles, makeStyles } from '@material-ui/core/styles';
import { BORDER_RADIUS } from '../../../../styles/constants';
import mixins from '../../../../styles/mixins';

export const FOLDER_STYLES = {
  FOLDER_MIN_WIDTH: 250,
  FOLDER_MAX_WIDTH: 400,
  FOLDER_HEIGHT: 72,
};

export const useFoldersViewStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(4),
    },

    caption: {
      marginBottom: theme.spacing(1),
    },

    gridContainer: {
      display: 'grid',
      gridGap: theme.spacing(1),
      gridTemplateColumns: `repeat(auto-fit, minmax(${FOLDER_STYLES.FOLDER_MIN_WIDTH}px, 1fr))`,
    },
    gridContainerShort: {
      gridTemplateColumns: `repeat(auto-fit, minmax(${FOLDER_STYLES.FOLDER_MIN_WIDTH}px, ${FOLDER_STYLES.FOLDER_MAX_WIDTH}px))`,
    },

    gridElement: {
      // maxWidth: FOLDER_STYLES.FOLDER_MAX_WIDTH,

      padding: theme.spacing(1, 2, 1, 1.5),
      height: FOLDER_STYLES.FOLDER_HEIGHT,

      backgroundColor: '#FFFFFF',
      border: '1px solid rgba(3, 57, 61, 0.15)',
      borderRadius: BORDER_RADIUS,

      cursor: 'pointer',

      transition: theme.transitions.create('borderColor', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),

      '&:hover': {
        borderColor: theme.palette.brand.main,
      },
    },

    gridElementActive: {
      border: `1px solid ${theme.palette.brand.main}`,
      boxShadow: `0px 4px 8px rgba(92, 140, 192, 0.16), 
        0px 0px 2px rgba(92, 140, 192, 0.24), 
        0px 0px 1px rgba(92, 140, 192, 0.16)`,
    },

    content: {
      maxWidth: '100%',
      overflow: 'hidden',
    },
    textFolderName: {
      ...mixins.textOverflow,
      maxWidth: '100%',
      display: 'inline-block',

      userSelect: 'none',
    },
    textFolderDate: {
      ...mixins.textOverflow,
      maxWidth: '100%',
      display: 'inline-block',

      color: theme.palette.text.disabled,
      userSelect: 'none',
    },
  }),
);
