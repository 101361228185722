import { CommonListAdvancedStoreCRUD } from '@platform/front-lib';
import { IActionMeta } from '@platform/front-lib/dist/stores/_helpers';
import { action, makeObservable, observable } from 'mobx';
import { ISZRootFolder } from '../../../../models';

import { GoverningBoardsService } from './service';
import { VirtualFoldersStore } from './virtual-folders/store';

export class GoverningBoardsStore extends CommonListAdvancedStoreCRUD<
  GoverningBoardsService,
  ISZRootFolder
> {
  virtualFoldersStore: VirtualFoldersStore;

  error: Record<string, any> | null | string = null;

  getRootList = async (meta?: IActionMeta) => {
    return this.getList({ rootFolders: true }, meta);
  };

  isFetching: boolean = false;
  isLoaded: boolean = false;
  isError: boolean = false;

  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };

  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();
  };

  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();
  };

  constructor(
    module: string,
    subModule: string,
    sessionKey?: string,
    disableAutoSave?: boolean,
  ) {
    super({ sessionKey, disableAutoSave });
    this.service = new GoverningBoardsService(
      module,
      'governing-boards',
      subModule,
    );
    this.virtualFoldersStore = new VirtualFoldersStore(
      module,
      subModule,
      'virtual-folders-governing-boards',
    );

    makeObservable(this, {
      isFetching: observable,
      isLoaded: observable,
      isError: observable,
      error: observable,

      getRootList: action,
      setLoading: action,
      setError: action,
      setLoaded: action,
    });
  }
}
