import { createStyles, makeStyles } from '@material-ui/core/styles';
const fixedHeightOfGroupHeader = 33;
export const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      paddingLeft: 16,
      paddingRight: 16,
    },

    lastControl: {
      '&&': {
        marginBottom: 0,
      },
    },

    autocompleteGroupHeader: {
      fontFamily: "'Inter', sans-serif",
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 13,
      lineHeight: '22px',

      letterSpacing: '-0.18px',

      color: theme.palette.text.secondary,
    },

    mainTabs: {
      marginBottom: theme.spacing(2),
    },

    historyGroup: {
      fontWeight: 600,
    },
    autocompleteFooter: {
      marginTop: 16,
      marginBottom: 24,
    },
    autocompleteHeaderButton: {
      '&&&': {
        padding: 0,
        fontSize: 13,
        lineHeight: '22px',
      },
    },
    autocompleteFooterButton: {
      '&&&': {
        padding: theme.spacing(0.5),
        fontFamily: "'Inter', sans-serif",
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: ' 24px',
        letterSpacing: '-0.18px',
      },
    },

    schoolAddressCaption: {
      fontFamily: "'Inter', sans-serif",
      fontStyle: 'italic',
      fontWeight: 'normal',
      fontSize: 13,
      lineHeight: '22px',

      color: theme.palette.text.disabled,
    },

    recentlyButton: {
      '&&&': {
        fontSize: 15,
        fontWeight: 700,
        lineHeight: '18px',
      },
    },

    expandMore: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.short,
      }),
    },
    expandRotate: {
      transform: 'rotate(180deg)',
      transformOrigin: '50% 50%',
    },
    schoolName: {
      maxWidth: 350,
      whiteSpace: 'normal',

      [theme.breakpoints.down(375)]: {
        maxWidth: 275,
      },
    },
    fixedHeader: {
      paddingTop: 8,
      position: 'fixed',
      width: 'inherit',
      zIndex: 5,
      backgroundColor: 'white',
      height: fixedHeightOfGroupHeader,
    },
    customList: {
      paddingTop: 'unset',
      width: '400px !important',
      [theme.breakpoints.down(960)]: {
        width: '350px !important',
      },
      [theme.breakpoints.down(756)]: {
        width: '300px !important',
      },
      [theme.breakpoints.down(425)]: {
        width: '275px !important',
      },
    },
    customListItem: {
      justifyContent: 'space-between',
      padding: 16,
      height: 'auto',
      whiteSpace: 'break-spaces',
    },
    replaceRectangle: {
      height: fixedHeightOfGroupHeader,
    },

    emptyRecentlyViewText: {
      padding: theme.spacing(1, 2),
    },
  }),
);
