import React, { Component } from 'react';
import { Grid, MuiThemeProvider } from '@material-ui/core';
import { AnvButton, AnvIcon, TextBody, TextH1 } from '@platform/front-lib';
import { createMuiTheme } from '@material-ui/core/styles';
import { themePlain } from '../styles/theme';
import { palette } from '../styles/palette';

// @ts-ignore
const newTheme = createMuiTheme(themePlain(palette));

export default class ErrorBoundary extends Component {
  state = {
    error: '',
    errorInfo: '',
    hasError: false,
  };

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ errorInfo });
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return (
        <MuiThemeProvider theme={newTheme}>
          <Grid
            container
            direction={'column'}
            alignItems={'center'}
            spacing={2}
          >
            <Grid item>
              <AnvIcon icon={'deny'} size={128} />
            </Grid>

            <Grid item>
              <TextH1>Error has occurred</TextH1>
            </Grid>
            <Grid item>
              <TextBody>
                Please try reload the page. Or go to the home page
              </TextBody>
            </Grid>

            <Grid item>
              <Grid
                container
                spacing={2}
                justify={'center'}
                alignItems={'center'}
              >
                <Grid item>
                  <AnvButton
                    onClick={() => {
                      window.location.reload();
                    }}
                    variant={'contained'}
                    color={'primary'}
                  >
                    Reload
                  </AnvButton>
                </Grid>
                <Grid item>
                  <AnvButton variant={'contained'} color={'primary'}>
                    GO TO HOME
                  </AnvButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MuiThemeProvider>
      );
    }

    return this.props.children;
  }
}
