import { IFile } from '@platform/front-lib/dist/models';
import React from 'react';
import { Divider, Grid, IconButton, MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { GridDirection } from '@material-ui/core/Grid/Grid';

import { formatDistance, differenceInSeconds } from 'date-fns';

import {
  AnvDateTimeIcons,
  AnvIcon,
  AnvListContainer,
  AnvListData,
  AnvMenu,
  AnvUtils,
  TextBody,
  TextH4,
  useMenu,
} from '@platform/front-lib';

import { useStores } from '../../../stores';
import { getFileName } from '../../../utils';
import {
  isCommonFileObject,
  isSZFileObject,
  isSZFolderFile,
} from '../../../utils/tool';
import { useRouteMatchEntity } from '../folders-breadcrumbs/use-route-match-entity';

import { ModuleContext } from '../module-context';

import type {
  ISZFileObject,
  ISZFolderFile,
  ISZObjectFolder,
} from '../../../models';
import type { IParamsFolders } from '../../../types/storage-zone';
import { AnvFileView } from '../../../components/shared/anv-file';

import { envConst, TOOL_MODULES } from '../../../constants';

import { useRightDrawerStyles } from './styles';

interface IFoldersDrawerProps {
  folder: ISZObjectFolder | null;
  file: ISZFileObject | IFile | ISZFolderFile;
}

declare type TMd = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 12;

interface IListDataLocal {
  labelMd?: TMd;
  label?: string | JSX.Element | null;
  value?: string | JSX.Element | null;
  direction?: GridDirection;
  omitColon?: boolean;
  omitMargin?: boolean;
}
const LABEL_MD = 4;
const listDataConfig: IListDataLocal = {
  direction: 'row',
  labelMd: LABEL_MD,
  omitMargin: true,
};

const durationFromDate = (date: any) => {
  if (!date) {
    return null;
  }
  return duration(differenceInSeconds(new Date(), new Date(date)));
};

const duration = (s: number) =>
  formatDistance(0, s * 1000, { includeSeconds: true });

export const ViewDrawerFileData: React.FC<IFoldersDrawerProps> = observer(
  ({ file }) => {
    const classes = useRightDrawerStyles();

    const { locale } = useIntl();

    const params = useParams<IParamsFolders>();

    const { rootId } = params;

    const module = React.useContext(ModuleContext);
    const isOrganizer = module === TOOL_MODULES.FILE_ORGANIZER;

    const { isWorkZoneCompliance } = useRouteMatchEntity();

    const {
      authStores: { token, tokenOperator },
      fileExplorerStore,
      fileOrganizerStore,
      complianceFilesStore: { dataMap: dataMapComplianceFiles },
    } = useStores();

    const storageStore = isOrganizer ? fileOrganizerStore : fileExplorerStore;

    const {
      setActiveFileId,
      folderRootStore: { dataMap: dataMapFolderRoot },
      fileStore: { mapDetailed, getOneDetailed },
    } = storageStore;

    const szFileEntityDetailed = mapDetailed[file._id];
    const complianceFileEntityDetailed = dataMapComplianceFiles[file._id];

    const fileEntityDetailed =
      szFileEntityDetailed || complianceFileEntityDetailed;

    const isSZFile = isSZFileObject(file);

    const apiUrl = isSZFile
      ? `${envConst.apiUrl}/file-organizer/files/get`
      : `${envConst.apiUrl}/files-common/get`;

    React.useEffect(() => {
      if (!szFileEntityDetailed && isSZFile) {
        getOneDetailed({ id: file._id });
      }
    }, [szFileEntityDetailed, isSZFile, file?._id, getOneDetailed, file]);

    const fileName = getFileName(file);

    const { closeMenu, handleClose: handleCloseMenu } = useMenu();

    /** menu folder end */

    const rootFolderEntity = dataMapFolderRoot?.[rootId];

    const rootEntityTitle = AnvUtils.getNameByLangNullable(
      rootFolderEntity?.schoolRef ||
        rootFolderEntity?.organizationRef ||
        rootFolderEntity?.govBodyRef,
      'name',
      locale,
    );

    const handleCloseDrawer = () => {
      setActiveFileId(null);
    };

    return (
      <>
        <div className={classes.headerBlock}>
          <TextH4 className={classes.header}>
            <Grid
              item
              className={classes.headerTitleItem}
              style={{
                flex: '1',
              }}
            >
              {fileName}
            </Grid>

            <Grid item>
              <div className={classes.headerMenu}>
                <AnvMenu
                  size={'small'}
                  closeMenu={closeMenu}
                  hideMenuWhenChildrenEmpty
                  customIcon={
                    <AnvIcon
                      icon={'tableAction'}
                      color={'white'}
                      opacity={0.5}
                    />
                  }
                >
                  {isWorkZoneCompliance && (
                    <MenuItem
                      onClick={() => {
                        AnvUtils.downloadFile({
                          url: `${apiUrl}/${file?._id}`,
                          filename: fileName || 'file',
                          token: token || tokenOperator || undefined,
                        })();
                        handleCloseMenu();
                      }}
                    >
                      <AnvIcon icon={'download'} />
                      <span>
                        <FormattedMessage
                          id={'STORAGE_ZONE.FILES.DRAWER.MENU.DOWNLOAD'}
                          defaultMessage={'Download'}
                        />
                      </span>
                    </MenuItem>
                  )}
                </AnvMenu>
              </div>
            </Grid>

            <Grid item>
              <IconButton
                size={'small'}
                onClick={handleCloseDrawer}
                className={classes.closeButtonStatic}
              >
                <AnvIcon
                  icon={'closeCross'}
                  color={'white'}
                  opacity={0.5}
                  size={24}
                />
              </IconButton>
            </Grid>
          </TextH4>
        </div>

        <div className={classes.body}>
          <div className={classes.bigIcon}>
            <AnvFileView
              existsFileUrl={apiUrl}
              file={file}
              placeholder={<AnvIcon icon={'fileListDefault'} size={160} />}
            />
          </div>

          <Divider className={classes.bigIconDivider} />

          <div>
            <div className={classes.bodyHeader}>
              <TextBody semiBold>
                <FormattedMessage
                  id="STORAGE_ZONE.FILES.DRAWER.CAPTION"
                  defaultMessage="Object Storage Considerations:"
                />
              </TextBody>
            </div>

            <AnvListContainer>
              <AnvListData
                {...listDataConfig}
                label={
                  <FormattedMessage
                    id="STORAGE_ZONE.FILES.DRAWER.LABEL.TITLE"
                    defaultMessage="Title"
                  />
                }
                value={<span className={classes.listValue}>{fileName}</span>}
              />

              {file?.fileType && (
                <AnvListData
                  {...listDataConfig}
                  label={
                    <FormattedMessage
                      id="STORAGE_ZONE.FILES.DRAWER.LABEL.FILE_TYPE"
                      defaultMessage="File Type"
                    />
                  }
                  value={
                    <span className={classes.listValue}>{file?.fileType}</span>
                  }
                />
              )}

              {rootEntityTitle && (
                <AnvListData
                  {...listDataConfig}
                  label={
                    <FormattedMessage
                      id="STORAGE_ZONE.FILES.DRAWER.LABEL.OWNERSHIP"
                      defaultMessage="Ownership"
                    />
                  }
                  value={
                    <span className={classes.listValue}>{rootEntityTitle}</span>
                  }
                />
              )}

              <AnvListData
                {...listDataConfig}
                label={
                  <FormattedMessage
                    id="STORAGE_ZONE.FILES.DRAWER.LABEL.SIZE"
                    defaultMessage="Size"
                  />
                }
                value={
                  <span className={classes.listValue}>
                    {AnvUtils.formatBytes(
                      fileEntityDetailed?.fileSize || file?.fileSize || 0,
                    )}
                  </span>
                }
              />

              {isOrganizer && (
                <AnvListData
                  {...listDataConfig}
                  label={
                    <FormattedMessage
                      id="STORAGE_ZONE.FILES.DRAWER.LABEL.VISIBILITY"
                      defaultMessage="Visibility"
                    />
                  }
                  value={
                    <span className={classes.listValue}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AnvIcon
                          icon={
                            fileEntityDetailed?.attributesRef?.publicAccess
                              ? 'unlock'
                              : 'lock'
                          }
                          size={16}
                          color={
                            fileEntityDetailed?.attributesRef?.publicAccess
                              ? 'lightIcons'
                              : 'primary'
                          }
                        />
                        <span style={{ marginLeft: 4 }}>
                          {fileEntityDetailed?.attributesRef?.publicAccess ? (
                            <FormattedMessage
                              id="STORAGE_ZONE.COMMON.FILE.PUBLIC"
                              defaultMessage="Public"
                            />
                          ) : (
                            <FormattedMessage
                              id="STORAGE_ZONE.COMMON.FILE.PRIVATE"
                              defaultMessage="Private"
                            />
                          )}
                        </span>
                      </div>
                    </span>
                  }
                />
              )}

              {isOrganizer && (
                <AnvListData
                  {...listDataConfig}
                  label={
                    <FormattedMessage
                      id="STORAGE_ZONE.FILES.DRAWER.LABEL.COST"
                      defaultMessage="Cost"
                    />
                  }
                  value={<span className={classes.listValue}>$0</span>}
                />
              )}

              <AnvListData
                {...listDataConfig}
                label={
                  <FormattedMessage
                    id="STORAGE_ZONE.FILES.DRAWER.LABEL.AGE"
                    defaultMessage="Age"
                  />
                }
                value={
                  <span className={classes.listValue}>
                    {durationFromDate(
                      fileEntityDetailed?.createdAt || file?.createdAt,
                    )}
                  </span>
                }
              />

              {((isSZFileObject(fileEntityDetailed) &&
                fileEntityDetailed?.uploaderRef) ||
                (isCommonFileObject(fileEntityDetailed) &&
                  fileEntityDetailed?.userRef)) && (
                <AnvListData
                  {...listDataConfig}
                  label={
                    <FormattedMessage
                      id="STORAGE_ZONE.FILES.DRAWER.LABEL.UPLOADED_BY"
                      defaultMessage="Uploaded By"
                    />
                  }
                  value={
                    <span className={classes.listValue}>
                      {AnvUtils.formatFullName({
                        entity:
                          (isSZFileObject(fileEntityDetailed) &&
                            fileEntityDetailed?.uploaderRef) ||
                          (isCommonFileObject(fileEntityDetailed) &&
                            fileEntityDetailed?.userRef) ||
                          undefined,
                      })}
                    </span>
                  }
                />
              )}

              {isOrganizer && (
                <AnvListData
                  {...listDataConfig}
                  label={
                    <FormattedMessage
                      id="STORAGE_ZONE.FILES.DRAWER.LABEL.UPLOAD_TIME"
                      defaultMessage="Upload Time"
                    />
                  }
                  value={
                    <span className={classes.listValue}>
                      <AnvDateTimeIcons
                        date={fileEntityDetailed?.createdAt || file?.createdAt}
                        isTwoRow={false}
                      />
                    </span>
                  }
                />
              )}
            </AnvListContainer>
          </div>
        </div>
      </>
    );
  },
);
