import React from 'react';

import { observer } from 'mobx-react-lite';
import { generatePath, useParams } from 'react-router-dom';

import { AnvBreadcrumbs, AnvIcon, AnvUtils } from '@platform/front-lib';

import type { TBreadcrumbsItem } from '@platform/front-lib/dist/components/shared/anv-tool-breadcrumbs';

import { IParamsFolders } from '../../../types/storage-zone';

import { useStores } from '../../../stores';

import { ROUTES, TOOL_MODULES } from '../../../constants';
import { useFoldersBreadcrumbsStyles } from './styles';
import { ModuleContext } from '../module-context';
import { useRouteMatchEntity } from './use-route-match-entity';
import { FormattedMessage, useIntl } from 'react-intl';
import { SZBreadcrumbItemWrapperWIcon } from './common-item-wrapper';

export type TBreadcrumbsFoldersItem = TBreadcrumbsItem & {
  toolCode?: string;
  moduleCode?: string;
  showToolName?: boolean;
};

interface ISZFoldersViewProps {
  items?: TBreadcrumbsFoldersItem[];
  lastItems?: TBreadcrumbsFoldersItem[];
  rootLink?: string;
  rootFolderName?: string;
}

export const SZFoldersBreadcrumbs: React.FC<ISZFoldersViewProps> = observer(
  ({ items, rootLink, rootFolderName, lastItems }) => {
    const classes = useFoldersBreadcrumbsStyles();

    const { locale } = useIntl();

    const {
      routeView,
      isWorkZoneCompliance,
      isSearchPage,
      isPersonalZoneGovernance,
      isWorkZoneGovernance,
    } = useRouteMatchEntity();

    const params = useParams<IParamsFolders>();

    const { folders, rootId } = params;

    const {
      fileOrganizerStore,
      fileExplorerStore,
      metaStores: {
        toolsStore: {
          dataMap: toolsDataMap,
          getTools,
          isLoaded: isToolsLoaded,
        },
      },
    } = useStores();

    React.useEffect(() => {
      if (!isToolsLoaded) {
        getTools();
      }
    }, [isToolsLoaded, getTools]);

    const module = React.useContext(ModuleContext);
    const isOrganizer = module === TOOL_MODULES.FILE_ORGANIZER;
    const storageStore = isOrganizer ? fileOrganizerStore : fileExplorerStore;

    const {
      folderStore: { dataMapShort, dataMap },
    } = storageStore;

    const foldersArray = React.useMemo(() => {
      return folders?.split?.('/') || [];
    }, [folders]);

    let link =
      isPersonalZoneGovernance || isWorkZoneGovernance
        ? rootId &&
          generatePath(
            isWorkZoneGovernance
              ? ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_GOVERNING_BOARDS_VIEW
              : ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_GOVERNING_BOARDS_VIEW,
            {
              rootId: rootId,
            },
          )
        : isWorkZoneCompliance
        ? generatePath(ROUTES.MODULE_FILE_EXPLORER_WORK_LIST)
        : rootId &&
          generatePath(
            routeView ||
              (isOrganizer
                ? ROUTES.MODULE_FILE_ORGANIZER_VIEW
                : ROUTES.MODULE_FILE_EXPLORER_VIEW_EE),
            {
              rootId: rootId,
            },
          );

    const total = foldersArray?.length;

    const itemsNew: TBreadcrumbsItem[] = [
      {
        to: isOrganizer ? (total === 1 ? undefined : link) : rootLink || link,
        component: (
          <AnvIcon
            icon={'fileCabinet'}
            color={
              isOrganizer
                ? foldersArray.length === 1
                  ? 'lightIcons'
                  : 'inherit'
                : 'inherit'
            }
            // color={'inherit'}
            size={32}
            offset={1}
          />
        ),
      },
    ];

    if (items?.length) {
      itemsNew.push(
        ...items.map((item) => ({
          ...item,
          component: item.toolCode ? (
            <SZBreadcrumbItemWrapperWIcon>
              {AnvUtils.getNameByLangNullable(
                toolsDataMap[item.toolCode],
                'name',
                locale,
              )}
            </SZBreadcrumbItemWrapperWIcon>
          ) : (
            item.component
          ),
        })),
      );
    }

    itemsNew.push(
      ...(foldersArray?.map((item, index) => {
        link += `/${item}`;

        const isLast = total === index + 1;

        const folderEntity = dataMapShort?.[item] || dataMap?.[item];

        const folderName = folderEntity?.parentFolder
          ? folderEntity?.folderName || 'Folder'
          : rootFolderName || folderEntity?.folderName || 'Root';

        let to: string | undefined = link;

        if (isLast && !isSearchPage && !lastItems?.length) {
          to = undefined;
        }

        return {
          to,
          component: (
            <div className={classes.item}>
              <AnvIcon icon={'folderBigOpen'} size={32} offset={1} />
              {folderName}
            </div>
          ),
        };
      }) || []),
    );

    if (isSearchPage) {
      itemsNew.push({
        component: (
          <div className={classes.item}>
            <AnvIcon icon={'search'} size={32} offset={1} color={'primary'} />
            <FormattedMessage
              id={'STORAGE_ZONE.FOLDERS.BREADCRUMBS.SEARCH_RESULTS'}
              defaultMessage={'Search Results'}
            />
          </div>
        ),
      });
    }

    if (lastItems?.length) {
      itemsNew.push(...lastItems);
    }

    return (
      <AnvBreadcrumbs
        items={itemsNew}
        disableRootItem={true}
        className={classes.unsetMargin}
      />
    );
  },
);
