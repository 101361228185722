import { IModuleAccessFilter } from 'src/stores/_helpers/interfaces';

export const prepareAccessFilterParam = (
  accessFilter: IModuleAccessFilter | null,
) => {
  if (!accessFilter) {
    return null;
  }

  const accessFilterParam: Record<string, any> = {
    accessScope: accessFilter?.accessScope,
  };

  if (accessFilter?.scope.jurisdiction) {
    accessFilterParam.jurisdiction = accessFilter?.scope.jurisdiction;
  }
  if (accessFilter?.scope.orgType) {
    accessFilterParam.orgType = accessFilter?.scope.orgType;
  }
  if (accessFilter?.scope.organization) {
    accessFilterParam.organization = accessFilter?.scope.organization;
  }
  if (accessFilter?.scope.organizationSecondary) {
    accessFilterParam.organizationSecondary =
      accessFilter?.scope.organizationSecondary;
  }
  if (accessFilter?.scope.school) {
    accessFilterParam.school = accessFilter?.scope.school;
  }
  if (accessFilter?.scope.tag || accessFilter?.scope.tags) {
    accessFilterParam.tags =
      accessFilter?.scope.tags || accessFilter?.scope.tag;
  }
  if (accessFilter?.scope?.particularOrganization) {
    accessFilterParam.particularOrganization =
      accessFilter?.scope?.particularOrganization;
  }

  return accessFilterParam;
};
