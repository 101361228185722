import React from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import { AnvIcon, AnvUtils, TextBody } from '@platform/front-lib';

import { useStores } from '../../../stores';

import useClickPreventionOnDoubleClick from '../../../utils/hook-useDoubleClick';
import { TOOL_MODULES } from '../../../constants';
import { ModuleContext } from '../module-context';
import { useFoldersViewStyles } from '../folders/styles';
import { IVirtualFolder } from './types';
import { SzToolFolder } from './anv-folder-tool';

interface ISZFolderVirtualViewProps {
  handleCreateRootFolder?: (orgId: string) => () => void;
  virtualFolder: IVirtualFolder;
  showToolIcon?: boolean;
}

export const SZFolderVirtualViewGrid: React.FC<ISZFolderVirtualViewProps> = observer(
  ({ showToolIcon = false, virtualFolder, handleCreateRootFolder }) => {
    const classes = useFoldersViewStyles();

    const history = useHistory();

    const module = React.useContext(ModuleContext);
    const isOrganizer = module === TOOL_MODULES.FILE_ORGANIZER;

    const {
      metaStores: {
        toolsStore: { dataMap: toolsMap },
      },
      fileExplorerStore,
      fileOrganizerStore,
    } = useStores();

    // get tools by useEffect
    // React.useEffect(() => {
    //   if (!isToolsLoaded) {
    //     getTools();
    //   }
    // }, [isToolsLoaded, getTools]);

    const storageStore = isOrganizer ? fileOrganizerStore : fileExplorerStore;

    const { activeFolderId, setActiveFolderId } = storageStore;

    const onClick = () => {
      setActiveFolderId(virtualFolder._id);
    };

    const onDoubleClick = () => {
      if (virtualFolder.to) {
        history.push(virtualFolder.to);

        return;
      }

      handleCreateRootFolder?.(virtualFolder._id)?.();
    };

    const handlePreventDefault = (
      ev: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      // prevent text selection on double click
      ev.preventDefault();
    };

    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
      onClick,
      onDoubleClick,
    );

    const isActive = activeFolderId === virtualFolder._id;

    return (
      <div
        onMouseDown={handlePreventDefault}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        className={clsx(classes.gridElement, {
          [classes.gridElementActive]: isActive,
        })}
      >
        <Grid
          className={classes.content}
          container
          justify={'flex-start'}
          alignItems={'center'}
          spacing={1}
          wrap={'nowrap'}
        >
          <Grid item>
            {showToolIcon &&
            virtualFolder.toolCode &&
            toolsMap[virtualFolder.toolCode] ? (
              <SzToolFolder size={56} tool={toolsMap[virtualFolder.toolCode]} />
            ) : (
              <AnvIcon icon={'folderBig'} size={56} />
            )}
          </Grid>

          <Grid item className={classes.content}>
            <div>
              <TextBody className={classes.textFolderName}>
                {virtualFolder.folderName}
              </TextBody>
            </div>

            {virtualFolder.date && (
              <div>
                <TextBody className={classes.textFolderDate}>
                  {AnvUtils.formatWLocale(virtualFolder.date)}
                </TextBody>
              </div>
            )}

            {!virtualFolder.date && virtualFolder.subName && (
              <div>
                <TextBody className={classes.textFolderDate}>
                  {virtualFolder.subName}
                </TextBody>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  },
);
