import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: theme.spacing(6),
    },
    title: {
      display: 'flex',
      marginLeft: -32, //calc from size of icon 24 and icon right-offset 8
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginLeft: 'unset',
      },
    },
    contentWrapper: {
      paddingBottom: theme.spacing(2),
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: -0.18,
    },
    entityTitle: {
      fontSize: 16,
      lineHeight: '24px',
      color: 'rgba(0, 0, 0, 0.88)',
    },
    entityCaption: {
      fontSize: 13,
      lineHeight: '22px',
      color: 'rgba(0, 0, 0, 0.7)',
    },
    deleteButtonWrapper: { position: 'relative' },

    deleteButton: {
      padding: 8,
      position: 'absolute',
      top: -8,
    },

    inputAdornmentRoot: {
      alignItems: 'baseline',
      marginBottom: theme.spacing(1),
      fontSize: 16,
      color: '#8C8C8C',
    },
  }),
);
