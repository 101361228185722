export const FILE_EXTENSION_BY_TYPE: Record<string, string> = {
  'image/gif': 'gif',
  'image/jpg': 'jpeg',
  'image/jpeg': 'jpeg',
  'image/png': 'png',
  'application/pdf': 'pdf',

  'application/javascript': 'js',
  'application/json': 'json',
  'application/msword': 'doc',
  'application/postscript': 'ps',
  'application/rss+xml': 'rss',
  'application/rtf': 'rtf',
  'application/vnd.apple.mpegurl': 'm3u8',
  'application/vnd.google-earth.kml+xml': 'kml',
  'application/vnd.google-earth.kmz': 'kmz',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.ms-fontobject': 'eot',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.oasis.opendocument.graphics': 'odg',
  'application/vnd.oasis.opendocument.presentation': 'odp',
  'application/vnd.oasis.opendocument.spreadsheet': 'ods',
  'application/vnd.oasis.opendocument.text': 'odt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'pptx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'docx',
  'application/vnd.wap.wmlc': 'wmlc',
  'application/wasm': 'wasm',
  'application/x-7z-compressed': '7z',
  'application/zip': 'zip',
  'audio/mpeg': 'mp3',
  'audio/ogg': 'ogg',
  'audio/x-m4a': 'm4a',
  'audio/x-realaudio': 'ra',
  'font/woff': 'woff',
  'font/woff2': 'woff2',
  'image/avif': 'avif',

  'image/svg+xml': 'svg',
  'image/tiff': 'tiff',
  'image/vnd.wap.wbmp': 'wbmp',
  'image/webp': 'webp',
  'image/x-icon': 'ico',
  'image/x-jng': 'jng',
  'image/x-ms-bmp': 'bmp',
  'text/css': 'css',
  'text/html': 'html',
  'text/plain': 'txt',
  'text/x-component': 'htc',
  'text/xml': 'xml',
  'video/3gpp': '3gp',
  'video/mp2t': 'ts',
  'video/mp4': 'mp4',
  'video/mpeg': 'mpeg',
  'video/quicktime': 'mov',
  'video/webm': 'webm',
  'video/x-flv': 'flv',
  'video/x-m4v': 'm4v',
  'video/x-mng': 'mng',
  'video/x-ms-wmv': 'wmv',
  'video/x-msvideo': 'avi',
};
