import { TextH1, useCommonOffsetsStyles } from '@platform/front-lib';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

interface IWorkZoneTitle {}

export const WorkZoneTitle: React.FC<IWorkZoneTitle> = observer(() => {
  const { formatMessage } = useIntl();
  const classesOffset = useCommonOffsetsStyles();

  return (
    <TextH1 className={classesOffset.pb_2}>
      {formatMessage({
        id: 'STORAGE_ZONE.EXPLORER.TITLE.MY_WORK_ZONE',
        defaultMessage: 'My Storage Zone',
      })}
    </TextH1>
  );
});
