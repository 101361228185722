import React from 'react';
import { ModuleContext } from '../pages/common/module-context';

interface IProps {
  module: string;
}

export const ContextWrap: React.FC<IProps> = ({ module, children }) => {
  return (
    <ModuleContext.Provider value={module}>{children}</ModuleContext.Provider>
  );
};
