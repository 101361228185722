import React from 'react';
import { observer } from 'mobx-react-lite';
import { Drawer } from '@material-ui/core';
import { useStores } from '../../../stores';
import { isSZFileObject } from '../../../utils/tool';
import { ViewDrawerFolderData } from './view-folder';
import { useRightDrawerStyles } from './styles';
import { ViewDrawerFileData } from './view-file';
import { ModuleContext } from '../module-context';
import { TOOL_MODULES } from '../../../constants';
import { FOLDER_VIEW_CUSTOM, TCustomViewFolder } from './custom';

interface IFoldersDrawerProps {
  customMap?: Record<string, any>;
  customView?: TCustomViewFolder;
}

const getEntityFromMap = (
  map: Record<string, any> | undefined,
  id: string | null,
) => {
  if (!id || !map) {
    return null;
  }
  if (map[id]) {
    return map[id];
  }
  return null;
};

const isFileEntity = (entity: any) => entity && isSZFileObject(entity);

export const ViewDrawerData: React.FC<IFoldersDrawerProps> = observer(
  ({ customMap, customView }) => {
    const classes = useRightDrawerStyles();
    const module = React.useContext(ModuleContext);

    const CustomView =
      customView && customMap && FOLDER_VIEW_CUSTOM[customView];

    const {
      fileExplorerStore,
      fileOrganizerStore,
      complianceFilesStore: { dataMap: complianceFilesMap },
    } = useStores();

    const storageStore =
      module === TOOL_MODULES.FILE_ORGANIZER
        ? fileOrganizerStore
        : fileExplorerStore;

    const {
      activeFolderId,
      setActiveFolderId,
      activeFileId,
      setActiveFileId,
      folderStore: { dataMap: foldersMap, getOne },
      fileStore: { dataMap: filesMap, mapDetailed: filesDetailedMap },
      folderFilesStore: { dataMap: folderFilesMap },
    } = storageStore;

    const handleCloseDrawer = () => {
      setActiveFolderId(null);
      setActiveFileId(null);
    };

    const szFileEntity =
      getEntityFromMap(filesDetailedMap, activeFileId) ||
      getEntityFromMap(filesMap, activeFileId) ||
      getEntityFromMap(folderFilesMap, activeFileId);

    const complianceFileEntity = getEntityFromMap(
      complianceFilesMap,
      activeFileId,
    );

    const fileEntity = szFileEntity || complianceFileEntity;

    const folderEntity =
      getEntityFromMap(foldersMap, activeFolderId) ||
      getEntityFromMap(folderFilesMap, activeFolderId) ||
      (isFileEntity(fileEntity) &&
        getEntityFromMap(foldersMap, fileEntity.parentFolder));

    React.useEffect(() => {
      if (
        !customView &&
        isFileEntity(fileEntity) &&
        !folderEntity &&
        fileEntity.parentFolder
      ) {
        getOne({ id: fileEntity.parentFolder });
      }
    }, [customView, fileEntity, folderEntity, getOne]);

    const customEntity = getEntityFromMap(customMap, activeFolderId);

    const isOpen =
      (CustomView && customEntity && activeFolderId) ||
      (folderEntity && activeFolderId) ||
      (fileEntity && activeFileId);

    const isCustomView = customEntity && CustomView;

    return (
      <>
        <Drawer
          variant="persistent"
          anchor="right"
          open={!!isOpen}
          classes={{ paper: classes.paper }}
          ModalProps={{ onBackdropClick: handleCloseDrawer }}
        >
          {isCustomView ? (
            customEntity && CustomView && <CustomView entity={customEntity} />
          ) : (
            <>
              {folderEntity && !fileEntity && (
                <ViewDrawerFolderData folder={folderEntity} />
              )}
              {!!fileEntity && (
                <ViewDrawerFileData file={fileEntity} folder={folderEntity} />
              )}
            </>
          )}
        </Drawer>
      </>
    );
  },
);
