import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TextH1, useCommonOffsetsStyles } from '@platform/front-lib';

interface IMyStorageZoneTitleProps {}

export const MyStorageZoneTitle: React.FC<IMyStorageZoneTitleProps> = () => {
  const classesOffset = useCommonOffsetsStyles();

  return (
    <TextH1 className={classesOffset.pb_2}>
      <FormattedMessage
        id={'STORAGE_ZONE.EXPLORER.TITLE.MY_STORAGE_ZONE'}
        defaultMessage={'My Storage Zone'}
      />
    </TextH1>
  );
};
