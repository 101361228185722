import { AnvButton, AnvIcon, useModal } from '@platform/front-lib';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FILE_EXPLORER_SUB_MODULES,
  PZ_GOV_ROOT_LIST_DEFAULT_REQUEST_ID,
} from '../../../../constants';
import { SubModuleContext } from '../../../../contexts/sub-module';
import { useStores } from '../../../../stores';

import { CommonGoverningBoardsPage } from '../../common/governance';

import { observer } from 'mobx-react-lite';
import { ExplorerListAddNewGovBody } from './AddNew';

interface IFamilyCircleFirstLevelProps {
  children?: React.ReactNode;
}

const requestId = PZ_GOV_ROOT_LIST_DEFAULT_REQUEST_ID;

export const PersonalZoneGoverningBoardsPage: React.FC<IFamilyCircleFirstLevelProps> = observer(
  () => {
    const {
      userStore: { userInfo, getUserInfo, isLoaded: isLoadedUserInfo },
      fileExplorerStore: {
        folderRootStore: { addToList },
        PERSONAL_ZONE: { governingBoardsStore },
      },
    } = useStores();

    const { getRootList, getListData } = governingBoardsStore;

    const { isFetchingList } = getListData(requestId);

    const {
      isOpen: openFilters,
      handleToggleModal: handleToggleFilters,
    } = useModal();

    React.useEffect(() => {
      if (!isLoadedUserInfo) {
        getUserInfo();
      }
    }, [isLoadedUserInfo, getUserInfo]);

    const handleAddToList = (params: any) => {
      const successFunc = () => {
        getRootList({
          requestId,
        });

        handleToggleFilters();
      };

      addToList(params, { successFunc });
    };

    return (
      <SubModuleContext.Provider
        value={FILE_EXPLORER_SUB_MODULES.PERSONAL_ZONE}
      >
        {isLoadedUserInfo && openFilters && (
          <ExplorerListAddNewGovBody
            isLoadingData={isFetchingList}
            userInfo={userInfo}
            handleAddToList={handleAddToList}
            total={0}
            handleToggleFilters={handleToggleFilters}
            openFilters={openFilters}
          />
        )}

        <CommonGoverningBoardsPage
          requestId={requestId}
          RightAction={
            <AnvButton
              data-test={'add-new-organization-open'}
              onClick={handleToggleFilters}
              variant={'contained'}
              size={'small'}
              startIcon={<AnvIcon icon={'plus'} size={16} color={'inherit'} />}
            >
              <span>
                <FormattedMessage
                  id="STORAGE_ZONE.EXPLORER.PERSONAL_ZONE.GOV.LIST.EMPTY_LIST.ACTION.FIND_GOV_BODY"
                  defaultMessage="Find Governing Body"
                />
              </span>
            </AnvButton>
          }
        />
      </SubModuleContext.Provider>
    );
  },
);
