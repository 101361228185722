import { createStyles, makeStyles } from '@material-ui/core/styles';
import mixinStyles from 'src/styles/mixins';

const DETAILS_HEIGHT_MOB = 50;
const DETAILS_HEIGHT = 50;

const PREVIEW_HEIGHT_MOB = 146;
const PREVIEW_HEIGHT = 158;

export const useStyles = makeStyles((theme) =>
  createStyles({
    filesGroupRoot: {
      marginBottom: theme.spacing(6),
    },
    filesGroupHeader: {
      marginBottom: theme.spacing(1),

      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '-0.02em',

      color: theme.palette.text.primary,
    },
    filesGroupCaption: {
      marginBottom: theme.spacing(2),
    },
    root: {
      outline: 'none !important',
    },
    containerFullHeight: {
      height: '100%',
    },

    placeholderWrapper: {
      cursor: 'pointer',
    },

    container: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      // backgroundColor: theme.palette.background.default,
      border: '2px dashed rgba(23, 43, 77, 0.4)',
      borderRadius: 8,

      cursor: 'pointer',

      '& .anv-icon': {
        color: theme.palette.brand.main,
        opacity: 0.65,
      },

      'div:focus > &, div:hover > &': {
        border: `2px dashed ${theme.palette.primary.main}`,

        '& .anv-icon': {
          opacity: 1,
        },
      },

      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
        flexWrap: 'wrap',
      },
    },
    containerActive: {
      borderColor: theme.palette.primary.main,
    },
    files: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: theme.spacing(-1),
    },
    gridItem: {
      position: 'relative',
      width: '50vw',
      minWidth: 163,
      height: PREVIEW_HEIGHT_MOB + theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        width: 192,
        height: PREVIEW_HEIGHT + theme.spacing(2),
      },
    },
    fileDocContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: theme.spacing(8),
      height: theme.spacing(8),
      fontSize: 32,
    },
    filePreviewContainer: {
      position: 'relative',
      // marginLeft: theme.spacing(1),
      cursor: 'pointer',

      boxShadow: '0px 1px 0px #D9D9D9, 0px 0px 0px 1px #E1E1E1',
      borderRadius: 6,

      height: PREVIEW_HEIGHT_MOB,
      maxHeight: PREVIEW_HEIGHT_MOB,

      [theme.breakpoints.up('sm')]: {
        // height: `calc(100% - ${DETAILS_HEIGHT}px)`,
        height: PREVIEW_HEIGHT,
        maxHeight: PREVIEW_HEIGHT,
      },
    },
    imgModal: {
      maxHeight: 'calc(100vh - 140px)',
      objectFit: 'contain',
    },
    filePreview: {
      width: '100%',
      height: PREVIEW_HEIGHT_MOB - DETAILS_HEIGHT_MOB,
      maxHeight: PREVIEW_HEIGHT_MOB - DETAILS_HEIGHT_MOB,
      // maxHeight: `calc(100% - ${DETAILS_HEIGHT_MOB}px)`,
      // width: theme.spacing(8),
      // height: theme.spacing(8),
      objectFit: 'cover',
      overflow: 'hidden',

      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,

      [theme.breakpoints.up('sm')]: {
        // height: `calc(100% - ${DETAILS_HEIGHT}px)`,
        height: PREVIEW_HEIGHT - DETAILS_HEIGHT,
        maxHeight: PREVIEW_HEIGHT - DETAILS_HEIGHT,
      },
    },
    filePreviewAlone: {
      height: PREVIEW_HEIGHT_MOB,
      maxHeight: PREVIEW_HEIGHT_MOB,

      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,

      [theme.breakpoints.up('sm')]: {
        // height: `calc(100% - ${DETAILS_HEIGHT}px)`,
        height: PREVIEW_HEIGHT,
        maxHeight: PREVIEW_HEIGHT,
      },
    },

    fileDetails: {
      height: DETAILS_HEIGHT_MOB,
      padding: theme.spacing(1, 1.5),

      [theme.breakpoints.up('sm')]: {
        height: DETAILS_HEIGHT,

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    fileDetailsName: {
      paddingRight: theme.spacing(1),
      fontFamily: 'Inter',
      fontSize: 13,
      lineHeight: '16px',

      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    fileDetailsSize: {
      fontFamily: 'Inter',
      fontSize: 11,
      lineHeight: '13px',
      color: theme.palette.text.secondary,

      whiteSpace: 'nowrap',
    },

    filePreviewRemoveButton: {
      position: 'absolute',
      padding: theme.spacing(0.5),
      zIndex: 1,
      top: theme.spacing(1.5),
      right: theme.spacing(1.5),
      borderRadius: '50%',
      overflow: 'hidden',
      transition: theme.transitions.create(['color'], {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.short,
      }),

      '& .MuiIconButton-label': {
        zIndex: 1,
      },

      '& svg path': {
        opacity: 0.65,

        transition: theme.transitions.create(['opacity'], {
          easing: theme.transitions.easing.easeIn,
          duration: theme.transitions.duration.shorter,
        }),
      },
      '&:hover svg path': {
        opacity: 1,
      },

      '&:hover::before': {
        content: "''",
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'block',
        backgroundColor: `${theme.palette.divider} !important`,
        transition: theme.transitions.create(['background-color'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.short,
        }),
        opacity: 0.44,
      },
    },
    labelText: {
      marginRight: theme.spacing(8),
      color: theme.palette.text.secondary,

      [theme.breakpoints.down('md')]: {
        marginTop: 0,
        marginRight: 0,
      },
    },
    buttonSelectIcon: {
      marginRight: theme.spacing(1),
      height: 24,
    },
    buttonSelect: {
      ...mixinStyles.buttonReset,
      padding: theme.spacing(1, 2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      fontWeight: 'bold',
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 24,

      '&:hover': {
        opacity: 0.8,
        outline: 'none',
      },
      '&:active': {
        opacity: 0.6,
        outline: 'none',
      },
    },

    helper: {
      color: theme.palette.text.secondary,
    },
    helperError: {
      color: theme.palette.error.main,
    },
  }),
);
