import { ApiServiceCommon } from '@platform/front-lib';
import { CancelToken } from 'axios';

import { IGetList } from '../../../../service/interfaces';

type TGetListParams = IGetList & { searchRoute: boolean; subModule: string };

export class FolderFilesService extends ApiServiceCommon {
  private async getListCommonList(
    data: IGetList & { searchRoute: boolean },
    source: { token?: CancelToken },
  ) {
    const slug = data.searchRoute ? 'search' : this.collection;

    return this.axiosCatch(
      this.axios.get(`${this.apiUrl}/folders/${data.id}/${slug}`, {
        params: data,
        cancelToken: source.token,
      }),
    );
  }

  private async getGoverningBoardsDocumentsList(
    data: IGetList & { subModule: string },
    source: { token?: CancelToken },
  ) {
    return this.axiosCatch(
      this.axios.get(
        `${this.apiUrl}/${data.subModule}/governing-boards/folders/${data.id}/${this.collection}`,
        {
          params: data,
          cancelToken: source.token,
        },
      ),
    );
  }

  async getList(data: TGetListParams, source: { token?: CancelToken }) {
    if (data.subModule) {
      return this.getGoverningBoardsDocumentsList(data, source);
    }

    return this.getListCommonList(data, source);
  }
}
