import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { Grid, IconButton, MenuItem } from '@material-ui/core';
import {
  AnvIcon,
  AnvListContainer,
  AnvListData,
  AnvMenu,
  AnvUtils,
  ModalConfirm,
  TextBody,
  TextH4,
  useMenu,
} from '@platform/front-lib';

import type { IAnvListDataProps } from '@platform/front-lib/dist/components/shared/anv-list-data/AnvListData';
import {
  FILE_EXPLORER_SUB_MODULES,
  PZ_GOV_ROOT_LIST_DEFAULT_REQUEST_ID,
} from '../../../../constants';
import { useSubModuleContext } from '../../../../contexts/sub-module';
import { ISZRootFolder } from '../../../../models';

import { useStores } from '../../../../stores';
import { ModuleContext } from '../../module-context';
import { useRightDrawerStyles } from '../styles';

import { IViewDrawerCustomDataCommonProps } from './index';

interface IViewDrawerGovBodyDataProps
  extends IViewDrawerCustomDataCommonProps {}

export const ViewDrawerGovBodyData: React.FC<IViewDrawerGovBodyDataProps> = observer(
  ({ entity: entityDirty }) => {
    const classes = useRightDrawerStyles();

    const entity = entityDirty as ISZRootFolder & { historyId: string | null };
    const educationEntity = entity?.govBodyRef;

    const { locale } = useIntl();

    const {
      fileExplorerStore: {
        setActiveFolderId,
        PERSONAL_ZONE: { governingBoardsStore },
      },
      metaStores: {
        accessHistoryStore: { hideAccessHistoryForTool },
      },
    } = useStores();

    const { getRootList } = governingBoardsStore;

    const subModule = useSubModuleContext();
    const module = React.useContext(ModuleContext);

    const isPersonalZone =
      subModule === FILE_EXPLORER_SUB_MODULES.PERSONAL_ZONE;

    const { closeMenu, handleClose: handleCloseMenu } = useMenu();

    const [hideHistoryId, setHideHistoryId] = React.useState<string | null>(
      null,
    );
    const [isHideModal, setIsHideModalOpen] = React.useState(false);
    const handleCloseHideModal = () => {
      setIsHideModalOpen(false);
      setHideHistoryId(null);
    };

    const handleRemoveFromList = (id: string) => () => {
      handleCloseMenu();
      setHideHistoryId(id);
      setIsHideModalOpen(true);
    };

    const listDataConfig: IAnvListDataProps = React.useMemo(() => {
      const LABEL_MD = 4;

      return {
        direction: 'row',
        labelMd: LABEL_MD,
        omitMargin: true,
      };
    }, []);

    const confirmHideOrganization = () => {
      if (hideHistoryId) {
        const successFunc = () => {
          getRootList({
            requestId: PZ_GOV_ROOT_LIST_DEFAULT_REQUEST_ID,
          });

          setIsHideModalOpen(false);
        };

        hideAccessHistoryForTool(
          {
            id: hideHistoryId,
            moduleCode: module,
          },
          { successFunc },
        );
        setHideHistoryId(null);
        setActiveFolderId(null);
      }
    };

    const handleCloseDrawer = () => {
      setActiveFolderId(null);
    };

    return (
      <>
        <div className={classes.headerBlock}>
          <TextH4 className={classes.header}>
            <Grid
              container
              alignItems={'center'}
              className={classes.headerTitleGrid}
              wrap={'nowrap'}
            >
              <Grid item style={{ height: 24 }}>
                <AnvIcon
                  icon={'folderBig'}
                  offset={0.5}
                  size={24}
                  alignSelfCenter={true}
                />
              </Grid>
              <Grid
                item
                className={classes.headerTitleItem}
                style={{
                  flex: '1',
                }}
              >
                {AnvUtils.getNameByLangNullable(
                  educationEntity,
                  'name',
                  locale,
                ) || ''}
              </Grid>

              {isPersonalZone && entity?.historyId && (
                <Grid item>
                  <div className={classes.headerMenu}>
                    <AnvMenu
                      size={'small'}
                      closeMenu={closeMenu}
                      customIcon={
                        <AnvIcon
                          icon={'tableAction'}
                          color={'white'}
                          opacity={0.5}
                        />
                      }
                    >
                      <MenuItem
                        onClick={handleRemoveFromList(entity.historyId)}
                      >
                        <AnvIcon icon={'filterArchive'} />
                        <span>
                          <FormattedMessage
                            id={
                              'STORAGE_ZONE.EXPLORER.ORGS.LIST.ACTION.ARCHIVE'
                            }
                            defaultMessage={'Archive'}
                          />
                        </span>
                      </MenuItem>
                    </AnvMenu>
                  </div>
                </Grid>
              )}

              <Grid item>
                <IconButton
                  size={'small'}
                  onClick={handleCloseDrawer}
                  className={classes.closeButtonStatic}
                >
                  <AnvIcon
                    icon={'closeCross'}
                    color={'white'}
                    opacity={0.5}
                    size={24}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </TextH4>
        </div>

        <div className={classes.body}>
          <div>
            <div className={classes.bodyHeader}>
              <TextBody semiBold>
                <FormattedMessage
                  id="STORAGE_ZONE.FOLDERS.DRAWER.CAPTION"
                  defaultMessage="Object Storage Considerations:"
                />
              </TextBody>
            </div>

            <AnvListContainer>
              {educationEntity?.name && (
                <AnvListData
                  {...listDataConfig}
                  label={
                    <FormattedMessage
                      id="STORAGE_ZONE.FOLDERS.DRAWER.GOV_BODY.LABEL.TITLE"
                      defaultMessage="Title"
                    />
                  }
                  value={
                    <span className={classes.listValue}>
                      {AnvUtils.getNameByLangNullable(
                        educationEntity,
                        'name',
                        locale,
                      ) || ''}
                    </span>
                  }
                />
              )}

              {educationEntity?.phone && (
                <AnvListData
                  {...listDataConfig}
                  label={
                    <FormattedMessage
                      id="STORAGE_ZONE.FOLDERS.DRAWER.GOV_BODY.LABEL.PHONE"
                      defaultMessage="Phone"
                    />
                  }
                  value={
                    <span className={classes.listValue}>
                      {AnvUtils.formatPhoneNumber(educationEntity?.phone)}
                    </span>
                  }
                />
              )}

              {educationEntity?.addressFullName && (
                <AnvListData
                  {...listDataConfig}
                  alignItems={'flex-start'}
                  label={
                    <FormattedMessage
                      id="STORAGE_ZONE.FOLDERS.DRAWER.GOV_BODY.LABEL.ADDRESS"
                      defaultMessage="Address"
                    />
                  }
                  value={
                    <span className={classes.listValue}>
                      {AnvUtils.getNameByLangNullable(
                        educationEntity,
                        'addressFullName',
                        locale,
                      ) || ''}
                    </span>
                  }
                />
              )}

              {/*{educationEntity?.organizationType && (*/}
              {/*  <AnvListData*/}
              {/*    {...listDataConfig}*/}
              {/*    label={*/}
              {/*      <FormattedMessage*/}
              {/*        id="STORAGE_ZONE.FOLDERS.DRAWER.EDUCATION_ENTITY.LABEL.ORG_TYPE"*/}
              {/*        defaultMessage="Organization Type"*/}
              {/*      />*/}
              {/*    }*/}
              {/*    value={*/}
              {/*      <span className={classes.listValue}>*/}
              {/*        {AnvUtils.getNameByLangNullable(*/}
              {/*          organizationTypesMap[educationEntity?.organizationType],*/}
              {/*          'description',*/}
              {/*          locale,*/}
              {/*        ) || educationEntity?.organizationType}*/}
              {/*      </span>*/}
              {/*    }*/}
              {/*  />*/}
              {/*)}*/}

              {/*{address?.stateRef && (*/}
              {/*  <AnvListData*/}
              {/*    {...listDataConfig}*/}
              {/*    label={*/}
              {/*      <FormattedMessage*/}
              {/*        id="STORAGE_ZONE.FOLDERS.DRAWER.EDUCATION_ENTITY.LABEL.JURISDICTION"*/}
              {/*        defaultMessage="Jurisdiction"*/}
              {/*      />*/}
              {/*    }*/}
              {/*    value={*/}
              {/*      <span className={classes.listValue}>*/}
              {/*        {AnvUtils.getNameByLangNullable(*/}
              {/*          address.stateRef,*/}
              {/*          'name',*/}
              {/*          locale,*/}
              {/*        ) || address?.stateRef?.code}*/}
              {/*      </span>*/}
              {/*    }*/}
              {/*  />*/}
              {/*)}*/}

              {/*{address?.county && (*/}
              {/*  <AnvListData*/}
              {/*    {...listDataConfig}*/}
              {/*    label={*/}
              {/*      <FormattedMessage*/}
              {/*        id="STORAGE_ZONE.FOLDERS.DRAWER.EDUCATION_ENTITY.LABEL.COUNTY"*/}
              {/*        defaultMessage="County"*/}
              {/*      />*/}
              {/*    }*/}
              {/*    value={*/}
              {/*      <span className={classes.listValue}>*/}
              {/*        {AnvUtils.getNameByLangNullable(*/}
              {/*          address?.county,*/}
              {/*          'name',*/}
              {/*          locale,*/}
              {/*        )}*/}
              {/*      </span>*/}
              {/*    }*/}
              {/*  />*/}
              {/*)}*/}

              {/*{address?.city && (*/}
              {/*  <AnvListData*/}
              {/*    {...listDataConfig}*/}
              {/*    label={*/}
              {/*      <FormattedMessage*/}
              {/*        id="STORAGE_ZONE.FOLDERS.DRAWER.EDUCATION_ENTITY.LABEL.CITY"*/}
              {/*        defaultMessage="City"*/}
              {/*      />*/}
              {/*    }*/}
              {/*    value={*/}
              {/*      <span className={classes.listValue}>*/}
              {/*        {AnvUtils.getNameByLangNullable(*/}
              {/*          address?.city,*/}
              {/*          'name',*/}
              {/*          locale,*/}
              {/*        )}*/}
              {/*      </span>*/}
              {/*    }*/}
              {/*  />*/}
              {/*)}*/}
            </AnvListContainer>
          </div>
        </div>

        <ModalConfirm
          text={
            <FormattedMessage
              id="STORAGE_ZONE.EXPLORER.PERSONAL_ZONE.GOV.ARCHIVE_MODAL.TEXT"
              defaultMessage="Are you sure to hide this Governance body?"
            />
          }
          isOpen={isHideModal}
          handleClose={handleCloseHideModal}
          confirmAction={confirmHideOrganization}
          cancelAction={handleCloseHideModal}
        />
      </>
    );
  },
);
