// const ROUTE_PREFIX = process.env.REACT_APP_ROUTE_PREFIX || '';
export const ROUTE_PREFIX = '';

const MODULE_ACCESS = `${ROUTE_PREFIX}/access`;
export const ROUTE_ACCESS_PREFIX = `${MODULE_ACCESS}/:section?/:subSection?/:other?`;

const MODULE_FILE_EXPLORER = `${ROUTE_PREFIX}/file-explorer`;
const MODULE_FILE_ORGANIZER = `${ROUTE_PREFIX}/file-organizer`;
const MODULE_TRASH = `${ROUTE_PREFIX}/trash`;

// const MODULE_FILE_EXPLORER = `/file-explorer/:globalType?/sub`;
//
// // PERSONAL
// const MODULE_FILE_EXPLORER = `/file-explorer/personal-zone/education-entities/:educationEntity?`;
// const MODULE_FILE_EXPLORER = `/file-explorer/personal-zone/:tool?*`;
//
// // WORK
// const MODULE_FILE_EXPLORER = `/file-explorer/work-zone/:tool?/:moduleCode?/:educationEntity?`;
//
// // COMPLIANCE
// const MODULE_FILE_EXPLORER = `/file-explorer/work-zone/compliance/review/:educationEntity?/:month`;

export const ROUTES = {
  ROOT: ROUTE_PREFIX,

  //**auth */
  USER_AUTH: ROUTE_PREFIX + '/auth',
  AUTH_FAIL: ROUTE_PREFIX + '/auth-fail',
  AUTH_FORBIDDEN: ROUTE_PREFIX + '/forbidden',
  AUTH_LOGOUT: ROUTE_PREFIX + '/logout',

  //** MODULE_FILE_EXPLORER aka My Storage Zone ... */
  MODULE_FILE_EXPLORER,
  MODULE_FILE_EXPLORER_PREFIX: MODULE_FILE_EXPLORER + '/*',

  // MODULE_FILE_EXPLORER_LIST: MODULE_FILE_EXPLORER + '/list',
  MODULE_FILE_EXPLORER_PERSONAL_LIST: MODULE_FILE_EXPLORER + '/personal-list',
  MODULE_FILE_EXPLORER_PERSONAL_ROOT: MODULE_FILE_EXPLORER + '/personal-root',

  MODULE_FILE_EXPLORER_PERSONAL_LIST_PREFIX:
    MODULE_FILE_EXPLORER + '/personal-list*',

  // EDUCATION ENTITIES
  MODULE_FILE_EXPLORER_PERSONAL_LIST_EDUCATION:
    MODULE_FILE_EXPLORER + '/personal-list/education-entities',
  MODULE_FILE_EXPLORER_PERSONAL_LIST_EDUCATION_PREFIX:
    MODULE_FILE_EXPLORER + '/personal-list/education-entities*',
  MODULE_FILE_EXPLORER_VIEW_EE_PREFIX:
    MODULE_FILE_EXPLORER +
    '/personal-list/education-entities/view/:rootId?/:folders*',
  MODULE_FILE_EXPLORER_VIEW_EE:
    MODULE_FILE_EXPLORER +
    '/personal-list/education-entities/view/:rootId/:folders?',

  MODULE_FILE_EXPLORER_VIEW_EE_SEARCH:
    MODULE_FILE_EXPLORER +
    '/personal-list/education-entities/search/:rootId/:folders?',
  MODULE_FILE_EXPLORER_VIEW_EE_SEARCH_PREFIX:
    MODULE_FILE_EXPLORER +
    '/personal-list/education-entities/search/:rootId/:folders*',
  // EDUCATION ENTITIES END

  // FAMILY_CIRCLE
  MODULE_FILE_EXPLORER_PERSONAL_LIST_FAMILY_CIRCLE:
    MODULE_FILE_EXPLORER + '/personal-list/family-circle',
  MODULE_FILE_EXPLORER_PERSONAL_LIST_FAMILY_CIRCLE_PREFIX:
    MODULE_FILE_EXPLORER + '/personal-list/family-circle*',
  MODULE_FILE_EXPLORER_VIEW_FC_PREFIX:
    MODULE_FILE_EXPLORER +
    '/personal-list/family-circle/view/:rootId?/:folders*',
  MODULE_FILE_EXPLORER_VIEW_FC:
    MODULE_FILE_EXPLORER +
    '/personal-list/family-circle/view/:rootId/:folders?',
  // FAMILY_CIRCLE END

  // GOVERNING BOARDS

  // PERSONAL ZONE
  MODULE_FILE_EXPLORER_PERSONAL_LIST_GOVERNING_BOARDS:
    MODULE_FILE_EXPLORER + '/personal-list/governing-boards',
  MODULE_FILE_EXPLORER_PERSONAL_LIST_GOVERNING_BOARDS_PREFIX:
    MODULE_FILE_EXPLORER + '/personal-list/governing-boards*',
  MODULE_FILE_EXPLORER_PERSONAL_LIST_GOVERNING_BOARDS_VIEW_PREFIX:
    MODULE_FILE_EXPLORER +
    '/personal-list/governing-boards/view/:rootId?/:folders*',
  MODULE_FILE_EXPLORER_PERSONAL_LIST_GOVERNING_BOARDS_VIEW:
    MODULE_FILE_EXPLORER +
    '/personal-list/governing-boards/view/:rootId/:folders?',

  // WORK ZONE
  MODULE_FILE_EXPLORER_WORK_LIST_GOVERNING_BOARDS:
    MODULE_FILE_EXPLORER + '/work-zone/governing-boards',
  MODULE_FILE_EXPLORER_WORK_LIST_GOVERNING_BOARDS_PREFIX:
    MODULE_FILE_EXPLORER + '/work-zone/governing-boards*',
  MODULE_FILE_EXPLORER_WORK_LIST_GOVERNING_BOARDS_VIEW_PREFIX:
    MODULE_FILE_EXPLORER +
    '/work-zone/governing-boards/view/:rootId?/:folders*',
  MODULE_FILE_EXPLORER_WORK_LIST_GOVERNING_BOARDS_VIEW:
    MODULE_FILE_EXPLORER + '/work-zone/governing-boards/view/:rootId/:folders?',

  // GOVERNING BOARDS END

  MODULE_FILE_EXPLORER_WORK_LIST: MODULE_FILE_EXPLORER + '/work-zone',
  MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE:
    MODULE_FILE_EXPLORER + '/work-zone/compliance',

  MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_PREFIX:
    MODULE_FILE_EXPLORER + '/work-zone/compliance*',

  MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REPORT:
    MODULE_FILE_EXPLORER + '/work-zone/compliance/report',
  MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REPORT_EDU_ENTITY:
    MODULE_FILE_EXPLORER + '/work-zone/compliance/report/:schoolId',
  MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REPORT_ANNUAL_REPORT:
    MODULE_FILE_EXPLORER +
    '/work-zone/compliance/report/:schoolId/:annualReportId',
  MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REPORT_MONTH:
    MODULE_FILE_EXPLORER +
    '/work-zone/compliance/report/:schoolId/:annualReportId/:month',

  MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REVIEW:
    MODULE_FILE_EXPLORER + '/work-zone/compliance/review',

  MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REVIEW_ORG:
    MODULE_FILE_EXPLORER + '/work-zone/compliance/review/:organizationId',
  MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REVIEW_SCHOOL:
    MODULE_FILE_EXPLORER +
    '/work-zone/compliance/review/:organizationId/:schoolId',
  MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REVIEW_ANNUAL_REPORT:
    MODULE_FILE_EXPLORER +
    '/work-zone/compliance/review/:organizationId/:schoolId/:annualReportId',
  MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REVIEW_MONTH:
    MODULE_FILE_EXPLORER +
    '/work-zone/compliance/review/:organizationId/:schoolId/:annualReportId/:month',

  MODULE_FILE_EXPLORER_WORK_LIST_PREFIX: MODULE_FILE_EXPLORER + '/work-zone*',

  MODULE_FILE_EXPLORER_LIST: MODULE_FILE_EXPLORER + '/list',

  MODULE_FILE_EXPLORER_VIEW_PREFIX:
    MODULE_FILE_EXPLORER + '/view/:rootId?/:folders*',
  MODULE_FILE_EXPLORER_VIEW: MODULE_FILE_EXPLORER + '/view/:rootId/:folders?',

  //** MODULE_FILE_ORGANIZER */
  MODULE_FILE_ORGANIZER,
  MODULE_FILE_ORGANIZER_NEW: MODULE_FILE_ORGANIZER + '/new/:id',
  MODULE_FILE_ORGANIZER_LIST: MODULE_FILE_ORGANIZER + '/list',

  MODULE_FILE_ORGANIZER_VIEW_PREFIX:
    MODULE_FILE_ORGANIZER + '/view/:rootId?/:folders*',
  MODULE_FILE_ORGANIZER_VIEW: MODULE_FILE_ORGANIZER + '/view/:rootId/:folders?',

  MODULE_FILE_ORGANIZER_SEARCH:
    MODULE_FILE_ORGANIZER + '/search/:rootId/:folders?',
  MODULE_FILE_ORGANIZER_SEARCH_PREFIX:
    MODULE_FILE_ORGANIZER + '/search/:rootId/:folders*',

  //** MODULE_TRASH */
  MODULE_TRASH,

  //**access */
  MODULE_ACCESS,
  ROUTE_ACCESS_PREFIX,
  ADMIN_ACCESS_LIST: MODULE_ACCESS + '/list',
  //**common */
  PUBLIC_TERMS: ROUTE_PREFIX + '/terms-conditions',
  PUBLIC_PRIVACY: ROUTE_PREFIX + '/privacy-policy',
  PUBLIC_CONTACT_US: ROUTE_PREFIX + '/contact-us',
};
