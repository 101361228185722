import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

const ContainerAuthStyle = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      padding: theme.spacing(0, 1),
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 'calc(100vh - 64px)',
      width: '100%',
    },
  }),
);

export default ContainerAuthStyle;
