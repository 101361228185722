import React from 'react';
import { capitalize, Grid } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import {
  AnvButton,
  AnvDialog,
  AnvDialogActionsGrid,
} from '@platform/front-lib';
import { observer } from 'mobx-react-lite';

import { checkFilesDuplicates } from 'src/utils';
import { AnvFileInputLabConnectedLoc } from '../../../../components/shared/form-controls/anv-file-input-lab/anv-file-input-lab-connected';
import { useStores } from '../../../../stores';

interface IProps {
  isFetching: boolean;
  isOpen: boolean;
  handleCloseModal: () => void;
  handleUploadFiles: (data: any) => void;

  serverErrors?: any;
}

export const FileUploadForm: React.FC<IProps> = observer(
  ({
    isFetching,
    isOpen,
    handleUploadFiles,
    handleCloseModal,
    serverErrors,
  }) => {
    const { formatMessage } = useIntl();

    const {
      metaStores: { generalSettingStore },
    } = useStores();

    const [fileTitles, setFileTitles] = React.useState<Record<string, any>>({});

    const [filePublicState, setFilePublicState] = React.useState<
      Record<string, boolean>
    >({});

    const {
      register,
      unregister,
      setValue,
      watch,
      errors,
      handleSubmit,
    } = useForm({
      defaultValues: {
        files: [],
      },
    });

    const errorsComputed =
      (Object.keys(errors).length ? errors : serverErrors) || {};

    const onSubmit = (data: any) => {
      const dirtyFiles = data.files;

      const cleanFiles = checkFilesDuplicates(dirtyFiles);

      // TODO ???? if files don't passed check return
      if (dirtyFiles.length !== cleanFiles.length) {
        // return handleCloseModal();
      }

      handleUploadFiles({
        files: data.files,
        fileTitles,
        filePublicState,
      });

      // handleCloseModal();
    };

    const filesWatch = watch('files');

    return (
      <AnvDialog
        open={isOpen}
        handleClose={handleCloseModal}
        title={
          <FormattedMessage
            id={`STORAGE_ZONE.FILES.UPLOAD.TITLE`}
            defaultMessage={capitalize('Add Files')}
          />
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <AnvFileInputLabConnectedLoc
            generalSettingsStore={generalSettingStore}
            // maxSize={50 * 1024 * 1024}
            label={''}
            name={'files'}
            mode={'append'}
            removeFile={() => {}}
            setFilePublicState={setFilePublicState}
            filePublicState={filePublicState}
            setFileTitles={setFileTitles}
            fileTitles={fileTitles}
            register={register}
            unregister={unregister}
            setValue={setValue}
            watch={watch}
            errors={errorsComputed}
          />

          <AnvDialogActionsGrid paddingTopOffset={48}>
            <Grid item xs={12}>
              <AnvButton
                data-test={'upload-files-submit'}
                variant={'contained'}
                color={'primary'}
                fullWidth
                disabled={!filesWatch.length || isFetching}
                onClick={handleSubmit(onSubmit)}
              >
                {formatMessage({
                  id: 'STORAGE_ZONE.FILES.UPLOAD.ACTION.SUBMIT',
                  defaultMessage: 'Upload',
                })}
              </AnvButton>
            </Grid>
            <Grid item xs={12}>
              <AnvButton
                disabled={isFetching}
                data-test={'upload-files-cancel'}
                variant={'text'}
                customColor={'muted'}
                fullWidth
                onClick={handleCloseModal}
              >
                {formatMessage({
                  id: 'STORAGE_ZONE.FILES.UPLOAD.ACTION.CANCEL',
                  defaultMessage: 'Cancel',
                })}
              </AnvButton>
            </Grid>
          </AnvDialogActionsGrid>
        </form>
      </AnvDialog>
    );
  },
);
