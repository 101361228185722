import { CommonListAdvancedStoreCRUD } from '@platform/front-lib';
import { IActionMeta } from '@platform/front-lib/dist/stores/_helpers';
import { action, makeObservable, observable } from 'mobx';
import { IVirtualFolder } from '../../../../pages/common/folders-virtual/types';

import { PersonalZoneVirtualFoldersService } from './service';

export class PersonalZoneVirtualFoldersStore extends CommonListAdvancedStoreCRUD<
  PersonalZoneVirtualFoldersService,
  IVirtualFolder
> {
  error: Record<string, any> | null | string = null;

  isFetching: boolean = false;
  isLoaded: boolean = false;
  isError: boolean = false;

  getDataByPath = (path: string): IVirtualFolder[] => {
    // split path by '/'
    const pathArray = path.split('/');

    //iterate over pathArray
    return pathArray
      .map((item) => {
        return this.dataMap[item];
      })
      .filter((item) => !!item);
  };

  getVirtualEntities = async (
    payload: { path: string },
    meta?: IActionMeta,
  ) => {
    this.setLoading();

    const [error, response] = await this.service.getVirtualEntities(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.error = error || response?.error;
      });
    }

    this.setLoaded(() => {
      response.forEach((virtualFolder: IVirtualFolder) => {
        this.dataMap[virtualFolder._id] = virtualFolder;
      });
    });
  };

  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };

  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();
  };

  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();
  };

  constructor(module: string, sessionKey?: string, disableAutoSave?: boolean) {
    super({ sessionKey, disableAutoSave });
    this.service = new PersonalZoneVirtualFoldersService(
      module,
      'virtual-folders',
    );

    makeObservable(this, {
      getDataByPath: action,
      getVirtualEntities: action,

      isFetching: observable,
      isLoaded: observable,
      isError: observable,
      error: observable,

      setLoading: action,
      setError: action,
      setLoaded: action,
    });
  }
}
