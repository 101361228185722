export const APP_BAR_HEIGHT = 64;
export const APP_BAR_MOBILE_HEIGHT = 56;
export const BREADCRUMBS_HEIGHT = 60;
export const BORDER_RADIUS = 6;
export const FOOTER_HEIGHT = 80; // 36;

export const drawerWidth = 264;
export const drawerWidthDesktopClose = 64;
export const drawerHeaderHeight = 64;

export const DRAWER_PROPERTIES_WIDTH = 368;
