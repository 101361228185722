export * from './props';

const mixins = {
  scrollBar: {
    scrollBehavior: 'smooth' as
      | '-moz-initial'
      | 'inherit'
      | 'initial'
      | 'revert'
      | 'unset'
      | 'auto'
      | 'smooth',
    scrollbarWidth: 'thin' as
      | '-moz-initial'
      | 'inherit'
      | 'initial'
      | 'revert'
      | 'unset'
      | 'auto'
      | 'none'
      | 'thin',
    scrollbarColor: 'rgba(0,0,0,0.25) transparent',

    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
      transition: 'all 1s ease-in-out',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 10,
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 2,
      backgroundColor: 'rgba(0,0,0,0.15)',
      transition: 'all 1s ease-in-out',
    },
  },
  scrollBarBody: {
    scrollBehavior: 'smooth' as
      | '-moz-initial'
      | 'inherit'
      | 'initial'
      | 'revert'
      | 'unset'
      | 'auto'
      | 'smooth',
    scrollbarWidth: 'thin' as
      | '-moz-initial'
      | 'inherit'
      | 'initial'
      | 'revert'
      | 'unset'
      | 'auto'
      | 'none'
      | 'thin',
    scrollbarColor: 'rgba(0,0,0,0.25) transparent',

    '&::-webkit-scrollbar': {
      width: 8,
      height: 8,
      transition: 'all 1s ease-in-out',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 10,
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 2,
      backgroundColor: 'rgba(0,0,0,0.15)',
      transition: 'all 1s ease-in-out',
    },
  },
  listReset: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  buttonReset: {
    padding: 0,
    border: 'none',
    font: 'inherit',
    color: 'inherit',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  visuallyHidden: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    margin: '-1px',
    padding: 0,
    overflow: 'hidden',
    border: 0,
    clip: 'rect(0 0 0 0)',
  },
  textOverflow: {
    textOverflow: 'ellipsis' as 'ellipsis',
    whiteSpace: 'nowrap' as 'nowrap',
    overflow: 'hidden' as 'hidden',
  },
};

export default mixins;
