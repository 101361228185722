import React, { RefObject } from 'react';
import { useLocation } from 'react-router';
import qs from 'query-string';

export function useModal() {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpenModal = (e?: any) => {
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleToggleModal = () => {
    setIsOpen((state) => !state);
  };

  return {
    isOpen,
    setIsOpen,
    handleOpenModal,
    handleCloseModal,
    handleToggleModal,
  };
}

export function useQuery() {
  const { search } = useLocation();
  return qs.parse(search);
}

export function usePaginationQuery() {
  const params: Record<string, any> = useQuery();

  params &&
    Object.keys(params).forEach(function (key) {
      const value = params[key];

      if (key === 'page' && !!value && !+value) {
        delete params.page;
      }

      if (key.startsWith('filter.')) {
        if (!params.filter) {
          params.filter = {};
        }

        params.filter[key.replace('filter.', '')] = value;
      }
    });

  return params;
}

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = React.useRef(callback);

  // Remember the latest callback if it changes.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

type AnyEvent = MouseEvent | TouchEvent;

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: AnyEvent) => void,
) {
  React.useEffect(() => {
    const listener = (event: AnyEvent) => {
      const el = ref?.current;

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);

    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };

    // Reload only if ref or handler changes
  }, [ref, handler]);
}

export function useDebounce(value: any, delay: number = 500) {
  const [debouncedValue, setDebouncedValue] = React.useState<any>(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
