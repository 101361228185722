import { useParams, useRouteMatch } from 'react-router-dom';
import { TBreadcrumbsItem } from '../../../components/connected/anv-breadcrumbs-container';
import { ROUTES } from '../../../constants';
import { IParamsFolders } from '../../../types/storage-zone';
import { SZCustomBreadCrumbs } from './common';

export const useRouteMatchEntity = () => {
  const isPersonalZoneEE = useRouteMatch([
    ROUTES.MODULE_FILE_EXPLORER_VIEW_EE_PREFIX,
    ROUTES.MODULE_FILE_EXPLORER_VIEW_EE_SEARCH_PREFIX,
  ])?.isExact;

  const isPersonalZoneFamilyCircle = useRouteMatch(
    ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_FAMILY_CIRCLE_PREFIX,
  )?.isExact;

  const isWorkZone = useRouteMatch(ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_PREFIX)
    ?.isExact;
  const isWorkZoneCompliance = useRouteMatch(
    ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_PREFIX,
  )?.isExact;

  const isPersonalZoneGovernance = useRouteMatch(
    ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_GOVERNING_BOARDS_PREFIX,
  )?.isExact;

  const isWorkZoneGovernance = useRouteMatch(
    ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_GOVERNING_BOARDS_PREFIX,
  )?.isExact;

  const isSearchPageFO = useRouteMatch(
    ROUTES.MODULE_FILE_ORGANIZER_SEARCH_PREFIX,
  )?.isExact;

  const isSearchPageFEEduEntities = useRouteMatch(
    ROUTES.MODULE_FILE_EXPLORER_VIEW_EE_SEARCH_PREFIX,
  )?.isExact;

  const isSearchPage = isSearchPageFO || isSearchPageFEEduEntities;

  const routeView = isPersonalZoneFamilyCircle
    ? ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_FAMILY_CIRCLE
    : isPersonalZoneEE
    ? ROUTES.MODULE_FILE_EXPLORER_VIEW_EE
    : undefined;
  // : ROUTES.MODULE_FILE_EXPLORER_VIEW;

  return {
    routeView,
    isPersonalZoneEE,
    isPersonalZoneFamilyCircle,
    isSearchPageFO,
    isWorkZone,
    isWorkZoneCompliance,
    isSearchPage,
    isSearchPageFEEduEntities,
    isPersonalZoneGovernance,
    isWorkZoneGovernance,
    // etc isWorkZoneCompliance
  };
};

export const useRouteMatchEntityBCItems = (): TBreadcrumbsItem[] => {
  const items: TBreadcrumbsItem[] = [];

  const params = useParams<IParamsFolders>();
  const { rootId } = params;

  const {
    isPersonalZoneFamilyCircle,
    isPersonalZoneEE,
    isSearchPageFEEduEntities,
  } = useRouteMatchEntity();

  if (isPersonalZoneEE || isSearchPageFEEduEntities) {
    // items.push(SZCustomBreadCrumbs.personalZone);
    items.push({
      ...SZCustomBreadCrumbs.personalZoneEE,
      to: rootId ? SZCustomBreadCrumbs.personalZoneEE.to : undefined,
    });
  }

  if (isPersonalZoneFamilyCircle) {
    // items.push(SZCustomBreadCrumbs.personalZone);
    items.push({
      ...SZCustomBreadCrumbs.personalZoneFC,
      to: rootId ? SZCustomBreadCrumbs.personalZoneFC.to : undefined,
    });
  }

  return items;
};
