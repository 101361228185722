import React from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, NavLink, useHistory } from 'react-router-dom';

import { MenuItem } from '@material-ui/core';

import {
  AnvIcon,
  AnvMenu,
  AnvPaginationMui,
  AnvTable,
  AnvTableTitle,
  AnvTableWrapper,
  AnvUtils,
  Constants,
  DefaultIcons,
  ISelectOption,
  useCheckIsTableView,
  useCommonTable,
  useMenu,
  useModuleAccessHistory,
} from '@platform/front-lib';
import { ISZRootFolder } from '../../../models/storage-zone';

import { useStores } from '../../../stores';

import { ModuleContext } from '../../common/module-context';

import { AnvBreadcrumbsConnected } from '../../../components/connected/anv-breadcrumbs-container';

import { commonColumns } from './columns';

import { prepareAccessFilterParam } from '../../../utils';
import { ROUTES } from '../../../constants';
import { ListToolbarFilterCounter } from '../../common/tool-list-toolbar/ListToolbarFilterCounter';

interface IFileOrganizerTempProps {}

const defaultFilterState = {
  accessScope: '',
  organizationType: '',
  name: '',
  // directoryAccessible: '',
  // directoryPublishedAt: null,
  // directoryUpdatedAt: null,
};

const requestId = Constants.DEFAULT_REQUEST_ID;

export const FileOrganizerListScreen: React.FC<IFileOrganizerTempProps> = observer(
  () => {
    const { formatMessage, locale } = useIntl();

    const history = useHistory();

    const { closeMenu, handleClose: handleCloseMenu } = useMenu();

    const {
      authStores: { modulesFilter, isOperatorLoggedIn },
      fileOrganizerStore: {
        folderRootStore: {
          getList,
          createNewRootFolder,
          isLoadedList,

          data: entities,
          pagination,
          total,
          setPage,
          setFilter,
          setSort,

          sort: storeSorting,
          filter: storeFilter,
        },
      },
      metaStores: {
        organizationTypesStore: {
          data: organizationTypes,
          organizationTypesMap,
          getOrganizationTypes,
          isLoaded: isLoadedOrganizationTypes,
        },
      },
      uiStores: { tool },
    } = useStores();

    React.useEffect(() => {
      if (!isLoadedOrganizationTypes) {
        getOrganizationTypes();
      }
    }, [isLoadedOrganizationTypes, getOrganizationTypes]);

    const module = React.useContext(ModuleContext);

    useModuleAccessHistory({
      moduleCodeFallback: module,
    });

    const accessFilter = modulesFilter?.[module];

    const {
      sorting,
      page,
      filterState,
      filterMemo,
      memoQueryFilter,
      handleChangeFilterDate,
      handleChangeFilter,
      handleChangePhone,
      handleChangePage,
    } = useCommonTable({
      defaultFilterState,
      pagination,
      setPage,
      setFilter,
      setSort,
      storeSorting,
      storeFilter,
      requestId,
    });

    /** get entities */
    React.useEffect(() => {
      getList({
        accessFilter: prepareAccessFilterParam(accessFilter),
        page: +page,
        sort: sorting,
        filter: filterMemo,
        limit: Constants.PAGINATION_LIMIT,
      });
    }, [accessFilter, page, filterMemo, sorting, getList]);

    /** COLUMNS  */
    const organizationTypesOptions = React.useMemo(() => {
      const options: ISelectOption[] = AnvUtils.prepareOptions(
        organizationTypes,
        {
          locale,
          field: 'description',
        },
      );

      options.push({
        label: formatMessage({
          id: 'STORAGE_ZONE.ORGANIZER.LIST.FILTER.SCHOOL',
          defaultMessage: 'School',
        }),
        value: 'SCHOOL',
      });

      return options;
    }, [organizationTypes, locale, formatMessage]);

    const columns = React.useMemo(() => {
      return commonColumns(locale, formatMessage, {
        organizationTypesOptions,
        organizationTypesMap,
      });
    }, [formatMessage, locale, organizationTypesOptions, organizationTypesMap]);

    const data = React.useMemo(() => {
      return entities || [];
    }, [entities]);

    const handleCreateRootFolder = (orgId: string) => () => {
      handleCloseMenu();
      const successFunc = (response: ISZRootFolder) => {
        const url = generatePath(ROUTES.MODULE_FILE_ORGANIZER_VIEW, {
          rootId: response?._id,
          folders: response?.folderId,
        });
        history.replace(url);
      };

      createNewRootFolder({ id: orgId }, { successFunc });
    };

    const { isShowTable } = useCheckIsTableView({
      tableDataIsLoaded: isLoadedList,
      tableDataLength: entities?.length || 0,
      memoQueryFilter,
    });

    return (
      <>
        <AnvBreadcrumbsConnected
          tool={tool}
          moduleCode={module}
          isOperator={isOperatorLoggedIn}
        />

        <AnvTableWrapper>
          <AnvTableTitle
            tableTitle={
              <FormattedMessage
                id="STORAGE_ZONE.ORGANIZER.LIST.TITLE"
                defaultMessage="Entities List"
              />
            }
          >
            <ListToolbarFilterCounter total={total} />
          </AnvTableTitle>

          <AnvTable
            size={'small'}
            isShowTable={isShowTable}
            setFilter={setFilter}
            filterMemo={filterMemo}
            filterState={filterState}
            handleChangeFilterDate={handleChangeFilterDate}
            handleChangeFilter={handleChangeFilter}
            handleChangePhone={handleChangePhone}
            //common
            data={data}
            columns={columns}
            querySort={sorting}
            setSort={setSort}
            TableActionComponent={({ data }) => (
              <AnvMenu closeMenu={closeMenu}>
                {data?.rootFolderId && (
                  <MenuItem
                    component={NavLink}
                    to={generatePath(ROUTES.MODULE_FILE_ORGANIZER_VIEW, {
                      rootId: data?.rootFolderId,
                      folders: data?.rootFolderFirstLvlId,
                    })}
                    title={formatMessage({
                      id: 'STORAGE_ZONE.ORGANIZER.LIST.ACTION.VIEW_DETAILS',
                      defaultMessage: 'View Details',
                    })}
                    onClick={() => {
                      handleCloseMenu();
                    }}
                  >
                    <AnvIcon
                      icon={DefaultIcons.VIEW_DETAILS}
                      color={'inherit'}
                    />
                    <span>
                      <FormattedMessage
                        id="STORAGE_ZONE.ORGANIZER.LIST.ACTION.VIEW_DETAILS"
                        defaultMessage="View Details"
                      />
                    </span>
                  </MenuItem>
                )}

                {!data?.rootFolderId && (
                  <MenuItem
                    onClick={handleCreateRootFolder(data?._id)}
                    title={formatMessage({
                      id: 'STORAGE_ZONE.ORGANIZER.LIST.ACTION.VIEW_DETAILS',
                      defaultMessage: 'View Details',
                    })}
                  >
                    <AnvIcon
                      icon={DefaultIcons.VIEW_DETAILS}
                      color={'inherit'}
                    />
                    <span>
                      <FormattedMessage
                        id="STORAGE_ZONE.ORGANIZER.LIST.ACTION.VIEW_DETAILS"
                        defaultMessage="View Details"
                      />
                    </span>
                  </MenuItem>
                )}
              </AnvMenu>
            )}
            isLoadedList={isLoadedList}
            noDataMessage={formatMessage({
              id: 'STORAGE_ZONE.ORGANIZER.LIST.EMPTY_LIST.TEXT',
              defaultMessage: 'There is no entities in this list',
            })}
          />

          <AnvPaginationMui
            pagination={pagination}
            totalPages={pagination?.totalPages}
            handleChangePage={handleChangePage}
            page={+page}
          />
        </AnvTableWrapper>
      </>
    );
  },
);
