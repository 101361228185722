import React from 'react';

import { observer } from 'mobx-react-lite';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';

import {
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import {
  getItemById,
  getNameByLangNullable,
  prepareOptions,
} from '../../utils';

import { FILTER_SETTINGS } from './constants-filter';
import { ModuleRequestScopeGovBody } from './form-parts/ScopeFormGovBody';
import { useStyles } from './styles';
import { ACCESS_SCOPES } from '../../constants';

import {
  useModal,
  AnvDialogBody,
  AnvButtonGroup,
  AnvButton,
  AnvIcon,
  IsLoadingComponent,
  AnvSelect,
  AnvSchoolName,
  AnvAccessHistoryModal,
  GroupHeaderAutocomplete,
} from '@platform/front-lib';
import {
  ModuleRequestScopeSchool as ScopeFilterSchool,
  ModuleScopeFilterOrganization as ScopeFilterOrganization,
} from './form-parts';
import { defaultMetaStore, IProps, ScopeFormContext } from './common';

export interface IGetAccessHistoryParams {
  filter?: any;
  getWithTotal?: boolean;
  [index: string]: any;
}

export const GovScopeFormFilterLoc: React.FC<IProps> = observer(
  ({
    isModal = true,
    forceHideFilterTabs = false,
    metaStores,
    particularModule,
    particularTool,
    particularAccessScope,
    hideTool,
    hideModule,
    hideTags,
    hideAccessScope,
    withHistory = false,

    schoolRequired,
    organizationRequired,

    displayEmptyTool = true,
    displayEmptyModule = true,
    displayEmptyAccessScope = true,

    onlyMyAccessByModule,
    isOneLevelAccess,

    tools = [],
    modules = [],
    organizations = [],
    jurisdictions = [],
    schools = [],
    // accessScopes = [],

    moduleSettings,

    accessScopeStore,
    defaultJurisdiction,

    existsData,
    serverErrors,
    children,
  }) => {
    const { locale, formatMessage } = useIntl();
    const { setValue, control, register, getValues } = useFormContext();

    React.useEffect(() => {
      register('accessScope');
    });

    const classes = useStyles();

    const moduleWatcher = useWatch({
      control,
      name: 'module',
      defaultValue: '',
    });

    const orgTypeWatcher = useWatch({
      control,
      name: 'scope.orgType',
      defaultValue: '',
    });

    //////////////////
    const { getAccessScopes } = accessScopeStore || {};
    const {
      organizationTypesStore: { data: organizationTypes },
      accessHistoryStore: {
        getRecentlyViewed,
        updateAccessHistoryState,
        recentlyViewed = [],
        total,
        totalArchive,
        isFetching: isFetchingAccessHistory,
      },
    } = metaStores || defaultMetaStore;

    const recentlyViewedEnabled = total > 0 || totalArchive > 0;

    React.useEffect(() => {
      if (getAccessScopes) {
        const filterAccess: Record<string, any> = {};

        if (onlyMyAccessByModule) {
          filterAccess.moduleCode = onlyMyAccessByModule;
          filterAccess.toolCode = particularTool;
        }

        getAccessScopes({ filterAccess });
      }
    }, [particularTool, onlyMyAccessByModule, getAccessScopes]);

    /////// HISTORY //////
    const {
      handleToggleModal: handleToggleHistory,
      isOpen: historyOpen,
    } = useModal();

    const [
      anchorHistory,
      setAnchorHistory,
    ] = React.useState<HTMLButtonElement | null>(null);

    const handleClickHistory = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorHistory(event.currentTarget);
    };

    const handleCloseHistory = () => {
      setAnchorHistory(null);
    };

    const handleMenuItemHistoryClick = (item: Record<string, any>) => () => {
      handleCloseHistory();

      if (item.school) {
        setValue('scope.jurisdiction', item.schoolRef?.jurisdiction || '');
        setValue('scope.tag', '');
        setValue('scope.school', item.school);
      } else if (item.organization) {
        if (item.organizationRef?.organizationType?.accessScope) {
          setValue(
            'accessScope',
            item.organizationRef?.organizationType?.accessScope,
          );
        }
        setValue('scope.orgType', item.organizationRef?.organizationTypeId);

        setValue(
          'scope.jurisdiction',
          item.organizationRef?.jurisdiction || '',
        );
        setValue('scope.organization', item.organization);
      }
    };

    const organizationTypeEntity =
      orgTypeWatcher && getItemById(organizationTypes, orgTypeWatcher);
    const orgAccessScope =
      organizationTypeEntity && organizationTypeEntity?.accessScope;

    let actualOrgAccessScope =
      orgAccessScope || existsData?.accessScope || null;
    actualOrgAccessScope =
      actualOrgAccessScope !== ACCESS_SCOPES.SCHOOL
        ? actualOrgAccessScope
        : null;

    const moduleSettingsSchoolFilter =
      moduleSettings?.[FILTER_SETTINGS.FILTER_BY_SCHOOL];

    const schoolFilterAvailable =
      !moduleSettingsSchoolFilter ||
      moduleSettingsSchoolFilter === 'true' ||
      moduleSettingsSchoolFilter?.value === 'true';

    const moduleSettingsOrganizationFilter =
      moduleSettings?.[FILTER_SETTINGS.FILTER_BY_ORGANIZATION];

    const organizationFilterAvailable =
      !moduleSettingsOrganizationFilter ||
      moduleSettingsOrganizationFilter === 'true' ||
      moduleSettingsOrganizationFilter?.value === 'true';

    // const organizationFilterParticular =
    //   moduleSettings?.[FILTER_SETTINGS.FILTER_PARTICULAR_ORGANIZATION] ===
    //   'true';

    const isTabsTypeAvailable =
      schoolFilterAvailable && organizationFilterAvailable;

    const [filterTabOrganization, setFilterTabOrganization] = React.useState(
      (existsData?.accessScope &&
        existsData?.accessScope !== ACCESS_SCOPES.SCHOOL) ||
        !schoolFilterAvailable,
    );

    const [prevTabState, setPrevTabState] = React.useState<Record<string, any>>(
      {},
    );

    const handleChangeTab = (isOrganization = false) => () => {
      setFilterTabOrganization(isOrganization);

      const currentValues = getValues();

      if (isOrganization && !filterTabOrganization) {
        setValue(
          'accessScope',
          prevTabState?.accessScope || actualOrgAccessScope || '',
        );

        setValue(
          'scope.jurisdiction',
          prevTabState?.scope?.jurisdiction || null,
        );
        setValue(
          'scope.organizationThird',
          prevTabState?.scope?.organizationThird || null,
        );
        setValue(
          'scope.organizationSecondary',
          prevTabState?.scope?.organizationSecondary || null,
        );
        setValue(
          'scope.organization',
          prevTabState?.scope?.organization || null,
        );
        setValue('scope.orgType', prevTabState?.scope?.orgType || null);

        setValue('scope.tags', null);
        setValue('scope.school', null);
      }

      if (!isOrganization && filterTabOrganization) {
        setValue('accessScope', ACCESS_SCOPES.SCHOOL);

        setValue(
          'scope.jurisdiction',
          prevTabState?.scope?.jurisdiction || null,
        );
        setValue('scope.tags', prevTabState?.scope?.tags || null);
        setValue('scope.school', prevTabState?.scope?.school || null);

        setValue('scope.organizationThird', null);
        setValue('scope.organizationSecondary', null);
        setValue('scope.organization', null);
        setValue('scope.orgType', null);
      }

      setPrevTabState({
        accessScope: currentValues?.accessScope || '',
        scope: currentValues?.scope || {},
      });
    };

    const getRecentlyViewedCallback = React.useCallback(() => {
      const payload: IGetAccessHistoryParams = {
        moduleCode: moduleWatcher || particularModule,
      };

      if (!filterTabOrganization) {
        payload.accessScope = ACCESS_SCOPES.SCHOOL;
      }

      if (filterTabOrganization) {
        payload.isOrganization = true;
      }

      return getRecentlyViewed(payload);
    }, [
      getRecentlyViewed,
      moduleWatcher,
      filterTabOrganization,
      particularModule,
    ]);

    React.useEffect(() => {
      if (withHistory && (moduleWatcher || particularModule)) {
        getRecentlyViewedCallback();
      }
    }, [
      getRecentlyViewedCallback,
      moduleWatcher,
      particularModule,
      withHistory,
    ]);
    ///// history end

    const toolsOptions =
      !hideTool && tools
        ? prepareOptions(tools, {
            locale,
            customId: 'code',
          })
        : [];
    const moduleOptions =
      !hideModule && modules
        ? prepareOptions(
            modules?.filter((item) => item?.restricted),
            {
              locale,
              fallbackFunc: (item) => item?.moduleCode,
            },
          )
        : [];

    const handleToArchive = (item: any) => (ev: any) => {
      ev.stopPropagation();
      updateAccessHistoryState(
        {
          id: item._id,
          active: false,
          moduleCode: particularModule,
          school: item?.school,
          organization: item?.organization,
        },
        {
          successFunc: () => {
            getRecentlyViewedCallback();
          },
        },
      );
    };

    const Wrapper = isModal ? AnvDialogBody : React.Fragment;

    return (
      <ScopeFormContext.Provider
        value={{
          jurisdictions,
          existsData,
          hideTags,
          onlyMyAccessByModule,
          isOneLevelAccess,
          metaStores,
          particularModule,
          particularTool,

          withHistory,
          serverErrors,

          schoolRequired,
          organizationRequired,
        }}
      >
        <div style={{ position: 'relative' }}>
          <Wrapper>
            {!hideTool && (
              <AnvSelect
                required
                control={control}
                displayEmpty={displayEmptyTool}
                name={'tool'}
                disabled={!!particularTool}
                label={
                  <FormattedMessage
                    id="LAUNCHPAD.TOOL.LABEL.TOOL"
                    defaultMessage="Tool"
                  />
                }
                options={toolsOptions}
                defaultValue={particularTool}
              />
            )}

            {!hideModule && (
              <AnvSelect
                required
                control={control}
                displayEmpty={displayEmptyModule}
                name={'module'}
                label={
                  <FormattedMessage
                    id="COMMON.ACCESS.LABEL.MODULE"
                    defaultMessage="Module"
                  />
                }
                disabled={!!particularModule}
                options={moduleOptions}
                value={particularModule}
              />
            )}

            <Grid
              container
              justify={'space-between'}
              alignItems={'center'}
              className={clsx({
                [classes.mainTabs]:
                  (isTabsTypeAvailable && !particularAccessScope) ||
                  recentlyViewedEnabled,
              })}
            >
              {!forceHideFilterTabs && (
                <Grid item>
                  {isTabsTypeAvailable && !particularAccessScope && (
                    <AnvButtonGroup>
                      <AnvButton
                        inactive={!filterTabOrganization}
                        color={'primary'}
                        variant={
                          filterTabOrganization ? 'outlined' : 'contained'
                        }
                        onClick={handleChangeTab(false)}
                      >
                        <FormattedMessage
                          id="APP.COMMON.FILTER.HISTORY.BY_SCHOOL"
                          defaultMessage="By School"
                        />
                      </AnvButton>
                      <AnvButton
                        inactive={filterTabOrganization}
                        color={'primary'}
                        variant={
                          !filterTabOrganization ? 'outlined' : 'contained'
                        }
                        onClick={handleChangeTab(true)}
                      >
                        <FormattedMessage
                          id="APP.COMMON.FILTER.HISTORY.BY_ORGANIZATION"
                          defaultMessage="By Organization"
                        />
                      </AnvButton>
                    </AnvButtonGroup>
                  )}
                </Grid>
              )}

              <Grid item>
                {withHistory && recentlyViewedEnabled && (
                  <>
                    <AnvButton
                      className={classes.recentlyButton}
                      disabled={isFetchingAccessHistory}
                      size={'small'}
                      variant={'text'}
                      color={'primary'}
                      startIcon={<AnvIcon icon={'history'} size={16} />}
                      endIcon={
                        isFetchingAccessHistory ? (
                          <CircularProgress size={12} />
                        ) : (
                          <ExpandMore
                            className={clsx(classes.expandMore, {
                              [classes.expandRotate]: !!anchorHistory,
                            })}
                          />
                        )
                      }
                      onClick={handleClickHistory}
                    >
                      <FormattedMessage
                        id="APP.COMMON.FILTER.HISTORY.BUTTON"
                        defaultMessage="Recently Viewed"
                      />
                    </AnvButton>

                    <Menu
                      id="lock-menu"
                      open={!!anchorHistory}
                      anchorEl={anchorHistory}
                      onClose={handleCloseHistory}
                      keepMounted
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      classes={{ list: classes.customList }}
                    >
                      {!!anchorHistory && (
                        <IsLoadingComponent
                          isAbsolute
                          isLoading={isFetchingAccessHistory}
                        />
                      )}

                      <div className={classes.fixedHeader}>
                        <GroupHeaderAutocomplete
                          title={
                            <FormattedMessage
                              id={'APP.COMMON.FILTER.HISTORY.HEADER'}
                              defaultMessage={'Recently viewed'}
                            />
                          }
                        >
                          <AnvButton
                            className={classes.autocompleteHeaderButton}
                            variant={'text'}
                            color={'primary'}
                            onClick={handleToggleHistory}
                          >
                            <FormattedMessage
                              id="APP.COMMON.FILTER.ARCHIVE"
                              defaultMessage="Archive"
                            />
                          </AnvButton>
                        </GroupHeaderAutocomplete>

                        <Divider
                          style={{
                            margin: '2px 0',
                          }}
                        />
                      </div>
                      <div className={classes.replaceRectangle} />

                      {!recentlyViewed?.length && (
                        <div className={classes.emptyRecentlyViewText}>
                          <FormattedMessage
                            id="APP.COMMON.RECENTLY_VIEWED.EMPTY_LIST.TEXT"
                            defaultMessage="There are no recently viewed items"
                          />
                        </div>
                      )}

                      {recentlyViewed.map((item: Record<string, any>) => {
                        let name = item?.schoolRef ? (
                          <AnvSchoolName
                            school={item.schoolRef}
                            className={classes.schoolName}
                          />
                        ) : (
                          getNameByLangNullable(
                            item.organizationRef,
                            'name',
                            locale,
                          )
                        );

                        if (!name) return null;

                        return (
                          <MenuItem
                            key={item?._id}
                            onClick={handleMenuItemHistoryClick(item)}
                            className={classes.customListItem}
                            disableRipple
                          >
                            <span>{name}</span>
                            <IconButton
                              title={formatMessage({
                                id: 'APP.MODAL.HISTORY.ARCHIVE.ACTION.ARCHIVE',
                                defaultMessage: 'Archive',
                              })}
                              disabled={isFetchingAccessHistory}
                              onClick={handleToArchive(item)}
                            >
                              <AnvIcon
                                icon={'filterArchive'}
                                size={24}
                                color={'lightIcons'}
                              />
                            </IconButton>
                          </MenuItem>
                        );
                      })}
                    </Menu>

                    <AnvAccessHistoryModal
                      handleToggle={handleToggleHistory}
                      open={historyOpen}
                      module={particularModule || moduleWatcher}
                      isOrganization={filterTabOrganization}
                    />
                  </>
                )}
              </Grid>
            </Grid>

            {/* SCHOOL */}
            <Collapse in={!filterTabOrganization}>
              <ScopeFilterSchool
                active={!filterTabOrganization}
                jurisdictions={jurisdictions}
                defaultJurisdiction={defaultJurisdiction}
              >
                {children}
              </ScopeFilterSchool>
              <div
                style={{
                  height: 16,
                }}
              />
              <ModuleRequestScopeGovBody />
            </Collapse>

            {/* Org */}
            <Collapse in={filterTabOrganization}>
              <ScopeFilterOrganization
                active={filterTabOrganization}
                jurisdictions={jurisdictions}
              >
                {children}
              </ScopeFilterOrganization>
              <div
                style={{
                  height: 16,
                }}
              />
              <ModuleRequestScopeGovBody />
            </Collapse>
          </Wrapper>
        </div>
      </ScopeFormContext.Provider>
    );
  },
);
