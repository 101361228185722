import React from 'react';
import { Column } from 'react-table';

import { AnvIcon, AnvUtils, Constants } from '@platform/front-lib';

import { IFormatMessage } from 'src/models/_helpers/react-intl';

export function prepareColumns(
  locale: string | undefined,
  formatMessage: IFormatMessage,
) {
  const columns: Record<string, any>[] = [
    {
      width: 30,
      Header: '',
      headerString: '',
      id: 'icon',

      Cell: () => {
        return (
          <div>
            <AnvIcon icon={'fileListDefault'} size={40} />
          </div>
        );
      },
    },
    {
      width: 'auto',
      sortable: true,
      Header: () => {
        return formatMessage({
          id: 'STORAGE_ZONE.FILES.LIST.TH.FILE_NAME',
          defaultMessage: 'Name',
        });
      },
      headerString: formatMessage({
        id: 'STORAGE_ZONE.FILES.LIST.TH.FILE_NAME',
        defaultMessage: 'Name',
      }),
      accessor: 'title',
      id: 'title',

      Cell: ({ cell }: Record<string, any>) => {
        const entity = cell?.row?.original;

        return <div>{entity?.title}</div>;
      },
    },

    // show file type in right drawer instead
    // {
    //   maxWidth: 180,
    //   // sortable: true,
    //   Header: () => {
    //     return formatMessage({
    //       id: 'STORAGE_ZONE.FILES.LIST.TH.FILE_TYPE',
    //       defaultMessage: 'Type',
    //     });
    //   },
    //   headerString: formatMessage({
    //     id: 'STORAGE_ZONE.FILES.LIST.TH.FILE_TYPE',
    //     defaultMessage: 'Type',
    //   }),
    //   accessor: 'fileType',
    //   id: 'fileType',
    //
    //   Cell: ({ cell }: Record<string, any>) => {
    //     const entity = cell?.row?.original;
    //
    //     return <div>{entity?.fileType}</div>;
    //   },
    // },

    {
      // sortable: true,
      maxWidth: 120,
      Header: () => {
        return formatMessage({
          id: 'STORAGE_ZONE.FILES.LIST.TH.FILE_SIZE',
          defaultMessage: 'File Size',
        });
      },
      headerString: formatMessage({
        id: 'STORAGE_ZONE.FILES.LIST.TH.FILE_SIZE',
        defaultMessage: 'File Size',
      }),
      accessor: 'fileSize',
      id: 'fileSize',

      Cell: ({ cell }: Record<string, any>) => {
        const entity = cell?.row?.original;

        return <div>{AnvUtils.formatBytes(entity?.fileSize)}</div>;
      },
    },
  ];

  columns.push({
    sortable: true,
    maxWidth: 180,
    Header: () => {
      return formatMessage({
        id: 'STORAGE_ZONE.FILES.LIST.TH.UPLOAD_TIME',
        defaultMessage: 'Upload Time',
      });
    },
    headerString: formatMessage({
      id: 'STORAGE_ZONE.FILES.LIST.TH.UPLOAD_TIME',
      defaultMessage: 'Upload Time',
    }),
    accessor: 'createdAt',
    id: 'createdAt',

    Cell: ({ cell }: Record<string, any>) => {
      const entity = cell?.row?.original;

      return (
        <div>
          {AnvUtils.formatWLocale(
            entity?.createdAt,
            Constants.DATE_FORMATS.DEFAULT_FORMAT_WITH_TIME,
          )}
        </div>
      );
    },
  });

  return columns as Column<Record<string, any>>[];
}
