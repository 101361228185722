import React from 'react';

import { envConst } from 'src/constants';
import { useQuery } from 'src/utils/hooks';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../stores';

const RedirectSourceSystemFail = observer(() => {
  const {
    authStores: { silentReturnUrl },
  } = useStores();

  const query = useQuery();
  const queryReturnUrl = (!silentReturnUrl && query?.returnUrl) || '';

  window.location.href = `${envConst?.launchpadUrlLogOut}/${
    queryReturnUrl ? '?returnUrl=' + queryReturnUrl : ''
  }`;

  return (
    <div className="login__container">
      <div className="login__form">
        {/*<FormattedMessage*/}
        {/*  id="APP.PERMISSION_DENIED"*/}
        {/*  defaultMessage="Permission Denied"*/}
        {/*/>*/}
      </div>
    </div>
  );
});

export default RedirectSourceSystemFail;
