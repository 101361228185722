import React from 'react';

import { FILE_EXPLORER_SUB_MODULES } from '../../../../constants';
import { SubModuleContext } from '../../../../contexts/sub-module';
import { CommonGoverningBoardsViewPage } from '../../common/governance/view';

import { observer } from 'mobx-react-lite';

interface IWorkZoneGoverningBoardsViewPageProps {
  children?: React.ReactNode;
}

export const WorkZoneGoverningBoardsViewPage: React.FC<IWorkZoneGoverningBoardsViewPageProps> = observer(
  () => {
    return (
      <SubModuleContext.Provider value={FILE_EXPLORER_SUB_MODULES.WORK_ZONE}>
        <CommonGoverningBoardsViewPage />
      </SubModuleContext.Provider>
    );
  },
);
