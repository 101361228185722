import { ApiServiceCommon } from '@platform/front-lib';
import { IGetList } from '../../../../service/interfaces';

interface IGetListBuild extends IGetList {
  rootFolders?: boolean;
}

export class GoverningBoardsService extends ApiServiceCommon {
  private subModule: string;

  constructor(module: string, collection: string, subModule: string) {
    super(module, collection);

    this.subModule = subModule;
  }

  getList(data: IGetListBuild) {
    return this.axiosCatch(
      this.axios.get(
        `${this.apiUrl}/${this.subModule}/governing-boards${
          data?.rootFolders ? '/root' : ''
        }`,
        {
          params: data,
        },
      ),
    );
  }
}
