import { IActionMeta } from '@platform/front-lib/dist/stores/_helpers';
import { action, makeObservable, observable } from 'mobx';

import { ISZFileObject } from '../../../models/storage-zone';
import { FilesCommonService } from './files-common-service';
import { CommonListAdvancedStore } from '../../common/list-advanced-store';
import { IGetList } from '../../../service/interfaces';

export interface IGetOneFilePayload {
  id: string;
}
export interface IDeleteFilePayload {
  id: string;
}
export interface IUpdateFilePayload {
  id: string;
  title: string;
  publicAccess: boolean;
}
export interface IMoveFilePayload {
  id: string;
  newParentId: string;
}
export interface ICreateFilePayload extends Omit<IUpdateFilePayload, 'id'> {
  parentFolder: string;
}

export class FilesCommonStore<T> extends CommonListAdvancedStore<
  T,
  ISZFileObject
> {
  service: FilesCommonService;

  @observable
  error: any = null;

  @observable
  errorItem: Record<string, any> | null | string = null;
  @observable
  item: ISZFileObject | null = null;

  @observable isFetching: boolean = false;
  @observable isSaving: boolean = false;
  @observable isLoaded: boolean = false;
  @observable isError: boolean = false;

  @observable isFetchingChain: boolean = false;
  @observable isSavingChain: boolean = false;
  @observable isLoadedChain: boolean = false;
  @observable isErrorChain: boolean = false;

  @action
  getFilesByFolder = async (payload: IGetList, meta?: IActionMeta) => {
    this.setLoading();

    this.errorItem = null;
    const [error, response] = await this.service.getList(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.errorItem = error || response.error;
      });
    }

    this.setLoaded(() => {
      this.dataMap[response?._id] = response;
      meta?.successFunc?.(response);
    });
  };

  @action
  getOne = async (payload: IGetOneFilePayload, meta?: IActionMeta) => {
    this.setLoading();

    this.errorItem = null;
    const [error, response] = await this.service.getOne(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.errorItem = error || response.error;
      });
    }

    this.setLoaded(() => {
      this.dataMap[response?._id] = response;
      meta?.successFunc?.(response);
    });
  };

  @action
  resetError = () => {
    this.errorItem = null;
    this.isError = false;
    this.error = null;
  };

  @action
  private reset = () => {
    this.dataByRequestId = {};
    this.dataMap = {};
    this.isFetching = false;
    this.isLoaded = false;
    this.isError = false;
    this.error = null;
  };

  // common
  @action
  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };
  @action
  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();

    // [error, success]
    return [true, false];
  };
  @action
  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();

    // [error, success]
    return [null, true];
  };

  constructor({ isView }: { isView: boolean }) {
    super();
    this.service = new FilesCommonService({ isView });
    makeObservable(this);
  }
}
