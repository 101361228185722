import { CommonListAdvancedStoreCRUD } from '@platform/front-lib';
import { IFile } from '@platform/front-lib/dist/models';

import { ComplianceFilesService } from './service';

export class ComplianceFilesStore extends CommonListAdvancedStoreCRUD<
  ComplianceFilesService,
  IFile
> {
  constructor(module: string, sessionKey?: string, disableAutoSave?: boolean) {
    super({ sessionKey, disableAutoSave });
    this.service = new ComplianceFilesService(module, 'compliance-files');
  }
}
