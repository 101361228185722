import { AnvUtils, Constants } from '@platform/front-lib';
import {
  IActionMeta,
  IPagination,
} from '@platform/front-lib/dist/stores/_helpers';
import { action, computed, makeObservable, observable } from 'mobx';
import axios, { CancelTokenSource } from 'axios';

interface ISetStateMeta {
  history?: any;
  requestId?: string;
  ignorePage?: boolean;
}
interface ISetStateRequestId {
  requestId?: string;
}

interface IListParams {
  [index: string]: any;
}

const defaultRequest = 'default';

export class CommonListAdvancedStore<T, V extends { _id: string }> {
  service: any | T;

  cancelToken: Record<string, CancelTokenSource | null> = {};

  @observable
  errorListMap: Record<string, any> = { [defaultRequest]: null };
  @observable isFetchingListMap: Record<string, boolean> = {
    [defaultRequest]: false,
  };
  @observable isLoadedListMap: Record<string, boolean> = {
    [defaultRequest]: false,
  };
  @observable isErrorListMap: Record<string, boolean> = {
    [defaultRequest]: false,
  };

  @observable
  dataByRequestId: Record<string, V[]> = {};

  @observable
  dataMap: Record<string, V> = {};

  @observable
  totalMap: Record<string, number> = {
    [defaultRequest]: 0,
  };
  @observable
  paginationMap: Record<string, IPagination> = {
    [defaultRequest]: Constants.DEFAULT_PAGINATION,
  };
  @observable
  sortMap: Record<string, string> = { [defaultRequest]: '' };
  @observable
  filterMap: Record<string, any[] | Record<string, any>> = {};

  @observable
  listParams: IListParams = {};

  @action
  setDataMapById = ({
    id,
    entity,
    requestId = Constants.DEFAULT_REQUEST_ID,
    setOnlyMap = false,
  }: {
    id: string;
    entity: V;
    requestId?: string;
    setOnlyMap?: boolean;
  }) => {
    const prevEntity = this.dataMap?.[id] || Constants.mockObj;
    this.dataMap[id] = { ...prevEntity, ...entity };

    if (!setOnlyMap) {
      const existIndexInDataByRequestId = this.dataByRequestId[
        requestId
      ]?.findIndex((item) => {
        return item._id === id;
      });

      if (existIndexInDataByRequestId > -1) {
        this.dataByRequestId[requestId][existIndexInDataByRequestId] = entity;
      } else {
        if (!this.dataByRequestId?.[requestId]) {
          this.dataByRequestId[requestId] = [];
        }

        this.dataByRequestId?.[requestId]?.push?.(entity);
      }

      this.dataByRequestId[requestId] = [...this.dataByRequestId[requestId]];
    }
  };

  @action
  setListParams = (params: IListParams) => {
    this.listParams = params;
  };

  @action
  getList = async (payload: Record<string, any>, meta?: IActionMeta) => {
    if (this.service?.getList) {
      this.setLoadingList();

      const requestId = meta?.requestId || 'default';
      if (this.cancelToken[requestId]) {
        this.cancelToken[requestId]?.cancel();
      }

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      this.cancelToken[requestId] = source;

      this.setListParams(payload);

      const [error, response] = await this.service?.getList?.(
        {
          ...this.listParams,
          ...payload,
        },
        source,
      );

      if (error || response?.error) {
        return this.setErrorList(() => {
          this.errorListMap[requestId] = error || response?.error;

          if (!axios.isCancel(error)) {
            this.errorListMap = error || response.error;
          }
        });
      }

      this.setLoadedList(() => {
        const { docs, totalDocs, distinct, ...pagination } = response;
        this.paginationMap[requestId] = pagination;
        this.totalMap[requestId] = totalDocs;

        this.dataByRequestId[requestId] = docs?.map((item: V) => {
          if (item._id) {
            this.dataMap[item._id] = item;
          }

          return item;
        });
        meta?.successFunc?.();
      });
    }
  };

  @computed
  get data() {
    return this.dataByRequestId[defaultRequest];
  }
  @computed
  get total() {
    return this.totalMap[defaultRequest];
  }
  @computed
  get sort() {
    return this.sortMap[defaultRequest];
  }
  @computed
  get pagination() {
    return this.paginationMap[defaultRequest];
  }
  @computed
  get filter() {
    return this.filterMap[defaultRequest];
  }

  @computed
  get errorList() {
    return this.errorListMap[defaultRequest];
  }
  @computed
  get isFetchingList() {
    return this.isFetchingListMap[defaultRequest];
  }
  @computed
  get isLoadedList() {
    return this.isLoadedListMap[defaultRequest];
  }
  @computed
  get isErrorList() {
    return this.isErrorListMap[defaultRequest];
  }

  @action
  setPage = (
    page: number,
    { history, requestId = defaultRequest }: ISetStateMeta,
  ) => {
    if (history) {
      AnvUtils.historyPushPagination({ payload: { page }, history });
    }

    this.paginationMap[requestId as string].page = page;
  };
  @action
  setSort = (
    sort: string,
    { history, requestId = defaultRequest }: ISetStateMeta,
  ) => {
    if (history) {
      AnvUtils.historyPushPagination({ payload: { sort }, history });
    }

    this.sortMap[requestId] = sort;
  };
  @action
  setFilter = (
    filter: any[] | Record<string, any>,
    { history, requestId = defaultRequest, ignorePage }: ISetStateMeta,
  ) => {
    let filterParam = AnvUtils.preparePaginationFilterData(filter, {
      ignorePage,
    });
    if (history) {
      AnvUtils.historyPushPagination({
        payload: filterParam,
        history,
        isFilters: true,
      });
    }

    this.filterMap[requestId] = filter;
  };

  // common
  @action
  setLoadingList = (func?: () => void, meta?: ISetStateRequestId) => {
    const { requestId = defaultRequest } = meta || {};
    this.errorListMap[requestId] = null;
    this.isErrorListMap[requestId] = false;
    this.isFetchingListMap[requestId] = true;

    func?.();
  };
  @action
  setErrorList = (func?: () => void, meta?: ISetStateRequestId) => {
    const { requestId = defaultRequest } = meta || {};

    this.isFetchingListMap[requestId] = false;
    this.isErrorListMap[requestId] = true;

    func?.();
  };
  @action
  setLoadedList = (func?: () => void, meta?: ISetStateRequestId) => {
    const { requestId = defaultRequest } = meta || {};

    this.isFetchingListMap[requestId] = false;
    this.isLoadedListMap[requestId] = true;
    this.isErrorListMap[requestId] = false;

    func?.();
  };

  constructor() {
    makeObservable(this);
  }
}
