import { FolderFilesStore } from '../common/folder-files/store';
import FileOrganizerFolderStore from './folder-store';
import FileOrganizerFolderRootStore from './folder-root-store';
import FileOrganizerFileStore from './file-store';
import { action, makeObservable, observable } from 'mobx';

class FileOrganizerStoreClass {
  folderStore = FileOrganizerFolderStore;
  folderRootStore = FileOrganizerFolderRootStore;
  fileStore = FileOrganizerFileStore;

  folderFilesStore = new FolderFilesStore({
    isView: false,
  });

  activeFileId: string | null = null;

  setActiveFileId = (id: string | null) => {
    this.activeFileId = id;

    if (id && this.activeFolderId) {
      this.activeFolderId = null;
    }
  };

  activeFolderId: string | null = null;

  setActiveFolderId = (id: string | null) => {
    this.activeFolderId = id;
    if (id && this.activeFileId) {
      this.activeFileId = null;
    }
  };

  constructor() {
    makeObservable(this, {
      activeFileId: observable,
      activeFolderId: observable,
      setActiveFileId: action,
      setActiveFolderId: action,
    });
  }
}

export const FileOrganizerStore = new FileOrganizerStoreClass();
