import { ROUTES } from './routes';
import { USER_ROLES } from './auth';
import { TOOL_MODULES } from './tool';

const drawerClient: any[] = [
  {
    key: 'FILE_EXPLORER',
    module: TOOL_MODULES.FILE_EXPLORER,
    name: 'File Explorer',
    nameI18n: 'STORAGE_ZONE.DRAWER.FILE_EXPLORER',
    to: ROUTES.MODULE_FILE_EXPLORER,
    toPrefix: [
      ROUTES.MODULE_FILE_EXPLORER_PREFIX,
      ROUTES.MODULE_FILE_EXPLORER_VIEW_PREFIX,
      ROUTES.MODULE_FILE_EXPLORER_PERSONAL_ROOT,
    ],
    icon: 'dotsGrid',
    position: 1,
    items: [
      {
        key: 'FILE_EXPLORER_LIST',
        name: 'Organizations & Schools List',
        nameI18n: 'STORAGE_ZONE.DRAWER.FILE_EXPLORER.LIST',
        to: ROUTES.MODULE_FILE_EXPLORER,
        toPrefix: [
          ROUTES.MODULE_FILE_EXPLORER_PREFIX,
          ROUTES.MODULE_FILE_EXPLORER_VIEW_PREFIX,
          ROUTES.MODULE_FILE_EXPLORER_PERSONAL_ROOT,
        ],
        icon: 'reviewList',
        disabled: false,
      },
    ],
  },
  {
    key: 'FILE_ORGANIZER',
    module: TOOL_MODULES.FILE_ORGANIZER,
    name: 'File Organizer',
    nameI18n: 'STORAGE_ZONE.DRAWER.FILE_ORGANIZER',
    to: ROUTES.MODULE_FILE_ORGANIZER,
    toPrefix: [
      ROUTES.MODULE_FILE_ORGANIZER_VIEW_PREFIX,
      ROUTES.MODULE_FILE_ORGANIZER_LIST,
    ],
    icon: 'folders',
    position: 1,
    items: [
      {
        key: 'FILE_ORGANIZER_LIST',
        name: 'Organizations & Schools List',
        nameI18n: 'STORAGE_ZONE.DRAWER.FILE_ORGANIZER.LIST',
        to: ROUTES.MODULE_FILE_ORGANIZER,
        toPrefix: [
          ROUTES.MODULE_FILE_ORGANIZER_VIEW_PREFIX,
          ROUTES.MODULE_FILE_ORGANIZER_LIST,
        ],
        icon: 'structureOrg',
        disabled: false,
      },
    ],
  },

  {
    key: 'access',
    module: TOOL_MODULES.ACCESS,
    name: 'User Access Manager',
    nameI18n: 'STORAGE_ZONE.DRAWER.ACCESS',
    to: ROUTES.ROUTE_ACCESS_PREFIX,
    icon: 'moduleAccess',
    position: 3,
    disabled: false,
    items: [
      {
        key: 'user_access',
        name: 'User Access',
        nameI18n: 'STORAGE_ZONE.DRAWER.ACCESS.LIST',
        to: ROUTES.ADMIN_ACCESS_LIST,
        icon: 'userAccess',
        disabled: true,
      },
    ],
  },
];

export const drawerAllEntries = {
  [USER_ROLES.ROLE_CLIENT]: drawerClient,
};
