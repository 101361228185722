import { Grid } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

import { generatePath, useHistory, useParams } from 'react-router-dom';

import {
  AnvNotFoundPage,
  AnvUtils,
  IsLoadingComponent,
  useCommonOffsetsStyles,
  useModuleAccessHistory,
} from '@platform/front-lib';

import { IParamsFolders } from '../../../../../types/storage-zone';

import { useStores } from '../../../../../stores';

import { ContentPaper } from '../../../../../components/layout/content';
import { SZFoldersFilesListView } from '../../../../common/folder-files/list';

import { SZFoldersBreadcrumbs } from '../../../../common/folders-breadcrumbs';
import { ViewDrawerData } from '../../../../common/entity-drawer';

import { ROUTES, SZ_EVENT_TYPES } from '../../../../../constants';
import { ModuleContext } from '../../../../common/module-context';
import { SZToolbar } from '../../../../common/toolbar';

import { useStylesContentWDrawer } from './styles';
import {
  useRouteMatchEntity,
  useRouteMatchEntityBCItems,
} from '../../../../common/folders-breadcrumbs/use-route-match-entity';
import { SZBreadcrumbs } from '../../../../common/SZBreadcrumbs';
import { MyStorageZoneTitle } from '../../../common/title';
import { ExplorerMainTabs } from '../../../tabs';

interface IFileOrganizerViewProps {}

export const FileExplorerView: React.FC<IFileOrganizerViewProps> = observer(
  () => {
    const module = React.useContext(ModuleContext);

    const classes = useStylesContentWDrawer();
    const history = useHistory();

    const classesOffset = useCommonOffsetsStyles();

    const { locale } = useIntl();

    // const { url } = useRouteMatch();

    const params = useParams<IParamsFolders>();
    const { folders, rootId } = params;

    const items = useRouteMatchEntityBCItems();
    const {
      // routeView,
      isPersonalZoneEE,
    } = useRouteMatchEntity();

    const {
      authStores: { profile, profileOperator },
      fileExplorerStore: {
        activeFileId,
        activeFolderId,
        setActiveFolderId,
        setActiveFileId,
        folderStore,

        folderRootStore: {
          getOne: getOneRootFolders,
          dataMap: rootFoldersMap,
          isFetching: isFetchingRootFolders,
        },
        fileStore: { setListParams },
      },
    } = useStores();

    const userId = profile?._id || profileOperator?._id;

    React.useEffect(() => {
      return () => {
        setActiveFolderId(null);
        setActiveFileId(null);
      };
    }, [setActiveFolderId, setActiveFileId]);

    React.useEffect(() => {
      setListParams({});

      return () => {
        setListParams({});
      };
    }, [folders, setListParams]);

    const {
      // isFetching,
      // data: childFolders,

      getList: getChildFolders,
      getChain,
    } = folderStore;

    const rootEntity = rootFoldersMap?.[rootId];

    const isRelatedToEduEntity = [
      SZ_EVENT_TYPES.SCHOOL,
      SZ_EVENT_TYPES.ORGANIZATION,
    ].includes(rootEntity?.entityType || '');

    const isSchool = rootEntity?.entityType === SZ_EVENT_TYPES.SCHOOL;

    useModuleAccessHistory({
      moduleCodeFallback: module,
      entitiesRequired: isRelatedToEduEntity,
      data: {
        organization: !isSchool ? rootEntity?.organization : undefined,
        school: isSchool ? rootEntity?.school : undefined,
      },
    });

    if (rootId && !folders?.length && rootEntity) {
      const url = generatePath(ROUTES.MODULE_FILE_ORGANIZER_VIEW, {
        rootId: rootEntity?._id,
        folders: rootEntity?.folderId,
      });
      history.replace(url);
    }

    React.useEffect(() => {
      if (!rootEntity) {
        getOneRootFolders({ id: rootId });
      }
    }, [rootEntity, rootId, userId, getOneRootFolders]);

    React.useEffect(() => {
      if (rootId) {
        getChain({ rootId, folders: folders?.split?.('/') || [] });
      }
    }, [folders, rootId, userId, getChain]);

    React.useEffect(() => {
      if (rootId && folders) {
        const foldersArray = folders?.split('/');
        const lastId = foldersArray?.pop?.();

        if (lastId) {
          getChildFolders({ id: lastId, limit: 1_000 });
        }
      }
    }, [rootId, folders, userId, getChildFolders]);

    // reset active folder
    React.useEffect(() => {
      setActiveFolderId(null);
      setActiveFileId(null);
    }, [rootId, folders, setActiveFolderId, setActiveFileId]);

    // Error has occurred
    if (!rootEntity && !isFetchingRootFolders && isFetchingRootFolders) {
      return <AnvNotFoundPage />;
    }

    const rootEntityTitle = AnvUtils.getNameByLangNullable(
      rootEntity?.schoolRef || rootEntity?.organizationRef,
      'name',
      locale,
    );

    // todo
    const breadCrumbsItems = React.useMemo(() => {
      return [
        {
          name: 'entity',
          component: rootEntityTitle || '',
        },
      ];
    }, [rootEntityTitle]);

    return (
      <>
        <ViewDrawerData />

        <div
          data-clickaway={true}
          className={clsx(classes.mainContent, {
            [classes.mainContentWDrawer]: !!activeFolderId || !!activeFileId,
          })}
        >
          {!rootEntity && <IsLoadingComponent isLoading />}

          <SZBreadcrumbs items={breadCrumbsItems} />

          <ContentPaper>
            <Grid
              container
              spacing={2}
              justify={'space-between'}
              alignItems={'center'}
            >
              <Grid item>
                <MyStorageZoneTitle />
              </Grid>
              <Grid item>
                <SZToolbar />
              </Grid>
            </Grid>

            <ExplorerMainTabs />

            <div className={clsx(classesOffset.pb_2, classesOffset.pt_1)}>
              <SZFoldersBreadcrumbs
                rootLink={
                  isPersonalZoneEE
                    ? ROUTES.MODULE_FILE_EXPLORER_PERSONAL_ROOT
                    : ROUTES.MODULE_FILE_EXPLORER_PERSONAL_ROOT // todo work zone root
                }
                rootFolderName={
                  (isPersonalZoneEE && rootEntityTitle) || undefined
                }
                items={items}
              />
            </div>

            <SZFoldersFilesListView />
          </ContentPaper>
        </div>
      </>
    );
  },
);
