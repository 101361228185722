import { makeStyles, createStyles } from '@material-ui/core/styles';
import { BORDER_RADIUS } from '../../../../styles/constants';
import mixins from '../../../../styles/mixins';

const FOLDERS_LIST_CONTAINER = {
  maxHeight: 350,
  height: 350,
};

export const useMoveFolderStyles = makeStyles((theme) =>
  createStyles({
    titleControlFolderName: {
      flex: 1,
    },
    titleControlFolderNameControl: {
      marginBottom: 0,
    },
    titleControlSubmit: {
      minHeight: '100%',
      height: 53,
      marginTop: -4,
    },

    foldersListContainer: {
      ...FOLDERS_LIST_CONTAINER,
      overflow: 'hidden',

      background: '#FFFFFF',
      border: '1px solid rgba(3, 57, 61, 0.25)',
      borderRadius: BORDER_RADIUS,
    },

    folderListContainerWrapper: {
      overflow: 'auto',
      maxHeight: '100%',
    },

    foldersListContainerEmpty: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      color: theme.palette.text.disabled,
    },
    foldersListItem: {
      padding: theme.spacing(1, 2),

      cursor: 'pointer',
    },
    foldersListItemActive: {
      backgroundColor: theme.palette.brand.light,
    },
    foldersListItemDisabled: {
      opacity: 0.5,
      backgroundColor: theme.palette.background.default,
      cursor: 'default',
    },

    foldersListName: {
      maxWidth: 'calc(100% - 60px)',
      ...mixins.textOverflow,
    },
  }),
);
