import { IActionMeta } from '@platform/front-lib/dist/stores/_helpers';
import { action, makeObservable, observable } from 'mobx';

import { ISZRootFolder } from '../../../models';
import { CommonListStore } from '../../common/list-store';
import { FolderRootCommonService } from './folder-root-common-service';
import { IPayloadItemById } from '../../../service/interfaces';

export class FolderRootCommonStore<T> extends CommonListStore<
  T,
  ISZRootFolder
> {
  service: FolderRootCommonService;

  @observable
  error: any = null;

  @observable
  errorItem: Record<string, any> | null | string = null;
  @observable
  item: ISZRootFolder | null = null;

  @observable isFetching: boolean = false;
  @observable isSaving: boolean = false;
  @observable isLoaded: boolean = false;
  @observable isError: boolean = false;

  @observable isFetchingChain: boolean = false;
  @observable isSavingChain: boolean = false;
  @observable isLoadedChain: boolean = false;
  @observable isErrorChain: boolean = false;

  @action
  getOne = async (payload: IPayloadItemById, meta?: IActionMeta) => {
    this.setLoading();

    this.errorItem = null;
    const [error, response] = await this.service.getOne(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.errorItem = error || response.error;
      });
    }

    this.setLoaded(() => {
      this.dataMap[response?._id] = response;
      meta?.successFunc?.(response);
    });
  };

  @action
  createNewRootFolder = async (
    payload: IPayloadItemById,
    meta?: IActionMeta,
  ) => {
    this.setLoadingList();

    this.errorItem = null;
    const [error, response] = await this.service.createNewRootFolder(payload);

    if (error || response.error) {
      return this.setErrorList(() => {
        this.errorItem = error || response.error;
      });
    }

    this.setLoadedList(() => {
      // this.dataMap[response?._id] = response;
      meta?.successFunc?.(response);
    });
  };

  // common
  @action
  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };
  @action
  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();
  };
  @action
  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();
  };

  constructor({ isView }: { isView: boolean }) {
    super();
    this.service = new FolderRootCommonService({ isView });
    makeObservable(this);
  }
}
