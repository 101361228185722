import { useState } from 'react';

export function useStateMiddleware<T = undefined>(initialValue: T) {
  const [selectedValue, _setSelectedValue] = useState<T>(initialValue);

  return [
    selectedValue,
    (callback: () => void) => (value: T) => {
      _setSelectedValue(value);
      callback();
    },
  ] as const;
}
