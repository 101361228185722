import {
  AnvTableWrapper,
  IsLoadingComponent,
  useModuleAccessHistory,
} from '@platform/front-lib';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { AnvBreadcrumbsConnected } from '../../../components/connected/anv-breadcrumbs-container';
import {
  ROUTES,
  WZ_ROOT_VIRTUAL_FOLDERS,
  wzRootVirtualFolders,
} from '../../../constants';
import { useStores } from '../../../stores';
import { SZFoldersVirtualView } from '../../common/folders-virtual';
import { IVirtualFolder } from '../../common/folders-virtual/types';
import { ModuleContext } from '../../common/module-context';
import { useStylesContentWDrawer } from '../personal-zone/list-education-entities/view/styles';
import { ExplorerMainTabs } from '../tabs';
import { WorkZoneTitle } from './common';

interface IPageFEWorkZone {}

const requestId = wzRootVirtualFolders;

const ADDITIONAL_INFO_BY_VIRTUAL_FOLDER_ID = {
  [WZ_ROOT_VIRTUAL_FOLDERS.GOVERNING_BOARDS]: {
    folderName: (
      <FormattedMessage
        id={'STORAGE_ZONE.COMMON.VIRTUAL_FOLDER.GOVERNANCE_TOOL.NAME'}
        defaultMessage={'Governing Boards'}
      />
    ),
    to: generatePath(
      ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_GOVERNING_BOARDS,
      {},
    ),
  },
};

export const PageFEWorkZone: React.FC<IPageFEWorkZone> = observer(() => {
  const classes = useStylesContentWDrawer();

  const module = React.useContext(ModuleContext);

  useModuleAccessHistory({
    moduleCodeFallback: module,
  });

  const {
    fileExplorerStore: { setActiveFolderId, setActiveFileId },
    authStores: { isOperatorLoggedIn },
    uiStores: { tool },

    workZoneVirtualFoldersStore: { getList, getListMetaDataByRequestId },
  } = useStores();

  const { dataList, isFetchingList } = getListMetaDataByRequestId(requestId);

  React.useEffect(() => {
    return () => {
      setActiveFolderId(null);
      setActiveFileId(null);
    };
  }, [setActiveFolderId, setActiveFileId]);

  React.useEffect(() => {
    getList(
      {
        path: '',
      },
      {
        requestId: requestId,
      },
    );
  }, [getList]);

  const preparedVirtualFolders: IVirtualFolder[] = React.useMemo(() => {
    return (
      dataList?.map?.((virtualFolder) => {
        const additionalInfo =
          ADDITIONAL_INFO_BY_VIRTUAL_FOLDER_ID[virtualFolder._id];
        return {
          ...virtualFolder,
          ...additionalInfo,
          to: !!additionalInfo?.to
            ? additionalInfo.to
            : window.location.pathname + `/${virtualFolder.to}`,
        };
      }) || []
    );
  }, [dataList]);

  return (
    <>
      <div data-clickaway={true} className={classes.mainContent}>
        <IsLoadingComponent isLoading={isFetchingList} />

        <AnvBreadcrumbsConnected
          tool={tool}
          moduleCode={module}
          isOperator={isOperatorLoggedIn}
        />

        <AnvTableWrapper>
          <WorkZoneTitle />

          <ExplorerMainTabs />

          <div>
            <SZFoldersVirtualView
              virtualFolders={preparedVirtualFolders}
              showToolIcon
            />
          </div>
        </AnvTableWrapper>
      </div>
    </>
  );
});
