import { autorun } from 'mobx';
import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';

import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import {
  AnvPaginationMui,
  Constants,
  useCheckIsTableView,
  useCommonTable,
  AnvTable,
  IsLoadingComponent,
  useModal,
} from '@platform/front-lib';
import { ROUTES, SZ_ENTITY_KIND, TOOL_MODULES } from '../../../../constants';
import { useStateMiddleware } from '../../../../hooks/use-set-selected-value-middleware';
import { ISZFolderFile } from '../../../../models';
import { useStores } from '../../../../stores';
import { IParamsFolders } from '../../../../types/storage-zone';
import useClickPreventionOnDoubleClick from '../../../../utils/hook-useDoubleClick';
import { ModuleContext } from '../../module-context';
import { EntityDeleteDialog } from '../delete-modal';
import { EditFolderFileModal } from '../edit-modal';
import { MoveModal } from '../move-modal';
import { FolderFilesActions } from './actions';

import { commonFilesColumns } from './columns';

import { useFilesViewStyles } from './styles';

interface ISZFilesViewProps {
  handleMoveToFolder?: (params: {
    rootId: string;
    folders: string | undefined;
    id: string;
  }) => void;
}

export const SZFoldersFilesListView: React.FC<ISZFilesViewProps> = observer(
  ({ handleMoveToFolder }) => {
    const history = useHistory();
    const params = useParams<IParamsFolders>();
    const { folders, rootId } = params;

    const foldersArray = folders?.split('/');
    const lastId = foldersArray?.pop?.() as string;

    const requestId = `${rootId}_${lastId}_list-page`;

    const classes = useFilesViewStyles();

    const { formatMessage, locale } = useIntl();

    const module = React.useContext(ModuleContext);

    const isView = module === TOOL_MODULES.FILE_EXPLORER;

    const { fileExplorerStore, fileOrganizerStore } = useStores();

    const storageStore =
      module === TOOL_MODULES.FILE_ORGANIZER
        ? fileOrganizerStore
        : fileExplorerStore;

    const {
      fileStore: { updateFile },
      folderStore: { updateFolder },
    } = fileOrganizerStore;

    const {
      activeFolderId,
      activeFileId,
      setActiveFileId,
      setActiveFolderId,

      folderFilesStore: {
        getList,
        getListData,
        setPage,
        setFilter,
        setSort,
        refetch,
        setRefetch,
        dataMap,
      },
    } = storageStore;

    const {
      isLoadedList,
      dataListFromMap: entities,
      pagination,
      storeFilter,
      storeSorting,
      isFetchingList,
    } = getListData(requestId);

    // watch for updates in dataMap
    const autorunId = autorun(() => dataMap);

    React.useEffect(() => {
      return () => autorunId();
    }, [autorunId]);

    const [selectedId, setSelectedIdMiddleware] = useStateMiddleware<
      string | null | undefined
    >(null);

    const {
      sorting = '',
      page,
      filterState,
      filterMemo,
      memoQueryFilter = '',
      handleChangeFilterDate,
      handleChangeFilter,
      handleChangePhone,
      handleChangePage,
    } = useCommonTable({
      defaultFilterState: Constants.mockObj,
      pagination,
      setPage,
      setFilter,
      setSort,
      storeFilter,
      storeSorting,
      requestId,
    });

    const getEntities = React.useCallback(() => {
      if (!lastId) {
        return;
      }

      getList(
        {
          id: lastId,
          page: +page,
          sort: !!sorting ? enhanceSorting(sorting) : sorting,
          filter: filterMemo,
          limit: Constants.PAGINATION_LIMIT,
        },
        {
          requestId,
        },
      );

      if (refetch) {
        setRefetch(false);
      }
    }, [
      lastId,
      refetch,
      page,
      requestId,
      setRefetch,
      filterMemo,
      sorting,
      getList,
    ]);

    React.useEffect(() => {
      getEntities();
    }, [getEntities]);

    /** get entities */

    const moveToFolder = React.useCallback(
      (id: string) => {
        if (handleMoveToFolder) {
          return handleMoveToFolder({
            rootId,
            folders,
            id,
          });
        }

        const link =
          generatePath(
            isView
              ? ROUTES.MODULE_FILE_EXPLORER_VIEW_EE_PREFIX
              : ROUTES.MODULE_FILE_ORGANIZER_VIEW,
            {
              rootId,
            },
          ) +
          (folders ? `/${folders}` : '') +
          `/${id}`;

        history.push(link);
      },
      // ignore history
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [folders, isView, rootId],
    );

    /** COLUMNS  */
    const columns = React.useMemo(() => {
      return commonFilesColumns(locale, formatMessage, {
        isView,
        classes,
        moveToFolder,
      });
    }, [locale, moveToFolder, formatMessage, isView, classes]);

    const {
      isOpen: isOpenDelete,
      handleOpenModal: handleOpenModalDelete,
      handleCloseModal: handleCloseModalDelete,
    } = useModal();

    const {
      isOpen: isOpenMove,
      handleOpenModal: handleOpenModalMove,
      handleCloseModal: handleCloseModalMove,
    } = useModal();

    const {
      isOpen: isOpenEdit,
      handleOpenModal: handleOpenModalEdit,
      handleCloseModal: handleCloseModalEdit,
    } = useModal();

    const handleChangePublicAccess = React.useCallback(
      (id: string) => {
        const entity = dataMap[id];
        const isFile = entity?.kind === SZ_ENTITY_KIND.FILE;

        if (isFile) {
          updateFile(
            {
              id: entity._id,
              title: entity.name,
              publicAccess: !entity.publicAccess,
            },
            {
              successFunc: () => {
                getEntities();
              },
            },
          );

          return;
        }

        updateFolder(
          {
            id: entity._id,
            folderName: entity.name,
            publicAccess: !entity.publicAccess,
          },
          {
            successFunc: () => {
              getEntities();
            },
          },
        );
      },
      [dataMap, getEntities, updateFile, updateFolder],
    );

    const data = React.useMemo(() => {
      return entities || [];
    }, [entities]);

    const { isShowTable } = useCheckIsTableView({
      tableDataIsLoaded: isLoadedList,
      tableDataLength: entities?.length || 0,
      memoQueryFilter,
    });

    const handleFileClick = (entity: ISZFolderFile) => {
      if (entity.kind === SZ_ENTITY_KIND.FOLDER) {
        setActiveFolderId(entity._id);
        return;
      }
      setActiveFileId(entity._id);
    };
    const handleFileDoubleClick = (entity: ISZFolderFile) => {
      if (entity.kind === SZ_ENTITY_KIND.FILE) {
        return;
      }
      moveToFolder(entity._id);
    };

    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
      handleFileClick,
      handleFileDoubleClick,
    );

    const TableActionComponent = React.useCallback(
      ({ data }) => {
        return (
          <FolderFilesActions
            data={data}
            handleChangePublicAccess={handleChangePublicAccess}
            handleOpenDeleteModal={setSelectedIdMiddleware(
              handleOpenModalDelete,
            )}
            handleOpenMoveModal={setSelectedIdMiddleware(handleOpenModalMove)}
            handleEditFolder={setSelectedIdMiddleware(handleOpenModalEdit)}
            handleEditFile={setSelectedIdMiddleware(handleOpenModalEdit)}
            setActiveFileId={setActiveFileId}
            setActiveFolderId={setActiveFolderId}
          />
        );
      },
      [
        setActiveFileId,
        setActiveFolderId,
        setSelectedIdMiddleware,
        handleChangePublicAccess,
        handleOpenModalDelete,
        handleOpenModalEdit,
        handleOpenModalMove,
      ],
    );

    return (
      <Fragment>
        <div className={classes.root}>
          <div className={classes.tableWrapper}>
            {isFetchingList && (
              <IsLoadingComponent isLoading={isFetchingList} />
            )}

            <AnvTable
              size={'small'}
              requestId={requestId}
              filterMemo={filterMemo}
              setFilter={setFilter}
              isShowTable={isShowTable}
              filterState={filterState}
              handleChangeFilterDate={handleChangeFilterDate}
              handleChangeFilter={handleChangeFilter}
              handleChangePhone={handleChangePhone}
              isLoadedList={isLoadedList}
              //common
              data={data}
              columns={columns}
              querySort={sorting}
              setSort={setSort}
              TableActionComponent={TableActionComponent}
              onRowClick={handleClick}
              onRowDoubleClick={handleDoubleClick}
              selectedRow={activeFileId || activeFolderId}
              noDataMessage={
                <FormattedMessage
                  id="STORAGE_ZONE.ORGANIZER.LIST.EMPTY_LIST.TEXT"
                  defaultMessage="Files and folders not found"
                />
              }
            />

            <AnvPaginationMui
              pagination={pagination}
              totalPages={pagination?.totalPages}
              handleChangePage={handleChangePage}
              page={+page}
            />
          </div>
        </div>

        {isOpenEdit && selectedId && (
          <EditFolderFileModal
            id={selectedId}
            open={isOpenEdit}
            handleClose={setSelectedIdMiddleware(handleCloseModalEdit)}
          />
        )}

        {isOpenMove && selectedId && (
          <MoveModal
            open={isOpenMove}
            id={selectedId}
            handleClose={setSelectedIdMiddleware(handleCloseModalMove)}
          />
        )}

        {isOpenDelete && selectedId && (
          <EntityDeleteDialog
            handleCloseModal={setSelectedIdMiddleware(handleCloseModalDelete)}
            id={selectedId}
            open={isOpenDelete}
            onSuccess={getEntities}
          />
        )}
      </Fragment>
    );
  },
);

const enhanceSorting = (sorting: string) => {
  return '-kind ' + sorting + ' _id';
};
