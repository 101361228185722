import { ITool } from '@platform/front-lib/dist/models';
import React from 'react';

export interface IMetaStores {
  jurisdictionStore: Record<string, any>;
  organizationTypesStore: Record<string, any>;
  organizationsStore: Record<string, any>;
  tagsStore: Record<string, any>;
  schoolsStore: Record<string, any>;
  accessHistoryStore: Record<string, any>;
  govBodiesStore: any;
}

export interface IProps {
  isModal?: boolean;
  forceHideFilterTabs?: boolean;

  metaStores?: IMetaStores;
  isShowActions?: boolean;

  isShowMyData?: boolean;

  displayEmptyTool?: boolean;
  displayEmptyModule?: boolean;
  displayEmptyAccessScope?: boolean;

  withHistory?: boolean;

  particularScope?: string;
  particularAccessScope?: string;
  particularTool?: string;
  particularModule?: string;

  hideTool?: boolean;
  hideModule?: boolean;
  hideTags?: boolean;
  hideAccessScope?: boolean;

  schoolRequired?: boolean;
  organizationRequired?: boolean;

  onlyMyAccessByModule?: string;
  isOneLevelAccess?: boolean;

  tools?: ITool[] | null;
  modules?: Record<string, any>[] | null;
  jurisdictions?: Record<string, any>[] | null;
  organizations?: Record<string, any>[] | null;
  schools?: Record<string, any>[] | null;
  accessScopes?: Record<string, any>[] | null;

  accessScopeStore?: Record<string, any> | null;

  moduleSettings?: Record<string, any> | null;

  existsData?: Record<string, any> | null;
  serverErrors?: Record<string, any> | null;
  defaultJurisdiction?: string;
}

export const defaultMetaStore = {
  govBodiesStore: {},
  jurisdictionStore: {
    getJurisdictions: (arg: any, arg2?: any) => ({}),
    data: null,
    isFetching: false,
    isLoaded: false,
  },
  accessHistoryStore: {
    getAccessHistory: (arg: any, arg2?: any) => ({}),
    data: null,
    isFetching: false,
    isLoaded: false,
  },
  organizationTypesStore: {
    getOrganizationTypes: () => ({}),
    data: null,
    isFetching: false,
    isLoaded: false,
  },
  organizationsStore: {
    getOrganizations: (arg: any, arg2?: any) => ({}),
    getOrganization: (arg: any, arg2?: any) => ({}),
    data: null,
    data2: null,
    organizationsMap: {},
    isFetching: false,
    isFetching2: false,
    isLoaded: false,
    total: 0,
    total2: 0,
  },
  tagsStore: {
    getTags: (arg: any, arg2?: any) => ({}),
    data: null,
    isFetching: false,
    isLoaded: false,
  },
  schoolsStore: {
    getSchool: (arg: any, arg2?: any) => ({}),
    getSchools: (arg: any, arg2?: any) => ({}),
    schoolsMap: {},
    data: null,
    isFetching: false,
    isLoaded: false,
    total: 0,
  },
};

export const ScopeFormContext = React.createContext<IProps>({
  metaStores: defaultMetaStore,
});
