import React from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useRouteMatch } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Grid, IconButton, InputAdornment } from '@material-ui/core';

import {
  AnvButton,
  AnvDialogActions,
  AnvDialogBody,
  AnvDialogTitle,
  AnvIcon,
  AnvTextField,
  AnvTooltip,
  AnvUtils,
  Constants,
  IconChevronBack,
  IsLoadingComponent,
  useModal,
} from '@platform/front-lib';

import { ISZFileObject, ISZObjectFolder } from 'src/models/storage-zone';
import { IParamsFolders } from 'src/types/storage-zone';

import { useStores } from 'src/stores';
import { ROUTES, SZ_ENTITY_KIND } from '../../../../constants';
import { ISZFileObjectFull } from '../../../../models/storage-zone/file-object';
import { useMoveFolderStyles } from './styles';
import { FolderMoveListItem } from './folder-list-item';
import clsx from 'clsx';

interface INewFolderFormProps {
  handleCloseModal: () => void;
  file?: ISZFileObject;
  id?: string;
}

interface INewFolderForm {
  folderName: string;
  publicAccess: boolean;
}

const validationSchema = (fm: (arg: any, values?: any) => string) =>
  yup.object().shape({
    folderName: yup
      .string()
      .required(fm(Constants.validationsMessages.required())),
  });

export const ItemFormMove: React.FC<INewFolderFormProps> = observer(
  ({ handleCloseModal, file, id }) => {
    const { formatMessage } = useIntl();
    const classes = useMoveFolderStyles();

    const isSearchPage = !!useRouteMatch(
      ROUTES.MODULE_FILE_ORGANIZER_SEARCH_PREFIX,
    )?.isExact;

    const params = useParams<IParamsFolders>();
    const { folders } = params;

    const foldersArray = folders?.split('/');
    let lastId = foldersArray?.pop?.() as string;

    const [currentParentId, setCurrentParentId] = React.useState<string>(
      lastId,
    );
    const [activeFolderId, setActiveFolderId] = React.useState<string>('');

    const {
      isOpen: isCreatFolderMode,
      handleOpenModal: handleCreateFolderOn,
      handleCloseModal: handleCreateFolderOff,
    } = useModal();

    const {
      fileOrganizerStore: {
        folderFilesStore: {
          dataMap: dataMapSearch,
          setDataMapById,
          setRefetch,
        },
        activeFolderId: currentFolderIdStore,
        folderStore: {
          dataMapShort,
          errorItem,
          dataByRequestId,
          isFetchingListMap,
          moveFolder,
          dataMap,
          createFolder,
          getOne,
          isFetching: isFetchingItem,
          getList: getChildFolders,
        },
        fileStore: { moveFile, getList: getFiles, listParams },
      },
    } = useStores();

    const currentFolderId = currentFolderIdStore || id || file?.parentFolder;

    // todo check "folder" from props?
    const currentFolderEntity = currentFolderId
      ? dataMap[currentFolderId] ||
        dataMapShort[currentFolderId] ||
        dataMapSearch[currentFolderId]
      : null;

    const currentFolderParentEntity =
      dataMap[currentParentId] || dataMapShort[currentParentId];
    const currentFolderParentId = currentFolderParentEntity?.parentFolder;

    React.useEffect(() => {
      if (
        currentFolderEntity &&
        isSearchPage &&
        currentFolderEntity.parentFolder
      ) {
        setCurrentParentId(currentFolderEntity.parentFolder);
      }
    }, [setCurrentParentId, isSearchPage, currentFolderEntity]);

    /** get parent for the list entity */
    React.useEffect(() => {
      if (!currentFolderParentEntity) {
        getOne({ id: currentParentId });
      }
    }, [currentFolderParentEntity, currentParentId, getOne]);

    const requestId = currentParentId === lastId ? 'default' : currentParentId;
    const isFetchingList = isFetchingListMap[requestId];

    const isFetching = isFetchingList || isFetchingItem;

    const serverErrors = errorItem || {};

    const currentListData = dataByRequestId?.[requestId];

    React.useEffect(() => {
      getChildFolders({ id: currentParentId }, { requestId });
    }, [currentParentId, getChildFolders, requestId]);

    /** create new folder */
    const {
      control,
      handleSubmit,
      errors,
      watch,
      setValue,
    } = useForm<INewFolderForm>({
      shouldUnregister: false,
      defaultValues: {
        folderName: '',
        publicAccess: true,
      },
      resolver: yupResolver(validationSchema(formatMessage)),
    });
    const publicAccessWatch = watch('publicAccess');

    const handleChangeParentId = (newParentId?: string | null) => {
      if (newParentId) {
        setCurrentParentId(newParentId);
      }
    };

    const handleMoveEntity = () => {
      if (lastId && currentFolderId) {
        const successFunc = () => {
          if (file) {
            getFiles({ ...listParams, id: lastId });
          } else {
            getChildFolders({ id: lastId });
          }
          handleCloseModal();
        };

        if (file) {
          return moveFile(
            {
              id: file._id,
              newParentId: activeFolderId || currentParentId,
            },
            {
              successFunc: (response: ISZFileObjectFull) => {
                if (isSearchPage) {
                  setDataMapById({
                    id: response._id,
                    entity: {
                      ...response,
                      name: response.title || '',
                      kind: SZ_ENTITY_KIND.FILE,
                    },
                    setOnlyMap: true,
                    forceUpdate: true,
                  });
                }

                if (!isSearchPage) {
                  setRefetch(true);
                }

                successFunc();
              },
            },
          );
        }

        moveFolder(
          {
            id: currentFolderId,
            newParentId: activeFolderId || currentParentId,
          },
          {
            successFunc: (response: ISZObjectFolder) => {
              if (isSearchPage) {
                setDataMapById({
                  id: response._id,
                  entity: {
                    ...response,
                    name: response.folderName || '',
                    kind: SZ_ENTITY_KIND.FOLDER,
                    timezone: '',
                    expiredAt: '',
                  },
                  setOnlyMap: true,
                  forceUpdate: true,
                });
              }

              if (!isSearchPage) {
                setRefetch(true);
              }

              successFunc();
            },
          },
        );
      }
    };

    const onSubmitCreateFolder = (data: INewFolderForm) => {
      const { folderName } = data;

      const successFunc = () => {
        getChildFolders(
          { id: currentParentId },
          {
            requestId: currentParentId !== lastId ? currentParentId : undefined,
          },
        );

        if (!isSearchPage) {
          setRefetch(true);
        }

        handleCreateFolderOff();
      };

      createFolder(
        {
          folderName,
          parentFolder: currentParentId,
          publicAccess: data.publicAccess,
        },
        { successFunc },
      );
    };

    const formHelper = (field: string) => {
      return AnvUtils.formErrorHelper(field, errors, serverErrors);
    };

    /////////////////////////

    return (
      <div style={{ position: 'relative' }}>
        <IsLoadingComponent isLoading={isFetching} isAbsolute />

        <AnvDialogTitle>
          {isCreatFolderMode ? (
            <form onSubmit={handleSubmit(onSubmitCreateFolder)}>
              <Grid container wrap={'nowrap'} spacing={1} alignItems={'center'}>
                <Grid item>
                  <IconButton size={'small'} onClick={handleCreateFolderOff}>
                    <AnvIcon icon={'chevron'} />
                  </IconButton>
                </Grid>
                <Grid item className={classes.titleControlFolderName}>
                  <AnvTextField
                    className={classes.titleControlFolderNameControl}
                    defaultValue={''}
                    fullWidth
                    required
                    control={control}
                    name={'folderName'}
                    label={
                      <FormattedMessage
                        id="STORAGE_ZONE.FOLDERS.MOVE_EDIT.LABEL.NAME"
                        defaultMessage="Name"
                      />
                    }
                    placeholder={formatMessage({
                      id: 'STORAGE_ZONE.FOLDERS.MOVE_EDIT.PLACEHOLDER.NAME',
                      defaultMessage: 'New Folder',
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <AnvTooltip
                            title={formatMessage(
                              publicAccessWatch
                                ? {
                                    id:
                                      'STORAGE_ZONE.FOLDERS.MOVE_EDIT.TOOLTIP.PUBLIC',
                                    defaultMessage: 'Public',
                                  }
                                : {
                                    id:
                                      'STORAGE_ZONE.FOLDERS.MOVE_EDIT.TOOLTIP.PRIVATE',
                                    defaultMessage: 'Private',
                                  },
                            )}
                            arrow
                          >
                            <IconButton
                              onClick={() => {
                                setValue('publicAccess', !publicAccessWatch);
                              }}
                            >
                              <AnvIcon
                                icon={publicAccessWatch ? 'unlock' : 'lock'}
                                size={24}
                                color={
                                  publicAccessWatch ? 'lightIcons' : 'primary'
                                }
                              />
                            </IconButton>
                          </AnvTooltip>
                        </InputAdornment>
                      ),
                    }}
                    error={!!formHelper('folderName')}
                    helperText={formHelper('folderName')}
                    zeroMargin={true}
                  />
                </Grid>
                <Grid item>
                  <AnvButton
                    data-test={'move-create-folder-submit'}
                    className={classes.titleControlSubmit}
                    color={'primary'}
                    variant={'contained'}
                    type={'submit'}
                  >
                    <FormattedMessage id="APP.OK" defaultMessage="Ok" />
                  </AnvButton>
                </Grid>
              </Grid>
            </form>
          ) : (
            <span>
              <IconButton
                data-test={'move-create-folder-cancel'}
                disabled={!currentFolderParentId}
                onClick={() => handleChangeParentId(currentFolderParentId)}
              >
                <IconChevronBack />
              </IconButton>{' '}
              {currentFolderParentEntity?.folderName}
            </span>
          )}

          {/*  New Folder Header */}
        </AnvDialogTitle>

        <AnvDialogBody>
          <div
            className={clsx(classes.foldersListContainer, {
              [classes.foldersListContainerEmpty]:
                isCreatFolderMode || !currentListData?.length,
            })}
          >
            <div className={classes.folderListContainerWrapper}>
              {isCreatFolderMode ? (
                <span>
                  <FormattedMessage
                    id="STORAGE_ZONE.FOLDERS.MOVE_EDIT.CAPTION"
                    defaultMessage='Create folder in "{parentFolderName}" folder'
                    values={{
                      parentFolderName:
                        currentFolderParentEntity?.folderName || '',
                    }}
                  />
                </span>
              ) : currentListData?.length ? (
                currentListData?.map?.((folderItem) => {
                  return (
                    currentFolderId &&
                    currentFolderEntity && (
                      <FolderMoveListItem
                        key={folderItem._id}
                        currentFolderEntity={currentFolderEntity}
                        folder={folderItem}
                        handleChangeParentId={handleChangeParentId}
                        setActiveFolderId={setActiveFolderId}
                        activeFolderId={activeFolderId}
                      />
                    )
                  );
                })
              ) : (
                <span>
                  <FormattedMessage
                    id="STORAGE_ZONE.FOLDERS.MOVE.LIST_EMPTY"
                    defaultMessage="This Folder has no any sub-folders"
                  />
                </span>
              )}
            </div>
          </div>
        </AnvDialogBody>

        <AnvDialogActions>
          <Grid container direction={'column'} spacing={1}>
            <Grid item>
              <AnvButton
                onClick={handleMoveEntity}
                data-test={'move-folder-submit'}
                variant={'contained'}
                color={'primary'}
                fullWidth
                type={'submit'}
                disabled={isFetching || isCreatFolderMode}
              >
                <FormattedMessage
                  id="STORAGE_ZONE.FOLDERS.MOVE.ACTION.TO_THIS_FOLDER"
                  defaultMessage="Move To This Folder"
                />
              </AnvButton>
            </Grid>

            <Grid item>
              <AnvButton
                onClick={handleCreateFolderOn}
                data-test={'move-folder-create-new'}
                variant={'outlined'}
                color={'primary'}
                fullWidth
                disabled={isFetching || isCreatFolderMode}
              >
                <FormattedMessage
                  id="STORAGE_ZONE.FOLDERS.MOVE.ACTION.CREATE_FOLDER"
                  defaultMessage="Create New Folder"
                />
              </AnvButton>
            </Grid>

            <Grid item>
              <AnvButton
                data-test={'move-folder-cancel'}
                onClick={handleCloseModal}
                variant={'text'}
                color={'primary'}
                fullWidth
                disabled={isFetching}
              >
                <FormattedMessage
                  id="STORAGE_ZONE.FOLDERS.MOVE.ACTION.CANCEL"
                  defaultMessage="Cancel"
                />
              </AnvButton>
            </Grid>
          </Grid>
        </AnvDialogActions>
      </div>
    );
  },
);
