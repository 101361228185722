import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

import {
  generatePath,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { Grid } from '@material-ui/core';

import {
  AnvNotFoundPage,
  AnvUtils,
  IsLoadingComponent,
  TextH1,
  useCheckIsTableView,
  useCommonOffsetsStyles,
  useModuleAccessHistory,
  useViewError,
} from '@platform/front-lib';

import { IParamsFolders } from '../../../types/storage-zone';

import { useStores } from '../../../stores';
import { SZFolderActions } from '../../common/folder-actions';
import { SZFoldersFilesListView } from '../../common/folder-files/list';

import { ModuleContext } from '../../common/module-context';

import PrivateRoute from '../../../route-handlers/PrivateRoute';
import { AnvBreadcrumbsConnected } from '../../../components/connected/anv-breadcrumbs-container';
import { ContentPaper } from '../../../components/layout/content';

import { SZFoldersBreadcrumbs } from '../../common/folders-breadcrumbs';
import { ViewDrawerData } from '../../common/entity-drawer';

import { ROUTES, SZ_EVENT_TYPES, TOOL_MODULES } from '../../../constants';
import { SZToolbar } from '../../common/toolbar';

import { useStyles } from './styles';

interface IFileOrganizerViewProps {}

export const FileOrganizerView: React.FC<IFileOrganizerViewProps> = observer(
  () => {
    const module = React.useContext(ModuleContext);

    const classes = useStyles();
    const classesOffset = useCommonOffsetsStyles();
    const history = useHistory();

    const { locale } = useIntl();

    const { url } = useRouteMatch();

    const params = useParams<IParamsFolders>();

    const { folders, rootId } = params;

    const {
      authStores: { isOperatorLoggedIn },
      uiStores: { tool },
      fileOrganizerStore: {
        activeFileId,
        activeFolderId,
        setActiveFolderId,
        setActiveFileId,

        folderStore,

        folderRootStore: {
          getOne: getOneRootFolders,
          dataMap: rootFoldersMap,
          isFetching: isFetchingRootFolders,

          errorItem,
          resetError,
        },
        fileStore: { setListParams },

        folderFilesStore: { getListData },
      },
    } = useStores();

    const { ErrorView, view_type } = useViewError({
      errorItem,
      dropErrors: () => {
        resetError();
      },
    });

    React.useEffect(() => {
      setListParams({});

      return () => {
        setListParams({});
      };
    }, [folders, setListParams]);

    const { getList: getChildFolders, getChain } = folderStore;

    const rootEntity = rootFoldersMap?.[rootId];

    const isRelatedToEduEntity = [
      SZ_EVENT_TYPES.SCHOOL,
      SZ_EVENT_TYPES.ORGANIZATION,
    ].includes(rootEntity?.entityType || '');

    const isSchool = rootEntity?.entityType === SZ_EVENT_TYPES.SCHOOL;

    useModuleAccessHistory({
      moduleCodeFallback: module,
      entitiesRequired: isRelatedToEduEntity,
      data: {
        organization: !isSchool ? rootEntity?.organization : undefined,
        school: isSchool ? rootEntity?.school : undefined,
      },
    });

    if (rootId && !folders?.length && rootEntity) {
      const url = generatePath(ROUTES.MODULE_FILE_ORGANIZER_VIEW, {
        rootId: rootEntity?._id,
        folders: rootEntity?.folderId,
      });
      history.replace(url);
    }

    React.useEffect(() => {
      if (!rootEntity) {
        getOneRootFolders({ id: rootId });
      }
    }, [rootEntity, rootId, getOneRootFolders]);
    React.useEffect(() => {
      if (rootId) {
        getChain({ rootId, folders: folders?.split?.('/') || [] });
      }
    }, [folders, rootId, getChain]);

    React.useEffect(() => {
      if (rootId && folders) {
        const foldersArray = folders?.split('/');
        const lastId = foldersArray?.pop?.();

        if (lastId) {
          getChildFolders({ id: lastId, limit: 1_000 });
        }
      }
    }, [rootId, folders, getChildFolders]);

    // reset active folder
    React.useEffect(() => {
      setActiveFolderId(null);
      setActiveFileId(null);
    }, [rootId, folders, setActiveFolderId, setActiveFileId]);

    // Error has occurred
    if (!rootEntity && !isFetchingRootFolders && isFetchingRootFolders) {
      return <AnvNotFoundPage />;
    }

    const rootEntityTitle = AnvUtils.getNameByLangNullable(
      rootEntity?.schoolRef || rootEntity?.organizationRef,
      'name',
      locale,
    );

    const breadCrumbsItems = [
      {
        name: 'entity',
        component: rootEntityTitle || '',
      },
    ];

    const foldersArray = folders?.split('/');
    const lastId = foldersArray?.pop?.() as string;

    const requestId = `${rootId}_${lastId}_list-page`;

    const { isLoadedList, dataListFromMap: entities } = getListData(requestId);

    const { isShowTable } = useCheckIsTableView({
      tableDataIsLoaded: isLoadedList,
      tableDataLength: entities?.length || 0,
      memoQueryFilter: '',
    });
    if (ErrorView && view_type === 'screen') {
      return (
        <>
          <AnvBreadcrumbsConnected
            tool={tool}
            moduleCode={module}
            isOperator={isOperatorLoggedIn}
            items={breadCrumbsItems}
          />

          {ErrorView}
        </>
      );
    }

    return (
      <>
        {ErrorView}

        <ViewDrawerData />

        <div
          data-clickaway={true}
          className={clsx(classes.mainContent, {
            [classes.mainContentWDrawer]: !!activeFolderId || !!activeFileId,
          })}
        >
          {!rootEntity && <IsLoadingComponent isLoading />}

          <AnvBreadcrumbsConnected
            tool={tool}
            moduleCode={module}
            isOperator={isOperatorLoggedIn}
            items={breadCrumbsItems}
          />

          <ContentPaper>
            <Grid
              container
              spacing={2}
              justify={'space-between'}
              alignItems={'center'}
            >
              <Grid item>
                <TextH1>{rootEntityTitle}</TextH1>
              </Grid>
              <Grid item>
                <SZToolbar />
              </Grid>
            </Grid>

            <Grid
              container
              justify={'space-between'}
              alignItems={'center'}
              className={clsx(classesOffset.pb_2, classesOffset.pt_1)}
            >
              <Grid item>
                {(isShowTable || !!folders) && <SZFoldersBreadcrumbs />}
              </Grid>
              <Grid item>
                <SZFolderActions />
              </Grid>
            </Grid>

            <SZFoldersFilesListView />
          </ContentPaper>
        </div>

        <Switch>
          <PrivateRoute
            exact
            path={`${url}/:id`}
            component={<FileOrganizerView />}
            module={TOOL_MODULES.FILE_ORGANIZER}
          />
        </Switch>
      </>
    );
  },
);
