import { ViewDrawerEEData } from './view-ee';
import { ViewDrawerGovBodyData } from './view-gov-body';

export type TCustomViewFolder = 'ee' | 'tool' | 'module' | 'govBody';

export interface IViewDrawerCustomDataCommonProps {
  entity: Record<string, any>;
}

export const FOLDER_VIEW_CUSTOM: Record<
  TCustomViewFolder,
  React.FC<IViewDrawerCustomDataCommonProps>
> = {
  ee: ViewDrawerEEData,
  tool: () => null, // todo
  module: () => null, // todo
  govBody: ViewDrawerGovBodyData,
} as const;
