import React from 'react';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Grid, MenuItem } from '@material-ui/core';

import {
  AnvButton,
  AnvButtonGroup,
  AnvDialog,
  AnvIcon,
  AnvMenu,
  useMenu,
  useModal,
} from '@platform/front-lib';

import { IParamsFolders } from '../../../types/storage-zone';

import { ModuleContext } from '../module-context';

import { useStores } from 'src/stores';

import { FolderFormAddEdit } from '../forms/folder-add-edit/folder-form-add-edit';
import { FileUploadForm } from '../forms/files/form-upload';

import { TOOL_MODULES } from 'src/constants';
import { useFoldersToolbarStyles } from './styles';

interface ISZToolbarProps {}

export const SZFolderActions: React.FC<ISZToolbarProps> = observer(() => {
  const { formatMessage } = useIntl();

  const classes = useFoldersToolbarStyles();

  const module = React.useContext(ModuleContext);

  const isBuild = module === TOOL_MODULES.FILE_ORGANIZER;

  const params = useParams<IParamsFolders>();
  const { folders } = params;

  const foldersArray = folders?.split('/');
  const lastId = foldersArray?.pop?.() as string;

  const {
    fileOrganizerStore: {
      folderStore: { resetError },
      folderFilesStore: { setRefetch },
      fileStore: {
        resetError: resetErrorFiles,
        uploadFiles,
        isFetching,
        error,
      },
    },
  } = useStores();

  const { closeMenu, handleClose: handleCloseMenu } = useMenu();

  const {
    isOpen: isOpenAddFolder,
    handleCloseModal: handleCloseModalAddFolderPlain,
    handleOpenModal: openModalAddFolder,
  } = useModal();

  const handleOpenModalAddFolder = () => {
    openModalAddFolder();
    handleCloseMenu();
  };

  const handleCloseModalAddFolder = () => {
    handleCloseModalAddFolderPlain();
    resetError();
  };

  /** upload files */
  const {
    isOpen: isOpenUploadFiles,
    handleCloseModal: handleCloseModalUploadFilesPlain,
    handleOpenModal: openModalUploadFiles,
  } = useModal();

  const handleOpenModalUploadFiles = () => {
    openModalUploadFiles();
    handleCloseMenu();
  };

  const handleCloseModalUploadFiles = () => {
    handleCloseModalUploadFilesPlain();
    resetError();
    resetErrorFiles();
  };

  const handleUploadFiles = async ({
    files,
    fileTitles,
    filePublicState,
  }: any) => {
    const successFunc = () => {
      setRefetch(true);
    };

    const [error] = await uploadFiles(
      {
        folderId: lastId,
        files,
        fileTitles,
        filePublicState,
      },
      { successFunc },
    );

    if (!error) {
      handleCloseModalUploadFiles();
    }
  };

  // todo temp
  const hideListViewTemp = true;

  return (
    <>
      <Grid container spacing={2}>
        {isBuild && (
          <Grid item>
            <AnvMenu
              closeMenu={closeMenu}
              size={'small'}
              anchorComponent={'button'}
              anchorButtonVariant={'contained'}
              anchorButtonProps={{
                startIcon: <AnvIcon size={16} icon={'plus'} />,
                endIcon: <AnvIcon size={16} icon={'chevronDown'} />,
              }}
              labelRight={formatMessage({
                id: 'xx',
                defaultMessage: 'Create',
              })}
            >
              <MenuItem onClick={handleOpenModalUploadFiles}>
                <AnvIcon size={24} icon={'uploadCloud'} />

                {formatMessage({
                  id: 'STORAGE_ZONE.TOOLBAR.ACTION.UPLOAD_FILES',
                  defaultMessage: 'Upload files',
                })}
              </MenuItem>
              <MenuItem onClick={handleOpenModalAddFolder}>
                <AnvIcon size={24} icon={'folder'} />

                {formatMessage({
                  id: 'STORAGE_ZONE.TOOLBAR.ACTION.CREATE_NEW_FOLDER',
                  defaultMessage: 'Create new folder',
                })}
              </MenuItem>
            </AnvMenu>
          </Grid>
        )}

        {!hideListViewTemp && (
          <Grid item>
            <AnvButtonGroup disableElevation variant="outlined">
              <AnvButton
                data-test={'toolbar-view-list'}
                title={formatMessage({
                  id: 'STORAGE_ZONE.TOOLBAR.ACTION.VIEW_AS_LIST',
                  defaultMessage: 'View as List',
                })}
                className={classes.buttonLeft}
                variant={'outlined'}
                size={'small'}
                color={'primary'}
              >
                <AnvIcon icon={'reviewList'} />
              </AnvButton>
              <AnvButton
                data-test={'toolbar-view-grid'}
                title={formatMessage({
                  id: 'STORAGE_ZONE.TOOLBAR.ACTION.VIEW_AS_GRID',
                  defaultMessage: 'View as grid',
                })}
                className={classes.buttonRight}
                color={'primary'}
                inactive
                variant={'contained'}
                size={'small'}
              >
                <AnvIcon icon={'grid'} />
              </AnvButton>
            </AnvButtonGroup>
          </Grid>
        )}
      </Grid>

      <AnvDialog open={isOpenAddFolder} handleClose={handleCloseModalAddFolder}>
        {isOpenAddFolder && (
          <FolderFormAddEdit handleCloseModal={handleCloseModalAddFolder} />
        )}
      </AnvDialog>

      <AnvDialog
        open={isOpenUploadFiles}
        handleClose={handleCloseModalAddFolder}
      >
        {isOpenUploadFiles && (
          <FileUploadForm
            isFetching={isFetching}
            handleUploadFiles={handleUploadFiles}
            isOpen={isOpenUploadFiles}
            handleCloseModal={handleCloseModalUploadFiles}
            serverErrors={error}
          />
        )}
      </AnvDialog>
    </>
  );
});
