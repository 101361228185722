import { autorun } from 'mobx';
import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';

import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import {
  AnvPaginationMui,
  Constants,
  useCheckIsTableView,
  useCommonTable,
  AnvTable,
  IsLoadingComponent,
  ModuleContext,
} from '@platform/front-lib';
import {
  FILE_EXPLORER_SUB_MODULES,
  ROUTES,
  SZ_ENTITY_KIND,
  SZ_ENTITY_TYPE,
  TOOL_MODULES,
} from '../../../../../constants';
import { useSubModuleContext } from '../../../../../contexts/sub-module';
import { ISZFolderFile, ISZObjectFolderShort } from '../../../../../models';
import { useStores } from '../../../../../stores';
import { useFilesViewStyles } from '../../../../../styles/common/files-view-styles';
import { IParamsFolders } from '../../../../../types/storage-zone';
import useClickPreventionOnDoubleClick from '../../../../../utils/hook-useDoubleClick';
import { SchoolYearsVirtualFoldersListFilter } from '../school-years-filter-list';

import { FolderFilesActions } from './actions';

import { commonFilesColumns } from './columns';

const MEETINGS_FOLDER_RESERVED_NAME = 'Meetings';

const SUB_MODULES_PARAMS = {
  workZone: 'work-zone',
  personalZone: 'personal-zone',
};

interface ISZFilesViewProps {
  currentFolderEntity: ISZObjectFolderShort;
}

export const CommonGoverningBoardsListView: React.FC<ISZFilesViewProps> = observer(
  ({ currentFolderEntity }) => {
    const params = useParams<IParamsFolders>();
    const { folders, rootId } = params;
    const history = useHistory();
    const subModule = useSubModuleContext();

    const foldersArray = folders?.split('/');
    const lastId = foldersArray?.pop?.() as string;

    const requestId = `${subModule}_${rootId}_${lastId}_governing-documents`;

    const classes = useFilesViewStyles();

    const { formatMessage, locale } = useIntl();

    const module = React.useContext(ModuleContext);

    const isView = module === TOOL_MODULES.FILE_EXPLORER;
    const isWorkZone = subModule === FILE_EXPLORER_SUB_MODULES.WORK_ZONE;

    const isCurrentFolderSystem =
      currentFolderEntity?.type === SZ_ENTITY_TYPE.SYSTEM;

    const isCurrentFolderMeetings =
      currentFolderEntity?.folderName === MEETINGS_FOLDER_RESERVED_NAME;

    const isSystemMeetingsFolder =
      isCurrentFolderSystem && isCurrentFolderMeetings;

    const { fileExplorerStore } = useStores();

    const {
      activeFolderId,
      activeFileId,
      setActiveFileId,
      setActiveFolderId,

      folderFilesStore: {
        getGoverningBoardsDocumentsList,
        getListData,
        setPage,
        setFilter,
        setSort,
        refetch,
        setRefetch,
        dataMap,
      },
    } = fileExplorerStore;

    const {
      isLoadedList,
      dataListFromMap: entities,
      pagination,
      storeFilter,
      storeSorting,
      isFetchingList,
    } = getListData(requestId);

    // watch for updates in dataMap
    const autorunId = autorun(() => dataMap);

    React.useEffect(() => {
      return () => autorunId();
    }, [autorunId]);

    const {
      sorting = '',
      page,
      // filterState,
      // filterMemo,
      memoQueryFilter = '',
      handleChangeFilter,
      handleChangePage,
      dropFilterState,
    } = useCommonTable({
      defaultFilterState: Constants.mockObj,
      pagination,
      setPage,
      setFilter,
      setSort,
      storeFilter,
      storeSorting,
      requestId,
      disableAutoSetFilter: true,
    });

    const isShowFilterVirtualFolders =
      isSystemMeetingsFolder && !memoQueryFilter;

    React.useEffect(() => {
      if (!isSystemMeetingsFolder && currentFolderEntity) {
        dropFilterState();
      }
    }, [dropFilterState, currentFolderEntity, isSystemMeetingsFolder]);

    const getEntities = React.useCallback(() => {
      if (!lastId) {
        return;
      }

      getGoverningBoardsDocumentsList(
        {
          id: lastId,
          page: +page,
          sort: !!sorting ? enhanceSorting(sorting) : sorting,
          //@ts-ignore
          filter: memoQueryFilter,
          limit: Constants.PAGINATION_LIMIT,
          subModule: isWorkZone
            ? SUB_MODULES_PARAMS.workZone
            : SUB_MODULES_PARAMS.personalZone,
        },
        {
          requestId,
        },
      );

      if (refetch) {
        setRefetch(false);
      }
    }, [
      lastId,
      getGoverningBoardsDocumentsList,
      page,
      sorting,
      memoQueryFilter,
      isWorkZone,
      requestId,
      refetch,
      setRefetch,
    ]);

    React.useEffect(() => {
      // get data only with filter
      if (isSystemMeetingsFolder) {
        if (memoQueryFilter) {
          getEntities();
        }
        return;
      }

      getEntities();
    }, [getEntities, isSystemMeetingsFolder, memoQueryFilter]);

    /** get entities */

    const moveToFolder = React.useCallback(
      (id: string) => {
        const link =
          generatePath(
            isWorkZone
              ? ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_GOVERNING_BOARDS_VIEW_PREFIX
              : ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_GOVERNING_BOARDS_VIEW_PREFIX,

            {
              rootId,
            },
          ) +
          (folders ? `/${folders}` : '') +
          `/${id}`;

        history.push(link);
      },
      // avoid history
      //eslint-disable-next-line
      [folders, rootId],
    );

    /** COLUMNS  */
    const columns = React.useMemo(() => {
      return commonFilesColumns(locale, formatMessage, {
        isView,
        classes,
        moveToFolder,
        subModule,
      });
    }, [locale, moveToFolder, subModule, formatMessage, isView, classes]);

    const data = React.useMemo(() => {
      return entities || [];
    }, [entities]);

    const { isShowTable } = useCheckIsTableView({
      tableDataIsLoaded: isLoadedList,
      tableDataLength: entities?.length || 0,
      memoQueryFilter,
    });

    const handleFileClick = (entity: ISZFolderFile) => {
      if (entity.kind === SZ_ENTITY_KIND.FOLDER) {
        setActiveFolderId(entity._id);
        return;
      }
      setActiveFileId(entity._id);
    };
    const handleFileDoubleClick = (entity: ISZFolderFile) => {
      if (entity.kind === SZ_ENTITY_KIND.FILE) {
        return;
      }
      moveToFolder(entity._id);
    };

    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
      handleFileClick,
      handleFileDoubleClick,
    );

    const TableActionComponent = React.useCallback(
      ({ data }) => {
        return (
          <FolderFilesActions
            data={data}
            handleChangePublicAccess={Constants.mockFunc}
            handleOpenDeleteModal={Constants.mockFunc}
            handleOpenMoveModal={Constants.mockFunc}
            handleEditFolder={Constants.mockFunc}
            handleEditFile={Constants.mockFunc}
            setActiveFileId={setActiveFileId}
            setActiveFolderId={setActiveFolderId}
          />
        );
      },
      [setActiveFileId, setActiveFolderId],
    );

    if (isShowFilterVirtualFolders) {
      return (
        <SchoolYearsVirtualFoldersListFilter
          rootFolderId={rootId}
          handleChangeFilter={handleChangeFilter}
          requestId={requestId}
          subModule={subModule}
        />
      );
    }

    return (
      <Fragment>
        <div className={classes.root}>
          <div className={classes.tableWrapper}>
            {isFetchingList && (
              <IsLoadingComponent isLoading={isFetchingList} />
            )}

            <AnvTable
              size={'small'}
              requestId={requestId}
              filterMemo={Constants.mockFunc}
              setFilter={Constants.mockFunc}
              isShowTable={isShowTable && data.length > 0}
              filterState={Constants.mockFunc}
              handleChangeFilterDate={Constants.mockFunc}
              handleChangeFilter={Constants.mockFunc}
              handleChangePhone={Constants.mockFunc}
              isLoadedList={isLoadedList}
              //common
              data={data}
              columns={columns}
              querySort={sorting}
              setSort={setSort}
              TableActionComponent={TableActionComponent}
              onRowClick={handleClick}
              onRowDoubleClick={handleDoubleClick}
              selectedRow={activeFileId || activeFolderId}
              noDataMessage={
                <FormattedMessage
                  id={`STORAGE_ZONE.EXPLORER.${subModule}.GOV.LIST.EMPTY_LIST.TEXT`}
                  defaultMessage="Files and folders not found"
                />
              }
            />

            <AnvPaginationMui
              pagination={pagination}
              totalPages={pagination?.totalPages}
              handleChangePage={handleChangePage}
              page={+page}
            />
          </div>
        </div>
      </Fragment>
    );
  },
);

const enhanceSorting = (sorting: string) => {
  return '-kind ' + sorting + ' _id';
};
