import React from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';

import { IsLoadingComponent } from '@platform/front-lib';
import Container from './components/layout/container';
import { FilesSearch } from './pages/common/search';
import { PersonalZoneGoverningBoardsPage } from './pages/file-explorer/personal-zone/governance';
import { PersonalZoneGoverningBoardsViewPage } from './pages/file-explorer/personal-zone/governance/view';
import { FileExplorerView } from './pages/file-explorer/personal-zone/list-education-entities/view';
import { PageFEWorkZone } from './pages/file-explorer/work-zone';
import { PageFEComplianceWorkZone } from './pages/file-explorer/work-zone/compliance';
import { PageFEComplianceReportWorkZone } from './pages/file-explorer/work-zone/compliance/report';
import { PageFEAnnualReportWorkZone } from './pages/file-explorer/work-zone/compliance/report/annual-reports';
import { PageFEAnnualReportMonthWorkZone } from './pages/file-explorer/work-zone/compliance/report/months';
import { PageFEEducationEntityWorkZone } from './pages/file-explorer/work-zone/compliance/report/schools';
import { PageFEComplianceReviewWorkZone } from './pages/file-explorer/work-zone/compliance/review';
import { PageFEComplianceReviewAnnualReportWorkZone } from './pages/file-explorer/work-zone/compliance/review/annual-reports';
import { PageFEComplianceReviewMonthsWorkZone } from './pages/file-explorer/work-zone/compliance/review/months';
import { PageFEComplianceReviewOrganizationsWorkZone } from './pages/file-explorer/work-zone/compliance/review/organizations';
import { PageFEComplianceReviewSchoolsWorkZone } from './pages/file-explorer/work-zone/compliance/review/schools';
import { WorkZoneGoverningBoardsPage } from './pages/file-explorer/work-zone/governance';
import { WorkZoneGoverningBoardsViewPage } from './pages/file-explorer/work-zone/governance/view';

import PrivateRoute from './route-handlers/PrivateRoute';
import ForbiddenPage from './route-handlers/ForbiddenPage';

import { TOOL_MODULES, ROUTES } from './constants';
import NotFoundPage from './route-handlers/NotFoundPage';
import AuthPage from './pages/auth';

import RedirectSourceSystemFail from './pages/auth/AuthFail';
import { AccessPage } from './pages/access';
import { FileOrganizerListScreen } from './pages/file-organizer/list-root-folders';
import { FileOrganizerView } from './pages/file-organizer/view';
import { PageFileExplorerList } from './pages/file-explorer/personal-zone/list-education-entities';
import { PageOrganizerViewNewPersonnel } from './pages/file-organizer/new';
import { PersonalMainRoot } from './pages/file-explorer/personal-zone';
import { FamilyCircleFirstLevel } from './pages/file-explorer/personal-zone/family-circle/first-level';

// const KnowledgeBaseForm = React.lazy(
//   () => import('./pages/build/knowledge-base/form'),
// );

interface IProps {}

const Routes: React.FC<IProps> = () => {
  return (
    <BrowserRouter>
      <Container>
        <React.Suspense fallback={<IsLoadingComponent isLoading />}>
          <Switch>
            {/* File Organizer */}
            <Redirect to={ROUTES.MODULE_FILE_EXPLORER} from={'/'} exact />

            <Redirect
              to={ROUTES.MODULE_FILE_EXPLORER_PERSONAL_ROOT}
              from={ROUTES.MODULE_FILE_EXPLORER}
              exact
            />

            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST}
              component={PageFileExplorerList}
              module={TOOL_MODULES.FILE_EXPLORER}
            />

            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_EXPLORER_WORK_LIST}
              component={PageFEWorkZone}
              module={TOOL_MODULES.FILE_EXPLORER}
            />

            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE}
              component={PageFEComplianceWorkZone}
              module={TOOL_MODULES.FILE_EXPLORER}
            />

            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REVIEW}
              component={PageFEComplianceReviewWorkZone}
              module={TOOL_MODULES.FILE_EXPLORER}
            />

            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REVIEW_ORG}
              component={PageFEComplianceReviewOrganizationsWorkZone}
              module={TOOL_MODULES.FILE_EXPLORER}
            />

            <PrivateRoute
              exact
              path={
                ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REVIEW_SCHOOL
              }
              component={PageFEComplianceReviewSchoolsWorkZone}
              module={TOOL_MODULES.FILE_EXPLORER}
            />

            <PrivateRoute
              exact
              path={
                ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REVIEW_ANNUAL_REPORT
              }
              component={PageFEComplianceReviewAnnualReportWorkZone}
              module={TOOL_MODULES.FILE_EXPLORER}
            />

            <PrivateRoute
              exact
              path={
                ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REVIEW_MONTH
              }
              component={PageFEComplianceReviewMonthsWorkZone}
              module={TOOL_MODULES.FILE_EXPLORER}
            />

            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REPORT}
              component={PageFEComplianceReportWorkZone}
              module={TOOL_MODULES.FILE_EXPLORER}
            />

            <PrivateRoute
              exact
              path={
                ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REPORT_EDU_ENTITY
              }
              component={PageFEEducationEntityWorkZone}
              module={TOOL_MODULES.FILE_EXPLORER}
            />

            <PrivateRoute
              exact
              path={
                ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REPORT_ANNUAL_REPORT
              }
              component={PageFEAnnualReportWorkZone}
              module={TOOL_MODULES.FILE_EXPLORER}
            />

            <PrivateRoute
              exact
              path={
                ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_COMPLIANCE_REPORT_MONTH
              }
              component={PageFEAnnualReportMonthWorkZone}
              module={TOOL_MODULES.FILE_EXPLORER}
            />

            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_FAMILY_CIRCLE}
              component={FamilyCircleFirstLevel}
              module={TOOL_MODULES.FILE_EXPLORER}
            />

            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_GOVERNING_BOARDS}
              component={PersonalZoneGoverningBoardsPage}
              module={TOOL_MODULES.FILE_EXPLORER}
            />

            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_GOVERNING_BOARDS}
              component={WorkZoneGoverningBoardsPage}
              module={TOOL_MODULES.FILE_EXPLORER}
            />

            <PrivateRoute
              exact
              path={
                ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_GOVERNING_BOARDS_VIEW_PREFIX
              }
              component={PersonalZoneGoverningBoardsViewPage}
              module={TOOL_MODULES.FILE_EXPLORER}
            />

            <PrivateRoute
              exact
              path={
                ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_GOVERNING_BOARDS_VIEW_PREFIX
              }
              component={WorkZoneGoverningBoardsViewPage}
              module={TOOL_MODULES.FILE_EXPLORER}
            />

            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_EXPLORER_PERSONAL_ROOT}
              component={PersonalMainRoot}
              module={TOOL_MODULES.FILE_EXPLORER}
            />
            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_EDUCATION}
              component={PageFileExplorerList}
              module={TOOL_MODULES.FILE_EXPLORER}
            />
            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_EXPLORER_VIEW_EE_PREFIX}
              component={FileExplorerView}
              module={TOOL_MODULES.FILE_EXPLORER}
            />
            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_EXPLORER_VIEW_PREFIX}
              component={FileExplorerView}
              module={TOOL_MODULES.FILE_EXPLORER}
            />
            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_EXPLORER_VIEW_EE_SEARCH_PREFIX}
              component={FilesSearch}
              module={TOOL_MODULES.FILE_EXPLORER}
            />
            {/* File Organizer */}
            <Redirect
              from={ROUTES.MODULE_FILE_ORGANIZER}
              to={ROUTES.MODULE_FILE_ORGANIZER_LIST}
              exact
            />
            {/*<PrivateRoute*/}
            {/*  exact*/}
            {/*  path={ROUTES.MODULE_FILE_ORGANIZER}*/}
            {/*  component={FileOrganizerTemp}*/}
            {/*  module={TOOL_MODULES.FILE_ORGANIZER}*/}
            {/*/>*/}
            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_ORGANIZER_NEW}
              component={PageOrganizerViewNewPersonnel}
              module={TOOL_MODULES.FILE_ORGANIZER}
            />
            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_ORGANIZER_LIST}
              component={FileOrganizerListScreen}
              module={TOOL_MODULES.FILE_ORGANIZER}
            />
            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_ORGANIZER_VIEW_PREFIX}
              component={FileOrganizerView}
              module={TOOL_MODULES.FILE_ORGANIZER}
            />
            <PrivateRoute
              exact
              path={ROUTES.MODULE_FILE_ORGANIZER_SEARCH_PREFIX}
              component={FilesSearch}
              module={TOOL_MODULES.FILE_ORGANIZER}
            />
            <Redirect
              exact
              to={ROUTES.ADMIN_ACCESS_LIST}
              from={ROUTES.MODULE_ACCESS}
            />
            <PrivateRoute
              exact
              path={ROUTES.ADMIN_ACCESS_LIST}
              component={AccessPage}
              module={TOOL_MODULES.ACCESS}
            />
            <Route exact path={ROUTES.USER_AUTH} component={AuthPage} />
            <Route
              exact
              path={ROUTES.AUTH_FORBIDDEN}
              component={ForbiddenPage}
            />
            <Route
              exact
              path={ROUTES.AUTH_FAIL}
              component={RedirectSourceSystemFail}
            />
            <Redirect from="//*" to="/*" />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </React.Suspense>
      </Container>
    </BrowserRouter>
  );
};

export default Routes;
