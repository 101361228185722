import React from 'react';
import { observer } from 'mobx-react-lite';

import { FormattedMessage, useIntl } from 'react-intl';

import { Constants, useCheckIsTableView, AnvTable } from '@platform/front-lib';
import { FILE_EXPLORER_SUB_MODULES } from '../../../../../constants';
import { ISZFolderFile } from '../../../../../models';
import { useStores } from '../../../../../stores';
import useClickPreventionOnDoubleClick from '../../../../../utils/hook-useDoubleClick';

import { commonFilesColumns } from './columns';

interface ISZFilesViewProps {
  requestId: string;
  handleChangeFilter: any;
  rootFolderId: string;
  subModule:
    | typeof FILE_EXPLORER_SUB_MODULES.PERSONAL_ZONE
    | typeof FILE_EXPLORER_SUB_MODULES.WORK_ZONE;
}

export const SchoolYearsVirtualFoldersListFilter: React.FC<ISZFilesViewProps> = observer(
  ({ requestId, rootFolderId, handleChangeFilter, subModule }) => {
    const { formatMessage } = useIntl();

    const { fileExplorerStore } = useStores();

    const subModuleStore = fileExplorerStore[subModule];

    const {
      virtualFoldersStore: { getSchoolYearsVirtualFolders, getListData },
    } = subModuleStore.governingBoardsStore;

    const { isLoadedList, dataListFromMap: entities } = getListData(requestId);

    const getEntities = React.useCallback(() => {
      getSchoolYearsVirtualFolders(
        {
          rootFolderId,
        },
        {
          requestId,
        },
      );
    }, [getSchoolYearsVirtualFolders, rootFolderId, requestId]);

    React.useEffect(() => {
      getEntities();
    }, [getEntities]);

    // /** COLUMNS  */
    const columns = React.useMemo(() => {
      return commonFilesColumns(formatMessage, {
        handleChangeFilter,
      });
    }, [handleChangeFilter, formatMessage]);

    const data = React.useMemo(() => {
      return entities || [];
    }, [entities]);

    const { isShowTable } = useCheckIsTableView({
      tableDataIsLoaded: isLoadedList,
      tableDataLength: entities?.length || 0,
      memoQueryFilter: Constants.mockObj,
    });

    const handleFileDoubleClick = (entity: ISZFolderFile) => {
      handleChangeFilter({
        target: {
          name: 'schoolYear',
          value: entity._id,
        },
      });
    };

    const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
      Constants.mockFunc,
      handleFileDoubleClick,
    );

    return (
      <AnvTable
        size={'small'}
        setFilter={Constants.mockFunc}
        filterMemo={Constants.mockObj}
        isShowTable={isShowTable}
        filterState={Constants.mockObj}
        handleChangeFilterDate={Constants.mockFunc}
        handleChangeFilter={Constants.mockFunc}
        handleChangePhone={Constants.mockFunc}
        //common
        requestId={requestId}
        isLoadedList={false}
        data={data}
        setSort={Constants.mockFunc}
        onRowClick={handleClick}
        onRowDoubleClick={handleDoubleClick}
        selectedRow={''}
        TableActionComponent={null}
        columns={columns}
        querySort={''}
        noDataMessage={
          <FormattedMessage
            id={`STORAGE_ZONE.EXPLORER.${subModule}.GOV.LIST.EMPTY_LIST.TEXT`}
            defaultMessage="Files and folders not found"
          />
        }
      />
    );
  },
);
