import { action, makeObservable } from 'mobx';

import { FolderRootCommonStore } from '../common/folder-root-common-store';
import { FolderRootCommonService } from '../common/folder-root-common-service';
import { FileExplorerService } from './file.service';

export class FileExplorerFolderRootStoreClass extends FolderRootCommonStore<FileExplorerService> {
  service: FolderRootCommonService;

  @action
  private reset() {
    this.data = null;
    this.isFetching = false;
    this.isLoaded = false;
    this.isError = false;
    this.error = null;
  }

  constructor() {
    super({ isView: false });
    this.service = new FolderRootCommonService({ isView: true });
    makeObservable(this);
  }
}

export default new FileExplorerFolderRootStoreClass();
