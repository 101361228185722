import { CommonListAdvancedStore } from '@platform/front-lib';
import { IActionMeta } from '@platform/front-lib/dist/stores/_helpers';
import { action, makeObservable, observable } from 'mobx';
import { ISZFolderFile } from '../../../../models';
import { IGetList } from '../../../../service/interfaces';
import { FolderFilesService } from './service';

export class FolderFilesStore extends CommonListAdvancedStore<
  FolderFilesService,
  ISZFolderFile
> {
  service: FolderFilesService;

  /**
   *
   * use that flag to refetch list on search page
   *
   */
  refetch: boolean = false;

  resetListServerErrors = (requestId: string) => {
    this.errorListMap[requestId] = {};
    this.isErrorListMap[requestId] = false;
  };

  setRefetch = (value: boolean) => {
    this.refetch = value;
  };

  updatePublicState = (id: string, publicAccess: boolean) => {
    this.dataMap[id].publicAccess = publicAccess;

    this.dataMap = { ...this.dataMap };
  };

  getSearchList = async (payload: IGetList, meta: IActionMeta) => {
    return this.getList({ ...payload, searchRoute: true }, meta);
  };

  getGoverningBoardsDocumentsList = async (
    payload: IGetList & { subModule: string },
    meta: IActionMeta,
  ) => {
    return this.getList(payload, meta);
  };

  error: any = null;

  errorItem: Record<string, any> | null | string = null;

  isFetching: boolean = false;
  isSaving: boolean = false;
  isLoaded: boolean = false;
  isError: boolean = false;

  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };
  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();
  };
  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();
  };

  constructor({ isView }: { isView: boolean }) {
    super({
      sessionKey: 'folder-files',
      disableAutoSave: true,
    });

    this.service = new FolderFilesService(
      isView ? 'file-explorer' : 'file-organizer',
      'folders-files',
    );
    makeObservable(this, {
      refetch: observable,
      setRefetch: action,
      updatePublicState: action,
      resetListServerErrors: action,

      getSearchList: action,
      getGoverningBoardsDocumentsList: action,

      error: observable,
      errorItem: observable,
      isFetching: observable,
      isSaving: observable,
      isLoaded: observable,
      isError: observable,

      setLoading: action,
      setError: action,
      setLoaded: action,
    });
  }
}
