import React from 'react';
import { Cell, Column } from 'react-table';

import { AnvIcon, TextBody } from '@platform/front-lib';

import { TFormatMessage } from '@platform/front-lib/dist/@types/intl/intl';
import { IVirtualFolder } from '../../../../common/folders-virtual/types';

export function commonFilesColumns(
  formatMessage: TFormatMessage,
  {
    handleChangeFilter,
  }: {
    handleChangeFilter: any;
  },
) {
  const columns: Record<string, any>[] = [
    {
      width: 30,
      Header: '',
      headerString: '',
      id: 'icon',

      Cell: () => {
        return (
          <div>
            <AnvIcon icon={'folderBig'} size={40} />
          </div>
        );
      },
    },
    {
      width: 'auto',
      Header: () => {
        return formatMessage({
          id: 'STORAGE_ZONE.FILES.LIST.TH.FILE_NAME',
          defaultMessage: 'Name',
        });
      },
      headerString: formatMessage({
        id: 'STORAGE_ZONE.FILES.LIST.TH.FILE_NAME',
        defaultMessage: 'Name',
      }),
      accessor: 'folderName',
      id: 'folderName',

      Cell: ({ cell }: { cell: Cell<IVirtualFolder> }) => {
        const entity = cell?.row?.original;

        return (
          <TextBody
            onClick={(ev: any) => {
              ev.stopPropagation();
              handleChangeFilter({
                target: {
                  name: 'schoolYear',
                  value: entity._id,
                },
              });
            }}
          >
            {entity?.folderName}
          </TextBody>
        );
      },
    },
  ];

  return columns as Column<Record<string, any>>[];
}
