import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { GridDirection } from '@material-ui/core/Grid/Grid';
import { observer } from 'mobx-react-lite';

import {
  AnvIcon,
  AnvListContainer,
  AnvListData,
  AnvUtils,
  TextBody,
  TextH4,
} from '@platform/front-lib';

import { ISZObjectFolder } from '../../../models';
import { IParamsFolders } from '../../../types/storage-zone';

import { useStores } from '../../../stores';

import { useRightDrawerStyles } from './styles';
import { ModuleContext } from '../module-context';
import { TOOL_MODULES } from '../../../constants';

interface IFoldersDrawerProps {
  folder: ISZObjectFolder & { name?: string };
}

declare type TMd = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 12;

interface IListDataLocal {
  labelMd?: TMd;
  label?: string | JSX.Element | null;
  value?: string | JSX.Element | null;
  direction?: GridDirection;
  omitColon?: boolean;
  omitMargin?: boolean;
}

export const ViewDrawerFolderData: React.FC<IFoldersDrawerProps> = observer(
  ({ folder }) => {
    const { locale } = useIntl();

    const params = useParams<IParamsFolders>();
    const { rootId } = params;

    const classes = useRightDrawerStyles();

    const module = React.useContext(ModuleContext);
    const isOrganizer = module === TOOL_MODULES.FILE_ORGANIZER;
    const { fileExplorerStore, fileOrganizerStore } = useStores();

    const storageStore = isOrganizer ? fileOrganizerStore : fileExplorerStore;

    const {
      setActiveFolderId,
      folderRootStore: { dataMap: dataMapFolderRoot },
    } = storageStore;

    /** menu folder end */

    const rootFolderEntity = dataMapFolderRoot?.[rootId];

    const rootEntityTitle = AnvUtils.getNameByLangNullable(
      rootFolderEntity?.schoolRef ||
        rootFolderEntity?.organizationRef ||
        rootFolderEntity?.govBodyRef,
      'name',
      locale,
    );

    const handleCloseDrawer = () => {
      setActiveFolderId(null);
    };

    const listDataConfig: IListDataLocal = React.useMemo(() => {
      const LABEL_MD = 4;

      return {
        direction: 'row',
        labelMd: LABEL_MD,
        omitMargin: true,
      };
    }, []);

    return (
      <>
        <div className={classes.headerBlock}>
          <TextH4 className={classes.header}>
            <Grid
              container
              alignItems={'center'}
              className={classes.headerTitleGrid}
              wrap={'nowrap'}
            >
              <Grid item style={{ height: 24 }}>
                <AnvIcon
                  icon={'folderBig'}
                  offset={0.5}
                  size={24}
                  alignSelfCenter={true}
                />
              </Grid>

              <Grid
                item
                className={classes.headerTitleItem}
                style={{
                  flex: '1',
                }}
              >
                {folder?.folderName || folder?.name}
              </Grid>

              <Grid item>
                <IconButton
                  size={'small'}
                  onClick={handleCloseDrawer}
                  // className={classes.closeButton}
                  className={classes.closeButtonStatic}
                >
                  <AnvIcon
                    icon={'closeCross'}
                    color={'white'}
                    opacity={0.5}
                    size={24}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </TextH4>
        </div>

        <div className={classes.body}>
          <div>
            <div className={classes.bodyHeader}>
              <TextBody semiBold>
                <FormattedMessage
                  id="STORAGE_ZONE.FOLDERS.DRAWER.CAPTION"
                  defaultMessage="Object Storage Considerations:"
                />
              </TextBody>
            </div>

            <AnvListContainer>
              <AnvListData
                {...listDataConfig}
                label={
                  <FormattedMessage
                    id="STORAGE_ZONE.FOLDERS.DRAWER.LABEL.NAME"
                    defaultMessage="Name"
                  />
                }
                value={
                  <span className={classes.listValue}>
                    {folder?.folderName || folder?.name}
                  </span>
                }
              />

              <AnvListData
                {...listDataConfig}
                label={
                  <FormattedMessage
                    id="STORAGE_ZONE.FOLDERS.DRAWER.LABEL.OWNERSHIP"
                    defaultMessage="Ownership"
                  />
                }
                value={
                  <span className={classes.listValue}>{rootEntityTitle}</span>
                }
              />

              <AnvListData
                {...listDataConfig}
                label={
                  <FormattedMessage
                    id="STORAGE_ZONE.FOLDERS.DRAWER.LABEL.SIZE"
                    defaultMessage="Size"
                  />
                }
                value={<span className={classes.listValue}>24 MB (TBD!)</span>}
              />
              <AnvListData
                {...listDataConfig}
                label={
                  <FormattedMessage
                    id="STORAGE_ZONE.FOLDERS.DRAWER.LABEL.REDUNDANCY"
                    defaultMessage="Redundancy"
                  />
                }
                value={
                  <span className={classes.listValue}>
                    not important (TBD!)
                  </span>
                }
              />
              <AnvListData
                {...listDataConfig}
                label={
                  <FormattedMessage
                    id="STORAGE_ZONE.FOLDERS.DRAWER.LABEL.COST"
                    defaultMessage="Cost"
                  />
                }
                value={<span className={classes.listValue}>$0 (TBD!)</span>}
              />
              <AnvListData
                {...listDataConfig}
                label={
                  <FormattedMessage
                    id="STORAGE_ZONE.FOLDERS.DRAWER.LABEL.RETENTION"
                    defaultMessage="Retention"
                  />
                }
                value={
                  <span className={classes.listValue}>
                    no restrictions (TBD!)
                  </span>
                }
              />
              <AnvListData
                {...listDataConfig}
                label={
                  <FormattedMessage
                    id="STORAGE_ZONE.FOLDERS.DRAWER.LABEL.SECURITY"
                    defaultMessage="Security"
                  />
                }
                value={
                  <span className={classes.listValue}>
                    not contain sensitive data (TBD!)
                  </span>
                }
              />
            </AnvListContainer>
          </div>
        </div>
      </>
    );
  },
);
