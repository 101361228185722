import { useRef } from 'react';

type TCancellablePromise = ReturnType<typeof cancellablePromise>;

export const cancellablePromise = (promise: Promise<any>) => {
  let isCanceled = false;

  const wrappedPromise = new Promise<any>((resolve, reject) => {
    promise.then(
      (value) => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      (error) => reject({ isCanceled, error }),
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => (isCanceled = true),
  };
};

export const delay = (n: number) =>
  new Promise((resolve) => setTimeout(resolve, n));

const useCancellablePromises = () => {
  const pendingPromises = useRef<TCancellablePromise[]>([]);

  const appendPendingPromise = (promise: TCancellablePromise) =>
    (pendingPromises.current = [...pendingPromises.current, promise]);

  const removePendingPromise = (promise: TCancellablePromise) =>
    (pendingPromises.current = pendingPromises.current.filter(
      (p) => p !== promise,
    ));

  const clearPendingPromises = () => {
    return pendingPromises.current.map((p) => {
      return p.cancel();
    });
  };

  return {
    appendPendingPromise,
    removePendingPromise,
    clearPendingPromises,
  };
};

const useClickPreventionOnDoubleClick = (onClick: any, onDoubleClick: any) => {
  const api = useCancellablePromises();

  const handleClick = (...params: any) => {
    api.clearPendingPromises();
    const waitForClick = cancellablePromise(delay(200));

    api.appendPendingPromise(waitForClick);

    return waitForClick.promise
      .then(() => {
        api.removePendingPromise(waitForClick);
        onClick(...params);
      })
      .catch((errorInfo) => {
        api.removePendingPromise(waitForClick);

        if (!errorInfo.isCanceled) {
          throw errorInfo.error;
        }
      });
  };

  const handleDoubleClick = (...params: any) => {
    api.clearPendingPromises();
    onDoubleClick(...params);
  };

  return [handleClick, handleDoubleClick];
};

export default useClickPreventionOnDoubleClick;
