import React from 'react';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

import { ModalConfirm } from '@platform/front-lib';
import { SZ_ENTITY_KIND } from '../../../constants';
import { useStores } from '../../../stores';

interface IFolderFormAddEditProps {
  handleCloseModal: (params?: any) => void;
  open: boolean;
  id: string | null;
  onSuccess?: () => void;
}

export const EntityDeleteDialog: React.FC<IFolderFormAddEditProps> = observer(
  ({ open, handleCloseModal, id, onSuccess }) => {
    const { formatMessage } = useIntl();

    const { fileOrganizerStore } = useStores();

    const {
      setActiveFileId,
      folderFilesStore: { dataMap, removeDataMapById },
      fileStore: { isFetching: isFetchingDeleteFile, deleteFile },
      folderStore: { isFetching: isFetchingDeleteFolder, deleteFolder },
    } = fileOrganizerStore;

    const isFetchingDelete = isFetchingDeleteFile || isFetchingDeleteFolder;

    const entity = (id && dataMap?.[id]) || null;
    const isFile = entity?.kind === SZ_ENTITY_KIND.FILE;

    const handleCloseDrawer = () => {
      setActiveFileId(null);
    };

    const confirmDelete = () => {
      if (!entity) {
        return;
      }
      const successFunc = () => {
        removeDataMapById({
          id: entity._id,
          deleteOnlyMap: true,
        });

        handleCloseModal();
        onSuccess?.();
        handleCloseDrawer();
      };

      if (isFile) {
        deleteFile(
          { id: entity._id },
          {
            successFunc,
          },
        );
        return;
      }

      deleteFolder(
        { id: entity._id },
        {
          successFunc,
        },
      );
    };

    return (
      <ModalConfirm
        loading={isFetchingDelete}
        disableActions={isFetchingDelete}
        isOpen={open}
        text={
          isFile
            ? formatMessage(
                {
                  id: 'STORAGE_ZONE.FILES.DELETE.CONFIRMATION"',
                  defaultMessage:
                    'Are you sure you want delete this file - {entityName}?',
                },
                {
                  entityName: entity?.name,
                },
              )
            : formatMessage(
                {
                  id: 'STORAGE_ZONE.FOLDERS.DELETE.CONFIRMATION',
                  defaultMessage:
                    'Are you sure you want delete this folder - {entityName}?',
                },
                {
                  entityName: entity?.name,
                },
              )
        }
        confirmAction={confirmDelete}
        handleClose={handleCloseModal}
        cancelAction={handleCloseModal}
      />
    );
  },
);
