import React from 'react';
import { observer } from 'mobx-react-lite';

import { generatePath, useHistory, useParams } from 'react-router-dom';

import { useStores } from 'src/stores/root-store';
import { ROUTES } from 'src/constants';
import IsLoadingComponent from 'src/components/layout/loading';
import { ISZRootFolder } from '../../../models/storage-zone';

export const PageOrganizerViewNewPersonnel = observer(() => {
  const { id } = useParams<Record<string, string>>();
  const history = useHistory();

  const {
    fileOrganizerStore: {
      folderRootStore: { createNewRootFolder },
    },
  } = useStores();

  React.useEffect(() => {
    if (id) {
      const successFunc = (szRoot: ISZRootFolder) => {
        const url = generatePath(
          generatePath(ROUTES.MODULE_FILE_ORGANIZER_VIEW, {
            rootId: szRoot?._id,
            folders: szRoot?.folderId,
          }),
        );
        history.push(url);
      };

      createNewRootFolder({ id: id }, { successFunc });
    }
  }, [id, createNewRootFolder, history]);

  return <IsLoadingComponent isLoading />;
});
