import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useFilesViewStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(4),
    },

    //  Table
    tableCaption: {
      marginBottom: theme.spacing(1),
    },
    tableWrapper: {
      marginBottom: theme.spacing(5),
    },
  }),
);
