import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStores } from '../stores';
import { AnvHelmetProvider } from '@platform/front-lib';
interface IProps {}

const AnvHelmetProviderConnected: React.FC<IProps> = observer(
  ({ children }) => {
    const {
      uiStores: { tool },
    } = useStores();

    return (
      <AnvHelmetProvider tool={(tool && tool) || {}}>
        {children}
      </AnvHelmetProvider>
    );
  },
);

export default AnvHelmetProviderConnected;
