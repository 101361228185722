export const PZ_ROOT_VIRTUAL_FOLDERS = {
  EDU_ENTITIES: 'EDU_ENTITIES',
  GOVERNING_BOARDS: 'GOVERNING_BOARDS',
  FAMILY_CIRCLE: 'FAMILY_CIRCLE',
};

export const WZ_ROOT_VIRTUAL_FOLDERS = {
  COMPLIANCE: 'COMPLIANCE',
  GOVERNING_BOARDS: 'GOVERNING_BOARDS',
};

export const PZ_GOV_ROOT_LIST_DEFAULT_REQUEST_ID =
  'pz-governing-boards-root-list';
