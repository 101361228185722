import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

import { generatePath, useHistory, useParams } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import {
  AnvNotFoundPage,
  AnvUtils,
  IsLoadingComponent,
  TextH1,
  TextSubTitleNew,
  useCommonOffsetsStyles,
  useModuleAccessHistory,
} from '@platform/front-lib';

import { IParamsFolders } from '../../../types/storage-zone';

import { useStores } from '../../../stores';
import {
  useRouteMatchEntity,
  useRouteMatchEntityBCItems,
} from '../folders-breadcrumbs/use-route-match-entity';
import { SZSearchFilesView } from './list';

import { ModuleContext } from '../module-context';

import { AnvBreadcrumbsConnected } from '../../../components/connected/anv-breadcrumbs-container';
import { ContentPaper } from '../../../components/layout/content';

import { SZFoldersBreadcrumbs } from '../folders-breadcrumbs';

import { ViewDrawerData } from '../entity-drawer';
import { SZToolbar } from '../toolbar';

import { ROUTES, SZ_EVENT_TYPES, TOOL_MODULES } from '../../../constants';
import { useStyles } from './styles';

interface IFileOrganizerViewProps {}

export const FilesSearch: React.FC<IFileOrganizerViewProps> = observer(() => {
  const module = React.useContext(ModuleContext);

  const { formatMessage } = useIntl();

  const classes = useStyles();
  const history = useHistory();

  const { locale } = useIntl();

  const params = useParams<IParamsFolders>();

  const { folders, rootId } = params;

  const classesOffset = useCommonOffsetsStyles();

  const { isSearchPageFEEduEntities } = useRouteMatchEntity();

  const isExplorer = module === TOOL_MODULES.FILE_EXPLORER;

  const store = isExplorer
    ? useStores().fileExplorerStore
    : useStores().fileOrganizerStore;

  const {
    authStores: { isOperatorLoggedIn },
    uiStores: { tool },
  } = useStores();

  const {
    activeFileId,
    activeFolderId,
    setActiveFolderId,
    setActiveFileId,

    folderStore,

    folderRootStore: {
      getOne: getOneRootFolders,
      dataMap: rootFoldersMap,
      isFetching: isFetchingRootFolders,
    },
    fileStore: { setListParams },
  } = store;

  React.useEffect(() => {
    setListParams({});

    return () => {
      setListParams({});
    };
  }, [folders, setListParams]);

  const { getList: getChildFolders, getChain } = folderStore;

  const rootEntity = rootFoldersMap?.[rootId];

  const isRelatedToEduEntity = [
    SZ_EVENT_TYPES.SCHOOL,
    SZ_EVENT_TYPES.ORGANIZATION,
  ].includes(rootEntity?.entityType || '');

  const isSchool = rootEntity?.entityType === SZ_EVENT_TYPES.SCHOOL;

  useModuleAccessHistory({
    moduleCodeFallback: module,
    entitiesRequired: isRelatedToEduEntity,
    data: {
      organization: !isSchool ? rootEntity?.organization : undefined,
      school: isSchool ? rootEntity?.school : undefined,
    },
  });

  if (rootId && !folders?.length && rootEntity) {
    const url = generatePath(ROUTES.MODULE_FILE_ORGANIZER_VIEW, {
      rootId: rootEntity?._id,
      folders: rootEntity?.folderId,
    });
    history.replace(url);
  }

  React.useEffect(() => {
    if (!rootEntity) {
      getOneRootFolders({ id: rootId });
    }
  }, [rootEntity, rootId, getOneRootFolders]);
  React.useEffect(() => {
    if (rootId) {
      getChain({ rootId, folders: folders?.split?.('/') || [] });
    }
  }, [folders, rootId, getChain]);

  React.useEffect(() => {
    if (rootId && folders) {
      const foldersArray = folders?.split('/');
      const lastId = foldersArray?.pop?.();

      if (lastId) {
        getChildFolders({ id: lastId, limit: 1_000 });
      }
    }
  }, [rootId, folders, getChildFolders]);

  // reset active folder
  React.useEffect(() => {
    setActiveFolderId(null);
    setActiveFileId(null);
  }, [rootId, folders, setActiveFolderId, setActiveFileId]);

  const additionalBreadcrumbs = useRouteMatchEntityBCItems();

  // Error has occurred
  if (!rootEntity && !isFetchingRootFolders && isFetchingRootFolders) {
    return <AnvNotFoundPage />;
  }

  const rootEntityTitle = AnvUtils.getNameByLangNullable(
    rootEntity?.schoolRef || rootEntity?.organizationRef,
    'name',
    locale,
  );

  const breadCrumbsItems = [
    {
      name: 'entity',
      component: rootEntityTitle || '',
    },
  ];

  return (
    <>
      <ViewDrawerData />

      <div
        data-clickaway={true}
        className={clsx(classes.mainContent, {
          [classes.mainContentWDrawer]: !!activeFolderId || !!activeFileId,
        })}
      >
        {!rootEntity && <IsLoadingComponent isLoading />}

        <AnvBreadcrumbsConnected
          tool={tool}
          moduleCode={module}
          isOperator={isOperatorLoggedIn}
          items={breadCrumbsItems}
        />

        <ContentPaper>
          <Grid
            container
            // spacing={2}
            justify={'space-between'}
            alignItems={'center'}
          >
            <Grid item>
              <TextH1>
                {formatMessage({
                  id: 'STORAGE_ZONE.ORGANIZER.SEARCH.LIST.TITLE',
                  defaultMessage: 'Search',
                })}
              </TextH1>
              <TextSubTitleNew colorSecondary>
                {rootEntityTitle}
              </TextSubTitleNew>
            </Grid>
            <Grid item>
              <SZToolbar />
            </Grid>
          </Grid>

          <div className={clsx(classesOffset.pb_2, classesOffset.pt_1)}>
            <SZFoldersBreadcrumbs
              items={isSearchPageFEEduEntities ? additionalBreadcrumbs : []}
            />
          </div>

          <div>
            <SZSearchFilesView />
          </div>
        </ContentPaper>
      </div>
    </>
  );
});
