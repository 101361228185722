import { action, makeObservable } from 'mobx';

import { FileOrganizerService } from './service';

import { FolderRootCommonStore } from '../common/folder-root-common-store';
import { FolderRootCommonService } from '../common/folder-root-common-service';

export class FileOrganizerFolderRootStoreClass extends FolderRootCommonStore<FileOrganizerService> {
  service: FolderRootCommonService;

  @action
  private reset() {
    this.data = null;
    this.isFetching = false;
    this.isLoaded = false;
    this.isError = false;
    this.error = null;
  }

  @action
  resetError = () => {
    this.errorItem = null;
    this.isError = false;
  };

  constructor() {
    super({ isView: false });
    this.service = new FolderRootCommonService({ isView: false });
    makeObservable(this);
  }
}

export default new FileOrganizerFolderRootStoreClass();
