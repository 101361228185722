import React from 'react';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import AnvModalInfo from 'src/components/shared/modals/modal-info';

import { downloadFile, formatBytes, getFileName } from 'src/utils';
import { envConst } from 'src/constants';
import { TUnionFile } from '../../../models/storage-zone/file-union';

import { useStyles } from './styles';
import docPlaceholder from './doc-placeholder.png';

interface IProps {
  file: TUnionFile;
  existsFileUrl?: string;

  imageClassName?: string;
  showDetails?: boolean;
  placeholder?: JSX.Element;
}

const { apiUrl: API_URL } = envConst;

export const AnvFileView: React.FC<IProps> = observer(
  ({
    file,
    imageClassName = '',
    showDetails,
    existsFileUrl = `${API_URL}/files/get`,
    placeholder,
  }) => {
    const { formatMessage } = useIntl();

    const classes = useStyles();

    const [modalGalleryOpen, setModalGalleryOpen] = React.useState<boolean>(
      false,
    );
    const openSlide = () => setModalGalleryOpen(true);
    const closeModalGallery = () => setModalGalleryOpen(false);

    if (!file) {
      return null;
    }

    const name = getFileName(file);
    const size = file?.fileSize || 0;
    const sizeHumanable = formatBytes(size);
    const isImage = file?.fileType?.startsWith('image/');

    const detailsComp = showDetails && (
      <div className={classes.fileDetails}>
        <div className={classes.fileDetailsName} title={name}>
          {name}
        </div>
        <div className={classes.fileDetailsSize}>{sizeHumanable}</div>
      </div>
    );

    if (!isImage && placeholder) {
      return (
        <div
          className={classes.placeholderWrapper}
          title={formatMessage({
            id: 'STORAGE_ZONE.COMMON.FILE.CLICK_TO_DOWNLOAD',
            defaultMessage: 'Click to download',
          })}
          onClick={() => {
            downloadFile({
              url: `${existsFileUrl}/${file?._id}`,
              filename: name || 'download',
            })();
          }}
        >
          {placeholder}
        </div>
      );
    }

    return (
      <>
        <div className={classes.filePreviewContainer}>
          {isImage ? (
            <img
              onClick={openSlide}
              className={clsx(classes.filePreview, imageClassName, {
                [classes.filePreviewAlone]: !showDetails,
              })}
              src={`${existsFileUrl}/${file?._id}`}
              alt=""
            />
          ) : placeholder ? (
            <div
              onClick={() => {
                downloadFile({
                  url: `${existsFileUrl}/${file?._id}`,
                  filename: name || 'download',
                })();
              }}
            >
              {placeholder}
            </div>
          ) : (
            <img
              onClick={() => {
                downloadFile({
                  url: `${existsFileUrl}/${file?._id}`,
                  filename: name || 'download',
                })();
              }}
              className={classes.filePreview}
              src={docPlaceholder}
              alt=""
            />
          )}

          {showDetails && detailsComp}
        </div>

        {modalGalleryOpen && (
          <AnvModalInfo
            maxWidth={'xl'}
            open={modalGalleryOpen}
            handleClose={closeModalGallery}
            hideFooter
          >
            <img
              className={classes.imgModal}
              src={`${existsFileUrl}/${file._id}`}
              alt={name}
              title={name}
            />
          </AnvModalInfo>
        )}
      </>
    );
  },
);
