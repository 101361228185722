import React from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Grid, IconButton } from '@material-ui/core';

import {
  AnvButton,
  AnvDialogActions,
  AnvDialogBody,
  AnvDialogTitle,
  AnvIcon,
  AnvTextField,
  AnvTooltip,
  AnvUtils,
  Constants,
  IsLoadingComponent,
} from '@platform/front-lib';

import { ISZFolderFile, ISZObjectFolder } from 'src/models/storage-zone';
import { IParamsFolders } from 'src/types/storage-zone';

import { useStores } from 'src/stores';
import { SZ_ENTITY_KIND } from '../../../../constants';

interface INewFolderFormProps {
  handleCloseModal: () => void;
  folder?: ISZFolderFile;
}

interface INewFolderForm {
  folderName: string;
  publicAccess: boolean;
}

const validationSchema = (fm: (arg: any, values?: any) => string) =>
  yup.object().shape({
    folderName: yup
      .string()
      .required(fm(Constants.validationsMessages.required())),
  });

export const FolderFormAddEdit: React.FC<INewFolderFormProps> = observer(
  ({ handleCloseModal, folder }) => {
    const { formatMessage } = useIntl();

    const isEdit = !!folder?._id;

    const params = useParams<IParamsFolders>();
    const { folders } = params;

    const {
      fileOrganizerStore: {
        folderFilesStore: { setDataMapById, setRefetch },
        folderStore: {
          errorItem,
          isFetching,
          createFolder,
          updateFolder,
          // getList: getChildFolders,
        },
      },
    } = useStores();

    const foldersArray = folders?.split('/');
    const lastId = foldersArray?.pop?.();

    const serverErrors = errorItem || {};

    const {
      control,
      handleSubmit,
      errors,
      setValue,
      watch,
    } = useForm<INewFolderForm>({
      shouldUnregister: false,
      defaultValues: {
        folderName: folder?.name || '',
        publicAccess: !!folder ? !!folder?.publicAccess : true,
      },
      resolver: yupResolver(validationSchema(formatMessage)),
    });

    const publicAccessWatch = watch('publicAccess');

    const onSubmit = (data: INewFolderForm) => {
      const { folderName } = data;

      if (lastId) {
        const successFunc = (response: ISZObjectFolder, create: boolean) => {
          setDataMapById({
            id: response._id,
            entity: {
              ...response,
              name: folderName,
              kind: SZ_ENTITY_KIND.FOLDER,
              timezone: '',
              expiredAt: '',
            },
            setOnlyMap: true,
            forceUpdate: true,
          });

          if (create) {
            setRefetch(true);
          }

          // if (!isSearchPage) {
          //   getChildFolders({ id: lastId });
          // }

          handleCloseModal();
        };

        if (isEdit && folder?._id) {
          updateFolder(
            { id: folder._id, folderName, publicAccess: data.publicAccess },
            { successFunc: (response) => successFunc(response, false) },
          );
        } else {
          createFolder(
            {
              folderName,
              parentFolder: lastId,
              publicAccess: data.publicAccess,
            },
            { successFunc: (response) => successFunc(response, true) },
          );
        }
      }
    };

    const formHelper = (field: string) => {
      return AnvUtils.formErrorHelper(field, errors, serverErrors);
    };

    return (
      <div style={{ position: 'relative' }}>
        <AnvDialogTitle>
          {isEdit ? (
            <FormattedMessage
              id="STORAGE_ZONE.FOLDERS.EDIT.TITLE.UPDATE"
              defaultMessage="Update Folder"
            />
          ) : (
            <FormattedMessage
              id="STORAGE_ZONE.FOLDERS.EDIT.TITLE.NEW"
              defaultMessage="New Folder"
            />
          )}
        </AnvDialogTitle>

        <form onSubmit={handleSubmit(onSubmit)}>
          <IsLoadingComponent isLoading={isFetching} isAbsolute />

          <AnvDialogBody>
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item style={{ flex: 1 }}>
                <AnvTextField
                  zeroMargin
                  defaultValue={''}
                  fullWidth
                  required
                  control={control}
                  name={'folderName'}
                  label={
                    <FormattedMessage
                      id="STORAGE_ZONE.FOLDERS.EDIT.LABEL.NAME"
                      defaultMessage="Name"
                    />
                  }
                  placeholder={formatMessage({
                    id: 'STORAGE_ZONE.FOLDERS.EDIT.PLACEHOLDER.NAME',
                    defaultMessage: 'New Folder',
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!formHelper('folderName')}
                  helperText={formHelper('folderName')}
                />
              </Grid>

              <Grid item>
                <AnvTooltip
                  title={formatMessage(
                    publicAccessWatch
                      ? {
                          id: 'STORAGE_ZONE.COMMON.FILE.PUBLIC',
                          defaultMessage: 'Public',
                        }
                      : {
                          id: 'STORAGE_ZONE.COMMON.FILE.PRIVATE',
                          defaultMessage: 'Private',
                        },
                  )}
                  arrow
                >
                  <IconButton
                    onClick={() => {
                      return setValue('publicAccess', !publicAccessWatch);
                    }}
                  >
                    <AnvIcon
                      icon={publicAccessWatch ? 'unlock' : 'lock'}
                      size={24}
                      color={publicAccessWatch ? 'lightIcons' : 'primary'}
                    />
                  </IconButton>
                </AnvTooltip>
              </Grid>
            </Grid>
          </AnvDialogBody>

          <AnvDialogActions>
            <Grid container direction={'column'} spacing={1}>
              <Grid item>
                <AnvButton
                  data-test={'create-folder-submit'}
                  variant={'contained'}
                  color={'primary'}
                  fullWidth
                  type={'submit'}
                  disabled={isFetching}
                >
                  {isEdit ? (
                    <FormattedMessage
                      id="STORAGE_ZONE.FOLDERS.ACTION.UPDATE.SUBMIT"
                      defaultMessage="Update"
                    />
                  ) : (
                    <FormattedMessage
                      id="STORAGE_ZONE.FOLDERS.ACTION.CREATE.SUBMIT"
                      defaultMessage="Create"
                    />
                  )}
                </AnvButton>
              </Grid>

              <Grid item>
                <AnvButton
                  data-test={'create-folder-cancel'}
                  onClick={handleCloseModal}
                  variant={'text'}
                  color={'primary'}
                  fullWidth
                  disabled={isFetching}
                >
                  <FormattedMessage
                    id="STORAGE_ZONE.FOLDERS.ACTION.CANCEL"
                    defaultMessage="Cancel"
                  />
                </AnvButton>
              </Grid>
            </Grid>
          </AnvDialogActions>
        </form>
      </div>
    );
  },
);
