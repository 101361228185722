import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import {
  AnvPaginationMui,
  AnvTableWrapper,
  AnvUtils,
  Constants,
  useCommonOffsetsStyles,
} from '@platform/front-lib';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { useSubModuleContext } from '../../../../contexts/sub-module';
import { ISZRootFolder } from '../../../../models';
import { useCommonTable } from '../../../../utils/hooks-common-table';
import { ViewDrawerData } from '../../../common/entity-drawer';
import { SZFoldersFilesViewGrid } from '../../../common/folder-files/grid';

import { SZBreadcrumbs } from '../../../common/SZBreadcrumbs';
import { FILE_EXPLORER_SUB_MODULES, ROUTES } from '../../../../constants';
import { useStylesContentWDrawer } from '../../personal-zone/list-education-entities/view/styles';
import { MyStorageZoneTitle } from '../title';
import { ExplorerMainTabs } from '../../tabs';
import { SZFoldersBreadcrumbs } from '../../../common/folders-breadcrumbs';
import { SZCustomBreadCrumbs } from '../../../common/folders-breadcrumbs/common';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../stores';

interface ICommonGoverningBoardsPageProps {
  children?: React.ReactNode;
  requestId: string;
  RightAction?: React.ReactNode;
}

export const CommonGoverningBoardsPage: React.FC<ICommonGoverningBoardsPageProps> = observer(
  ({ requestId, RightAction }) => {
    const classesOffset = useCommonOffsetsStyles();

    const classesContentWDrawer = useStylesContentWDrawer();

    const subModule = useSubModuleContext();

    const history = useHistory();

    const { fileExplorerStore } = useStores();

    const { activeFolderId } = fileExplorerStore;

    const isWorkZone = subModule === FILE_EXPLORER_SUB_MODULES.WORK_ZONE;

    const subModuleStore = fileExplorerStore[subModule];

    const { locale } = useIntl();

    const {
      getRootList,
      getListData,
      setPage,
      setFilter,
      setSort,
      dataMap,
    } = subModuleStore.governingBoardsStore;

    const { dataListFromMap: organizations, pagination } = getListData(
      requestId,
    );

    const {
      queryPage,

      handleChangePage,
    } = useCommonTable({
      defaultFilterState: Constants.mockObj,
      pagination,
      setPage,
      setFilter,
      setSort,
    });

    React.useEffect(() => {
      getRootList({
        requestId,
      });
    }, [requestId, getRootList]);

    const itemsSZFolders = React.useMemo(() => {
      return [
        {
          ...SZCustomBreadCrumbs.personalZoneGoverningBoard,
          to: undefined,
        },
      ];
    }, []);

    const handleFolderFileDoubleClick = (folder: any) => {
      const entity = folder as ISZRootFolder;

      const path = generatePath(
        isWorkZone
          ? ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_GOVERNING_BOARDS_VIEW
          : ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_GOVERNING_BOARDS_VIEW,
        {
          rootId: entity._id,
          folders: entity.folderId,
        },
      );

      history.push(path);
    };

    const preparedOrganizations = React.useMemo(() => {
      return organizations.map((item: ISZRootFolder) => {
        return {
          ...item,
          name:
            AnvUtils.getNameByLangNullable(item?.govBodyRef, 'name', locale) ||
            '',
          _id: item._id,
        };
      });
    }, [locale, organizations]);

    return (
      <div
        data-clickaway={true}
        className={clsx(classesContentWDrawer.mainContent, {
          [classesContentWDrawer.mainContentWDrawer]: !!activeFolderId,
        })}
      >
        <SZBreadcrumbs
          items={[
            {
              component: (
                <FormattedMessage
                  id={'STORAGE_ZONE.FOLDERS.BREADCRUMBS.GOVERNING_BOARDS'}
                  defaultMessage={'Governing Boards'}
                />
              ),
            },
          ]}
        />

        <ViewDrawerData customView={'govBody'} customMap={dataMap} />

        <AnvTableWrapper>
          <MyStorageZoneTitle />

          <Grid container spacing={2} justify={'space-between'}>
            <Grid item>
              <ExplorerMainTabs />
            </Grid>

            {RightAction && <Grid item>{RightAction}</Grid>}
          </Grid>

          <div className={clsx(classesOffset.pb_2, classesOffset.pt_1)}>
            <SZFoldersBreadcrumbs
              rootLink={
                isWorkZone
                  ? ROUTES.MODULE_FILE_EXPLORER_WORK_LIST
                  : ROUTES.MODULE_FILE_EXPLORER_PERSONAL_ROOT
              }
              items={itemsSZFolders}
            />
          </div>

          <SZFoldersFilesViewGrid
            //@ts-ignore
            folders={preparedOrganizations}
            onDoubleClick={handleFolderFileDoubleClick}
          />

          <AnvPaginationMui
            pagination={pagination}
            totalPages={pagination?.totalPages}
            handleChangePage={handleChangePage}
            page={+queryPage}
          />
        </AnvTableWrapper>
      </div>
    );
  },
);
