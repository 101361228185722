import React from 'react';

import { AnvIcon } from '@platform/front-lib';

import { useFoldersBreadcrumbsStyles } from './styles';

export const SZBreadcrumbItemWrapper: React.FC = ({ children }) => {
  const classes = useFoldersBreadcrumbsStyles();

  return <div className={classes.item}>{children}</div>;
};

export const SZBreadcrumbItemWrapperWIcon: React.FC = ({ children }) => {
  return (
    <SZBreadcrumbItemWrapper>
      <AnvIcon icon={'folderBigOpen'} size={32} offset={1} />
      {children}
    </SZBreadcrumbItemWrapper>
  );
};
