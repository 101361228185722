import React from 'react';

import { observer } from 'mobx-react-lite';

import { AnvBreadcrumbs, AnvIcon, AnvTooltip } from '@platform/front-lib';

import { useIntl } from 'react-intl';
import { TBreadcrumbsItem } from '@platform/front-lib/dist/components/shared/anv-tool-breadcrumbs';
import { ROUTES } from '../../../../constants';
import { IVirtualFolder } from '../../../common/folders-virtual/types';
import { useFoldersBreadcrumbsStyles } from './styles';

interface IWZFoldersViewProps {
  items?: IVirtualFolder[];
}

export const WZFoldersBreadcrumbs: React.FC<IWZFoldersViewProps> = observer(
  ({ items }) => {
    const classes = useFoldersBreadcrumbsStyles();

    const { formatMessage } = useIntl();

    const itemsNew: TBreadcrumbsItem[] = React.useMemo(() => {
      const breacrumbs = [
        {
          component: (
            <AnvTooltip
              title={formatMessage({
                id: 'STORAGE_ZONE.FOLDERS.BREADCRUMBS.ROOT_FOLDER',
                defaultMessage: 'Root Folder',
              })}
            >
              <AnvIcon
                icon={'fileCabinet'}
                color={'primary'} //: 'inherit'
                size={32}
                offset={1}
              />
            </AnvTooltip>
          ),
        },

        {
          to: !!items?.length
            ? ROUTES.MODULE_FILE_EXPLORER_WORK_LIST
            : undefined,
          component: (
            <div className={classes.item}>
              <AnvIcon icon={'folderBig'} size={32} offset={1} />
              {formatMessage({
                id: 'STORAGE_ZONE.FOLDERS.BREADCRUMBS.WORK_ZONE',
                defaultMessage: 'Work Zone',
              })}
            </div>
          ),
        },
      ];

      if (items?.length) {
        const total = items.length;

        // TODO MOVE TO SZFoldersBreadcrumbs
        items.forEach((item, index) => {
          const isLastElement = total === index + 1;

          breacrumbs.push({
            to: isLastElement ? undefined : item?.to,
            component: (
              <div className={classes.item}>
                <AnvIcon icon={'folderBig'} size={32} offset={1} />
                {item?.folderName || ''}
              </div>
            ),
          });
        });
      }

      return breacrumbs;
    }, [classes.item, formatMessage, items]);

    return (
      <div className={classes.root}>
        <AnvBreadcrumbs items={itemsNew} disableRootItem={true} />
      </div>
    );
  },
);
