import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  AnvDialog,
  FILTER_SETTINGS,
  usePrevious,
  AnvDialogTitle,
  AnvDialogBody,
  ScopeFormFilter,
  ScopeFilterActions,
} from '@platform/front-lib';
// test
// import { ScopeFormFilter } from '../../../components/scope-form-filter';

import { useStores } from 'src/stores/root-store';
import { ModuleContext } from 'src/pages/common/module-context';

import { getItemById, getNameByLangNullable } from 'src/utils';
import { ACCESS_SCOPES, validationsMessages } from 'src/constants';
import { IFilterForm } from '../../file-explorer/personal-zone/list-education-entities/AddNew';

import { useStyles } from './styles';

interface IProps {
  openFilters: boolean;
  handleToggleFilters: () => void;
}

const defaultValues = {
  accessScope: ACCESS_SCOPES.SCHOOL,
  scope: {
    jurisdiction: '',
    organization: null,
    organizationSecondary: null,
    tags: [],
    school: null,
    particularOrganization: false,
  },
};

interface IValidationSchemaModuleRequest {
  accessScope?: string;
}

const validationSchema = (
  fm: (arg: any, values?: any) => string,
  { accessScope }: IValidationSchemaModuleRequest,
) => {
  const shape: Record<string, any> = {
    accessScope: yup.string().required(fm(validationsMessages.required())),
  };

  let scopeObjectShape: null | Record<string, any> = null;

  if (accessScope === ACCESS_SCOPES.SCHOOL) {
    scopeObjectShape = {
      jurisdiction: yup
        .string()
        .typeError(fm(validationsMessages.required()))
        .required(fm(validationsMessages.required())),

      school: yup.string().nullable(),
    };
  }
  if (accessScope !== ACCESS_SCOPES.SCHOOL) {
    scopeObjectShape = {
      orgType: yup
        .string()
        .typeError(fm(validationsMessages.required()))
        .required(fm(validationsMessages.required())),
      organization: yup.string().nullable(),
    };
  }

  if (scopeObjectShape) {
    shape.scope = yup
      .object()
      .shape(scopeObjectShape)
      .required(fm(validationsMessages.required()));
  }

  return yup.object().shape(shape);
};

export const ListFilter: React.FC<IProps> = observer(
  ({ openFilters, handleToggleFilters }) => {
    const { formatMessage, locale } = useIntl();
    const classes = useStyles();

    const {
      authStores: { modulesFilter, setAccessFilter },
      uiStores: { tool, anvilarModulesMap },
      metaStores,
    } = useStores();

    const module = React.useContext(ModuleContext);
    const moduleSettings = anvilarModulesMap?.[module]?.settingsMap;

    // @ts-ignore
    const accessFilter = modulesFilter[module];

    const schoolFilterAvailable =
      !moduleSettings?.[FILTER_SETTINGS.FILTER_BY_SCHOOL] ||
      moduleSettings?.[FILTER_SETTINGS.FILTER_BY_SCHOOL] === 'true';

    const organizationFilterAvailable =
      !moduleSettings?.[FILTER_SETTINGS.FILTER_BY_ORGANIZATION] ||
      moduleSettings?.[FILTER_SETTINGS.FILTER_BY_ORGANIZATION] === 'true';
    const organizationFilterParticular =
      moduleSettings?.[FILTER_SETTINGS.FILTER_PARTICULAR_ORGANIZATION] ===
      'true';

    const isForbiddenAccessFilter =
      accessFilter?.accessScope &&
      ((accessFilter?.accessScope !== ACCESS_SCOPES.SCHOOL &&
        !organizationFilterAvailable) ||
        (accessFilter?.accessScope === ACCESS_SCOPES.SCHOOL &&
          !schoolFilterAvailable));

    React.useEffect(() => {
      if (isForbiddenAccessFilter) {
        setAccessFilter(module);
      }
    }, [isForbiddenAccessFilter, module, setAccessFilter]);

    const {
      accessScopeStore,
      organizationTypesStore: { data: orgTypes },
      jurisdictionStore: { data: jurisdictions, getJurisdictions },
      organizationsStore: {
        data: organizations,
        data2: organizations2,
        // isFetching: isFetchingOrganizations,
      },
      schoolsStore: { data: schools /*, isFetching: isFetchingSchools*/ },
      accessHistoryStore: { recentlyViewed: accessHistory },
    } = metaStores;

    const { data: accessScopes, getAccessScopes } = accessScopeStore;

    React.useEffect(() => {
      getJurisdictions();
      getAccessScopes();
    }, [getJurisdictions, getAccessScopes]);

    const [accessScopeState, setAccessScopeState] = React.useState('');

    const methods = useForm<IFilterForm>({
      defaultValues: {
        ...defaultValues,
        ...{
          accessScope: accessFilter?.accessScope,
          scope: {
            jurisdiction: accessFilter?.scope?.jurisdiction || '',
            organizationSecondary: accessFilter?.scope?.organizationSecondary,
            organization: accessFilter?.scope?.organization,
            orgType: accessFilter?.scope?.orgType,
            tags: accessFilter?.scope?.tags || accessFilter?.scope?.tag,
            tag: accessFilter?.scope?.tag,
            school: accessFilter?.scope?.school,
          },
        },
        // ...accessFilter ,
      },
      resolver: yupResolver(
        validationSchema(formatMessage, {
          accessScope: accessScopeState,
        }),
      ),
    });

    const { handleSubmit, control, setValue, reset } = methods;

    const accessScope = useWatch({
      control,
      name: 'accessScope',
      defaultValue: accessFilter?.accessScope || '',
    });
    const scope = useWatch({
      control,
      name: 'scope',
      defaultValue: accessFilter?.scope || {},
    });

    const { organization } = scope;

    const accessScopePrev = usePrevious(accessScope);
    const accessScopeChanged =
      accessScope && accessScopePrev && accessScopePrev !== accessScope;

    const schoolsWithHistory = React.useMemo(() => {
      const array: Record<string, any>[] = [];

      if (schools) {
        array.push(...(schools || []));
      }

      if (accessHistory) {
        array.push(
          ...(accessHistory
            .map((historyItem: Record<string, any>) => historyItem?.schoolRef)
            .filter((item: any) => !!item) || []),
        );
      }

      return array;
    }, [schools, accessHistory]);
    const organizationsWithHistory = React.useMemo(() => {
      const array: Record<string, any>[] = [];

      if (organizations) {
        array.push(...(organizations || []));
      }

      if (accessHistory) {
        array.push(
          ...(accessHistory
            .map(
              (historyItem: Record<string, any>) =>
                historyItem?.organizationRef,
            )
            .filter((item: any) => !!item) || []),
        );
      }

      return array;
    }, [organizations, accessHistory]);

    const organizationEntity = getItemById(
      organizationsWithHistory,
      organization,
    );

    React.useEffect(() => {
      setAccessScopeState(accessScope);

      if (
        accessScopeChanged &&
        organizationEntity?.organizationType?.accessScope !== accessScope
      ) {
        setValue('scope.jurisdiction', '');
        setValue('scope.organization', '');
        setValue('scope.organizationSecondary', '');
        setValue('scope.school', '');
      }
      // [setAccessScopeState]
      // eslint-disable-next-line
    }, [organizationEntity, accessScope, accessScopeChanged, setValue]);

    const submitFilter = (fields: any) => {
      if (fields.accessScope === ACCESS_SCOPES.SCHOOL) {
        delete fields?.scope?.organization;
        delete fields?.scope?.orgType;
      } else if (fields.accessScope !== ACCESS_SCOPES.SCHOOL) {
        delete fields?.scope?.school;
      }

      const organizationEntity = getItemById(
        organizationsWithHistory,
        fields?.scope?.organization,
      );
      const organizationSecondaryEntity = getItemById(
        organizations2,
        fields?.scope?.organizationSecondary,
      );
      const schoolEntity = getItemById(
        schoolsWithHistory,
        fields?.scope?.school,
      );

      if (organizationFilterParticular) {
        fields.scope.particularOrganization = true;
      }

      const schoolName = getNameByLangNullable(schoolEntity, 'name', locale);

      const jurisdictionEntity = getItemById(
        jurisdictions,
        fields?.scope?.jurisdiction,
      );
      const jurName = getNameByLangNullable(jurisdictionEntity, 'name', locale);

      const orgTypeEntity = getItemById(orgTypes, fields?.scope?.orgType);
      const orgTypeName = getNameByLangNullable(
        orgTypeEntity,
        'description',
        locale,
      );

      const labelArray: string[] = [];

      const orgName = getNameByLangNullable(organizationEntity, 'name', locale);
      const org2Name =
        fields?.scope?.organizationSecondary &&
        getNameByLangNullable(organizationSecondaryEntity, 'name', locale);

      if (schoolName) labelArray.push(schoolName);
      if (orgName) labelArray.push(orgName);
      if (org2Name) labelArray.push(org2Name);
      if (!orgName && orgTypeName) labelArray.push(orgTypeName);
      if (jurName) labelArray.push(jurName);

      const label = labelArray.filter((item) => !!item).join(', ');
      setAccessFilter(module, { ...fields, label });
      handleToggleFilters();
    };

    React.useEffect(() => {
      const values = {
        ...defaultValues,
        ...accessFilter,
      };
      // accessScope: values.accessScope;
      values.scope = {
        jurisdiction: values.scope.jurisdiction,
        organizationSecondary: values.scope.organizationSecondary,
        organization: values.scope.organization,
        orgType: values?.scope?.orgType,
        tags: values.scope.tags,
        tag: values.scope.tag,
        school: values.scope.school,
        particularOrganization: values.scope.particularOrganization,
      };
      reset(values);
      setValue('accessScope', values.accessScope);
      setValue('scope.organization', values.scope.organization);
      setValue(
        'scope.organizationSecondary',
        values.scope.organizationSecondary,
      );
      setValue('scope.school', values?.scope?.school);
      setValue('scope.tags', values?.scope?.tags);
      setValue('scope.tag', values?.scope?.tag);
      setValue('scope.orgType', values?.scope?.orgType || '');
      setValue('scope.jurisdiction', values?.scope?.jurisdiction || '');
    }, [accessFilter, reset, setValue]);

    return (
      <>
        <AnvDialog handleClose={handleToggleFilters} open={openFilters}>
          <AnvDialogTitle>
            <FormattedMessage
              id="STORAGE_ZONE.COMMON.LABEL.FILTER"
              defaultMessage="Filter"
            />
          </AnvDialogTitle>

          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(submitFilter)}
              noValidate
              autoComplete={'off'}
            >
              <AnvDialogBody>
                <ScopeFormFilter
                  existsData={accessFilter}
                  metaStores={metaStores}
                  onlyMyAccessByModule={module}
                  isOneLevelAccess={true}
                  withHistory={true}
                  hideTool
                  hideModule
                  displayEmptyAccessScope={false}
                  particularTool={tool?.code}
                  particularModule={module}
                  accessScopeStore={accessScopeStore}
                  accessScopes={accessScopes}
                  jurisdictions={jurisdictions}
                  moduleSettings={moduleSettings}
                />
              </AnvDialogBody>

              <ScopeFilterActions
                classes={classes}
                handleToggleFilters={handleToggleFilters}
              />
            </form>
          </FormProvider>
        </AnvDialog>
      </>
    );
  },
);
