import React from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useStores } from 'src/stores/root-store';
import {
  AnvAppBar,
  AnvDrawerOperator,
  AnvToolDisabledPage,
  Constants,
  ContainerView,
  IsLoadingComponent,
  OperatorNavbarCenter,
} from '@platform/front-lib';
import { USER_ROLES } from '../../../constants/auth';
import { drawerAllEntries } from '../../../constants/drawer-map';
import { getCookieToken } from '../../../utils/cookie';
import { useInterval } from '../../../utils/hooks';

const ContainerConnected: React.FC = observer(({ children }) => {
  const {
    authStores: {
      token,
      tokenOperator,
      profile,
      profileOperator,
      isLoggedIn,
      isOperatorLoggedIn,
      getMyModules,
      logOut,
      setActiveLocale,
      modulesCodes,
      updateDataFromCookie,
    },
    uiStores: { drawerOpen, toggleDrawerOpen, languages, tool },

    metaStores: {
      toolsStore: {
        getTools,
        data: tools,
        dataMap: toolsMapClean,
        isLoaded: isLoadedTools,
      },
    },
  } = useStores();

  const toolsMap = React.useMemo(() => {
    return toJS(toolsMapClean);
    // force update props for drawer
    // eslint-disable-next-line
  }, [tools, toolsMapClean]);

  React.useEffect(() => {
    if (!isLoadedTools) {
      getTools();
    }
  }, [getTools, isLoadedTools]);

  useInterval(() => {
    if (
      (getCookieToken() !== token && (getCookieToken() || token)) ||
      (getCookieToken({ isOperator: true }) !== tokenOperator &&
        (getCookieToken({ isOperator: true }) || tokenOperator))
    ) {
      updateDataFromCookie();
    }
  }, 2000);

  React.useEffect(() => {
    if (isLoggedIn) {
      getMyModules();
    }
  }, [isLoggedIn, isOperatorLoggedIn, getMyModules]);

  if (tool?.disabled && !isOperatorLoggedIn) {
    return <AnvToolDisabledPage themeColor withWrapper />;
  }

  const header = (
    <AnvAppBar
      profile={profileOperator}
      isLoggedIn={isOperatorLoggedIn}
      toggleDrawerOpen={toggleDrawerOpen}
      drawerOpen={drawerOpen}
      logOut={logOut}
      customCenterTitle={<OperatorNavbarCenter />}
      isOperator={isOperatorLoggedIn}
    />
  );

  const drawer = isOperatorLoggedIn ? (
    <AnvDrawerOperator
      drawerData={Constants.drawerOperator}
      toolsMap={toolsMap}
      profile={profile}
      logOut={logOut}
      drawerOpen={drawerOpen}
      toggleDrawerOpen={toggleDrawerOpen}
      languages={languages}
      setActiveLocale={setActiveLocale}
    />
  ) : undefined;

  return (
    <ContainerView
      header={isOperatorLoggedIn ? header : undefined}
      drawer={drawer}
      profile={profileOperator || profile}
      isLoggedIn={isLoggedIn}
      logOut={logOut}
      drawerOpen={drawerOpen}
      toggleDrawerOpen={toggleDrawerOpen}
      languages={languages}
      setActiveLocale={setActiveLocale}
      modulesCodes={modulesCodes}
      // @ts-ignore
      drawerData={drawerAllEntries[USER_ROLES.ROLE_CLIENT]}
      // @ts-ignore
      tool={tool}
      footerJustify={'space-between'}
    >
      {isOperatorLoggedIn && !isLoadedTools ? (
        <IsLoadingComponent isLoading />
      ) : (
        children
      )}
    </ContainerView>
  );
});

export default ContainerConnected;
