import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

import {
  AnvTableWrapper,
  AnvUtils,
  Constants,
  TextSubTitleNew,
  useCommonOffsetsStyles,
} from '@platform/front-lib';

import { SZBreadcrumbs } from '../../../../common/SZBreadcrumbs';
import { ROUTES } from '../../../../../constants';
import { MyStorageZoneTitle } from '../../../common/title';
import { ExplorerMainTabs } from '../../../tabs';
import { SZFoldersBreadcrumbs } from '../../../../common/folders-breadcrumbs';
import { SZCustomBreadCrumbs } from '../../../../common/folders-breadcrumbs/common';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../../stores';
import { TBreadcrumbsItem } from '../../../../../components/connected/anv-breadcrumbs-container';

interface IFamilyCircleFirstLevelProps {
  children?: React.ReactNode;
}

const breadcrumbsItems: TBreadcrumbsItem[] = [
  // {
  //   component: (
  //     <FormattedMessage
  //       id={'STORAGE_ZONE.FOLDERS.BREADCRUMBS.PERSONAL_ZONE'}
  //       defaultMessage={'Personal Zone'}
  //     />
  //   ),
  //   to: ROUTES.MODULE_FILE_EXPLORER_PERSONAL_ROOT,
  // },
  {
    toolCode: Constants.TOOLS.FAMILY_CIRCLE,
    showToolName: true,
    component: 'Family Circle',
  },
];

export const FamilyCircleFirstLevel: React.FC<IFamilyCircleFirstLevelProps> = observer(
  () => {
    //     React.useEffect(() => {
    //       return () => {
    //         setActiveFolderId(null);
    // setActiveFileId(null);
    //       };
    //     }, [setActiveFolderId]);

    const classesOffset = useCommonOffsetsStyles();

    const { locale } = useIntl();

    const {
      metaStores: {
        toolsStore: { dataMap: toolsMap },
      },
    } = useStores();

    const itemsSZFolders = React.useMemo(() => {
      return [
        {
          ...SZCustomBreadCrumbs.personalZoneFC,
          to: undefined,
        },
      ];
    }, []);

    const breadcrumbsItemsPrepared = React.useMemo(() => {
      return breadcrumbsItems.map((item) =>
        item.toolCode && item.showToolName
          ? {
              ...item,
              component: toolsMap[item.toolCode]
                ? AnvUtils.getNameByLangNullable(
                    toolsMap[item.toolCode],
                    'name',
                    locale,
                  )
                : (item.component as any),
            }
          : item,
      );
    }, [locale, toolsMap]);

    return (
      <>
        <SZBreadcrumbs items={breadcrumbsItemsPrepared} />

        <AnvTableWrapper>
          <MyStorageZoneTitle />

          <ExplorerMainTabs />

          <div className={clsx(classesOffset.pb_2, classesOffset.pt_1)}>
            <SZFoldersBreadcrumbs
              rootLink={ROUTES.MODULE_FILE_EXPLORER_PERSONAL_ROOT}
              items={itemsSZFolders}
            />
          </div>

          <TextSubTitleNew color={'secondary'}>
            Development in process
          </TextSubTitleNew>
        </AnvTableWrapper>
      </>
    );
  },
);
