import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    filterModalActions: {
      marginTop: theme.spacing(4),
    },
    toolbarFilterCounter: {},
    toolbarFilterWrapper: {},
    toolbarFilterButtonMobile: {
      width: 56,
      height: 56,
      backgroundColor: theme.palette.brand.main,
      borderRadius: 6,
      '&:hover': {
        backgroundColor: theme.palette.brand.main,
      },
    },

    toolbarFilterButton: {},
    toolbarFilterButtonLabel: {},
    toolbarFilterLabel: {},
    toolbarStatusContainer: {},

    root: {
      padding: theme.spacing(5, 5, 7),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4, 2),
      },
    },
    title: {
      marginBottom: theme.spacing(3),
    },
    commonControl: {
      marginBottom: theme.spacing(2),
    },

    thSortable: {
      userSelect: 'none',
    },
    thDateFilter: {
      '&&': {
        minWidth: 200,
      },
    },
    tableHeadLabelSortable: {
      display: 'flex',
      alignItems: 'center',
    },

    filterControl: {
      marginTop: 2,
      minHeight: 24,
      marginBottom: 0,

      '& input': {
        background: theme.palette.common.white,
      },
      '& .MuiInputBase-adornedEnd': {
        background: theme.palette.common.white,
      },
    },
  }),
);
