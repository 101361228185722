import React from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { Grid } from '@material-ui/core';

import { AnvIcon, AnvUtils, Constants, TextBody } from '@platform/front-lib';
import { TOOL_MODULES } from '../../../../constants';
import { ISZFolderFile } from '../../../../models';
import { useStores } from '../../../../stores';
import useClickPreventionOnDoubleClick from '../../../../utils/hook-useDoubleClick';
import { ModuleContext } from '../../module-context';

import { useFoldersViewStyles } from './styles';

interface ISZFolderViewProps {
  folder: ISZFolderFile;
  onDoubleClick?: (entity: ISZFolderFile) => void;
}

export const SZFolderFileViewGridItem: React.FC<ISZFolderViewProps> = observer(
  ({ folder, onDoubleClick }) => {
    const classes = useFoldersViewStyles();

    const module = React.useContext(ModuleContext);
    const isOrganizer = module === TOOL_MODULES.FILE_ORGANIZER;
    const { fileExplorerStore, fileOrganizerStore } = useStores();

    const storageStore = isOrganizer ? fileOrganizerStore : fileExplorerStore;

    const { activeFolderId, setActiveFolderId } = storageStore;

    const onClick = () => {
      setActiveFolderId(folder._id);
    };

    const handlePreventDefault = (
      ev: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      // prevent text selection on double click
      ev.preventDefault();
    };

    const [
      handleClick,
      handleDoubleClick,
    ] = useClickPreventionOnDoubleClick(onClick, () => onDoubleClick?.(folder));

    const isActive = activeFolderId === folder._id;

    return (
      <div
        onMouseDown={handlePreventDefault}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        className={clsx(classes.gridElement, {
          [classes.gridElementActive]: isActive,
        })}
      >
        <Grid
          className={classes.content}
          container
          justify={'flex-start'}
          alignItems={'center'}
          spacing={1}
          wrap={'nowrap'}
        >
          <Grid item>
            <AnvIcon icon={'folderBig'} size={56} />
          </Grid>

          <Grid item className={classes.content}>
            <div>
              <TextBody className={classes.textFolderName}>
                {folder.name}
              </TextBody>
            </div>
            <div>
              <TextBody className={classes.textFolderDate}>
                {AnvUtils.formatDate(folder.createdAt, {
                  formatStr: Constants.DATE_FORMATS.DEFAULT_FORMAT,
                })}
              </TextBody>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  },
);
