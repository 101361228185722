import { createStyles, makeStyles } from '@material-ui/core/styles';
import { BORDER_RADIUS } from '../../../styles/constants';

export const useFoldersToolbarStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },

    button: {
      '&&': {
        borderRadius: BORDER_RADIUS,
        padding: theme.spacing(1.5, 4),
      },
    },

    buttonRight: {
      '&&': {
        padding: theme.spacing(1.5, 4),
        borderTopRightRadius: BORDER_RADIUS,
        borderBottomRightRadius: BORDER_RADIUS,
      },
    },
    buttonLeft: {
      '&&': {
        borderTopLeftRadius: BORDER_RADIUS,
        borderBottomLeftRadius: BORDER_RADIUS,
      },
    },
  }),
);
