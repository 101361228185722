import React from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { Grid } from '@material-ui/core';

import {
  AnvButton,
  AnvPaginationMui,
  AnvIcon,
  useModal,
  AnvTableWrapper,
  AnvUtils,
  useCommonOffsetsStyles,
} from '@platform/front-lib';

import { ModuleContext } from '../../../common/module-context';

import { ExplorerListAddNew } from './AddNew';

import { prepareAccessFilterParam } from '../../../../utils';
import { useCommonTable } from '../../../../utils/hooks-common-table';

import { PAGINATION_LIMIT, ROUTES } from '../../../../constants';
import { useStyles } from './styles';
import { useStores } from '../../../../stores';
import { ExplorerMainTabs } from '../../tabs';
import { SZFoldersVirtualView } from '../../../common/folders-virtual';
import { IVirtualFolder } from '../../../common/folders-virtual/types';
import { ViewDrawerData } from '../../../common/entity-drawer';
import { SZFoldersBreadcrumbs } from '../../../common/folders-breadcrumbs';
import { SZCustomBreadCrumbs } from '../../../common/folders-breadcrumbs/common';
import { MyStorageZoneTitle } from '../../common/title';
import { SZBreadcrumbs } from '../../../common/SZBreadcrumbs';
import { useStylesContentWDrawer } from './view/styles';

interface IPageBrowsingList {}

const defaultFilterState = {
  accessScope: '',
  organizationType: '',
  name: '',
  directoryAccessible: '',
  directoryPublishedAt: null,
  directoryUpdatedAt: null,
};

export const PageFileExplorerList: React.FC<IPageBrowsingList> = observer(
  () => {
    const history = useHistory();
    const classesOffset = useCommonOffsetsStyles();

    const {
      authStores: { modulesFilter, profile, profileOperator },
      fileExplorerStore: {
        activeFolderId,

        folderRootStore: {
          createNewRootFolder,
          getList,
          addToList,
          isFetchingList,
          data: organizations,
          dataMap,
          pagination,
          // isLoadedList,
          setPage,
          setFilter,
          setSort,
        },
      },
      metaStores: {
        accessScopeStore: {
          // data: accessScopes,
          // accessScopesMap,
          getAccessScopes,
          isLoaded: isLoadedAccessScopes,
        },
        organizationTypesStore: {
          // data: organizationTypes,
          organizationTypesMap,
          getOrganizationTypes,
          isLoaded: isLoadedOrganizationTypes,
        },
        accessHistoryStore: { getRecentlyViewed },
      },
      userStore: { userInfo, getUserInfo, isLoaded: isLoadedUserInfo },
    } = useStores();

    const userId = profile?.id || profileOperator?.id;

    React.useEffect(() => {
      if (!isLoadedUserInfo) {
        getUserInfo();
      }
    }, [isLoadedUserInfo, getUserInfo]);

    const classes = useStyles();
    const classesContentWDrawer = useStylesContentWDrawer();

    const { formatMessage, locale } = useIntl();

    const module = React.useContext(ModuleContext);

    // Request modal

    const handleCreateRootFolder = (orgId: string) => () => {
      const successFunc = (response: any) => {
        const url = generatePath(ROUTES.MODULE_FILE_EXPLORER_VIEW_EE, {
          rootId: response?._id,
          folders: response?.folderId,
        });

        history.push(url);
      };

      createNewRootFolder({ id: orgId }, { successFunc });
    };

    const handleAddToList = ({
      organization,
      school,
      accessScope,
    }: {
      organization: string;
      school: string;
      accessScope: string;
    }) => {
      const successFunc = () => {
        getList({
          accessFilter: prepareAccessFilterParam(accessFilter),
          page: 1,
          sort: querySort,
          filter: memoQueryFilter,
          limit: PAGINATION_LIMIT,
        });

        handleToggleFilters();
      };

      addToList({ organization, school, accessScope }, { successFunc });
    };

    const accessFilter = modulesFilter?.[module];
    React.useEffect(() => {
      if (!isLoadedAccessScopes) {
        getAccessScopes();
      }
    }, [isLoadedAccessScopes, getAccessScopes]);

    React.useEffect(() => {
      if (!isLoadedOrganizationTypes) {
        getOrganizationTypes();
      }
    }, [isLoadedOrganizationTypes, getOrganizationTypes]);

    React.useEffect(() => {
      const payload = {
        moduleCode: module,
        isOrganization: true,
      };

      getRecentlyViewed(payload);
    }, [module, getRecentlyViewed]);

    const {
      querySort,
      // queryFilter,
      queryPage,

      memoQueryFilter,
      handleChangePage,
    } = useCommonTable({
      defaultFilterState,
      pagination,
      setPage,
      setFilter,
      setSort,
    });

    /** get entities */
    React.useEffect(() => {
      getList({
        accessFilter: prepareAccessFilterParam(accessFilter),
        page: +queryPage,
        sort: querySort,
        filter: memoQueryFilter,
        limit: PAGINATION_LIMIT,
      });
    }, [accessFilter, queryPage, memoQueryFilter, querySort, userId, getList]);

    const preparedVirtualFolders: IVirtualFolder[] = React.useMemo(() => {
      return (
        organizations?.map?.((org: any) => {
          return {
            _id: org._id || '',
            to:
              org?.rootFolderId && org?.rootFolderFirstLvlId
                ? generatePath(ROUTES.MODULE_FILE_EXPLORER_VIEW_EE, {
                    rootId: org?.rootFolderId,
                    folders: org?.rootFolderFirstLvlId,
                  })
                : '',
            folderName: AnvUtils.getNameByLangNullable(org, 'name', locale),
            subName: org?.organizationType
              ? AnvUtils.getNameByLangNullable(
                  organizationTypesMap[org.organizationType],
                  'description',
                  locale,
                )
              : formatMessage({
                  id: 'APP.COMMON.ORG_TYPE.SCHOOL',
                  defaultMessage: 'School',
                }),
          };
        }) || []
      );
    }, [organizations, organizationTypesMap, formatMessage, locale]);

    const {
      isOpen: openFilters,
      handleToggleModal: handleToggleFilters,
    } = useModal();

    const itemsSZFolders = React.useMemo(() => {
      return [
        {
          ...SZCustomBreadCrumbs.personalZoneEE,
          to: undefined,
        },
      ];
    }, []);

    return (
      <div
        data-clickaway={true}
        className={clsx(classesContentWDrawer.mainContent, {
          [classesContentWDrawer.mainContentWDrawer]: !!activeFolderId,
        })}
      >
        <SZBreadcrumbs />

        <ViewDrawerData customView={'ee'} customMap={dataMap} />

        <AnvTableWrapper>
          <MyStorageZoneTitle />

          <Grid container spacing={2} justify={'space-between'}>
            <Grid item>
              <ExplorerMainTabs />
            </Grid>
            <Grid item>
              <AnvButton
                data-test={'add-new-organization-open'}
                onClick={handleToggleFilters}
                variant={'contained'}
                size={'small'}
                className={classes.toolbarFilterButton}
                startIcon={
                  <AnvIcon icon={'plus'} size={16} color={'inherit'} />
                }
              >
                <span className={classes.toolbarFilterButtonLabel}>
                  <FormattedMessage
                    id="STORAGE_ZONE.EXPLORER.ORGS.LIST.ACTION.ADD"
                    defaultMessage="Find organization"
                  />
                </span>
              </AnvButton>
            </Grid>
          </Grid>

          {isLoadedUserInfo && (
            <ExplorerListAddNew
              isLoadingData={isFetchingList}
              userInfo={userInfo}
              handleAddToList={handleAddToList}
              total={pagination?.totalDocs}
              handleToggleFilters={handleToggleFilters}
              openFilters={openFilters}
            />
          )}

          <div className={clsx(classesOffset.pb_2, classesOffset.pt_1)}>
            <SZFoldersBreadcrumbs
              rootLink={ROUTES.MODULE_FILE_EXPLORER_PERSONAL_ROOT}
              items={itemsSZFolders}
            />
          </div>

          <SZFoldersVirtualView
            handleCreateRootFolder={handleCreateRootFolder}
            virtualFolders={preparedVirtualFolders}
          />

          <AnvPaginationMui
            pagination={pagination}
            totalPages={pagination?.totalPages}
            handleChangePage={handleChangePage}
            page={+queryPage}
          />
        </AnvTableWrapper>
      </div>
    );
  },
);
