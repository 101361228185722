import { createStyles, makeStyles } from '@material-ui/core/styles';
import { BORDER_RADIUS } from '../../../styles/constants';

export const useFoldersToolbarStyles = makeStyles((theme) =>
  createStyles({
    searchInputButton: {
      width: '52px',
      height: '40px',
      borderTopLeftRadius: 'unset !important',
      borderBottomLeftRadius: 'unset !important',
      padding: 'unset !important',
      margin: '-1px',
    },
    searchInputButtonDisabled: {
      padding: 'unset !important',
      opacity: 'unset !important',
      backgroundColor: `#E0E0E0 !important`,
    },
    searchInputRoot: {
      minHeight: 40,
      '& .MuiFilledInput-adornedEnd': {
        paddingRight: 0,
      },
    },
    searchInput: {
      padding: '9px 0px 9px 12px',
    },
    root: {
      marginBottom: theme.spacing(2),
    },

    button: {
      '&&': {
        borderRadius: BORDER_RADIUS,
        padding: theme.spacing(1.5, 4),
      },
    },

    buttonRight: {
      '&&': {
        padding: theme.spacing(1.5, 4),
        borderTopRightRadius: BORDER_RADIUS,
        borderBottomRightRadius: BORDER_RADIUS,
      },
    },
    buttonLeft: {
      '&&': {
        borderTopLeftRadius: BORDER_RADIUS,
        borderBottomLeftRadius: BORDER_RADIUS,
      },
    },
  }),
);
