import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    reviewListButtonGroup: {
      border: `1px solid ${theme.palette.brand.main}`,
      color: `${theme.palette.common.white}`,
      '& .MuiButton-root': {
        padding: theme.spacing(1, 3),
        border: 'none',
        borderRadius: 'none',
        // color: `${theme.palette.brand.main}`,
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '13px',
        lineHeight: '22px',
      },
    },
    // reviewListButtonGroupButtonActive: {
    //   color: `${theme.palette.common.white}`,
    // },
    toolbarFilterCounter: {},
    toolbarFilterWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    toolbarFilterLabel: {
      marginRight: theme.spacing(1),

      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '-0.4px',
      color: theme.palette.text.secondary,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
    toolbarFilterButton: {
      '&&&': {
        minWidth: 80,

        padding: theme.spacing(0.5),
        marginRight: theme.spacing(2),
        '&:hover': {
          fontWeight: 600,
          '& svg path': {
            fill: theme.palette.primary.main,
          },
        },
      },
    },
    toolbarFilterButtonLabel: {
      display: 'inline-block',
      marginTop: theme.spacing(0.5),
    },
    filterModalActions: {
      marginTop: theme.spacing(4),
    },
    toolbarStatusContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'end',
    },
    toolbarStatusItem: {
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    filterTitle: {
      marginBottom: theme.spacing(3),
    },
  }),
);
