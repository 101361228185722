import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { ROUTES } from 'src/constants';
import { useStores } from 'src/stores/root-store';
import IsLoadingComponent from '../components/layout/loading';
import { ContextWrap } from './ContextWrap';
import ForbiddenPage from './ForbiddenPage';

interface IProps {
  // component: typeof React.Component;
  component: React.FC | any;
  Container?: typeof React.Component | React.FC<any>;
  authFallback?: typeof React.Component;
  isAdminRoute?: boolean;
  isOperatorRoute?: boolean;
  path?: string;
  module?: string;
  exact?: boolean;
}

const PrivateRoute: React.FC<IProps> = observer(
  ({
    component: Component,
    authFallback: AuthFallback,
    isAdminRoute,
    isOperatorRoute,
    Container,
    module,
    ...rest
  }) => {
    const {
      authStores: { isLoggedIn, isRequested, modulesCodes },
    } = useStores();

    if (isLoggedIn && !isRequested && !modulesCodes?.length) {
      return <IsLoadingComponent isLoading />;
    }

    if (
      isLoggedIn &&
      module &&
      modulesCodes &&
      modulesCodes.indexOf(module) === -1
    ) {
      return <ForbiddenPage />;
    }

    const Wrapper = Container || React.Fragment;

    const ModuleWrapper = module ? ContextWrap : null;

    return (
      <Route
        {...rest}
        render={(props) =>
          isLoggedIn ? (
            ModuleWrapper && module ? (
              <ModuleWrapper module={module}>
                <Wrapper>
                  <Component {...props} {...rest} />
                </Wrapper>
              </ModuleWrapper>
            ) : (
              <Wrapper>
                <Component {...props} {...rest} />
              </Wrapper>
            )
          ) : AuthFallback ? (
            <AuthFallback {...props} />
          ) : (
            <Redirect
              to={{
                pathname: ROUTES.AUTH_FAIL,
                search: `?returnUrl=${encodeURI(window.location.href)}`,
              }}
            />
          )
        }
      />
    );
  },
);

export default PrivateRoute;
