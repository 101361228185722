import React from 'react';
import { observer } from 'mobx-react-lite';

import { AnvBreadcrumbs } from '@platform/front-lib';
import { useStores } from '../../../stores';

export interface IBreadcrumbsItem {
  name?: string;
  component?: string | JSX.Element;
  href?: string;
  to?: string;

  [index: string]: any;
}

export type TBreadcrumbsItem = IBreadcrumbsItem;

interface IProps {
  items?: TBreadcrumbsItem[];
  moduleCode?: string;
  tool?: any;
  isOperator?: boolean;
}

export const AnvBreadcrumbsConnected: React.FC<IProps> = observer(
  ({ items = [], moduleCode, tool = null, isOperator = false }) => {
    const {
      knowledgeBaseStore: { tutorialsForModuleByModuleIdMap },
      authStores: { modulesCodeId },
    } = useStores();

    const tutorialLink =
      moduleCode &&
      modulesCodeId?.[moduleCode] &&
      tutorialsForModuleByModuleIdMap?.[modulesCodeId?.[moduleCode]];

    return (
      <AnvBreadcrumbs
        tool={tool}
        moduleCode={moduleCode}
        items={items}
        isOperator={isOperator}
        tutorialLink={tutorialLink}
      />
    );
  },
);
