import {
  AnvAutocomplete2,
  AnvUtils,
  Constants,
  useDebounce,
  usePrevious,
} from '@platform/front-lib';
import {
  IRequestAccessModuleScope,
  ISchool,
} from '@platform/front-lib/dist/models';
import React, { useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext, useWatch } from 'react-hook-form';

import { defaultMetaStore, ScopeFormContext } from '../common';
import { useStyles } from '../styles';

interface IProps {
  defaultJurisdiction?: string;
  jurisdictions?: any;
  active?: boolean;
}

export const ModuleRequestScopeSchool: React.FC<IProps> = observer(
  ({ active, jurisdictions, defaultJurisdiction, children }) => {
    const classes = useStyles();
    const { control, errors, setValue, getValues } = useFormContext();

    const {
      existsData: item,
      hideTags,
      onlyMyAccessByModule,
      metaStores = defaultMetaStore,
      serverErrors = {},
      schoolRequired,
    } = React.useContext(ScopeFormContext) || {};

    const { locale } = useIntl();

    const [schoolInputValue, setSchoolInputValue] = React.useState('');
    const schoolInputValueDef = useDebounce(
      schoolInputValue,
      Constants.INPUT_THROTTLE_TIME,
    );

    // const [orgInputValue, setOrgInputValue] = React.useState('');
    // const orgInputValueDef = useDebounce(orgInputValue, INPUT_THROTTLE_TIME);

    const {
      jurisdictionStore: { isFetching: isFetchingJurisdictions },
      // organizationsStore: {
      //   getOrganization,
      //   getOrganizations,
      //   data: organizations,
      //   // organizationsMap,
      //   total: totalOrganizations,
      //
      //   isFetching: isFetchingOrganizations,
      // },
      tagsStore: {
        getTags,
        total: totalTags,
        data: tagsEntities,
        isFetching: isFetchingTags,
        isLoaded: isLoadedTags,
      },
      schoolsStore: {
        getSchool,
        getSchools,
        total: totalSchools,
        schoolsMap,
        data: schools,
        isFetching: isFetchingSchools,
      },
      accessHistoryStore: { recentlyViewed: accessHistory = [] },
    } = metaStores;

    const scope: IRequestAccessModuleScope = useWatch({
      control,
      name: 'scope',
      defaultValue: item
        ? item?.scope
        : {
            jurisdiction: defaultJurisdiction,
            organization: null,
            school: null,
          },
    });
    const tag = useWatch({
      control,
      name: 'scope.tag',
      defaultValue: item?.tag || '',
    });

    const [initialSchool] = React.useState(item?.scope?.school);

    React.useEffect(() => {
      if (initialSchool) {
        getSchool({ id: initialSchool });
      }
    }, [initialSchool, getSchool]);

    const { jurisdiction, school } = scope;

    // const tagsStringIds = (
    //   tags.map((tagOption) => tagOption?.value) || []
    // ).toString();

    useEffect(() => {
      if (!isLoadedTags) {
        getTags({ getWithTotal: true });
      }
    }, [isLoadedTags, getTags]);

    ///////////////////////////////////////////////////////////////
    const entitiesAll = React.useMemo(() => {
      const array: Record<string, any>[] = [];

      if (schools) {
        array.push(...(schools || []));
      }

      if (accessHistory) {
        array.push(
          ...(accessHistory
            .map(
              (historyItem: Record<string, any>) =>
                historyItem?.schoolRef /*|| historyItem?.organizationRef*/,
            )
            .filter((item: any) => !!item) || []),
        );
      }

      return array;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schools, accessHistory, accessHistory?.length]);

    const schoolStore =
      school &&
      (schoolsMap?.[school] ||
        entitiesAll?.find((item) => item._id === school));

    const schoolEntity = AnvUtils.getItemById(entitiesAll || [], school);

    const schoolEntityName = AnvUtils.getNameByLangNullable(
      schoolEntity,
      'name',
      locale,
    );
    const schoolEntityJur = schoolEntity?.jurisdiction;

    const schoolInputValuePrev = usePrevious(schoolInputValueDef);
    const tagPrev = usePrevious(tag);
    const jurisdictionPrev = usePrevious(jurisdiction);

    const jurisdictionChanged =
      item?.jurisdiction !== jurisdiction &&
      jurisdiction !== jurisdictionPrev &&
      schoolEntity?.jurisdiction !== jurisdiction;

    // JUR changed
    React.useEffect(() => {
      /** Jur changed */
      if (
        jurisdictionChanged &&
        jurisdictionPrev &&
        schoolEntityJur !== jurisdiction
      ) {
        setValue('scope.school', '');
        setSchoolInputValue('');
      }
    }, [
      schoolEntityJur,
      jurisdiction,
      jurisdictionChanged,
      jurisdictionPrev,
      setValue,
    ]);

    // Set values from school ▲
    React.useEffect(() => {
      if (active && schoolEntity) {
        const schoolJurisdiction = schoolEntity?.jurisdiction;

        if (!jurisdiction && schoolJurisdiction && !jurisdictionPrev) {
          setValue('scope.jurisdiction', schoolJurisdiction || '');
        }

        if (
          jurisdiction &&
          schoolJurisdiction &&
          jurisdictionPrev &&
          jurisdiction !== schoolJurisdiction &&
          jurisdiction === jurisdictionPrev
        ) {
          setValue('scope.jurisdiction', schoolEntityJur || '');
        }
      }
    }, [
      active,
      schoolEntity,
      jurisdictionPrev,
      schoolEntityJur,
      jurisdiction,
      setValue,
    ]);

    const isTagChangedFromItem = !isEqual(item?.tag, tag);

    /** Get SCHOOLS */
    React.useEffect(() => {
      if (active && jurisdiction) {
        const jurisdictionChanged =
          jurisdictionPrev !== jurisdiction &&
          schoolEntityJur !== jurisdiction &&
          !(!jurisdiction && !jurisdictionPrev && !schoolEntityJur);

        const tagsChanged = tag !== tagPrev && isTagChangedFromItem;

        if (
          active &&
          (!jurisdiction || schoolEntityJur !== jurisdiction) &&
          schoolEntityJur &&
          !jurisdictionPrev
        ) {
          setValue('scope.jurisdiction', schoolEntityJur || '');
        }

        if (
          active &&
          (jurisdictionChanged ||
            tagsChanged ||
            (schoolInputValuePrev !== schoolInputValueDef &&
              schoolInputValueDef !== schoolEntityName))
        ) {
          // if (!jurisdiction && schoolEntityJur) {
          //   setValue('scope.jurisdiction', schoolEntityJur || '');
          // }
          if (
            (jurisdictionChanged && jurisdictionPrev && jurisdiction) ||
            (jurisdiction &&
              schoolEntity &&
              schoolEntity?.jurisdiction !== jurisdiction)
          ) {
            setValue('scope.school', '');
          }

          if (
            tag &&
            tagsChanged &&
            school &&
            schoolEntity &&
            schoolEntity?.tags?.indexOf(tag) === -1
          ) {
            setValue('scope.school', '');
          }

          const data: Record<string, any> = {
            filter: {},
            getWithTotal: true,
            disableOrgRef: true,
            fieldsShortList: true,
          };
          if (locale !== Constants.DEFAULT_LANGUAGE) {
            data.locale = locale;
          }

          if (jurisdiction) {
            if (!data.filter?.or) {
              data.filter.or = [];
            }

            data.filter.or.push({
              jurisdiction: {
                eq: jurisdiction,
              },
            });
          }

          if (schoolInputValueDef) {
            data.name = schoolInputValueDef;
            data.locale = locale;
          }
          if (onlyMyAccessByModule) {
            data.filterAccess = {};

            data.filterAccess.accessScope = Constants.ACCESS_SCOPES.SCHOOL;
            data.filterAccess.moduleCode = onlyMyAccessByModule;
          }

          if (tag) {
            data.filter.tags = {
              in: [tag],
            };
          }

          getSchools(data);
        }
      }
      // schoolEntity -> organizations
      // eslint-disable-next-line
    }, [
      active,
      schoolEntityName,
      schoolEntityJur,
      schoolInputValueDef,
      schoolInputValuePrev,
      jurisdiction,
      jurisdictionPrev,
      tag,
      tagPrev,
      isTagChangedFromItem,
      item,
      onlyMyAccessByModule,
      locale,
      getSchools,
      setValue,
      getValues,
    ]);

    const jurisdictionsOptions = AnvUtils.prepareOptions(jurisdictions, {
      sortByLabel: true,
      locale,
    });

    const tagsOptions = isFetchingTags
      ? []
      : AnvUtils.prepareOptions(tagsEntities, {
          locale,
        });

    const schoolsOptionsActual = isFetchingSchools
      ? []
      : AnvUtils.prepareOptions<ISchool>(schools, {
          locale,
          type: 'school',
          captionBottom: true,
          captionFunc: (school) =>
            AnvUtils.prepareSchoolAddressCaption({
              school,
              locale,
              classes,
            }),
        });

    const schoolsOptions = React.useMemo(() => {
      const isSchoolExistsInActual =
        schoolStore &&
        schoolsOptionsActual.findIndex(
          (item) => item.value === schoolStore?._id,
        ) > -1;

      const schoolOptionInitial =
        !isSchoolExistsInActual && schoolStore
          ? AnvUtils.prepareOptions([schoolStore], {
              locale,
              type: 'school',
              captionBottom: true,
              captionFunc: (school) =>
                AnvUtils.prepareSchoolAddressCaption({
                  school,
                  locale,
                  classes,
                }),
            })
          : [];

      return [...schoolOptionInitial, ...schoolsOptionsActual];
      // [classes]
      // eslint-disable-next-line
    }, [schoolsOptionsActual, schoolStore, locale]);

    const formHelper = (field: string) => {
      return AnvUtils.formErrorHelper(field, errors, serverErrors);
    };

    return (
      <>
        {active ? (
          <AnvAutocomplete2
            displayEmpty
            required={!schoolRequired}
            name={'scope.jurisdiction'}
            control={control}
            disabled={isFetchingJurisdictions}
            label={
              <FormattedMessage
                id="APP.COMMON.LABEL.JURISDICTION"
                defaultMessage="Jurisdiction"
              />
            }
            options={jurisdictionsOptions}
            helperText={formHelper('scope.jurisdiction')}
            error={!!formHelper('scope.jurisdiction')}
          />
        ) : (
          <div style={{ width: 1, height: 72 }} />
        )}

        {/* temp hide this field */}
        {!hideTags && false && (
          <AnvAutocomplete2
            total={totalTags || 0}
            // multiple
            options={tagsOptions}
            loading={isFetchingTags}
            label={
              <FormattedMessage
                id="APP.COMMON.LABEL.SYSTEM_LEVEL_TAG"
                defaultMessage="System Level Tag"
              />
            }
            control={control}
            name={'scope.tag'}
            helperText={formHelper('scope.tag')}
            error={!!formHelper('scope.tag')}
            value={tag}
          />
        )}

        <AnvAutocomplete2
          textFieldClassName={classes.lastControl}
          total={totalSchools}
          required={!!schoolRequired}
          options={schoolsOptions}
          loading={isFetchingSchools}
          label={
            <FormattedMessage
              id="APP.COMMON.LABEL.SCHOOL"
              defaultMessage="School"
            />
          }
          onInputChange={(event, newInputValue) => {
            setSchoolInputValue(newInputValue);
          }}
          control={control}
          name={'scope.school'}
          helperText={formHelper('scope.school')}
          error={!!formHelper('scope.school')}
          value={school}
        />
        {children}
      </>
    );
  },
);
