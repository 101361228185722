import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  APP_BAR_HEIGHT,
  DRAWER_PROPERTIES_WIDTH,
} from '../../../styles/constants';
import mixins from '../../../styles/mixins';

export const useRightDrawerStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginTop: APP_BAR_HEIGHT,
      paddingBottom: APP_BAR_HEIGHT,
      width: DRAWER_PROPERTIES_WIDTH,
      maxWidth: '100%',

      backgroundColor: theme.palette.brand.light,

      boxShadow:
        '0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
      // borderRadius: '6px 0px 0px 6px',
    },

    headerBlock: {
      position: 'relative',

      height: 68,
      padding: theme.spacing(2.5, 3),

      color: theme.palette.common.white,
      backgroundColor: theme.palette.brand.main,
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      color: theme.palette.custom.white,
    },

    headerTitleItem: {
      height: 28,
      flex: 1,
      // overflow: 'hidden',
      ...mixins.textOverflow,
    },
    headerTitle: {
      display: 'inline-block',
      ...mixins.textOverflow,
    },
    headerTitleGrid: {
      // maxWidth: 'calc(100% - 40px)',
      ...mixins.textOverflow,
    },

    headerMenu: {
      marginLeft: theme.spacing(1),
    },

    closeButton: {
      position: 'absolute',
      top: 16,
      right: 16,
    },

    closeButtonStatic: {
      marginLeft: theme.spacing(1),
    },

    body: {
      padding: theme.spacing(2, 3, 3),
    },

    bigIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      marginBottom: theme.spacing(3),
    },

    bigIconDivider: {
      marginBottom: theme.spacing(3),
    },

    bodyHeader: {
      marginBottom: theme.spacing(3),
    },

    listValue: {
      fontSize: 13,
      color: theme.palette.text.primary,
    },
  }),
);
