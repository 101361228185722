import React from 'react';
import { Box } from '@material-ui/core';
import ContainerAuthStyle from './style';

const ContainerAuth: React.FC = ({ children }) => {
  const style = ContainerAuthStyle();
  return <Box className={style.wrapper}>{children}</Box>;
};

export default ContainerAuth;
