import React from 'react';

import { CircularProgress } from '@material-ui/core';

import './IsLoadingComponent.css';

interface IProps {
  isLoading?: boolean;
}

export const IsLoadingComponent: React.FC<IProps> = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="loading-container">
      <div className="loading-container__overlay" />
      <CircularProgress color={'primary'} />
    </div>
  );
  // if (!isLoading) return null;
  //
  // return <CircularProgress color={'primary'} />;
};

export default IsLoadingComponent;
