import React from 'react';
import { useHistory } from 'react-router-dom';
import { usePaginationQuery } from './hooks';
import { IPagination } from '../stores/_helpers/interfaces';
// import { getDateWithoutTimeAndZone } from '@platform/front-lib';
import isEqual from 'lodash/isEqual';
import { INPUT_THROTTLE_TIME } from '../constants';
import { getDateWithoutTimeAndZone } from './formatWLocale';
import { useMediaQuery, useTheme } from '@material-ui/core';

export interface IUseCommonTableArgs {
  pagination: IPagination;

  defaultFilterState: Record<string, any>;

  setPage: (page: number, { history }: Record<string, any>) => void;
  setFilter: (
    filter: any[] | Record<string, any>,
    { history }: Record<string, any>,
  ) => void;
  setSort: (sort: string, { history }: Record<string, any>) => void;
}

/**
 *
 * @param pagination
 * @param setPage
 * @param setFilter
 * @param setSort
 * @param defaultFilterState
 */
export function useCommonTable({
  pagination,
  setPage,
  setFilter,
  setSort,

  defaultFilterState,
}: IUseCommonTableArgs) {
  const theme = useTheme();
  const screenSM = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  let {
    page: queryPage = 1,
    sort: querySort,
    filter: queryFilter,
  } = usePaginationQuery();

  const queryFilterString = JSON.stringify(queryFilter);
  const memoQueryFilter = React.useMemo(() => {
    return queryFilter;
    // eslint-disable-next-line
  }, [queryFilterString]);

  React.useEffect(() => {
    if (pagination?.totalPages && pagination?.totalPages < pagination?.page) {
      setPage(pagination?.totalPages, { history });
    }
    //- ignore "history"
    // eslint-disable-next-line
  }, [pagination, setPage]);

  const [filterState, setFilterState] = React.useState<Record<string, any>>({
    ...defaultFilterState,
    ...queryFilter,
  });

  /** Filters */
  const filterMemo = React.useMemo(() => {
    const filterLocal: Record<string, any> = {};
    Object.keys(filterState).forEach((key) => {
      if (filterState[key] || typeof filterState[key] === 'boolean')
        filterLocal[key] = filterState[key];
    });
    return filterLocal || {};
  }, [filterState]);

  const [timeoutId, setTimeoutId] = React.useState<any>(null);
  const handleChangePhone = (value: any) => {
    setFilterState((state) => ({
      ...state,
      phone: value,
    }));
  };
  const handleChangeFilter = (ev: any) => {
    const {
      target: { name, value },
    } = ev;

    setFilterState((state: Record<string, any>) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleChangeFilterDate = (name: string, value: any) => {
    let stateValue = value;
    try {
      stateValue = getDateWithoutTimeAndZone(value);
    } catch (e) {}

    setFilterState((state) => ({
      ...state,
      [name]: stateValue,
    }));
  };

  // /** Sorting */
  // const sortByString = sortBy
  //   .map((item) => `${item.desc ? '-' : ''}${item.id}`)
  //   .join(',');
  //
  // React.useEffect(() => {
  //   if ((!!querySort || !!sortByString) && querySort !== sortByString) {
  //     setSort(sortByString, { history });
  //   }
  // }, [sortByString, querySort, setSort, history]);

  /** Change page */

  const handleChangePage = (ev: React.ChangeEvent<unknown>, value: number) => {
    setPage(value, { history });
  };
  //////////////////////

  React.useEffect(() => {
    if (screenSM) {
      return;
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    let timeoutHandler: any;

    let isChanged =
      ((filterMemo && !!Object.keys(filterMemo)?.length) || queryFilter) &&
      !isEqual(filterMemo, queryFilter);

    if (isChanged) {
      timeoutHandler = setTimeout(() => {
        setFilter(filterMemo, { history });
      }, INPUT_THROTTLE_TIME);

      setTimeoutId(timeoutHandler);
    }

    return () => {
      if (timeoutHandler) {
        clearTimeout(timeoutHandler);
      }
    };
    // eslint-disable-next-line
  }, [filterMemo, setFilter, screenSM]);

  return {
    queryPage,
    querySort,
    queryFilter,

    filterMemo,
    filterState,
    memoQueryFilter,

    handleChangePage,
    handleChangeFilter,
    handleChangeFilterDate,
    handleChangePhone,
  };
}
