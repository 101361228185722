import React, { useState } from 'react';

import { useIntl } from 'react-intl';

import { Grid, IconButton, InputAdornment } from '@material-ui/core';

import {
  AnvButton,
  AnvIcon,
  AnvTextField,
  useCommonOffsetsStyles,
} from '@platform/front-lib';

import { useFoldersToolbarStyles } from './styles';

interface SZToolbarSearchInputProps {
  handleClearSearch: () => void;
  handleSearch: (value: string) => void;
  searchDefaultValue?: string;
}

const STATE_DEFAULT_VALUE = '';

const KEY_ENTER = 'Enter';

export const SZToolbarSearchInput: React.FC<SZToolbarSearchInputProps> = ({
  handleClearSearch,
  handleSearch,
  searchDefaultValue,
}) => {
  const { formatMessage } = useIntl();

  const classes = useFoldersToolbarStyles();
  const classesOffset = useCommonOffsetsStyles();

  const [inputValue, setInputValue] = useState(
    searchDefaultValue || STATE_DEFAULT_VALUE,
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const clearSearch = () => {
    setInputValue(STATE_DEFAULT_VALUE);
  };

  const handleSearchEnhanced = () => {
    handleSearch(inputValue);
  };

  const handleClearSearchEnhanced = () => {
    clearSearch();
    handleClearSearch();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== KEY_ENTER) {
      return;
    }

    handleSearchEnhanced();
  };

  const isValueExist = !!inputValue;

  return (
    <Grid container justify={'flex-end'} wrap={'nowrap'} alignItems={'center'}>
      <AnvTextField
        value={inputValue}
        name={'search'}
        placeholder={formatMessage({
          id: 'STORAGE_ZONE.ORGANIZER.SEARCH_INPUT.PLACEHOLDER',
          defaultMessage: 'Search',
        })}
        onChange={onInputChange}
        onKeyPress={handleKeyPress}
        size={'small'}
        fullWidth
        zeroMargin
        classes={{
          root: classes.searchInputRoot,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AnvButton
                onClick={handleSearchEnhanced}
                size={'small'}
                variant="contained"
                disabled={!isValueExist}
                className={classes.searchInputButton}
                classes={{
                  disabled: classes.searchInputButtonDisabled,
                }}
              >
                <AnvIcon size={24} icon={'search'} color={'white'} />
              </AnvButton>
            </InputAdornment>
          ),
          classes: {
            root: classes.searchInputRoot,
            input: classes.searchInput,
          },
        }}
      />

      <IconButton
        onClick={handleClearSearchEnhanced}
        size={'small'}
        className={classesOffset.ml_1}
      >
        <AnvIcon size={24} icon={'closeCross'} color={'primary'} />
      </IconButton>
    </Grid>
  );
};
