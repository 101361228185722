import React from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Grid, IconButton, InputAdornment } from '@material-ui/core';

import {
  AnvButton,
  AnvDialogActions,
  AnvDialogBody,
  AnvDialogTitle,
  AnvIcon,
  AnvTextField,
  AnvTooltip,
  AnvUtils,
  Constants,
  IsLoadingComponent,
} from '@platform/front-lib';

import { ISZFolderFile } from 'src/models/storage-zone';
import { IParamsFolders } from 'src/types/storage-zone';

import { useStores } from 'src/stores';
import { SZ_ENTITY_KIND } from '../../../../constants';
import { FILE_EXTENSION_BY_TYPE } from '../../../../constants/mime-types';
import { ISZFileObjectFull } from '../../../../models/storage-zone/file-object';
import { useStyles } from './styles';

interface INewFileFormProps {
  handleCloseModal: () => void;
  file?: ISZFolderFile;
}

interface INewFileForm {
  title: string;
  publicAccess: boolean;
}

const validationSchema = (fm: (arg: any, values?: any) => string) =>
  yup.object().shape({
    title: yup.string().required(fm(Constants.validationsMessages.required())),
    publicAccess: yup.boolean().optional(),
  });

export const FileFormAddEdit: React.FC<INewFileFormProps> = observer(
  ({ handleCloseModal, file }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    const isEdit = !!file?._id;

    const params = useParams<IParamsFolders>();
    const { folders } = params;

    const {
      fileOrganizerStore: {
        folderFilesStore: { setDataMapById },
        // folderStore: { dataMapShort: foldersShortMap },
        fileStore: {
          mapDetailed,
          getOneDetailed,
          errorItem,
          isFetching,
          updateFile,
        },
      },
    } = useStores();

    const foldersArray = folders?.split('/');
    const lastId = foldersArray?.pop?.();

    const serverErrors = errorItem || {};

    const fileEntityDetailed = file?._id ? mapDetailed[file._id] : null;

    React.useEffect(() => {
      if (!fileEntityDetailed && file?._id) {
        getOneDetailed({ id: file._id });
      }
    }, [fileEntityDetailed, file?._id, getOneDetailed]);

    const {
      control,
      handleSubmit,
      errors,
      setValue,
      register,
    } = useForm<INewFileForm>({
      defaultValues: {
        title: file?.name?.slice(0, file?.name.lastIndexOf('.')) || '',
        publicAccess: fileEntityDetailed?.attributesRef?.publicAccess || false,
      },
      resolver: yupResolver(validationSchema(formatMessage)),
    });

    React.useEffect(() => {
      register('publicAccess');
    }, [register]);

    React.useEffect(() => {
      if (fileEntityDetailed?.attributesRef) {
        setValue('publicAccess', fileEntityDetailed.attributesRef.publicAccess);
      }
    }, [fileEntityDetailed, setValue]);

    const publicAccess = useWatch({
      control,
      name: 'publicAccess',
      defaultValue: fileEntityDetailed?.attributesRef?.publicAccess ?? false,
    });

    // const publicAccess =
    //   watch('publicAccess') ??
    //   fileEntityDetailed?.attributesRef?.publicAccess ??
    //   false;

    const onSubmit = (data: INewFileForm) => {
      const { title, publicAccess } = data;

      if (lastId) {
        const successFunc = (response: ISZFileObjectFull) => {
          if (file?._id) {
            getOneDetailed({ id: file?._id });
          }

          setDataMapById({
            id: response._id,
            entity: {
              ...response,
              name: response.title || response.originalFileName || '',
              publicAccess,
              kind: SZ_ENTITY_KIND.FILE,
            },
            setOnlyMap: true,
            forceUpdate: true,
          });

          handleCloseModal();
        };

        if (isEdit && file?._id) {
          updateFile({ id: file._id, title, publicAccess }, { successFunc });
        }
      }
    };

    const formHelper = (field: string) => {
      return AnvUtils.formErrorHelper(field, errors, serverErrors);
    };

    return (
      <div style={{ position: 'relative' }}>
        <AnvDialogTitle>
          <FormattedMessage
            id="STORAGE_ZONE.FILES.EDIT.TITLE.UPDATE_FILE"
            defaultMessage="Update File"
          />
        </AnvDialogTitle>

        <form onSubmit={handleSubmit(onSubmit)}>
          <IsLoadingComponent isLoading={isFetching} isAbsolute />

          <AnvDialogBody>
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item style={{ flex: 1 }}>
                <AnvTextField
                  style={{ marginBottom: 0 }}
                  defaultValue={''}
                  fullWidth
                  required
                  control={control}
                  name={'title'}
                  label={
                    <FormattedMessage
                      id="STORAGE_ZONE.FILES.EDIT.LABEL.TITLE"
                      defaultMessage="Title"
                    />
                  }
                  placeholder={formatMessage({
                    id: 'STORAGE_ZONE.FILES.EDIT.LABEL.TITLE',
                    defaultMessage: 'Title',
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!formHelper('title')}
                  helperText={formHelper('title')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        classes={{ root: classes.inputAdornmentRoot }}
                      >
                        {`${
                          file?.name?.slice(file?.name?.lastIndexOf('.')) ||
                          (file?.fileType
                            ? FILE_EXTENSION_BY_TYPE[file.fileType] || ''
                            : '')
                        }`}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item>
                <AnvTooltip
                  title={formatMessage(
                    publicAccess
                      ? {
                          id: 'STORAGE_ZONE.COMMON.FILE.PUBLIC',
                          defaultMessage: 'Public',
                        }
                      : {
                          id: 'STORAGE_ZONE.COMMON.FILE.PRIVATE',
                          defaultMessage: 'Private',
                        },
                  )}
                  arrow
                >
                  <IconButton
                    onClick={() => {
                      return setValue('publicAccess', !publicAccess);
                    }}
                  >
                    <AnvIcon
                      icon={publicAccess ? 'unlock' : 'lock'}
                      size={24}
                      color={publicAccess ? 'lightIcons' : 'primary'}
                    />
                  </IconButton>
                </AnvTooltip>
              </Grid>
            </Grid>
          </AnvDialogBody>

          <AnvDialogActions>
            <Grid container direction={'column'} spacing={1}>
              <Grid item>
                <AnvButton
                  data-test={'update-file-submit'}
                  variant={'contained'}
                  color={'primary'}
                  fullWidth
                  type={'submit'}
                  disabled={isFetching}
                >
                  <FormattedMessage
                    id="STORAGE_ZONE.FILES.EDIT.ACTION.SUBMIT"
                    defaultMessage="Update"
                  />
                </AnvButton>
              </Grid>

              <Grid item>
                <AnvButton
                  data-test={'update-file-cancel'}
                  onClick={handleCloseModal}
                  variant={'text'}
                  color={'primary'}
                  fullWidth
                  disabled={isFetching}
                >
                  <FormattedMessage
                    id="STORAGE_ZONE.FILES.EDIT.ACTION.CANCEL"
                    defaultMessage="Cancel"
                  />
                </AnvButton>
              </Grid>
            </Grid>
          </AnvDialogActions>
        </form>
      </div>
    );
  },
);
