import { ApiServiceCommon } from '@platform/front-lib';
import { ICrudData } from '@platform/front-lib/dist/stores/common/common-model-store';
import { IGetList } from '../../../../service/interfaces';

interface IGetListBuild extends IGetList {
  rootFolders?: boolean;
  complianceFolders?: boolean;
}

export class PersonalZoneVirtualFoldersService extends ApiServiceCommon {
  getList(data: IGetListBuild) {
    return this.axiosCatch(
      this.axios.get(`${this.apiUrl}/personal-zone/virtual-folders`, {
        params: data,
      }),
    );
  }

  getOne({ apiParams, query }: ICrudData) {
    return this.axiosCatch(
      this.axios.get(
        `${this.apiUrl}/personal-zone/virtual-folders/${apiParams?.id}`,
        {
          params: query,
        },
      ),
    );
  }
}
