import { AnvUtils } from '@platform/front-lib';
import Cookies from 'js-cookie';

import { extractRootDomain } from './http';
import { AUTH_TOKEN_KEY, envConst } from '../constants';
import { rootStore } from 'src/stores/root-store';

export function removeCookieTokenAndProfile({ isOperator = false } = {}) {
  const operatorPostfix = isOperator ? '-operator' : '';

  Cookies.remove('anvilar-refresh' + operatorPostfix, {
    domain: extractRootDomain(envConst.launchpadUrl),
  });
  Cookies.remove('anvilar-token' + operatorPostfix, {
    domain: extractRootDomain(envConst.launchpadUrl),
  });
  Cookies.remove('anvilar-profile' + operatorPostfix, {
    domain: extractRootDomain(envConst.launchpadUrl),
  });

  try {
    rootStore.authStores.reset();
  } catch (e) {
    console.log('----', { e });
  }
}

export function getCookieToken({ isOperator = false } = {}) {
  const operatorPostfix = isOperator ? '-operator' : '';
  const cookieKey = 'anvilar-token' + operatorPostfix;

  const token = Cookies.get(cookieKey);

  if (!token && process.env.NODE_ENV !== 'production') {
    return AnvUtils.LocalStorage.get(AUTH_TOKEN_KEY);
  }

  return token;
}
