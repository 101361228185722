import React from 'react';
import { Cell, Column } from 'react-table';

import { AnvIcon, AnvUtils, Constants, TextBody } from '@platform/front-lib';
import {
  EMPTY_FILE_SIZE_PLACEHOLDER,
  SZ_ENTITY_KIND,
} from '../../../../../constants';

import { ISZFolderFile } from '../../../../../models';
import { TFormatMessage } from '@platform/front-lib/dist/@types/intl/intl';

export function commonFilesColumns(
  locale: string | undefined,
  formatMessage: TFormatMessage,
  {
    isView = false,
    classes,
    moveToFolder,
    subModule,
  }: {
    isView?: boolean;
    classes: Record<string, string>;
    moveToFolder: (folderId: string) => void;
    subModule: string;
  },
) {
  const columns: Record<string, any>[] = [
    {
      width: 30,
      Header: '',
      headerString: '',
      id: 'icon',

      Cell: ({ cell }: { cell: Cell<ISZFolderFile> }) => {
        const entity = cell?.row?.original;
        const isFolder = entity?.kind === SZ_ENTITY_KIND.FOLDER;
        return (
          <div>
            <AnvIcon
              icon={isFolder ? 'folderBig' : 'fileListDefault'}
              size={40}
            />
          </div>
        );
      },
    },
    {
      width: 'auto',
      sortable: true,
      Header: () => {
        return formatMessage({
          id: `STORAGE_ZONE.EXPLORER.${subModule}.GOV.LIST.TH.NAME`,
          defaultMessage: 'Name',
        });
      },
      headerString: formatMessage({
        id: `STORAGE_ZONE.EXPLORER.${subModule}.GOV.LIST.TH.NAME`,
        defaultMessage: 'Name',
      }),
      accessor: 'name',
      id: 'name',

      Cell: ({ cell }: { cell: Cell<ISZFolderFile> }) => {
        const entity = cell?.row?.original;

        const isFolder = entity?.kind === SZ_ENTITY_KIND.FOLDER;

        return (
          <TextBody
            className={isFolder ? classes.link : undefined}
            onClick={(ev: any) => {
              if (!isFolder) {
                return;
              }
              ev.stopPropagation();
              moveToFolder(entity._id);
            }}
          >
            {entity?.name}
          </TextBody>
        );
      },
    },

    {
      maxWidth: 120,
      Header: () => {
        return formatMessage({
          id: `STORAGE_ZONE.EXPLORER.${subModule}.GOV.LIST.TH.SIZE`,
          defaultMessage: 'File Size',
        });
      },
      headerString: formatMessage({
        id: `STORAGE_ZONE.EXPLORER.${subModule}.GOV.LIST.TH.SIZE`,
        defaultMessage: 'File Size',
      }),
      accessor: 'fileSize',
      id: 'fileSize',

      Cell: ({ cell }: Record<string, any>) => {
        const entity = cell?.row?.original;

        return (
          <div>
            {!!entity?.fileSize
              ? AnvUtils.formatBytes(entity?.fileSize)
              : EMPTY_FILE_SIZE_PLACEHOLDER}
          </div>
        );
      },
    },
  ];

  columns.push({
    sortable: true,
    maxWidth: 180,
    Header: () => {
      return formatMessage({
        id: `STORAGE_ZONE.EXPLORER.${subModule}.GOV.LIST.TH.UPLOAD_TIME`,
        defaultMessage: 'Upload Time',
      });
    },
    headerString: formatMessage({
      id: `STORAGE_ZONE.EXPLORER.${subModule}.GOV.LIST.TH.UPLOAD_TIME`,
      defaultMessage: 'Upload Time',
    }),
    accessor: 'createdAt',
    id: 'createdAt',

    Cell: ({ cell }: Record<string, any>) => {
      const entity = cell?.row?.original;

      return (
        <div>
          {AnvUtils.formatWLocale(
            entity?.createdAt,
            Constants.DATE_FORMATS.DEFAULT_FORMAT_WITH_TIME,
          )}
        </div>
      );
    },
  });

  return columns as Column<Record<string, any>>[];
}
