import { AnvUtils, Constants } from '@platform/front-lib';
import {
  IActionMeta,
  IPagination,
} from '@platform/front-lib/dist/stores/_helpers';
import { action, makeObservable, observable } from 'mobx';

export interface IExplorerAddToList {
  organization?: string;
  school?: string;
  accessScope: string;
}

export class CommonListStore<T, V extends { _id: string }> {
  service: any | T;

  @observable
  errorList: any = null;
  @observable isFetchingList: boolean = false;
  @observable isLoadedList: boolean = false;
  @observable isErrorList: boolean = false;

  @observable
  data: V[] | null = null;
  @observable
  dataMap: Record<string, V> = {};

  @observable
  total: number = 0;
  @observable
  pagination: IPagination = Constants.DEFAULT_PAGINATION;

  @observable
  sort: string = '';
  @observable
  filter: any[] | Record<string, any> = {};

  @action
  addToList = async (payload?: IExplorerAddToList, meta?: IActionMeta) => {
    if (this.service.addToList) {
      this.setLoadingList();

      const [error, response] = await this.service.addToList(payload);

      if (error || response?.error) {
        return this.setErrorList(() => {
          this.errorList = error || response?.error;
        });
      }

      this.setLoadedList(() => {
        meta?.successFunc?.(response);
      });
    }
  };

  @action
  getList = async (payload: Record<string, any>, meta?: IActionMeta) => {
    if (this.service?.getList) {
      this.setLoadingList();

      const [error, response] = await this.service?.getList?.(payload);

      if (error || response?.error) {
        return this.setErrorList(() => {
          this.errorList = error || response?.error;
        });
      }

      this.setLoadedList(() => {
        const { docs, totalDocs, distinct, ...pagination } = response;
        this.pagination = pagination;
        this.total = totalDocs;

        this.data = docs?.map((item: V) => {
          if (item._id) {
            this.dataMap[item._id] = item;
          }

          return item;
        });
        meta?.successFunc?.();
      });
    }
  };

  @action
  setPage = (page: number, { history }: Record<string, any>) => {
    if (history) {
      AnvUtils.historyPushPagination({ payload: { page }, history });
    }

    this.pagination.page = page;
  };
  @action
  setSort = (sort: string, { history }: Record<string, any>) => {
    if (history) {
      AnvUtils.historyPushPagination({ payload: { sort }, history });
    }

    this.sort = sort;
  };
  @action
  setFilter = (
    filter: any[] | Record<string, any>,
    { history, ignorePage }: Record<string, any>,
  ) => {
    let filterParam = AnvUtils.preparePaginationFilterData(filter, {
      ignorePage,
    });
    if (history) {
      AnvUtils.historyPushPagination({
        payload: filterParam,
        history,
        isFilters: true,
      });
    }

    this.filter = filter;
  };

  // common
  @action
  setLoadingList = (func?: () => void) => {
    this.errorList = null;
    this.isErrorList = false;
    this.isFetchingList = true;

    func?.();
  };
  @action
  setErrorList = (func?: () => void) => {
    this.isFetchingList = false;
    this.isErrorList = true;

    func?.();
  };
  @action
  setLoadedList = (func?: () => void) => {
    this.isFetchingList = false;
    this.isLoadedList = true;
    this.isErrorList = false;

    func?.();
  };

  constructor() {
    makeObservable(this);
  }
}
