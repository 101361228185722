import axios from 'axios';
import { TUnionFile } from '../models/storage-zone/file-union';
import { isCommonFileObject, isSZFileObject } from './tool';

interface IDownloadFile {
  url: string;
  filename: string;
  token?: string;
}

export const downloadFile = ({
  url,
  filename,
  token = '',
}: IDownloadFile) => () => {
  if (!filename) {
    return;
  }
  const options = {
    responseType: 'blob',
    headers: {
      Authorization: token,
    },
  };
  axios
    // @ts-ignore
    .get(url, options)
    .then(function (response) {
      const reader = new window.FileReader();
      reader.readAsDataURL(response.data);
      reader.onload = function () {
        const fileDataUrl = reader.result;

        const downloadLink = document.createElement('a');
        const fileName = filename;

        // @ts-ignore
        downloadLink.href = fileDataUrl;
        downloadLink.download = fileName;
        downloadLink.click();
      };
    })
    .catch((err) => {
      console.log({ err });
    });
};

export const checkFilesDuplicates = (
  files: Record<string, any>[],
): Record<string, any>[] => {
  if (!files.length) {
    return [];
  }
  return files.reduce((prev: any, file: Record<string, any>) => {
    const fo = Object.entries(file);
    if (
      prev.find((e: any) => {
        const eo = Object.entries(e);
        return eo.every(
          ([key, value], index) =>
            key === fo[index][0] && value === fo[index][1],
        );
      })
    ) {
      return prev;
    } else {
      return [...prev, file];
    }
  }, []);
};

export const getFileName = (file: TUnionFile) => {
  if (isSZFileObject(file) || isCommonFileObject(file)) {
    return file?.title || file?.originalFileName;
  }

  return file?.name;
};
