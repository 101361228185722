import { ApiServiceCommon } from '@platform/front-lib';
import { IGetList } from '../../../../service/interfaces';

interface IGetListBuild extends IGetList {
  module: string;
  schoolId: string;
  annualReportId: string;
  month: string;
}

export class ComplianceFilesService extends ApiServiceCommon {
  getList(data: IGetListBuild) {
    return this.axiosCatch(
      this.axios.get(
        `${this.apiUrl}/${data?.module}/${data?.schoolId}/${data?.annualReportId}/${data?.month}/${this.collection}`,
        {
          params: data,
        },
      ),
    );
  }
}
