import React from 'react';
import { createStyles, makeStyles, Paper } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import {
  APP_BAR_HEIGHT,
  BREADCRUMBS_HEIGHT,
  FOOTER_HEIGHT,
  BORDER_RADIUS,
} from 'src/styles/constants';

interface IProps {
  isWoBreadcrumbs?: boolean;
}

export const ContentPaper: React.FC<IProps> = ({ children }) => {
  const classes = useStyles();

  return <Paper className={classes.root}>{children}</Paper>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: `calc(100vh - ${
        APP_BAR_HEIGHT + FOOTER_HEIGHT + BREADCRUMBS_HEIGHT + theme.spacing(3)
      }px)`,

      margin: theme.spacing(0, 'auto'),
      padding: theme.spacing(1, 2),

      borderRadius: BORDER_RADIUS,
      boxShadow: `0px 1px 0px #D9D9D9, 0px 0px 0px 1px #E1E1E1`,

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(4, 5),
      },
    },
  }),
);
