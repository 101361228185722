import { IActionMeta } from '@platform/front-lib/dist/stores/_helpers';
import { action, makeObservable } from 'mobx';

import { FolderCommonService } from '../common/folders-common-service';

import { FolderCommonStore } from '../common/folders-common-store';

export interface IDeleteFolderPayload {
  id: string;
}
export interface IUpdateFolderPayload {
  id: string;
  folderName: string;
  publicAccess?: boolean;
}
export interface IMoveFolderPayload {
  id: string;
  newParentId: string;
}
export interface ICreateFolderPayload extends Omit<IUpdateFolderPayload, 'id'> {
  parentFolder: string;
}

export class FileOrganizerFolderStoreClass extends FolderCommonStore<FolderCommonService> {
  service: FolderCommonService;

  @action
  createFolder = async (payload: ICreateFolderPayload, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.service.createFolder(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.errorItem = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.setDataMapById({
        id: response._id,
        entity: response,
        setOnlyMap: true,
      });

      this.setDataMapShortById({
        id: response._id,
        entity: response,
      });
      meta?.successFunc?.(response);
    });
  };

  @action
  updateFolder = async (payload: IUpdateFolderPayload, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.service.updateFolder(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.errorItem = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.setDataMapById({
        id: response._id,
        entity: response,
        setOnlyMap: true,
      });

      this.setDataMapShortById({
        id: response._id,
        entity: response,
      });
      meta?.successFunc?.(response);
    });
  };

  @action
  moveFolder = async (payload: IMoveFolderPayload, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.service.moveFolder(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.errorItem = error || response?.error;
      });
    }

    this.setLoaded(() => {
      this.setDataMapById({
        id: response._id,
        entity: response,
        setOnlyMap: true,
      });

      this.setDataMapShortById({
        id: response._id,
        entity: response,
      });

      meta?.successFunc?.(response);
    });
  };

  @action
  deleteFolder = async (payload: IDeleteFolderPayload, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.service.deleteFolder(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.errorItem = error || response?.error;
      });
    }

    this.setLoaded(() => {
      meta?.successFunc?.(response);
    });
  };

  constructor() {
    super({ isView: false });
    this.service = new FolderCommonService({
      isView: false,
    });
    makeObservable(this);
  }
}

export default new FileOrganizerFolderStoreClass();
