import Collapse from '@material-ui/core/Collapse';
import React, { useState } from 'react';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

import {
  AnvButton,
  AnvButtonGroup,
  AnvIcon,
  useQuery,
} from '@platform/front-lib';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import { IParamsFolders } from '../../../types/storage-zone';
import { useRouteMatchEntity } from '../folders-breadcrumbs/use-route-match-entity';

import { SZToolbarSearchInput } from './search-input';

interface ISZToolbarProps {}

type TQueryParams = {
  search?: string;
};

export const SZToolbar: React.FC<ISZToolbarProps> = observer(() => {
  const { formatMessage } = useIntl();

  const params = useParams<IParamsFolders>();
  const history = useHistory();
  const query = useQuery<TQueryParams>();

  const { isSearchPage, isPersonalZoneEE } = useRouteMatchEntity();

  const [showSearch, setShowSearch] = useState(!!query.search);

  const handleToggleSearch = () => {
    setShowSearch(!showSearch);
  };

  const handleClearSearch = () => {
    if (isSearchPage) {
      const url = generatePath(
        isPersonalZoneEE
          ? ROUTES.MODULE_FILE_EXPLORER_VIEW_EE_PREFIX
          : ROUTES.MODULE_FILE_ORGANIZER_VIEW_PREFIX,
        {
          rootId: params.rootId,
          folders: params.folders,
        },
      );

      const path = decodeURIComponent(url);

      history.push(path);
      return;
    }

    handleToggleSearch();
  };

  const handleSearch = (value: string) => {
    const path = isPersonalZoneEE
      ? ROUTES.MODULE_FILE_EXPLORER_VIEW_EE_SEARCH_PREFIX
      : ROUTES.MODULE_FILE_ORGANIZER_SEARCH_PREFIX;

    const url = decodeURIComponent(
      generatePath(path, {
        rootId: params.rootId,
        folders: params.folders,
      }) + `?search=${value}`,
    );

    history.push(url);
  };

  return (
    <>
      <Collapse in={!showSearch}>
        <AnvButtonGroup
          size={'small'}
          color={'primary'}
          disableElevation
          // variant={'text'}
          // aria-label="text primary button group"
          style={{
            gap: '4px',
          }}
        >
          <AnvButton
            variant={'text'}
            size={'small'}
            disabled
            startIcon={<AnvIcon icon={'history'} size={16} />}
          >
            {formatMessage({
              id: 'STORAGE_ZONE.ORGANIZER.TOOLBAR.ACTION.SHOW_RECENT',
              defaultMessage: 'Show Recent',
            })}
          </AnvButton>
          <AnvButton
            variant={'text'}
            size={'small'}
            disabled
            startIcon={<AnvIcon icon={'bookmark'} size={16} />}
          >
            {formatMessage({
              id: 'STORAGE_ZONE.ORGANIZER.TOOLBAR.ACTION.BOOKMARKS',
              defaultMessage: 'Bookmarks',
            })}
          </AnvButton>
          <AnvButton
            variant={'text'}
            size={'small'}
            startIcon={<AnvIcon icon={'search'} size={16} />}
            onClick={handleToggleSearch}
          >
            {formatMessage({
              id: 'STORAGE_ZONE.ORGANIZER.TOOLBAR.ACTION.SEARCH',
              defaultMessage: 'Search',
            })}
          </AnvButton>
        </AnvButtonGroup>
      </Collapse>
      <Collapse in={showSearch}>
        <SZToolbarSearchInput
          handleClearSearch={handleClearSearch}
          handleSearch={handleSearch}
          searchDefaultValue={query.search || ''}
        />
      </Collapse>
    </>
  );
});
