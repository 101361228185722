import _get from 'lodash/get';

import {
  camelCaseToHumanableTitle,
  getNameByLangNullable,
  isObject,
} from './common';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_LANGUAGE } from '../constants';
import type { ISelectOption } from '@platform/front-lib';

export function isStringObjectId(string = '') {
  return string?.toString?.().match?.(/^[0-9a-fA-F]{24}$/);
}

export function formErrorHelper(
  field: string,
  errors: Record<string, any> | null,
  reduxErrors: Record<string, any> | null | string,
  isArray?: boolean,
) {
  if (!(errors || reduxErrors)) {
    return false;
  }

  const array = [];

  if (_get(errors, field)) {
    if (isArray) {
      array.push(_get(errors, field)?.message);
    } else {
      return _get(errors, field)?.message;
    }
  }

  if (_get(reduxErrors, field)) {
    const errorItem = _get(reduxErrors, field);
    // nestjs rest case
    if (isObject(errorItem) && errorItem?.key) {
      // errorItem.key;
      // errorItem.defaultMessage;
      const message = (
        <FormattedMessage
          id={errorItem.key || 'APP.ERROR'}
          // defaultMessage={errorItem.defaultMessage}
          defaultMessage={
            errorItem.defaultMessage || `${field} ${errorItem.key}`
          }
          values={{
            field: camelCaseToHumanableTitle(field),
          }}
        />
      );
      if (isArray) {
        array.push(message);
      } else {
        return message;
      }
    } else {
      array.push(errorItem);
    }

    // nestjs rest case

    if (!isArray) {
      return errorItem;
    }
  }

  if (isArray && array.length) {
    return array;
  }

  return false;
}
export function formErrorHelperAutofocus(
  field: string,
  errors: Record<string, any> | null,
  reduxErrors: Record<string, any> | null | string,
) {
  if (!(errors || reduxErrors)) {
    return false;
  }

  if (_get(errors, field)) {
    return true;
  }

  if (_get(reduxErrors, field)) {
    return true;
  }

  return false;
}

interface IPrepareOptions {
  field?: string;
  rightCaption?: string | JSX.Element;
  locale?: string;
  condition?: any;
  customId?: string;
  captionFunc?: (item: any) => string | JSX.Element;
  captionBottom?: boolean;
  fallbackFunc?: (item: any) => string;
  disabledFunc?: (item: any) => boolean;
  labelFunc?: (
    item: any,
    label: string | null | JSX.Element,
  ) => string | null | JSX.Element;
  fallbackField?: string;
  sortByField?: string;
  sortByLabel?: boolean;
  sortDesc?: boolean;
  disabledArray?: string[];
  excludedArray?: string[];
  type?: string;
}
export function prepareOptions(
  entities: Record<string, any>[] | null | undefined,
  {
    field = 'name',
    rightCaption,
    locale = DEFAULT_LANGUAGE,
    condition,
    customId,
    captionFunc,
    captionBottom,
    fallbackFunc,
    fallbackField,
    sortByField,
    sortByLabel,
    sortDesc,
    disabledArray,
    disabledFunc,
    excludedArray,
    labelFunc,
    type,
  }: IPrepareOptions = {},
): ISelectOption[] {
  if (!entities) {
    return [];
  }

  const options = entities?.length
    ? entities
        .map((item) => {
          if (condition && !condition(item)) {
            return null;
          }

          let label: string | JSX.Element | null = getNameByLangNullable(
            item,
            field,
            locale,
          );
          if (!label && fallbackField) {
            label = item?.[fallbackField];
          }
          if (!label && fallbackFunc) {
            label = fallbackFunc(item);
          }
          if (labelFunc) {
            label = labelFunc(item, label);
          }

          const value = customId ? item[customId] : item._id || item.id;

          if (excludedArray && excludedArray?.indexOf?.(value) > -1) {
            return null;
          }

          const disabled =
            (disabledArray && disabledArray?.indexOf?.(value) > -1) ||
            disabledFunc?.(item) ||
            false;

          if (!label) {
            return null;
          }
          const option: ISelectOption = {
            type,
            value,
            label,
            disabled,
            active: item?.active,
          };

          if (typeof rightCaption === 'string') {
            option.rightCaption =
              item?.[rightCaption] || item?.get?.(rightCaption);
          }
          if (rightCaption && typeof rightCaption !== 'string') {
            option.rightCaption = rightCaption;
          }

          if (!!captionFunc) {
            captionBottom
              ? (option.bottomCaption = captionFunc(item))
              : (option.rightCaption = captionFunc(item));
          }

          return option;
        })
        .filter((value) => !!value)
    : [];

  if (sortByLabel) {
    options.sort((a, b) => {
      if (
        a?.label &&
        b?.label &&
        typeof a?.label === 'string' &&
        typeof b?.label === 'string'
      ) {
        return sortDesc
          ? b.label?.localeCompare?.(a.label)
          : a.label?.localeCompare?.(b.label);
      }
      return 0;
    });
  }

  return options as ISelectOption[];
}
