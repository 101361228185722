import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { observer } from 'mobx-react-lite';

import { darken, lighten } from '@material-ui/core/styles';

import { useStores } from '../stores';
import { PaletteCommonOptionsBrand } from '../types/createPalette';
import { isValidHex } from '../utils';
import { palette } from '../styles/palette';
import { AnvMuiThemeProvider } from '@platform/front-lib';
import IsLoadingComponent from '../components/layout/loading';

interface IProps {}

const AnvMuiThemeProviderConnected: React.FC<IProps> = observer(
  ({ children }) => {
    const {
      uiStores: { tool, isLoaded, getToolInfo, languages },
      authStores: {
        isLoggedIn,
        isFetching: isFetchingAuth,
        isLoaded: isLoadedAuth,
      },
    } = useStores();

    React.useEffect(() => {
      if (!isLoaded && isLoggedIn) {
        getToolInfo();
      }
    }, [isLoadedAuth, isFetchingAuth, isLoaded, isLoggedIn, getToolInfo]);

    const brandColors: PaletteCommonOptionsBrand = {};
    const paletteGenerate: any = palette;

    if (tool?.theme?.brandColor && isValidHex(tool.theme.brandColor)) {
      brandColors.main = tool.theme.brandColor;
      brandColors.light =
        tool?.theme?.brandColorLight || lighten(tool.theme.brandColor, 0.75);
      brandColors.dark =
        tool?.theme?.brandColorDark || darken(tool.theme.brandColor, 0.5);
    }

    paletteGenerate.brand = {
      ...palette.brand,
      ...brandColors,
    };
    paletteGenerate.primary = {
      ...palette.primary,
      main: brandColors.main || palette.primary.main,
    };

    // wait until languages & tool loaded
    if (!languages && !tool) {
      return <IsLoadingComponent isLoading />;
    }

    return (
      <AnvMuiThemeProvider palette={paletteGenerate}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {children}
        </MuiPickersUtilsProvider>
      </AnvMuiThemeProvider>
    );
  },
);

export default AnvMuiThemeProviderConnected;
