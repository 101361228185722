import { Constants } from '@platform/front-lib';
import { IActionMeta } from '@platform/front-lib/dist/stores/_helpers';
import { action, makeObservable, observable } from 'mobx';

import { ISZObjectFolder, ISZObjectFolderShort } from '../../../models';
import { CommonListAdvancedStore } from '../../common/list-advanced-store';
import {
  FolderCommonService,
  IGetAllChain,
  IGetChainToEntity,
} from './folders-common-service';
import { IGetList, IPayloadItemById } from '../../../service/interfaces';

export class FolderCommonStore<T> extends CommonListAdvancedStore<
  T,
  ISZObjectFolder
> {
  service: FolderCommonService;

  @observable
  error: any = null;

  @observable
  errorItem: Record<string, any> | null | string = null;
  @observable
  item: ISZObjectFolder | null = null;

  @observable isFetching: boolean = false;
  @observable isSaving: boolean = false;
  @observable isLoaded: boolean = false;
  @observable isError: boolean = false;

  @observable isFetchingChain: boolean = false;
  @observable isSavingChain: boolean = false;
  @observable isLoadedChain: boolean = false;
  @observable isErrorChain: boolean = false;

  @observable
  dataMapShort: Record<string, ISZObjectFolderShort> = {};

  @action
  setDataMapShortById = ({
    id,
    entity,
  }: {
    id: string;
    entity: ISZObjectFolder;
  }) => {
    const prevEntity = this.dataMapShort?.[id] || Constants.mockObj;
    this.dataMapShort[id] = { ...prevEntity, ...entity };
  };

  @action
  getFolderChildren = async (payload: IGetList, meta?: IActionMeta) => {
    this.setLoading();

    this.errorItem = null;
    const [error, response] = await this.service.getList(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.errorItem = error || response.error;
      });
    }

    this.setLoaded(() => {
      this.dataMap[response?._id] = response;
      meta?.successFunc?.(response);
    });
  };

  @action
  getOne = async (payload: IPayloadItemById, meta?: IActionMeta) => {
    this.setLoading();

    this.errorItem = null;
    const [error, response] = await this.service.getOne(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.errorItem = error || response.error;
      });
    }

    this.setLoaded(() => {
      this.dataMap[response?._id] = response;
      meta?.successFunc?.(response);
    });
  };

  @action
  getChain = async (payload: IGetAllChain, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.service.getAllChain(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.errorItem = error || response.error;
      });
    }

    this.setLoaded(() => {
      const { folders } = response;

      folders?.forEach((folder: ISZObjectFolderShort) => {
        this.dataMapShort[folder._id] = folder;
      });

      // this.dataMap[response?._id] = response;
      meta?.successFunc?.(response);
    });
  };

  @action
  getChainToEntity = async (payload: IGetChainToEntity, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.service.getChainToEntity(payload);

    if (error || response.error) {
      return this.setError(() => {
        this.errorItem = error || response.error;
      });
    }

    this.setLoaded(() => {
      if (!payload.path) {
        response?.forEach((folder: ISZObjectFolderShort) => {
          const prevFolder = this.dataMap[folder._id];

          const prevFolderShort = this.dataMapShort[folder._id];

          this.dataMap[folder._id] = {
            ...prevFolder,
            ...folder,
          };

          this.dataMapShort[folder._id] = {
            ...prevFolderShort,
            ...folder,
          };
        });
      }

      meta?.successFunc?.(response);
    });
  };

  @action
  resetError = () => {
    this.errorItem = null;
    this.isError = false;
    this.error = null;
  };

  @action
  private reset = () => {
    this.dataByRequestId = {};
    this.dataMap = {};
    this.isFetching = false;
    this.isLoaded = false;
    this.isError = false;
    this.error = null;
  };

  // common
  @action
  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };
  @action
  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();
  };
  @action
  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();
  };

  constructor({ isView }: { isView: boolean }) {
    super();
    this.service = new FolderCommonService({ isView });
    makeObservable(this);
  }
}
