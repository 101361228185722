import { AnvDialog } from '@platform/front-lib';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { SZ_ENTITY_KIND } from '../../../constants';
import { ISZFileObject } from '../../../models';
import { useStores } from '../../../stores';
import { ItemFormMove } from '../forms/item-move/item-form-move';

interface IMoveModal {
  id: string;
  open: boolean;
  handleClose: (params?: any) => void;
}

export const MoveModal: React.FC<IMoveModal> = observer(
  ({ id, open, handleClose }) => {
    const {
      fileOrganizerStore: {
        folderFilesStore: { dataMap },
      },
    } = useStores();

    const entity = (id && dataMap?.[id]) || null;
    const isFile = entity?.kind === SZ_ENTITY_KIND.FILE;

    return (
      <AnvDialog open={open} handleClose={handleClose}>
        {entity && (
          <ItemFormMove
            id={entity._id}
            file={isFile ? (entity as ISZFileObject) : undefined}
            handleCloseModal={handleClose}
          />
        )}
      </AnvDialog>
    );
  },
);
