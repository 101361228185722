import { makeObservable } from 'mobx';

import { FolderCommonService } from '../common/folders-common-service';

import { FolderCommonStore } from '../common/folders-common-store';

export class FileExplorerFolderStoreClass extends FolderCommonStore<FolderCommonService> {
  service: FolderCommonService;

  constructor() {
    super({ isView: true });
    this.service = new FolderCommonService({
      isView: true,
    });
    makeObservable(this);
  }
}

export default new FileExplorerFolderStoreClass();
