export function prepareComplianceReviewPath(path: string) {
  // `${Constants.TOOLS.COMPLIANCE}/${MODULE_CODE}/${organizationId}/${schoolId}/${annualReportId}/${annualReportId}_${month}`;
  const pathParts = path.split('/');

  const [
    toolCode,
    moduleCode,
    organizationId,
    schoolId,
    annualReportId,
    month,
  ] = pathParts;

  let modifyPath = `${toolCode}/${toolCode}_${moduleCode}`;

  if (organizationId) {
    modifyPath += `/${organizationId}`;
  }
  if (schoolId) {
    modifyPath += `/${schoolId}`;
  }
  if (annualReportId) {
    modifyPath += `/${annualReportId}`;
  }
  if (month) {
    modifyPath += `/${annualReportId}_${month}`;
  }

  return modifyPath;
}

export function prepareComplianceReportPath(path: string) {
  // `${Constants.TOOLS.COMPLIANCE}/${MODULE_CODE}/${schoolId}/${annualReportId}/${annualReportId}_${month}`;
  const pathParts = path.split('/');

  const [toolCode, moduleCode, schoolId, annualReportId, month] = pathParts;

  let modifyPath = `${toolCode}/${toolCode}_${moduleCode}`;

  if (schoolId) {
    modifyPath += `/${schoolId}`;
  }
  if (annualReportId) {
    modifyPath += `/${annualReportId}`;
  }
  if (month) {
    modifyPath += `/${annualReportId}_${month}`;
  }

  return modifyPath;
}

export function prepareCompliancePath(moduleCode: string, path: string) {
  if (moduleCode === 'REVIEW') {
    return prepareComplianceReviewPath(path);
  }

  if (moduleCode === 'REPORT') {
    return prepareComplianceReportPath(path);
  }

  return '';
}
