import React from 'react';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { generatePath, NavLink, useParams } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import {
  AnvButton,
  AnvButtonGroup,
  AnvDialog,
  AnvIcon,
  useModal,
} from '@platform/front-lib';

import { IParamsFolders } from '../../../types/storage-zone';
import { useRouteMatchEntity } from '../folders-breadcrumbs/use-route-match-entity';

import { ModuleContext } from '../module-context';

import { useStores } from 'src/stores';

import { FolderFormAddEdit } from '../forms/folder-add-edit/folder-form-add-edit';
import { FileUploadForm } from '../forms/files/form-upload';

import { ROUTES, TOOL_MODULES } from 'src/constants';
import { useFoldersToolbarStyles } from './styles';

interface ISZToolbarProps {}

export const SZToolbarOld: React.FC<ISZToolbarProps> = observer(() => {
  const { formatMessage } = useIntl();

  const { isSearchPageFO } = useRouteMatchEntity();

  const classes = useFoldersToolbarStyles();

  const module = React.useContext(ModuleContext);

  const isBuild = module === TOOL_MODULES.FILE_ORGANIZER;

  const params = useParams<IParamsFolders>();
  const { folders } = params;

  const foldersArray = folders?.split('/');
  const lastId = foldersArray?.pop?.() as string;

  const {
    fileOrganizerStore: {
      folderStore: { resetError },
      folderFilesStore: { setRefetch },
      fileStore: {
        resetError: resetErrorFiles,
        uploadFiles,
        isFetching,
        error,
      },
    },
  } = useStores();

  const {
    isOpen: isOpenAddFolder,
    handleCloseModal: handleCloseModalAddFolderPlain,
    handleOpenModal: handleOpenModalAddFolder,
  } = useModal();

  const handleCloseModalAddFolder = () => {
    handleCloseModalAddFolderPlain();
    resetError();
  };

  /** upload files */
  const {
    isOpen: isOpenUploadFiles,
    handleCloseModal: handleCloseModalUploadFilesPlain,
    handleOpenModal: handleOpenModalUploadFiles,
  } = useModal();

  const handleCloseModalUploadFiles = () => {
    handleCloseModalUploadFilesPlain();
    resetError();
    resetErrorFiles();
  };

  const handleUploadFiles = async ({
    files,
    fileTitles,
    filePublicState,
  }: any) => {
    const successFunc = () => {
      setRefetch(true);
    };

    const [error] = await uploadFiles(
      {
        folderId: lastId,
        files,
        fileTitles,
        filePublicState,
      },
      { successFunc },
    );

    if (!error) {
      handleCloseModalUploadFiles();
    }
  };

  // todo temp
  const hideListViewTemp = true;

  return (
    <>
      <Grid container spacing={2}>
        {isBuild && !isSearchPageFO && (
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <AnvButton
                  data-test={'toolbar-upload-files'}
                  title={formatMessage({
                    id: 'STORAGE_ZONE.TOOLBAR.ACTION.UPLOAD_FILES',
                    defaultMessage: 'Upload files',
                  })}
                  onClick={handleOpenModalUploadFiles}
                  className={classes.button}
                  color={'primary'}
                  size={'small'}
                  variant={'outlined'}
                >
                  <AnvIcon size={24} icon={'uploadCloud'} />
                </AnvButton>
              </Grid>
              <Grid item>
                <AnvButton
                  data-test={'toolbar-create-folder'}
                  title={formatMessage({
                    id: 'STORAGE_ZONE.TOOLBAR.ACTION.CREATE_NEW_FOLDER',
                    defaultMessage: 'Create new folder',
                  })}
                  onClick={handleOpenModalAddFolder}
                  className={classes.button}
                  color={'primary'}
                  size={'small'}
                  variant={'outlined'}
                >
                  <AnvIcon size={24} icon={'folder'} />
                </AnvButton>
              </Grid>

              <Grid item>
                <AnvButton
                  //@ts-ignore
                  component={NavLink}
                  to={decodeURIComponent(
                    generatePath(ROUTES.MODULE_FILE_ORGANIZER_SEARCH_PREFIX, {
                      rootId: params.rootId,
                      folders: params.folders,
                    }),
                  )}
                  data-test={'toolbar-search'}
                  className={classes.button}
                  color={'primary'}
                  size={'small'}
                  variant={'outlined'}
                >
                  <AnvIcon size={24} icon={'search'} />
                </AnvButton>
              </Grid>
            </Grid>
          </Grid>
        )}

        {!hideListViewTemp && (
          <Grid item>
            <AnvButtonGroup disableElevation variant="outlined">
              <AnvButton
                data-test={'toolbar-view-list'}
                title={formatMessage({
                  id: 'STORAGE_ZONE.TOOLBAR.ACTION.VIEW_AS_LIST',
                  defaultMessage: 'View as List',
                })}
                className={classes.buttonLeft}
                variant={'outlined'}
                size={'small'}
                color={'primary'}
              >
                <AnvIcon icon={'reviewList'} />
              </AnvButton>
              <AnvButton
                data-test={'toolbar-view-grid'}
                title={formatMessage({
                  id: 'STORAGE_ZONE.TOOLBAR.ACTION.VIEW_AS_GRID',
                  defaultMessage: 'View as grid',
                })}
                className={classes.buttonRight}
                color={'primary'}
                inactive
                variant={'contained'}
                size={'small'}
              >
                <AnvIcon icon={'grid'} />
              </AnvButton>
            </AnvButtonGroup>
          </Grid>
        )}
      </Grid>

      <AnvDialog open={isOpenAddFolder} handleClose={handleCloseModalAddFolder}>
        {isOpenAddFolder && (
          <FolderFormAddEdit handleCloseModal={handleCloseModalAddFolder} />
        )}
      </AnvDialog>

      <AnvDialog
        open={isOpenUploadFiles}
        handleClose={handleCloseModalAddFolder}
      >
        {isOpenUploadFiles && (
          <FileUploadForm
            isFetching={isFetching}
            handleUploadFiles={handleUploadFiles}
            isOpen={isOpenUploadFiles}
            handleCloseModal={handleCloseModalUploadFiles}
            serverErrors={error}
          />
        )}
      </AnvDialog>
    </>
  );
});
