import React from 'react';
import {
  AnvButton,
  AnvButtonGroup,
  useCommonOffsetsStyles,
} from '@platform/front-lib';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { generatePath, NavLink, useRouteMatch } from 'react-router-dom';
import { ROUTES } from '../../../constants';

interface IOrganizerTabs {
  actions?: React.ReactNode;
}

export const TABS = {
  personal: {
    fm: {
      id: 'STORAGE_ZONE.EXPLORER.TABS.LABEL.PERSONAL_ZONE',
      defaultMessage: 'Personal Zone',
    },
    to: generatePath(ROUTES.MODULE_FILE_EXPLORER_PERSONAL_ROOT),
  },
  work: {
    to: generatePath(ROUTES.MODULE_FILE_EXPLORER_WORK_LIST),
    fm: {
      id: 'STORAGE_ZONE.EXPLORER.TABS.LABEL.WORK_ZONE',
      defaultMessage: 'Work Zone',
    },
  },
};

export const ExplorerMainTabs: React.FC<IOrganizerTabs> = ({ actions }) => {
  const isWorkZone = useRouteMatch(ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_PREFIX)
    ?.isExact;

  const classesOffset = useCommonOffsetsStyles();
  const { formatMessage } = useIntl();

  return (
    <Grid
      container
      justify={'space-between'}
      spacing={2}
      className={classesOffset.pb_2}
    >
      <Grid item xs={12} sm={'auto'}>
        <AnvButtonGroup size={'small'}>
          {Object.entries(TABS).map(([tabKey, tabValue]) => {
            const variantContained =
              tabKey === 'work' ? isWorkZone : !isWorkZone;

            return (
              <AnvButton
                key={`${tabKey}`}
                //@ts-ignore
                component={NavLink}
                to={tabValue.to}
                data-test={`${tabKey}`}
                color={'primary'}
                variant={variantContained ? 'contained' : 'outlined'}
                inactive={variantContained}
                size={'small'}
              >
                {formatMessage(tabValue.fm)}
              </AnvButton>
            );
          })}
        </AnvButtonGroup>
      </Grid>

      <Grid item xs={12} sm={'auto'}>
        {actions}
      </Grid>
    </Grid>
  );
};
