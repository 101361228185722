// import { formatBytes } from '../utils';

export const validationsMessages = {
  required: (fieldName?: string) =>
    fieldName
      ? {
          id: 'APP.COMMON.REQUIRED.FIELD.NAMED',
          defaultMessage: '{fieldName} is a required field',
          values: { fieldName },
        }
      : {
          id: 'APP.COMMON.REQUIRED.FIELD',
          defaultMessage: 'This is a required field',
        },
  zipCode: () => ({
    id: 'APP.COMMON.REQUIRED.ZIP_CODE',
    defaultMessage: 'Required valid zip code',
  }),
  withoutRequiredSymbols: () => ({
    id: 'APP.COMMON.VALIDATION.URL_SEGMENT',
    defaultMessage:
      'Url segment must be without required symbols and not one of special words',
  }),
  email: () => ({
    id: 'APP.COMMON.REQUIRED.EMAIL',
    defaultMessage: 'Required valid email address',
  }),
  emailRepeat: () => ({
    id: 'APP.COMMON.REQUIRED.EMAIL.MATCH',
    defaultMessage: 'Email addresses must match',
  }),
  phoneNotValid: () => ({
    id: 'APP.COMMON.REQUIRED.PHONE.NOT_VALID',
    defaultMessage: 'Required valid phone number',
  }),
  phoneRepeat: () => ({
    id: 'APP.COMMON.REQUIRED.PHONE.MATCH',
    defaultMessage: 'Mobile numbers must match',
  }),
  passwordRepeat: () => ({
    id: 'APP.COMMON.REQUIRED.PASSWORD.MATCH',
    defaultMessage: 'Password must match',
  }),
  olderThan18: () => ({
    id: 'APP.COMMON.REQUIRED.BIRTHDAY.OLDER_18',
    defaultMessage: 'Your age should be 18 or more',
  }),
  passwordStrength: () => ({
    id: 'VALIDATION.PASSWORD.STRENGTH',
    defaultMessage:
      'Password must be at least 8 characters long with combination of at least one each of the following: upper case letters, lower cases letters, digits, and certain special characters such as @#$%?.',
  }),
  ssnValidation: () => ({
    id: 'VALIDATION.SSN.VALID',
    defaultMessage: 'Not valid SSN',
  }),
  number: () => ({
    id: 'VALIDATION.NUMBER',
    defaultMessage: 'Number required',
  }),
  date: () => ({
    id: 'VALIDATION.DATE',
    defaultMessage: 'Date format should be yyyy-dd-mm',
  }),
  date18YearsOld: () => ({
    id: 'VALIDATION.DATE.18_YEARS_OLD',
    defaultMessage: 'Your age should be 18 or more',
  }),
  accountNumberRepeat: () => ({
    id: 'CLIENT.BANK_ACCOUNT_INFO.FORM.ACCOUNT_NUMBER.MATCH',
    defaultMessage: 'Account number must match',
  }),
  routingNumberRepeat: () => ({
    id: 'CLIENT.BANK_ACCOUNT_INFO.FORM.ROUTING_NUMBER.MATCH',
    defaultMessage: 'Routing number must match',
  }),
  zeroOrPositiveNumber: () => ({
    id: 'VALIDATION.GREATER_OR_EQUAL_TO_ZERO',
    defaultMessage: 'The value must be greater than or equal to zero',
  }),
  positiveNumber: () => ({
    id: 'VALIDATION.POSITIVE_NUMBER',
    defaultMessage: 'The value must be a positive number',
  }),
  integerNumber: () => ({
    id: 'VALIDATION.INTEGER_NUMBER',
    defaultMessage: 'The number must be an integer',
  }),
  numberMinMax: (min: number | string, max: number | string) => ({
    id: 'VALIDATION.MIN_AND_MAX',
    defaultMessage:
      'Enter number greater or equal to {min} and less or equal to {max}',
    values: {
      min,
      max,
    },
  }),
  numberMin: (min: number | string) => ({
    id: 'VALIDATION.MIN',
    defaultMessage: 'Enter number greater or equal to {min}',
    values: {
      min,
    },
  }),
  numberMax: (max: number | string) => ({
    id: 'VALIDATION.MAX',
    defaultMessage: 'Enter number less or equal to {max}',
    values: {
      max,
    },
  }),
  dateMin: () => ({
    id: 'VALIDATION.DATE.MIN',
    defaultMessage: 'Enter date in future',
  }),
  dateMax: () => ({
    id: 'VALIDATION.DATE.MAX',
    defaultMessage: 'Enter date in past',
  }),
  dateMinVal: (min: string) => [
    {
      id: 'VALIDATION.DATE.MIN.VAL',
      defaultMessage: 'Enter date greater than {min}',
    },
    {
      min,
    },
  ],
  dateMaxVal: (max: string) => [
    {
      id: 'VALIDATION.DATE.MAX.VAL',
      defaultMessage: 'Enter date less than {max}',
    },
    {
      max,
    },
  ],

  dateTodayAndFuture: (date: string | Date) => [
    {
      id: 'VALIDATION.DATE.DATE_TODAY_AND_IN_FUTURE',
      defaultMessage: 'Entered date must be in future',
    },
    {
      date,
    },
  ],
  filesMinOne: () => ({
    id: 'VALIDATION.FILES.MINIMUM_ONE',
    defaultMessage: 'Please add minimum one file',
  }),
  lengthMax: (max: string | number) => ({
    id: 'VALIDATION.LENGTH_MAX',
    defaultMessage: 'Value must not exceed {max} symbols',
    values: {
      max,
    },
  }),
  lengthEquals: (length: string | number) => ({
    id: 'VALIDATION.LENGTH_EQUALS',
    defaultMessage: 'Value must be {length} symbols in length',
    values: {
      length,
    },
  }),
  oneOfValues: (valuesArr: any[]) => ({
    id: 'VALIDATION.ONE_OF_VALUES',
    defaultMessage: 'Value must be one of {values}',
    value: {
      values: valuesArr.join(', '),
    },
  }),
  maxLengthString: (value?: number) =>
    value
      ? {
          id: 'APP.COMMON.REQUIRED.MAX_LENGTH_STRING.VALUE',
          defaultMessage: `Maximum ${value} characters exceeded`,
        }
      : {
          id: 'APP.COMMON.REQUIRED.MAX_LENGTH_STRING',
          defaultMessage: `Maximum characters exceeded`,
        },
  minLengthString: (value?: number) =>
    value
      ? {
          id: 'APP.COMMON.REQUIRED.MIN_LENGTH_STRING.VALUE',
          defaultMessage: `Minimum ${value} characters`,
        }
      : {
          id: 'APP.COMMON.REQUIRED.MIN_LENGTH_STRING',
          defaultMessage: `Minimum characters exceeded`,
        },
  minChipsLength: (value: any) => ({
    id: 'APP.COMMON.REQUIRED.MIN_LENGTH_CHIPS',
    defaultMessage: `Required minimum one ${value}`,
  }),
  maxFileSize: (value: any) => ({
    id: 'APP.COMMON.FILE.MAX_SIZE',
    defaultMessage: `Max file size is ${value}`,
  }),
  fileOnlyImage: () => ({
    id: 'APP.COMMON.FILE.TYPE',
    defaultMessage: `Required image file type`,
  }),
  emailShouldBeUnique: () => ({
    id: 'APP.COMMON.EMAIL.UNIQUE',
    defaultMessage: `Email should be unique`,
  }),
  positionNameShouldBeUnique: () => ({
    id: 'APP.COMMON.POSITION.UNIQUE',
    defaultMessage: `Position should be unique`,
  }),
};
