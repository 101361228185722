import React from 'react';
import { observer } from 'mobx-react-lite';

import { Constants } from '@platform/front-lib';
import GeneralSettingStore from '@platform/front-lib/dist/stores/meta/general-settings';

import { AnvFileInputLabLoc, IAnvFileInputLocProps } from './index';

export interface IAnvFileInputLabConnectedLocProps
  extends IAnvFileInputLocProps {
  generalSettingsStore: GeneralSettingStore;
}

const { GENERAL_SETTINGS } = Constants;

export const AnvFileInputLabConnectedLoc: React.FC<IAnvFileInputLabConnectedLocProps> = observer(
  ({ generalSettingsStore, ...rest }) => {
    const {
      generalSettings,
      getGeneralSettingMaxFileSize,
    } = generalSettingsStore;

    const maxFileSizeEntity =
      generalSettings?.[GENERAL_SETTINGS.DEFAULT_MAX_FILE_SIZE];

    React.useEffect(() => {
      if (!maxFileSizeEntity && typeof rest.maxSize === 'undefined') {
        getGeneralSettingMaxFileSize();
      }
    }, [getGeneralSettingMaxFileSize, maxFileSizeEntity, rest.maxSize]);

    const maxSize = rest.maxSize || maxFileSizeEntity?.value;

    return <AnvFileInputLabLoc {...rest} maxSize={+maxSize} />;
  },
);
