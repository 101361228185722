import React from 'react';
import { Cell, Column } from 'react-table';
import { FormattedMessage } from 'react-intl';

import { AnvIcon, AnvUtils, Constants, TextBody } from '@platform/front-lib';
import {
  EMPTY_FILE_SIZE_PLACEHOLDER,
  SZ_ENTITY_KIND,
} from '../../../../constants';

import { ISZFolderFile } from '../../../../models';
import { TFormatMessage } from '@platform/front-lib/dist/@types/intl/intl';

export function commonFilesColumns(
  locale: string | undefined,
  formatMessage: TFormatMessage,
  {
    isView = false,
    classes,
    moveToFolder,
  }: {
    isView?: boolean;
    classes: Record<string, string>;
    moveToFolder: (folderId: string) => void;
  },
) {
  const columns: Record<string, any>[] = [
    {
      width: 30,
      Header: '',
      headerString: '',
      id: 'icon',

      Cell: ({ cell }: { cell: Cell<ISZFolderFile> }) => {
        const entity = cell?.row?.original;
        const isFolder = entity?.kind === SZ_ENTITY_KIND.FOLDER;
        return (
          <div>
            <AnvIcon
              icon={isFolder ? 'folderBig' : 'fileListDefault'}
              size={40}
            />
          </div>
        );
      },
    },
    {
      width: 'auto',
      sortable: true,
      Header: () => {
        return formatMessage({
          id: 'STORAGE_ZONE.FILES.LIST.TH.FILE_NAME',
          defaultMessage: 'Name',
        });
      },
      headerString: formatMessage({
        id: 'STORAGE_ZONE.FILES.LIST.TH.FILE_NAME',
        defaultMessage: 'Name',
      }),
      accessor: 'name',
      id: 'name',

      Cell: ({ cell }: { cell: Cell<ISZFolderFile> }) => {
        const entity = cell?.row?.original;

        const isFolder = entity?.kind === SZ_ENTITY_KIND.FOLDER;

        return (
          <TextBody
            className={isFolder ? classes.link : undefined}
            onClick={(ev: any) => {
              if (!isFolder) {
                return;
              }
              ev.stopPropagation();
              moveToFolder(entity._id);
            }}
          >
            {entity?.name}
          </TextBody>
        );
      },
    },

    // show file type in right drawer instead
    // {
    //   maxWidth: 180,
    //   // sortable: true,
    //   Header: () => {
    //     return formatMessage({
    //       id: 'STORAGE_ZONE.FILES.LIST.TH.FILE_TYPExxx',
    //       defaultMessage: 'Type',
    //     });
    //   },
    //   headerString: formatMessage({
    //     id: 'STORAGE_ZONE.FILES.LIST.TH.FILE_TYPExxx',
    //     defaultMessage: 'Type',
    //   }),
    //   accessor: 'fileType',
    //   id: 'fileType',
    //
    //   Cell: ({ cell }: Record<string, any>) => {
    //     const entity = cell?.row?.original;
    //
    //     return (
    //       <div>
    //         {entity?.fileType ||
    //           formatMessage({
    //             id: 'STORAGE_ZONE.COMMON.TYPE.FOLDER',
    //             defaultMessage: 'folder',
    //           })}
    //       </div>
    //     );
    //   },
    // },

    {
      // sortable: true,
      maxWidth: 120,
      Header: () => {
        return formatMessage({
          id: 'STORAGE_ZONE.FILES.LIST.TH.FILE_SIZE',
          defaultMessage: 'File Size',
        });
      },
      headerString: formatMessage({
        id: 'STORAGE_ZONE.FILES.LIST.TH.FILE_SIZE',
        defaultMessage: 'File Size',
      }),
      accessor: 'fileSize',
      id: 'fileSize',

      Cell: ({ cell }: Record<string, any>) => {
        const entity = cell?.row?.original;

        return (
          <div>
            {!!entity?.fileSize
              ? AnvUtils.formatBytes(entity?.fileSize)
              : EMPTY_FILE_SIZE_PLACEHOLDER}
          </div>
        );
      },
    },
  ];

  if (!isView) {
    columns.push({
      sortable: true,
      maxWidth: 140,
      Header: () => {
        return formatMessage({
          id: 'STORAGE_ZONE.FILES.LIST.TH.VISIBILITY',
          defaultMessage: 'Visibility',
        });
      },
      headerString: formatMessage({
        id: 'STORAGE_ZONE.FILES.LIST.TH.VISIBILITY',
        defaultMessage: 'Visibility',
      }),
      accessor: 'publicAccess',
      id: 'publicAccess',

      Cell: ({ cell }: { cell: Cell<ISZFolderFile> }) => {
        const entity = cell?.row?.original;

        if (!entity) return null;

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AnvIcon
              icon={entity?.publicAccess ? 'unlock' : 'lock'}
              size={16}
              color={entity?.publicAccess ? 'lightIcons' : 'primary'}
            />
            <span style={{ marginLeft: 4 }}>
              {entity?.publicAccess ? (
                <FormattedMessage
                  id="STORAGE_ZONE.COMMON.FILE.PUBLIC"
                  defaultMessage="Public"
                />
              ) : (
                <FormattedMessage
                  id="STORAGE_ZONE.COMMON.FILE.PRIVATE"
                  defaultMessage="Private"
                />
              )}
            </span>
          </div>
        );
      },
    });
  }

  columns.push({
    sortable: true,
    maxWidth: 180,
    Header: () => {
      return formatMessage({
        id: 'STORAGE_ZONE.FILES.LIST.TH.UPLOAD_TIME',
        defaultMessage: 'Upload Time',
      });
    },
    headerString: formatMessage({
      id: 'STORAGE_ZONE.FILES.LIST.TH.UPLOAD_TIME',
      defaultMessage: 'Upload Time',
    }),
    accessor: 'createdAt',
    id: 'createdAt',

    Cell: ({ cell }: Record<string, any>) => {
      const entity = cell?.row?.original;

      return (
        <div>
          {AnvUtils.formatWLocale(
            entity?.createdAt,
            Constants.DATE_FORMATS.DEFAULT_FORMAT_WITH_TIME,
          )}
        </div>
      );
    },
  });

  if (!isView) {
    columns.push({
      // sortable: true,
      maxWidth: 180,
      Header: () => {
        return formatMessage({
          id: 'STORAGE_ZONE.FILES.LIST.TH.UPLOADED_BY',
          defaultMessage: 'Uploaded By',
        });
      },
      headerString: formatMessage({
        id: 'STORAGE_ZONE.FILES.LIST.TH.UPLOADED_BY',
        defaultMessage: 'Uploaded By',
      }),
      accessor: 'uploader',
      id: 'uploader',

      Cell: ({ cell }: { cell: Cell<ISZFolderFile> }) => {
        const entity = cell?.row?.original;

        return (
          <div>{AnvUtils.formatFullName({ entity: entity?.createdByRef })}</div>
        );
      },
    });
  }

  return columns as Column<Record<string, any>>[];
}
