import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  AnvNotFoundPage,
  Constants,
  IsLoadingComponent,
  useCommonOffsetsStyles,
  useQuery,
  useViewError,
} from '@platform/front-lib';
import { generatePath, useParams } from 'react-router-dom';
import { ContentPaper } from '../../../../components/layout/content';
import { useSubModuleContext } from '../../../../contexts/sub-module';
import { IParamsFolders } from '../../../../types/storage-zone';
import { ViewDrawerData } from '../../../common/entity-drawer';

import { SZBreadcrumbs } from '../../../common/SZBreadcrumbs';
import { FILE_EXPLORER_SUB_MODULES, ROUTES } from '../../../../constants';
import { MyStorageZoneTitle } from '../title';
import { useStylesContentWDrawer } from '../../personal-zone/list-education-entities/view/styles';
import { ExplorerMainTabs } from '../../tabs';
import { SZFoldersBreadcrumbs } from '../../../common/folders-breadcrumbs';
import { SZCustomBreadCrumbs } from '../../../common/folders-breadcrumbs/common';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../stores';
import { CommonGoverningBoardsListView } from './list';

interface IPersonalZoneGoverningBoardsViewPageProps {
  children?: React.ReactNode;
}

export const CommonGoverningBoardsViewPage: React.FC<IPersonalZoneGoverningBoardsViewPageProps> = observer(
  () => {
    const classes = useStylesContentWDrawer();
    const classesOffset = useCommonOffsetsStyles();

    const subModule = useSubModuleContext();

    const isWorkZone = subModule === FILE_EXPLORER_SUB_MODULES.WORK_ZONE;

    const { folders, rootId } = useParams<IParamsFolders>();
    const query = useQuery();

    const { formatMessage } = useIntl();
    const foldersArray = React.useMemo(() => folders?.split('/') || [], [
      folders,
    ]);
    const firstFolderId = foldersArray?.[0];
    const lastFolderId = foldersArray?.[foldersArray.length - 1];

    const currentFolderId = lastFolderId || rootId;

    const { fileExplorerStore } = useStores();

    const {
      activeFileId,
      activeFolderId,
      setActiveFolderId,
      setActiveFileId,

      folderStore: {
        getList: getChildFolders,
        getChain,
        dataMapShort: dataMapFolders,
      },

      folderRootStore: {
        getOne: getOneRootFolders,
        dataMap: rootFoldersMap,
        isFetching: isFetchingRootFolders,
      },

      folderFilesStore: { getListData, resetListServerErrors },
    } = fileExplorerStore;

    const listRequestId = `${subModule}_${rootId}_${lastFolderId}_governing-documents`;

    const { errorList } = getListData(listRequestId);

    const { ErrorView, view_type } = useViewError({
      errorItem: errorList,
      dropErrors: () => resetListServerErrors(listRequestId),
      actionText: formatMessage({
        id: 'STORAGE_ZONE.COMMON.ERROR_PAGE.ACTION.RETURN_TO_ROOT_FOLDER',
        defaultMessage: 'Return to root folder',
      }),
      isActionRouteLocal: true,
      actionRoute: isWorkZone
        ? ROUTES.MODULE_FILE_EXPLORER_WORK_LIST
        : ROUTES.MODULE_FILE_EXPLORER_PERSONAL_ROOT,
    });

    const subModuleStore = fileExplorerStore[subModule];

    const {
      virtualFoldersStore: { dataMap: virtualFoldersDataMap },
    } = subModuleStore.governingBoardsStore;

    const rootEntity = rootFoldersMap?.[rootId];

    const firstFolderElementEntity =
      (firstFolderId && dataMapFolders?.[firstFolderId]) || null;

    const currentFolderEntity = dataMapFolders?.[currentFolderId] || null;

    React.useEffect(() => {
      return () => {
        resetListServerErrors(listRequestId);
      };
      // only on unmount
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
      if (!rootEntity) {
        getOneRootFolders({ id: rootId });
      }
    }, [rootEntity, rootId, getOneRootFolders]);

    React.useEffect(() => {
      if (rootId) {
        getChain({ rootId, folders: foldersArray });
      }
    }, [foldersArray, rootId, getChain]);

    React.useEffect(() => {
      if (rootId && lastFolderId) {
        getChildFolders({ id: lastFolderId, limit: 1_000 });
      }
    }, [rootId, lastFolderId, getChildFolders]);

    React.useEffect(() => {
      setActiveFolderId(null);
      setActiveFileId(null);
    }, [rootId, folders, setActiveFolderId, setActiveFileId]);

    const breadCrumbsItems = React.useMemo(() => {
      return [
        {
          component: (
            <FormattedMessage
              id={'STORAGE_ZONE.FOLDERS.BREADCRUMBS.GOVERNING_BOARDS'}
              defaultMessage={'Governing Boards'}
            />
          ),
          to: generatePath(
            ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_GOVERNING_BOARDS,
          ),
        },
        {
          component: firstFolderElementEntity?.folderName,
        },
      ];
    }, [firstFolderElementEntity]);

    const filterSchoolYear = React.useMemo(
      () => query?.['filter.schoolYear'] || '',
      [query],
    );

    const lastItemsSZFolders = React.useMemo(() => {
      const schoolYearFilterEntity =
        virtualFoldersDataMap[filterSchoolYear] || null;

      return schoolYearFilterEntity
        ? [{ component: schoolYearFilterEntity?.folderName as string }]
        : [];
    }, [filterSchoolYear, virtualFoldersDataMap]);

    const itemsSZFolders = React.useMemo(() => {
      return [
        {
          ...SZCustomBreadCrumbs.personalZoneGoverningBoard,
          to: isWorkZone
            ? ROUTES.MODULE_FILE_EXPLORER_WORK_LIST_GOVERNING_BOARDS
            : ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_GOVERNING_BOARDS,
        },
      ];
    }, [isWorkZone]);

    // Error has occurred
    if (!rootEntity && !isFetchingRootFolders && isFetchingRootFolders) {
      return <AnvNotFoundPage />;
    }

    if (!!errorList && view_type === Constants.VIEW_ERROR.SCREEN) {
      return <>{ErrorView}</>;
    }

    return (
      <>
        <ViewDrawerData />
        {ErrorView}
        <div
          data-clickaway={true}
          className={clsx(classes.mainContent, {
            [classes.mainContentWDrawer]: !!activeFolderId || !!activeFileId,
          })}
        >
          {!rootEntity && <IsLoadingComponent isLoading />}

          <SZBreadcrumbs items={breadCrumbsItems} />

          <ContentPaper>
            <Grid
              container
              spacing={2}
              justify={'space-between'}
              alignItems={'center'}
            >
              <Grid item>
                <MyStorageZoneTitle />
              </Grid>
            </Grid>

            <ExplorerMainTabs />

            <div className={clsx(classesOffset.pb_2, classesOffset.pt_1)}>
              <SZFoldersBreadcrumbs
                rootLink={
                  isWorkZone
                    ? ROUTES.MODULE_FILE_EXPLORER_WORK_LIST
                    : ROUTES.MODULE_FILE_EXPLORER_PERSONAL_ROOT
                }
                items={itemsSZFolders}
                lastItems={lastItemsSZFolders}
              />
            </div>

            <CommonGoverningBoardsListView
              currentFolderEntity={currentFolderEntity}
            />
          </ContentPaper>
        </div>
      </>
    );
  },
);
