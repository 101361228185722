import { ApiServiceCommon } from '@platform/front-lib';
import { IGetList } from '../../../../../service/interfaces';

interface IGetListBuild extends IGetList {
  rootFolderId?: string;
  type?: string;
}

export class VirtualFoldersService extends ApiServiceCommon {
  private subModule: string;

  constructor(module: string, collection: string, subModule: string) {
    super(module, collection);
    this.subModule = subModule;
  }
  getList({ rootFolderId, type }: IGetListBuild) {
    return this.axiosCatch(
      this.axios.get(
        `${this.apiUrl}/${this.subModule}/governing-boards/${rootFolderId}/virtual-folders/${type}`,
        {},
      ),
    );
  }
}
