import { CommonListAdvancedStoreCRUD } from '@platform/front-lib';
import { IActionMeta } from '@platform/front-lib/dist/stores/_helpers';
import { action, makeObservable, observable } from 'mobx';
import { IVirtualFolder } from '../../../../../pages/common/folders-virtual/types';

import { VirtualFoldersService } from './service';

export class VirtualFoldersStore extends CommonListAdvancedStoreCRUD<
  VirtualFoldersService,
  IVirtualFolder
> {
  error: Record<string, any> | null | string = null;

  getSchoolYearsVirtualFolders = async (
    { rootFolderId }: { rootFolderId: string },
    meta?: IActionMeta,
  ) => {
    return this.getList(
      {
        rootFolderId,
        type: 'school-years',
      },
      meta,
    );
  };

  isFetching: boolean = false;
  isLoaded: boolean = false;
  isError: boolean = false;

  setLoading = (func?: () => void) => {
    this.error = null;
    this.isError = false;
    this.isFetching = true;

    func?.();
  };

  setError = (func?: () => void) => {
    this.isFetching = false;
    this.isError = true;

    func?.();
  };

  setLoaded = (func?: () => void) => {
    this.isFetching = false;
    this.isLoaded = true;
    this.isError = false;

    func?.();
  };

  constructor(
    module: string,
    subModule: string,
    sessionKey?: string,
    disableAutoSave?: boolean,
  ) {
    super({ sessionKey, disableAutoSave });
    this.service = new VirtualFoldersService(
      module,
      'governing-boards',
      subModule,
    );

    makeObservable(this, {
      isFetching: observable,
      isLoaded: observable,
      isError: observable,
      error: observable,

      getSchoolYearsVirtualFolders: action,
      setLoading: action,
      setError: action,
      setLoaded: action,
    });
  }
}
