import { IActionMeta } from '@platform/front-lib/dist/stores/_helpers';
import { action, makeObservable, observable } from 'mobx';

import { FileOrganizerService } from './service';

import {
  FilesCommonStore,
  IGetOneFilePayload,
} from '../common/files-common-store';
import { FilesCommonService } from '../common/files-common-service';
import { ISZFileObjectFull } from '../../../models/storage-zone/file-object';

export class FileExplorerFileStoreClass extends FilesCommonStore<FileOrganizerService> {
  service: FilesCommonService;
  @observable
  mapDetailed: Record<string, ISZFileObjectFull> = {};

  @action
  getOneDetailed = async (payload: IGetOneFilePayload, meta?: IActionMeta) => {
    this.setLoading();

    const [error, response] = await this.service.getOne(payload);

    if (error || response?.error) {
      return this.setError(() => {
        this.errorItem = error || response?.error;
      });
    }

    this.setLoaded(() => {
      if (response) {
        this.mapDetailed[response._id] = response;
      }

      meta?.successFunc?.(response);
    });
  };

  constructor() {
    super({ isView: true });
    this.service = new FilesCommonService({ isView: true });
    makeObservable(this);
  }
}

export default new FileExplorerFileStoreClass();
