import React from 'react';
import {
  AccessStore,
  AuthStore,
  KnowledgeBaseStore,
  MetaStores,
  ProfileInfoStore,
  UiStore,
  UserStore,
} from '@platform/front-lib';
import { ComplianceFilesStore } from './storage-zone/file-explorer-store/compliance-file/store';
import { WorkZoneVirtualFoldersStore } from './storage-zone/file-explorer-store/work-zone/store';

import { FileOrganizerStore } from './storage-zone/file-organizer-store';
import { FileExplorerStore } from './storage-zone/file-explorer-store';

import { TOOL_MODULES } from '../constants';

const initAuthSore = new AuthStore(TOOL_MODULES);

export const rootStore = {
  uiStores: UiStore,
  knowledgeBaseStore: KnowledgeBaseStore,
  authStores: initAuthSore,
  accessStore: AccessStore,
  metaStores: MetaStores,
  userStore: new UserStore(),
  profileInfoStore: new ProfileInfoStore(),

  // Storage Zone
  fileOrganizerStore: FileOrganizerStore,
  fileExplorerStore: FileExplorerStore,

  workZoneVirtualFoldersStore: new WorkZoneVirtualFoldersStore(
    'file-explorer',
    'file-explorer-work-zone',
    true,
  ),
  complianceFilesStore: new ComplianceFilesStore(
    'file-explorer',
    'file-explorer-compliance-files',
    true,
  ),

  // fileExplorerStore: '',
  // trashStore: '',
};

export const StoresContext = React.createContext(rootStore);

export const useStores = () => React.useContext(StoresContext);
