import { axiosInstance } from '@platform/front-lib';

import { axiosCatch } from 'src/service/utils';

import { IGetList } from '../../../service/interfaces';
import {
  ICreateFilePayload,
  IDeleteFilePayload,
  IGetOneFilePayload,
  IMoveFilePayload,
  IUpdateFilePayload,
} from './files-common-store';

const axios = axiosInstance;

export interface IFilesUpload {
  folderId: string;
  files: File[];
  data: any;
}

export class FilesCommonService {
  private readonly apiUrl: string;

  constructor({ isView }: { isView: boolean }) {
    this.apiUrl = isView
      ? process.env.REACT_APP_API_TOOL_ENDPOINT + '/file-explorer'
      : process.env.REACT_APP_API_TOOL_ENDPOINT + '/file-organizer';
  }

  async getList(data: IGetList) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/folders/${data.id}/files`, {
        params: data,
      }),
    );
  }

  async uploadFiles(payload: IFilesUpload) {
    const { folderId, files, ...data } = payload;

    const formData = new FormData();

    files.forEach((file: any) => {
      formData.append('files[]', file);
    });

    formData.append('data', JSON.stringify(data, null, 2));

    return axiosCatch(
      axios.post(`${this.apiUrl}/folders/${folderId}/files`, formData, {}),
    );
  }

  async getOne(data: IGetOneFilePayload) {
    return axiosCatch(axios.get(`${this.apiUrl}/files/${data.id}`));
  }

  async createFile(data: ICreateFilePayload) {
    return axiosCatch(axios.post(`${this.apiUrl}/files`, data));
  }

  async updateFile(data: IUpdateFilePayload) {
    const { id, ...payload } = data;
    return axiosCatch(axios.patch(`${this.apiUrl}/files/${id}`, payload));
  }

  async moveFile(data: IMoveFilePayload) {
    const { id, ...payload } = data;
    return axiosCatch(axios.patch(`${this.apiUrl}/files/${id}/move`, payload));
  }
  async deleteFile(data: IDeleteFilePayload) {
    const { id } = data;
    return axiosCatch(axios.delete(`${this.apiUrl}/files/${id}`));
  }
}
