import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useFoldersBreadcrumbsStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },

    item: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);
