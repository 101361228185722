import { FILE_EXPLORER_SUB_MODULES } from '../../../constants';
import { FolderFilesStore } from '../common/folder-files/store';
import FileExplorerFolderStore, {
  FileExplorerFolderStoreClass,
} from './folder-store';
import FileExplorerFolderRootStore, {
  FileExplorerFolderRootStoreClass,
} from './folder-root-store';
import FileExplorerFileStore, {
  FileExplorerFileStoreClass,
} from './file-store';
import { action, makeObservable, observable } from 'mobx';
import { GoverningBoardsStore } from './governing-boards/store';
import { PersonalZoneVirtualFoldersStore } from './personal-zone/store';

class FileExplorerStoreClass {
  folderStore: FileExplorerFolderStoreClass = FileExplorerFolderStore;
  folderRootStore: FileExplorerFolderRootStoreClass = FileExplorerFolderRootStore;
  fileStore: FileExplorerFileStoreClass = FileExplorerFileStore;

  folderFilesStore = new FolderFilesStore({
    isView: true,
  });

  personalZoneVirtualFoldersStore: PersonalZoneVirtualFoldersStore;

  [FILE_EXPLORER_SUB_MODULES.PERSONAL_ZONE] = {
    governingBoardsStore: new GoverningBoardsStore(
      'file-explorer',
      'personal-zone',
      'pz-governing-boards',
      true,
    ),
  };

  [FILE_EXPLORER_SUB_MODULES.WORK_ZONE] = {
    governingBoardsStore: new GoverningBoardsStore(
      'file-explorer',
      'work-zone',
      'wz-governing-boards',
      true,
    ),
  };

  activeFileId: string | null = null;

  setActiveFileId = (id: string | null) => {
    this.activeFileId = id;

    if (id && this.activeFolderId) {
      this.activeFolderId = null;
    }
  };

  activeFolderId: string | null = null;

  setActiveFolderId = (id: string | null) => {
    this.activeFolderId = id;
    if (id && this.activeFileId) {
      this.activeFileId = null;
    }
  };

  constructor() {
    this.personalZoneVirtualFoldersStore = new PersonalZoneVirtualFoldersStore(
      'file-explorer',
      'file-explorer-personal-zone-virtual-folders',
      true,
    );

    makeObservable(this, {
      activeFileId: observable,
      activeFolderId: observable,
      setActiveFileId: action,
      setActiveFolderId: action,
    });
  }
}

export const FileExplorerStore = new FileExplorerStoreClass();
