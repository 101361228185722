import { AnvIcon } from '@platform/front-lib';
import { IFile } from '@platform/front-lib/dist/models';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { ROUTES } from '../constants';
import { ISZFileObject, ISZFolderFile } from '../models';
import { IVirtualFolder } from '../pages/common/folders-virtual/types';

export function createBreadcrumbsFromVirtualFolders(
  entities: IVirtualFolder[],
  classes: Record<string, any>,
) {
  let link = generatePath(ROUTES.MODULE_FILE_EXPLORER_WORK_LIST);
  const breadcrumbs: any = [];

  const total = entities.length;

  // get current url from window
  const currentPathName = window.location.pathname;

  // get current folder name from url
  const currentFolderNamesDirty = currentPathName
    .split('/')
    .filter((item) => item !== '');

  // remove first two elements from array
  // /file-explorer/work-zone/
  const folderNames = currentFolderNamesDirty.slice(2);

  entities.forEach((item, index) => {
    const isLastElement = total === index + 1;

    const folderNameIndex = folderNames.indexOf(item?.to);

    const toFolders = folderNames
      .filter((_, i) => i <= folderNameIndex)
      .join('/');

    breadcrumbs.push({
      to: isLastElement ? undefined : link + '/' + toFolders,
      component: (
        <div className={classes.item}>
          <AnvIcon icon={'folderBigOpen'} size={32} offset={1} />
          {item?.folderName || ''}
        </div>
      ),
    });
  });

  return breadcrumbs;
}

export function isSZFileObject(
  entity: ISZFileObject | IFile | ISZFolderFile,
): entity is ISZFileObject {
  if (entity) {
    // case for ISZFolderFile
    if ('kind' in entity) {
      return entity.kind === 'file';
    }
    // case for ISZFileObject | IFile
    return 'originalFileName' in entity && 'parentFolder' in entity;
  } else {
    return false;
  }
}

export function isCommonFileObject(
  entity: ISZFileObject | IFile | ISZFolderFile,
): entity is IFile {
  return entity && !('parentFolder' in entity);
}

export function isSZFolderFile(
  entity: ISZFileObject | IFile | ISZFolderFile,
): entity is ISZFolderFile {
  return entity && 'kind' in entity;
}
