import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DRAWER_PROPERTIES_WIDTH } from '../../../../../styles/constants';

export const useStylesContentWDrawer = makeStyles((theme) =>
  createStyles({
    mainContent: {
      width: '100%',

      transition: theme.transitions.create(['width', 'maxWidth'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
    },
    mainContentWDrawer: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${DRAWER_PROPERTIES_WIDTH}px)`,
      },
    },
    item: { display: 'flex', alignItems: 'center' },
  }),
);
