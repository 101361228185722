import {
  AnvAutocomplete2,
  AnvSelect,
  AnvUtils,
  Constants,
  useDebounce,
  usePrevious,
} from '@platform/front-lib';
import { IRequestAccessModuleScope } from '@platform/front-lib/dist/models';
import React from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext, useWatch } from 'react-hook-form';

import { useStyles } from '../styles';
import { defaultMetaStore, ScopeFormContext } from '../common';

interface IProps {
  active?: boolean;
  jurisdictions?: any;
}

export const ModuleScopeFilterOrganization: React.FC<IProps> = observer(
  ({ active, jurisdictions, children }) => {
    const { control, errors, setValue } = useFormContext();
    const { locale } = useIntl();
    const classes = useStyles();

    const {
      existsData: item,
      onlyMyAccessByModule,
      isOneLevelAccess = false,
      particularTool,
      metaStores = defaultMetaStore,
      serverErrors = {},
      organizationRequired,
    } = React.useContext(ScopeFormContext) || {};

    const {
      jurisdictionStore: { isFetching: isFetchingJurisdictions },
      organizationTypesStore: { getOrganizationTypes, data: organizationTypes },
      organizationsStore: {
        getOrganizations,
        getOrganization,
        organizationsMap,
        data: organizations,
        data2: organizations2,
        total: organizationsTotal,
        total2: organizationsTotal2,
        isFetching: isFetchingOrganizations,
        isFetching2: isFetchingOrganizations2,
      },
      accessHistoryStore: { recentlyViewed: accessHistory = [] },
    } = metaStores;

    const [initialOrganization] = React.useState(item?.scope?.organization);

    /** get initial org */
    React.useEffect(() => {
      if (initialOrganization) {
        getOrganization({ id: initialOrganization });
      }
    }, [initialOrganization, getOrganization]);

    /** get org types */
    React.useEffect(() => {
      const filterAccess: Record<string, any> = {};

      if (onlyMyAccessByModule) {
        filterAccess.moduleCode = onlyMyAccessByModule;
        filterAccess.toolCode = particularTool;
        filterAccess.isOneLevelAccess = isOneLevelAccess;
      }

      getOrganizationTypes({ filterAccess });
    }, [
      isOneLevelAccess,
      onlyMyAccessByModule,
      particularTool,
      getOrganizationTypes,
    ]);

    const entitiesAll = React.useMemo(() => {
      const array: Record<string, any>[] = [];

      if (organizations) {
        array.push(...(organizations || []));
      }

      if (accessHistory) {
        array.push(
          ...(accessHistory
            .map(
              (historyItem: Record<string, any>) =>
                historyItem?.organizationRef,
            )
            .filter((item: any) => !!item) || []),
        );
      }

      return array;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizations, accessHistory, accessHistory?.length]);

    const [orgInputValuePlain, setOrgInputValue] = React.useState('');
    const [org2InputValuePlain, setOrg2InputValue] = React.useState('');

    const orgInputValue = useDebounce(
      orgInputValuePlain,
      Constants.INPUT_THROTTLE_TIME,
    );
    const org2InputValue = useDebounce(
      org2InputValuePlain,
      Constants.INPUT_THROTTLE_TIME,
    );
    const accessScope: string = useWatch({
      control,
      name: 'accessScope',
      defaultValue: item?.accessScope || '',
    });

    const scope: IRequestAccessModuleScope = useWatch({
      control,
      name: 'scope',
      defaultValue: item
        ? item?.scope
        : {
            jurisdiction: '',
            organization: null,
            school: null,
          },
    });

    const {
      jurisdiction,
      orgType,
      organization,
      organizationSecondary,
    } = scope;

    const organizationStore =
      organization &&
      (organizationsMap?.[organization] ||
        entitiesAll?.find((item) => item._id === organization));

    /** ORG */
    const organizationTypeEntity = AnvUtils.getItemById(
      organizationTypes,
      orgType,
    );
    const orgAccessScope = organizationTypeEntity?.accessScope;

    const organizationEntity = AnvUtils.getItemById(entitiesAll, organization);

    const organizationEntityName = AnvUtils.getNameByLangNullable(
      organizationEntity,
      'name',
      locale,
    );

    const organizationSecondaryEntity = AnvUtils.getItemById(
      organizations2,
      organizationSecondary,
    );
    const organizationSecondaryEntityName = AnvUtils.getNameByLangNullable(
      organizationSecondaryEntity,
      'name',
      locale,
    );

    /** set active access scopes when tab changed */
    React.useEffect(() => {
      if (active && accessScope !== orgAccessScope) {
        setValue('accessScope', orgAccessScope || '');
      }
    }, [accessScope, orgAccessScope, active, setValue]);

    const organizationEntityJurId = organizationEntity?.jurisdiction;

    const orgInputValuePrev = usePrevious(orgInputValue);
    const org2InputValuePrev = usePrevious(org2InputValue);
    // const org3InputValuePrev = usePrevious(org3InputValue);

    const jurisdictionPrev = usePrevious(jurisdiction || '');
    const orgTypePrev = usePrevious(orgType || '');

    const organizationSecondaryPrev = usePrevious(organizationSecondary);

    const jurisdictionChanged =
      item?.jurisdiction !== jurisdiction &&
      jurisdiction !== jurisdictionPrev &&
      organizationEntity?.jurisdiction !== jurisdiction;

    const organizationParentChanged =
      organizationSecondary &&
      (item?.organizationSecondary || '') !== organizationSecondary &&
      organizationSecondary !== organizationSecondaryPrev;

    // JUR or Type changed
    React.useEffect(() => {
      if (active && accessScope !== Constants.ACCESS_SCOPES.SCHOOL) {
        /** Type changed */
        if (
          orgType &&
          orgTypePrev &&
          orgTypePrev !== orgType &&
          organizationEntity?.organizationTypeId !== orgType
        ) {
          setValue('scope.jurisdiction', '');
          setValue('scope.organization', '');
          setValue('scope.organizationSecondary', '');

          return;
        }

        if (
          organizationParentChanged &&
          organizationEntity?.parentOrg !== organizationSecondary
        ) {
          setValue('scope.organization', '');
        }

        /** OrgType changed */
        if (!orgType && organizationEntity?.organizationTypeId) {
          setValue(
            'scope.orgType',
            organizationEntity?.organizationTypeId || '',
          );
        }
      }
    }, [
      active,
      organizationEntity,
      accessScope,
      orgType,
      orgTypePrev,
      organizationParentChanged,
      organizationSecondaryEntity,
      organizationSecondary,
      setValue,
    ]);

    // JUR
    React.useEffect(() => {
      if (active && accessScope !== Constants.ACCESS_SCOPES.SCHOOL) {
        /** Jur changed */
        if (
          jurisdictionChanged &&
          jurisdictionPrev &&
          organizationEntity?.jurisdiction !== jurisdiction
        ) {
          setValue('scope.organization', '');
          setValue('scope.organizationSecondary', '');
        }

        /** Jur changed */
        if (jurisdictionPrev && !jurisdiction) {
          if (organizationEntity?.jurisdiction) {
            setValue(
              'scope.jurisdiction',
              organizationEntity?.jurisdiction || '',
            );
          } else if (organizationSecondaryEntity?.jurisdiction) {
            setValue(
              'scope.jurisdiction',
              organizationSecondaryEntity?.jurisdiction || '',
            );
          }
        }
      }
    }, [
      active,
      organizationEntity,
      accessScope,
      jurisdiction,
      jurisdictionChanged,
      jurisdictionPrev,
      organizationParentChanged,
      organizationSecondaryEntity,
      organizationSecondary,
      setValue,
    ]);

    React.useEffect(() => {
      if (active && organizationEntity) {
        const parentOrganization = organizationEntity?.parentOrgRef;

        if (!jurisdiction) {
          setValue(
            'scope.jurisdiction',
            organizationEntity?.jurisdiction ||
              parentOrganization?.jurisdiction ||
              null,
          );
        }
      }
    }, [
      active,
      organizationEntity, // schoolEntity,
      jurisdiction,
      setValue,
    ]);
    const orgTypeChanged = orgType !== orgTypePrev;

    // GET SEA or LEA 2  (organizations2  "organizationSecondary")
    React.useEffect(() => {
      if (active && orgType === Constants.ORGANIZATION_TYPE_CODES.DEPARTMENT) {
        if (
          orgTypeChanged ||
          jurisdictionChanged ||
          (org2InputValuePrev !== org2InputValue &&
            organizationSecondaryEntityName !== org2InputValue) ||
          (!organizations2 && !isFetchingOrganizations2)
        ) {
          if (
            (item?.jurisdiction || '') !== jurisdiction &&
            jurisdictionPrev &&
            jurisdiction &&
            org2InputValuePrev !== org2InputValue &&
            organizationSecondaryEntityName !== org2InputValue
          ) {
            setValue('scope.organizationSecondary', '');
            setValue('scope.organization', '');
          }

          const data: Record<string, any> = {
            isDepartmentParents: true,
            getWithTotal: true,
            filter: {
              organizationType: {
                in: [
                  Constants.ORGANIZATION_TYPE_CODES.SEA,
                  Constants.ORGANIZATION_TYPE_CODES.LEA,
                ],
              },
            },
          };

          if (jurisdiction) {
            data.filter.and = [
              {
                jurisdiction: {
                  eq: jurisdiction,
                },
              },
            ];
          }

          if (org2InputValue) {
            data.name = org2InputValue;
            data.locale = locale;
          }

          if (onlyMyAccessByModule) {
            data.filterAccess = {};

            data.filterAccess.moduleCode = onlyMyAccessByModule;
          }

          getOrganizations(data, { secondary: true });
        }
      }
    }, [
      isFetchingOrganizations2,
      onlyMyAccessByModule,
      active,
      jurisdictionChanged,
      jurisdiction,
      jurisdictionPrev,
      orgType,
      org2InputValuePrev,
      org2InputValue,
      organizationSecondaryEntityName,
      organizationSecondary,
      organizationSecondaryPrev,
      item,
      locale,
      getOrganizations,
      setValue,
      organizations2,
      orgTypeChanged,
    ]);

    const departmentParentOrgChanged =
      orgType === Constants.ORGANIZATION_TYPE_CODES.DEPARTMENT &&
      organizationParentChanged;

    // GET ORG
    React.useEffect(() => {
      const orgTypeChanged = orgType !== orgTypePrev;

      const orgInputChanged =
        orgInputValuePrev !== orgInputValue &&
        organizationEntityName !== orgInputValue;

      if (
        active &&
        (orgType || organizationRequired) &&
        (orgTypeChanged ||
          jurisdictionChanged ||
          orgInputChanged ||
          departmentParentOrgChanged)
      ) {
        if (!jurisdiction && organizationEntityJurId) {
          setValue('scope.jurisdiction', organizationEntityJurId || null);
        }
        if (
          jurisdictionChanged &&
          jurisdictionPrev &&
          jurisdiction &&
          jurisdiction &&
          organizationEntityJurId &&
          organizationEntityJurId !== jurisdiction
        ) {
          setValue('scope.organization', null);
        }

        const data: Record<string, any> = {
          populateParent: true,
          isOneLevelAccess,
          getWithTotal: true,
          filter: {},
        };

        if (orgType) {
          data.filter.organizationType = { eq: orgType };
        }

        if (jurisdiction) {
          data.filter.and = [
            {
              jurisdiction: {
                eq: jurisdiction,
              },
            },
          ];
        }

        if (organizationSecondary) {
          data.filter.parentOrg = {
            eq: organizationSecondary,
          };
        }

        if (orgInputValue) {
          data.name = orgInputValue;
          data.locale = locale;
        }

        if (onlyMyAccessByModule) {
          data.filterAccess = {};

          data.filterAccess.moduleCode = onlyMyAccessByModule;
        }

        getOrganizations(data);
      }
    }, [
      isOneLevelAccess,
      organizationRequired,
      active,
      jurisdictionChanged,
      organizationSecondary,
      orgType,
      orgAccessScope,
      orgTypePrev,
      organizationEntityName,
      organizationEntityJurId,
      orgInputValue,
      orgInputValuePrev,
      onlyMyAccessByModule,
      jurisdiction,
      jurisdictionPrev,
      item,
      locale,
      getOrganizations,
      setValue,
      departmentParentOrgChanged,
    ]);

    const typesOptions = AnvUtils.prepareOptions(organizationTypes, {
      locale,
      field: 'description',
      disabledFunc: (item) => {
        let disabled = item.disabled;

        if (onlyMyAccessByModule && typeof disabled === 'undefined') {
          disabled = true;
        }

        return disabled;
      },
    });
    const jurisdictionsOptions = AnvUtils.prepareOptions(jurisdictions, {
      sortByLabel: true,
      locale,
    });

    const organizationsOptions = React.useMemo(() => {
      const organizationsOptionsActual = isFetchingOrganizations
        ? []
        : AnvUtils.prepareOptions(organizations, {
            locale,
            type: 'organization',
          });

      const isOrganizationExistsInActual =
        organizationStore &&
        organizationsOptionsActual.findIndex(
          (item) => item.value === organizationStore?._id,
        ) > -1;

      const organizationOptionInitial =
        !isOrganizationExistsInActual && organizationStore
          ? AnvUtils.prepareOptions([organizationStore], {
              locale,
              type: 'organization',
            })
          : [];

      return [...organizationOptionInitial, ...organizationsOptionsActual];
    }, [isFetchingOrganizations, organizations, organizationStore, locale]);

    const organizations2WithCurrentEntity = [
      ...(organizations2 || []),
      organizationSecondaryEntity,
    ]
      .filter((item) => !!item)
      .filter((value, index, self) => {
        return self.findIndex((subItem) => subItem._id === value._id) === index;
      });
    const organizations2Options = organizations2WithCurrentEntity
      ? AnvUtils.prepareOptions(organizations2WithCurrentEntity, {
          locale,
        })
      : [];

    const formHelper = (field: string) => {
      return AnvUtils.formErrorHelper(field, errors, serverErrors);
    };

    return (
      <>
        <AnvSelect
          name={'scope.orgType'}
          control={control}
          required={!organizationRequired}
          label={
            <FormattedMessage
              id="APP.COMMON.LABEL.ORGANIZATION.EDUCATIONAL_ORGANIZATION_TYPE"
              defaultMessage="Educational Organization Type"
            />
          }
          options={typesOptions}
          helperText={formHelper('scope.orgType')}
          error={!!formHelper('scope.orgType')}
          value={orgType}
        />

        {active ? (
          <AnvAutocomplete2
            displayEmpty
            required={false}
            name={'scope.jurisdiction'}
            control={control}
            disabled={isFetchingJurisdictions}
            label={
              <FormattedMessage
                id="APP.COMMON.LABEL.JURISDICTION"
                defaultMessage="Jurisdiction"
              />
            }
            onChange={(val) => {
              if (!val) {
                setValue('scope.organization', '');
                setValue('scope.organizationSecondary', '');
              }
            }}
            options={jurisdictionsOptions}
            helperText={formHelper('scope.jurisdiction')}
            error={!!formHelper('scope.jurisdiction')}
          />
        ) : (
          <div style={{ width: 1, height: 72 }} />
        )}

        {orgType === Constants.ORGANIZATION_TYPE_CODES.DEPARTMENT && (
          <AnvAutocomplete2
            total={organizationsTotal2}
            options={organizations2Options}
            loading={isFetchingOrganizations}
            label={
              <FormattedMessage
                id="APP.COMMON.LABEL.ORGANIZATION.SEA_OR_LEA"
                defaultMessage="SEA or LEA"
              />
            }
            onInputChange={(event, newInputValue) => {
              setOrg2InputValue(newInputValue);
            }}
            control={control}
            name={'scope.organizationSecondary'}
            helperText={formHelper('scope.organizationSecondary')}
            error={!!formHelper('scope.organizationSecondary')}
          />
        )}

        <AnvAutocomplete2
          textFieldClassName={classes.lastControl}
          total={organizationsTotal}
          required={!!organizationRequired}
          options={organizationsOptions}
          loading={isFetchingOrganizations}
          label={
            <FormattedMessage
              id="APP.COMMON.LABEL.ORGANIZATION"
              defaultMessage="Organization"
            />
          }
          onInputChange={(event, newInputValue) => {
            setOrgInputValue(newInputValue);
          }}
          control={control}
          name={'scope.organization'}
          helperText={formHelper('scope.organization')}
          error={!!formHelper('scope.organization')}
        />
        {children}
      </>
    );
  },
);
