import React from 'react';
import { FormattedMessage } from 'react-intl';

import { AnvIcon, Constants } from '@platform/front-lib';

import { ROUTES } from '../../../constants';
import { SZBreadcrumbItemWrapper } from './common-item-wrapper';

export const SZCustomBreadCrumbs = {
  workZone: {
    to: ROUTES.MODULE_FILE_EXPLORER_WORK_LIST,
    component: (
      <SZBreadcrumbItemWrapper>
        <AnvIcon icon={'folderBigOpen'} size={32} offset={1} />
        <FormattedMessage
          id="STORAGE_ZONE.FOLDERS.BREADCRUMBS.WORK_ZONE"
          defaultMessage="Work Zone"
        />
      </SZBreadcrumbItemWrapper>
    ),
  },

  personalZoneEE: {
    to: ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_EDUCATION,
    component: (
      <SZBreadcrumbItemWrapper>
        <AnvIcon icon={'folderBigOpen'} size={32} offset={1} />
        <FormattedMessage
          id="STORAGE_ZONE.FOLDERS.BREADCRUMBS.EDUCATION_ENTITIES"
          defaultMessage="Education Entities"
        />
      </SZBreadcrumbItemWrapper>
    ),
  },
  personalZoneFC: {
    toolCode: Constants.TOOLS.FAMILY_CIRCLE,
    showToolName: true,
    to: ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_FAMILY_CIRCLE,
    component: (
      <SZBreadcrumbItemWrapper>
        <AnvIcon icon={'folderBigOpen'} size={32} offset={1} />
        Family Circle
      </SZBreadcrumbItemWrapper>
    ),
  },

  personalZoneGoverningBoard: {
    to: ROUTES.MODULE_FILE_EXPLORER_PERSONAL_LIST_GOVERNING_BOARDS,
    component: (
      <SZBreadcrumbItemWrapper>
        <AnvIcon icon={'folderBigOpen'} size={32} offset={1} />
        <FormattedMessage
          id={'STORAGE_ZONE.FOLDERS.BREADCRUMBS.GOVERNING_BOARDS'}
          defaultMessage={'Governing Boards'}
        />
      </SZBreadcrumbItemWrapper>
    ),
  },
} as const;
