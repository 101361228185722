import React from 'react';

import { useTheme, Theme } from '@material-ui/core';

import ContainerAuth from '../components/layout/container-auth';
import { AnvNotFoundPage } from '@platform/front-lib';

const NotFoundPage = () => {
  const {
    palette: { brand },
  } = useTheme<Theme>();
  return (
    <ContainerAuth>
      <AnvNotFoundPage
        chainsColor={brand?.light}
        middleChainColor={brand?.main}
        numbersColor={brand?.main}
      />
    </ContainerAuth>
  );
};

export default NotFoundPage;
