import React from 'react';
import {
  FILE_EXPLORER_SUB_MODULES,
  TFILE_EXPLORER_SUB_MODULES,
} from '../constants';

type TSubModuleContext = TFILE_EXPLORER_SUB_MODULES;

export const SubModuleContext = React.createContext<TSubModuleContext>(
  FILE_EXPLORER_SUB_MODULES.PERSONAL_ZONE,
);

export function useSubModuleContext() {
  return React.useContext(SubModuleContext);
}
