import { AnvDialog } from '@platform/front-lib';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { SZ_ENTITY_KIND } from '../../../constants';
import { useStores } from '../../../stores';
import { FileFormAddEdit } from '../forms/files/file-form-add-edit';
import { FolderFormAddEdit } from '../forms/folder-add-edit/folder-form-add-edit';

interface IEditFolderFileModal {
  id: string;
  open: boolean;
  handleClose: (params?: any) => void;
}

export const EditFolderFileModal: React.FC<IEditFolderFileModal> = observer(
  ({ id, open, handleClose }) => {
    const {
      fileOrganizerStore: {
        folderFilesStore: { dataMap },
      },
    } = useStores();

    const entity = (id && dataMap?.[id]) || undefined;
    const isFile = entity?.kind === SZ_ENTITY_KIND.FILE;

    return (
      <>
        {open && isFile && (
          <AnvDialog open={open} handleClose={handleClose}>
            <FileFormAddEdit file={entity} handleCloseModal={handleClose} />
          </AnvDialog>
        )}

        {open && !isFile && (
          <AnvDialog open={open} handleClose={handleClose}>
            <FolderFormAddEdit folder={entity} handleCloseModal={handleClose} />
          </AnvDialog>
        )}
      </>
    );
  },
);
