import React from 'react';

import { TOOL_MODULES } from 'src/constants';

export const moduleStoreObject = {
  [TOOL_MODULES.FILE_EXPLORER]: 'fileExplorerStore',
  [TOOL_MODULES.FILE_ORGANIZER]: 'fileOrganizerStore',
  [TOOL_MODULES.TRASH]: 'trashStore',
};
export const ModuleContext = React.createContext(TOOL_MODULES.FILE_EXPLORER);
