import React from 'react';
import { FILE_EXPLORER_SUB_MODULES } from '../../../../constants';
import { SubModuleContext } from '../../../../contexts/sub-module';

import { CommonGoverningBoardsPage } from '../../common/governance';

import { observer } from 'mobx-react-lite';

interface IFamilyCircleFirstLevelProps {
  children?: React.ReactNode;
}

const requestId = 'wz-governing-boards-root-list';

export const WorkZoneGoverningBoardsPage: React.FC<IFamilyCircleFirstLevelProps> = observer(
  () => {
    return (
      <SubModuleContext.Provider value={FILE_EXPLORER_SUB_MODULES.WORK_ZONE}>
        <CommonGoverningBoardsPage requestId={requestId} />
      </SubModuleContext.Provider>
    );
  },
);
