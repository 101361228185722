import { ITool } from '@platform/front-lib/dist/models';
import React from 'react';
import { useTheme } from '@material-ui/core';
import { AnvIcon } from '@platform/front-lib';
import { TIcon } from '@platform/front-lib/dist/components/shared/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface IProps {
  size?: number;
  width?: number;
  height?: number;

  isToolLogo?: boolean;
  alt?: string;

  tool?: ITool;
}

const DEFAULT_ICON_SIZE = 24;

export const SzToolFolder: React.FC<IProps> = ({
  size = DEFAULT_ICON_SIZE,

  width: widthProps,
  height: heightProps,
  tool,
}) => {
  const classes = useFoldersToolStyles();

  const { palette } = useTheme();

  const width = widthProps || size;
  const height = heightProps || size;

  const colorMain = tool?.theme?.brandColor || palette.brand.main;
  const colorBg = tool?.theme?.brandColorLight || palette.brand.light;

  const icon: TIcon = (tool?.icon as TIcon) || 'toolDefault';

  return (
    <span
      className={classes.root}
      style={{
        display: 'inline-block',
        position: 'relative',
      }}
    >
      <AnvIcon
        icon={icon}
        size={size / 2}
        className={classes.toolIcon}
        style={{
          color: colorMain,
        }}
      />

      <svg
        width={width}
        height={height}
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.6 12.6C5.6 10.2804 7.4804 8.4 9.8 8.4H20.6603C21.7742 8.4 22.8425 8.8425 23.6301 9.63015L26.7698 12.7699C27.5575 13.5575 28.6258 14 29.7397 14H46.2C48.5196 14 50.4 15.8804 50.4 18.2V43.4C50.4 45.7196 48.5196 47.6 46.2 47.6H9.8C7.4804 47.6 5.6 45.7196 5.6 43.4V12.6Z"
          fill={colorMain}
        />
        <path
          d="M9.8 14.25H46.2C48.3815 14.25 50.15 16.0185 50.15 18.2V43.4C50.15 45.5815 48.3815 47.35 46.2 47.35H9.8C7.61848 47.35 5.85 45.5815 5.85 43.4V18.2C5.85 16.0185 7.61848 14.25 9.8 14.25Z"
          fill={colorBg}
          stroke={colorMain}
          strokeWidth="0.5"
        />
      </svg>
    </span>
  );
};

export const useFoldersToolStyles = makeStyles((/*theme*/) =>
  createStyles({
    root: {
      display: 'inline-block',
      position: 'relative',
    },

    toolIcon: {
      position: 'absolute',
      bottom: 2,
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  }));
