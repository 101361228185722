import React from 'react';

import AnvDialog from 'src/components/shared/anv-modal';
import { Button, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

interface IProps {
  title?: any;
  open?: boolean;
  hideFooter?: boolean;
  handleClose: () => void;

  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const AnvModalInfo: React.FC<IProps> = ({
  open = false,
  handleClose,
  title,
  hideFooter,
  children,
  maxWidth,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <AnvDialog
      maxWidth={maxWidth}
      handleClose={handleClose}
      open={open}
      title={title}
      {...rest}
    >
      {children}

      {!hideFooter && (
        <Grid className={classes.footer} container justify={'center'}>
          <Grid item>
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={handleClose}
            >
              <FormattedMessage id="APP.OK" defaultMessage="Ok" />
            </Button>
          </Grid>
        </Grid>
      )}
    </AnvDialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      marginTop: theme.spacing(3),
    },
  }),
);

export default AnvModalInfo;
