import format from 'date-fns/format';
import { enUS, es } from 'date-fns/esm/locale';
import { DATE_FORMATS, DEFAULT_LANGUAGE } from '../constants';

const locales = {
  en: enUS,
  es: es,
};

const defaultLanguage = DEFAULT_LANGUAGE;

/** format date (date-fns) */
export function formatWLocale(
  date: number | string | Date | null | undefined,
  formatStr?: string,
) {
  if (!date) {
    return '';
  }

  try {
    const newDate = new Date(date);

    return format(newDate || date, formatStr || DATE_FORMATS.DEFAULT_FORMAT, {
      // @ts-ignore
      locale: locales[window.__localeId__ || defaultLanguage], // or global.__localeId__
    });
  } catch (err) {
    return '';
  }
}

export function getDateWithoutTimeAndZone(
  input: string | number | Date | null | undefined,
) {
  try {
    if (!input) {
      return input;
    }
    const date = new Date(input);
    const dateString = formatWLocale(date, 'yyyy-MM-dd');
    return new Date(dateString + 'T00:00:00Z');
  } catch (e) {
    return input;
  }
}

export function getDateOnlyWithoutTimeAndZone(
  input: string | number | Date | null | undefined,
): Date | null | undefined {
  try {
    if (!input && typeof input !== 'number') {
      return null;
    }
    const date = new Date(input);
    const dateString = formatWLocale(date, 'yyyy-MM-dd');
    return new Date(dateString + 'T00:00:00Z');
  } catch (e) {
    return null;
  }
}

export function getOnlyDate() {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    new Date().getHours(),
    new Date().getMinutes(),
  );
}
