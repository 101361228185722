import { IOrganization, ISchool } from '@platform/front-lib/dist/models';
import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { Grid, IconButton, MenuItem } from '@material-ui/core';
import {
  AnvIcon,
  AnvListContainer,
  AnvListData,
  AnvMenu,
  AnvUtils,
  ModalConfirm,
  TextBody,
  TextH4,
  useMenu,
} from '@platform/front-lib';

import type { IAnvListDataProps } from '@platform/front-lib/dist/components/shared/anv-list-data/AnvListData';

import { useStores } from '../../../../stores';
import { useRightDrawerStyles } from '../styles';
import { ModuleContext } from '../../module-context';

import { IViewDrawerCustomDataCommonProps } from './index';

import { IAddress } from '@platform/front-lib/dist/models/core/address';
import { PAGINATION_LIMIT } from '../../../../constants';
import { prepareAccessFilterParam } from '../../../../utils';

interface IViewDrawerEEDataProps extends IViewDrawerCustomDataCommonProps {}

export const ViewDrawerEEData: React.FC<IViewDrawerEEDataProps> = observer(
  ({ entity }) => {
    const educationEntity = entity as ISchool | IOrganization;

    const address: IAddress | undefined =
      educationEntity?.address || educationEntity?.mailingAddress;

    const { locale } = useIntl();
    const classes = useRightDrawerStyles();

    const module = React.useContext(ModuleContext);

    const {
      authStores: { modulesFilter },
      fileExplorerStore: {
        setActiveFolderId,
        folderRootStore: { getList, pagination, sort, filter },
      },
      metaStores: {
        accessHistoryStore: { hideAccessHistoryForTool },
        organizationTypesStore: {
          dataMap: organizationTypesMap,
          getOrganizationTypes,
          isLoaded: isOrganizationTypesLoaded,
        },
      },
    } = useStores();

    // get organization types by useEffect
    React.useEffect(() => {
      if (!isOrganizationTypesLoaded) {
        getOrganizationTypes();
      }
    }, [isOrganizationTypesLoaded, getOrganizationTypes]);

    /** Remove from list */
    const { closeMenu, handleClose: handleCloseMenu } = useMenu();

    const [hideHistoryId, setHideHistoryId] = React.useState<string | null>(
      null,
    );
    const [isHideModal, setIsHideModalOpen] = React.useState(false);
    const handleCloseHideModal = () => {
      setIsHideModalOpen(false);
      setHideHistoryId(null);
    };

    const handleRemoveFromList = (id: string) => () => {
      handleCloseMenu();
      setHideHistoryId(id);
      setIsHideModalOpen(true);
    };

    const accessFilter = modulesFilter?.[module];

    const confirmHideOrganization = () => {
      if (hideHistoryId) {
        const successFunc = () => {
          getList({
            accessFilter: prepareAccessFilterParam(accessFilter),
            page: pagination.page,
            sort: sort,
            filter,
            limit: PAGINATION_LIMIT,
          });

          setIsHideModalOpen(false);
        };

        hideAccessHistoryForTool(
          {
            id: hideHistoryId,
            moduleCode: module,
            organization: hideHistoryId,
          },
          { successFunc },
        );
        setHideHistoryId(null);
        setActiveFolderId(null);
      }
    };

    const listDataConfig: IAnvListDataProps = React.useMemo(() => {
      const LABEL_MD = 4;

      return {
        direction: 'row',
        labelMd: LABEL_MD,
        omitMargin: true,
      };
    }, []);

    const handleCloseDrawer = () => {
      setActiveFolderId(null);
    };

    return (
      <>
        <div className={classes.headerBlock}>
          <TextH4 className={classes.header}>
            <Grid
              container
              alignItems={'center'}
              className={classes.headerTitleGrid}
              wrap={'nowrap'}
            >
              <Grid item style={{ height: 24 }}>
                <AnvIcon
                  icon={'folderBig'}
                  offset={0.5}
                  size={24}
                  alignSelfCenter={true}
                />
              </Grid>
              <Grid
                item
                className={classes.headerTitleItem}
                style={{
                  flex: '1',
                }}
              >
                {AnvUtils.getNameByLangNullable(entity, 'name', locale)}
              </Grid>

              <Grid item>
                <div className={classes.headerMenu}>
                  <AnvMenu
                    size={'small'}
                    closeMenu={closeMenu}
                    customIcon={
                      <AnvIcon
                        icon={'tableAction'}
                        color={'white'}
                        opacity={0.5}
                      />
                    }
                  >
                    <MenuItem
                      onClick={handleRemoveFromList(
                        entity.historyId || entity?._id,
                      )}
                    >
                      <AnvIcon icon={'filterArchive'} />
                      <span>
                        <FormattedMessage
                          id={'STORAGE_ZONE.EXPLORER.ORGS.LIST.ACTION.ARCHIVE'}
                          defaultMessage={'Archive'}
                        />
                      </span>
                    </MenuItem>
                  </AnvMenu>
                </div>
              </Grid>

              <Grid item>
                <IconButton
                  size={'small'}
                  onClick={handleCloseDrawer}
                  // className={classes.closeButton}
                  className={classes.closeButtonStatic}
                >
                  <AnvIcon
                    icon={'closeCross'}
                    color={'white'}
                    opacity={0.5}
                    size={24}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </TextH4>
        </div>

        <div className={classes.body}>
          <div>
            <div className={classes.bodyHeader}>
              <TextBody semiBold>
                <FormattedMessage
                  id="STORAGE_ZONE.FOLDERS.DRAWER.CAPTION"
                  defaultMessage="Object Storage Considerations:"
                />
              </TextBody>
            </div>

            <AnvListContainer>
              {educationEntity?.name && (
                <AnvListData
                  {...listDataConfig}
                  label={
                    <FormattedMessage
                      id="STORAGE_ZONE.FOLDERS.DRAWER.EDUCATION_ENTITY.LABEL.TITLE"
                      defaultMessage="Title"
                    />
                  }
                  value={
                    <span className={classes.listValue}>
                      {AnvUtils.getNameByLangNullable(
                        educationEntity,
                        'name',
                        locale,
                      )}
                    </span>
                  }
                />
              )}

              {educationEntity?.organizationType && (
                <AnvListData
                  {...listDataConfig}
                  label={
                    <FormattedMessage
                      id="STORAGE_ZONE.FOLDERS.DRAWER.EDUCATION_ENTITY.LABEL.ORG_TYPE"
                      defaultMessage="Organization Type"
                    />
                  }
                  value={
                    <span className={classes.listValue}>
                      {AnvUtils.getNameByLangNullable(
                        organizationTypesMap[educationEntity?.organizationType],
                        'description',
                        locale,
                      ) || educationEntity?.organizationType}
                    </span>
                  }
                />
              )}

              {address?.stateRef && (
                <AnvListData
                  {...listDataConfig}
                  label={
                    <FormattedMessage
                      id="STORAGE_ZONE.FOLDERS.DRAWER.EDUCATION_ENTITY.LABEL.JURISDICTION"
                      defaultMessage="Jurisdiction"
                    />
                  }
                  value={
                    <span className={classes.listValue}>
                      {AnvUtils.getNameByLangNullable(
                        address.stateRef,
                        'name',
                        locale,
                      ) || address?.stateRef?.code}
                    </span>
                  }
                />
              )}

              {address?.county && (
                <AnvListData
                  {...listDataConfig}
                  label={
                    <FormattedMessage
                      id="STORAGE_ZONE.FOLDERS.DRAWER.EDUCATION_ENTITY.LABEL.COUNTY"
                      defaultMessage="County"
                    />
                  }
                  value={
                    <span className={classes.listValue}>
                      {AnvUtils.getNameByLangNullable(
                        address?.county,
                        'name',
                        locale,
                      )}
                    </span>
                  }
                />
              )}

              {address?.city && (
                <AnvListData
                  {...listDataConfig}
                  label={
                    <FormattedMessage
                      id="STORAGE_ZONE.FOLDERS.DRAWER.EDUCATION_ENTITY.LABEL.CITY"
                      defaultMessage="City"
                    />
                  }
                  value={
                    <span className={classes.listValue}>
                      {AnvUtils.getNameByLangNullable(
                        address?.city,
                        'name',
                        locale,
                      )}
                    </span>
                  }
                />
              )}
            </AnvListContainer>
          </div>
        </div>

        <ModalConfirm
          text={
            <FormattedMessage
              id="STORAGE_ZONE.EXPLORER.ORGS.LIST.QUESTION.ARCHIVE"
              defaultMessage="Are you sure to hide this organization?"
            />
          }
          isOpen={isHideModal}
          handleClose={handleCloseHideModal}
          confirmAction={confirmHideOrganization}
          cancelAction={handleCloseHideModal}
        />
      </>
    );
  },
);
