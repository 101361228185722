import { MenuItem } from '@material-ui/core';
import {
  AnvIcon,
  AnvMenu,
  AnvUtils,
  DefaultIcons,
  IListActionsComponent,
  useMenu,
} from '@platform/front-lib';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  envConst,
  SZ_ENTITY_KIND,
  SZ_ENTITY_TYPE,
  TOOL_MODULES,
} from '../../../../constants';
import { ISZFolderFile } from '../../../../models';
import { ModuleContext } from '../../module-context';

interface IActions extends IListActionsComponent {
  data: ISZFolderFile;
  token?: string;
  handleOpenDeleteModal: (id: string) => void;
  handleOpenMoveModal: (id: string) => void;
  handleChangePublicAccess: (id: string) => void;
  handleEditFolder: (id: string) => void;
  handleEditFile: (id: string) => void;

  setActiveFileId: (id: string) => void;
  setActiveFolderId: (id: string) => void;
}
const apiUrl = `${envConst.apiUrl}/file-organizer/files/get`;

export const FolderFilesActions: React.FC<IActions> = observer(
  ({
    data,
    token,
    handleOpenDeleteModal,
    handleOpenMoveModal,
    handleChangePublicAccess,
    handleEditFolder,
    handleEditFile,
    setActiveFolderId,
    setActiveFileId,
  }) => {
    const { closeMenu, handleClose: handleCloseMenu } = useMenu();
    const { formatMessage } = useIntl();

    const module = React.useContext(ModuleContext);

    const isExplorerModule = module === TOOL_MODULES.FILE_EXPLORER;

    if (!data) return null;

    const isFile = data.kind === SZ_ENTITY_KIND.FILE;
    const fileName = data?.name;

    const isSystemEntity = data.type === SZ_ENTITY_TYPE.SYSTEM;

    if (isExplorerModule) {
      return (
        <AnvMenu closeMenu={closeMenu} stopPropagation>
          <MenuItem
            onClick={(ev: any) => {
              ev.stopPropagation();
              if (isFile) {
                setActiveFileId(data._id);
              } else {
                setActiveFolderId(data._id);
              }
              handleCloseMenu();
            }}
          >
            <AnvIcon icon={DefaultIcons.VIEW_DETAILS} />
            {formatMessage({
              id: isExplorerModule
                ? 'STORAGE_ZONE.EXPLORER.ORGS.LIST.ACTION.VIEW_DETAILS'
                : 'STORAGE_ZONE.ORGANIZER.LIST.ACTION.VIEW_DETAILS',
              defaultMessage: 'View Details',
            })}
          </MenuItem>

          {isFile && (
            <MenuItem
              onClick={(ev: any) => {
                ev.stopPropagation();
                AnvUtils.downloadFile({
                  url: `${apiUrl}/${data?._id}`,
                  filename: fileName || 'file',
                  token,
                })();
                handleCloseMenu();
              }}
            >
              <AnvIcon icon={'download'} />
              <span>
                <FormattedMessage
                  id={
                    isExplorerModule
                      ? 'STORAGE_ZONE.EXPLORER.ORGS.LIST.ACTION.DOWNLOAD'
                      : 'STORAGE_ZONE.ORGANIZER.LIST.ACTION.DOWNLOAD'
                  }
                  defaultMessage={'Download'}
                />
              </span>
            </MenuItem>
          )}
        </AnvMenu>
      );
    }

    if (isSystemEntity) {
      return (
        <AnvMenu closeMenu={closeMenu} stopPropagation>
          <MenuItem
            onClick={(ev: any) => {
              ev.stopPropagation();
              if (isFile) {
                setActiveFileId(data._id);
              } else {
                setActiveFolderId(data._id);
              }
              handleCloseMenu();
            }}
          >
            <AnvIcon icon={DefaultIcons.VIEW_DETAILS} />
            {formatMessage({
              id: isExplorerModule
                ? 'STORAGE_ZONE.EXPLORER.ORGS.LIST.ACTION.VIEW_DETAILS'
                : 'STORAGE_ZONE.ORGANIZER.LIST.ACTION.VIEW_DETAILS',
              defaultMessage: 'View Details',
            })}
          </MenuItem>
        </AnvMenu>
      );
    }

    return (
      <AnvMenu closeMenu={closeMenu} stopPropagation>
        <MenuItem
          onClick={(ev: any) => {
            ev.stopPropagation();
            if (isFile) {
              setActiveFileId(data._id);
            } else {
              setActiveFolderId(data._id);
            }
            handleCloseMenu();
          }}
        >
          <AnvIcon icon={DefaultIcons.VIEW_DETAILS} />
          {formatMessage({
            id: isExplorerModule
              ? 'STORAGE_ZONE.EXPLORER.ORGS.LIST.ACTION.VIEW_DETAILS'
              : 'STORAGE_ZONE.ORGANIZER.LIST.ACTION.VIEW_DETAILS',
            defaultMessage: 'View Details',
          })}
        </MenuItem>
        {isFile && (
          <MenuItem
            onClick={(ev: any) => {
              ev.stopPropagation();
              AnvUtils.downloadFile({
                url: `${apiUrl}/${data?._id}`,
                filename: fileName || 'file',
                token,
              })();
              handleCloseMenu();
            }}
          >
            <AnvIcon icon={'download'} />
            <span>
              <FormattedMessage
                id={
                  isExplorerModule
                    ? 'STORAGE_ZONE.EXPLORER.ORGS.LIST.ACTION.DOWNLOAD'
                    : 'STORAGE_ZONE.ORGANIZER.LIST.ACTION.DOWNLOAD'
                }
                defaultMessage={'Download'}
              />
            </span>
          </MenuItem>
        )}
        <MenuItem
          onClick={(ev: any) => {
            ev.stopPropagation();
            if (isFile) {
              handleEditFile(data._id);
            } else {
              handleEditFolder(data._id);
            }
            handleCloseMenu();
          }}
        >
          <AnvIcon icon={DefaultIcons.EDIT} />
          <span>
            <FormattedMessage
              id={
                isExplorerModule
                  ? 'STORAGE_ZONE.EXPLORER.ORGS.LIST.ACTION.EDIT_NAME'
                  : 'STORAGE_ZONE.ORGANIZER.LIST.ACTION.EDIT_NAME'
              }
              defaultMessage={'Edit Name'}
            />
          </span>
        </MenuItem>

        <MenuItem
          onClick={(ev: any) => {
            ev.stopPropagation();
            handleChangePublicAccess(data._id);
            handleCloseMenu();
          }}
        >
          <AnvIcon icon={data?.publicAccess ? 'lock' : 'unlock'} />
          <span>
            <>
              {data?.publicAccess ? (
                <FormattedMessage
                  id={
                    isExplorerModule
                      ? 'STORAGE_ZONE.EXPLORER.ORGS.LIST.ACTION.PRIVATE'
                      : 'STORAGE_ZONE.ORGANIZER.LIST.ACTION.PRIVATE'
                  }
                  defaultMessage={'Private'}
                />
              ) : (
                <FormattedMessage
                  id={
                    isExplorerModule
                      ? 'STORAGE_ZONE.EXPLORER.ORGS.LIST.ACTION.PUBLIC'
                      : 'STORAGE_ZONE.ORGANIZER.LIST.ACTION.PUBLIC'
                  }
                  defaultMessage={'Public'}
                />
              )}
            </>
          </span>
        </MenuItem>

        <MenuItem
          onClick={(ev: any) => {
            ev.stopPropagation();
            handleOpenMoveModal(data._id);
            handleCloseMenu();
          }}
        >
          <AnvIcon icon={'folderNetwork'} />
          <span>
            <FormattedMessage
              id={
                isExplorerModule
                  ? 'STORAGE_ZONE.EXPLORER.ORGS.LIST.ACTION.MOVE'
                  : 'STORAGE_ZONE.ORGANIZER.LIST.ACTION.MOVE'
              }
              defaultMessage={'Move'}
            />
          </span>
        </MenuItem>

        <MenuItem
          onClick={(ev: any) => {
            ev.stopPropagation();
            handleOpenDeleteModal(data._id);
            handleCloseMenu();
          }}
        >
          <AnvIcon color={'error'} icon={DefaultIcons.DELETE} />
          <span>
            {formatMessage({
              id: isExplorerModule
                ? 'STORAGE_ZONE.EXPLORER.ORGS.LIST.ACTION.DELETE'
                : 'STORAGE_ZONE.ORGANIZER.LIST.ACTION.DELETE',
              defaultMessage: 'Delete',
            })}
          </span>
        </MenuItem>
      </AnvMenu>
    );
  },
);
