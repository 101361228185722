import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  AnvBreadcrumbsConnected,
  TBreadcrumbsItem,
} from '../../components/connected/anv-breadcrumbs-container';
import { ModuleContext } from './module-context';
import { useStores } from '../../stores';
import { Skeleton } from '@material-ui/lab';

interface ISZBreadcrumbsProps {
  items?: TBreadcrumbsItem[];
}

export const SZBreadcrumbs: React.FC<ISZBreadcrumbsProps> = observer(
  ({ items }) => {
    const module = React.useContext(ModuleContext);

    const {
      authStores: { isOperatorLoggedIn },
      uiStores: { tool },
    } = useStores();

    if (!tool) {
      return <Skeleton />;
    }

    return (
      <AnvBreadcrumbsConnected
        tool={tool}
        moduleCode={module}
        isOperator={isOperatorLoggedIn}
        items={items}
      />
    );
  },
);
