import { IRequestAccessModuleScope } from '@platform/front-lib/dist/models';
import React from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  AnvAccessHistoryModal,
  AnvAutocomplete2,
  AnvButton,
  AnvUtils,
  Constants,
  GroupHeaderAutocomplete,
  useModal,
  usePrevious,
} from '@platform/front-lib';
import type { IGovGoverningBody } from '@platform/front-lib/dist/models/governance';

import { defaultMetaStore, ScopeFormContext } from '../common';
import { useStyles } from '../styles';
import { capitalize, Divider, Grid } from '@material-ui/core';
import clsx from 'clsx';

interface IProps {
  jurisdictions?: any;
  withHistory?: boolean;
  ignoreAutoSetJurisdiction?: boolean;
}

export const ModuleRequestScopeGovBody: React.FC<IProps> = observer(
  ({
    jurisdictions,
    withHistory = false,
    ignoreAutoSetJurisdiction = true,
  }) => {
    const { control, errors, setValue } = useFormContext();
    const { locale } = useIntl();
    const classes = useStyles();

    const {
      existsData: item,
      onlyMyAccessByModule,
      metaStores = defaultMetaStore,
      serverErrors = {},
    } = React.useContext(ScopeFormContext) || {};

    const {
      govBodiesStore: {
        getList,
        data: bodies,
        total: bodiesTotal,
        isFetchingList: isFetchingBodies,
        dataMap: bodiesMap,
      },
      accessHistoryStore: { data: accessHistory = [] },
    } = metaStores;

    const [orgInputValue, setOrgInputValue] = React.useState('');

    const scope: IRequestAccessModuleScope & { govBody?: string } = useWatch({
      control,
      name: 'scope',
      defaultValue: {},
    });

    const { jurisdiction, govBody, school, organization } = scope;

    const isNoEduEntityProvided = !school && !organization;

    /** Gov Body */
    const bodyEntity: IGovGoverningBody | null =
      (!!govBody && bodiesMap?.[govBody]) || null;

    const bodyEntityName = AnvUtils.getNameByLangNullable(
      bodyEntity,
      'name',
      locale,
    );
    const bodyEntityJurId = bodyEntity?.jurisdiction;

    const orgInputValuePrev = usePrevious(orgInputValue);
    const jurisdictionPrev = usePrevious(jurisdiction);
    const schoolPrev = usePrevious(school);
    const organizationPrev = usePrevious(organization);

    const isOrgChange = organizationPrev !== organization;
    const isSchoolChange = schoolPrev !== school;
    const isJurisdictionChanged =
      item?.jurisdiction !== jurisdiction &&
      jurisdiction !== jurisdictionPrev &&
      bodyEntity?.jurisdiction !== jurisdiction;

    // JUR changed
    React.useEffect(() => {
      if (
        isJurisdictionChanged &&
        jurisdictionPrev &&
        bodyEntityJurId !== jurisdiction
      ) {
        setValue('scope.govBody', null);
        setOrgInputValue('');
      }
    }, [
      bodyEntityJurId,
      jurisdiction,
      isJurisdictionChanged,
      jurisdictionPrev,
      setValue,
    ]);

    // Org changed
    React.useEffect(() => {
      if (isOrgChange) {
        setValue('scope.govBody', null);
        setOrgInputValue('');
      }
    }, [isOrgChange, setValue]);

    // School changed
    React.useEffect(() => {
      if (isSchoolChange) {
        setValue('scope.govBody', null);
        setOrgInputValue('');
      }
    }, [isSchoolChange, setValue]);

    React.useEffect(() => {
      if (ignoreAutoSetJurisdiction) {
        return;
      }
      if (bodyEntity) {
        const jurisdictionFromOrgs = bodyEntity?.jurisdiction;

        if (!jurisdiction && jurisdictionFromOrgs && !jurisdictionPrev) {
          setValue('scope.jurisdiction', jurisdictionFromOrgs || '');
        }

        if (
          jurisdiction &&
          jurisdictionFromOrgs &&
          jurisdictionPrev &&
          jurisdiction !== jurisdictionFromOrgs &&
          jurisdiction === jurisdictionPrev
        ) {
          setValue('scope.jurisdiction', jurisdictionFromOrgs || '');
        }
      }
    }, [
      ignoreAutoSetJurisdiction,
      bodyEntity,
      jurisdiction,
      jurisdictionPrev,
      setValue,
    ]);

    // GET GOV BODIES
    React.useEffect(() => {
      const jurisdictionChanged =
        item?.jurisdiction !== jurisdiction &&
        jurisdiction !== jurisdictionPrev;

      if (
        isOrgChange ||
        isSchoolChange ||
        jurisdictionChanged ||
        (orgInputValuePrev !== orgInputValue &&
          bodyEntityName !== orgInputValue)
      ) {
        if (!jurisdiction && bodyEntityJurId && !ignoreAutoSetJurisdiction) {
          setValue('scope.jurisdiction', bodyEntityJurId || null);
        }

        if (
          isOrgChange ||
          isSchoolChange ||
          (jurisdictionChanged && jurisdictionPrev && jurisdiction) ||
          (jurisdiction && bodyEntityJurId && bodyEntityJurId !== jurisdiction)
        ) {
          setValue('scope.govBody', null);
        }

        const data: Record<string, any> = {
          getWithTotal: true,
          filter: {},
        };

        if (orgInputValue) {
          data.filter.nameLang = { iLike: orgInputValue };

          data.locale = locale;
        }

        if (onlyMyAccessByModule) {
          data.filterAccess = {};

          data.filterAccess.moduleCode = onlyMyAccessByModule;
        }

        if (school) {
          data['filterEduEntity'] = {
            school: {
              eq: school,
            },
          };
        }

        if (organization) {
          data['filterEduEntity'] = {
            organization: {
              eq: organization,
            },
          };
        }

        if (jurisdiction && !data['filterEduEntity']) {
          data.filter.jurisdiction = {
            eq: jurisdiction,
          };
        }

        if (!school && !organization) {
          return;
        }

        getList(data);
      }
    }, [
      bodyEntityJurId,
      setValue,
      bodyEntityName,
      getList,
      isOrgChange,
      isSchoolChange,
      item?.jurisdiction,
      jurisdiction,
      jurisdictionPrev,
      locale,
      onlyMyAccessByModule,
      orgInputValue,
      orgInputValuePrev,
      organization,
      school,
      ignoreAutoSetJurisdiction,
    ]);

    const jurisdictionsOptions = AnvUtils.prepareOptions(jurisdictions, {
      locale,
      sortByLabel: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const bodiesOptionsActual = isFetchingBodies
      ? []
      : AnvUtils.prepareOptions(bodies, {
          locale,
          type: 'organization',
        });

    const optionsHistory = AnvUtils.prepareOptions(
      accessHistory?.filter((item: any) => item?.active && item?.govBodyRef),
      {
        locale,
        labelFunc: (item) => {
          return AnvUtils.getNameByLangNullable(
            item?.govBodyRef,
            'name',
            locale,
          );
        },
        customId: 'organization',
        type: 'history',
      },
    );

    const {
      handleToggleModal: toggleHistoryViewAll,
      isOpen: historyViewAll,
    } = useModal();

    const totalHistory = optionsHistory?.length || 0;
    const historyLengthToShow = historyViewAll
      ? optionsHistory.length
      : Constants.AUTOCOMPLETE_HISTORY_LIMIT;

    const bodiesOptions = React.useMemo(() => {
      if (isNoEduEntityProvided) {
        return [];
      }
      const historyFiltered =
        optionsHistory
          ?.filter((item: Record<any, any>) => !!item.active)
          .slice(0, historyLengthToShow) || [];

      return [...historyFiltered, ...bodiesOptionsActual];
    }, [
      isNoEduEntityProvided,
      optionsHistory,
      historyLengthToShow,
      bodiesOptionsActual,
    ]);

    const formHelper = (field: string) => {
      return AnvUtils.formErrorHelper(field, errors, serverErrors);
    };

    const {
      handleToggleModal: handleToggleHistory,
      isOpen: historyOpen,
    } = useModal();

    return (
      <>
        {jurisdictions && (
          <AnvAutocomplete2
            displayEmpty
            name={'scope.jurisdiction'}
            control={control}
            label={
              <FormattedMessage
                id="APP.COMMON.LABEL.JURISDICTION"
                defaultMessage="Jurisdiction"
              />
            }
            options={jurisdictionsOptions}
            helperText={formHelper('scope.jurisdiction')}
            error={!!formHelper('scope.jurisdiction')}
          />
        )}

        <AnvAutocomplete2
          total={bodiesTotal}
          required
          options={bodiesOptions}
          loading={isFetchingBodies}
          label={
            <FormattedMessage
              id="APP.COMMON.LABEL.GOV_BODY"
              defaultMessage="Governing Body"
            />
          }
          onInputChange={(event, newInputValue) => {
            setOrgInputValue(newInputValue);
          }}
          control={control}
          name={'scope.govBody'}
          helperText={formHelper('scope.govBody')}
          error={!!formHelper('scope.govBody')}
          groupBy={!withHistory ? undefined : (option) => option.type || ''}
          renderGroup={
            !withHistory
              ? undefined
              : (group) => {
                  const groupLocaleKey = group.group.toUpperCase();

                  const isHistory = group.group === 'history';

                  return (
                    <Grid
                      key={group.key || Math.random()}
                      className={clsx({
                        [classes.historyGroup]: isHistory,
                      })}
                    >
                      <GroupHeaderAutocomplete
                        title={
                          <FormattedMessage
                            id={`APP.COMMON.FILTER.DROPDOWN.${groupLocaleKey}`}
                            defaultMessage={capitalize(group.group)}
                          />
                        }
                      >
                        {isHistory && (
                          <AnvButton
                            className={classes.autocompleteHeaderButton}
                            variant={'text'}
                            color={'primary'}
                            onClick={handleToggleHistory}
                          >
                            <FormattedMessage
                              id="APP.COMMON.FILTER.ARCHIVE"
                              defaultMessage="Archive"
                            />
                          </AnvButton>
                        )}
                      </GroupHeaderAutocomplete>

                      <Divider />

                      {group.children}

                      {isHistory &&
                        totalHistory > Constants.AUTOCOMPLETE_HISTORY_LIMIT && (
                          <div
                            className={clsx(
                              classes.wrapper,
                              classes.autocompleteFooter,
                            )}
                          >
                            <AnvButton
                              variant={'text'}
                              color={'primary'}
                              className={classes.autocompleteFooterButton}
                              onClick={toggleHistoryViewAll}
                            >
                              {historyViewAll ? (
                                <FormattedMessage
                                  id="APP.COMMON.FILTER.HIDE_ALL"
                                  defaultMessage="Hide"
                                />
                              ) : (
                                <FormattedMessage
                                  id="APP.COMMON.FILTER.VIEW_ALL"
                                  defaultMessage="View all"
                                />
                              )}
                            </AnvButton>
                          </div>
                        )}
                    </Grid>
                  );
                }
          }
        />

        <AnvAccessHistoryModal
          handleToggle={handleToggleHistory}
          open={historyOpen}
        />
      </>
    );
  },
);
