import { axiosInstance } from '@platform/front-lib';

import { axiosCatch } from 'src/service/utils';

import { IGetList, IPayloadItemById } from '../../../service/interfaces';
import { IExplorerAddToList } from '../../common/list-store';

const axios = axiosInstance;

export interface IGetAllChain {
  rootId: string;
  folders?: string[];
}

export class FolderRootCommonService {
  private readonly apiUrl: string;

  constructor({ isView }: { isView: boolean }) {
    this.apiUrl = isView
      ? process.env.REACT_APP_API_TOOL_ENDPOINT + '/file-explorer'
      : process.env.REACT_APP_API_TOOL_ENDPOINT + '/file-organizer';
  }

  async addToList(data?: IExplorerAddToList) {
    return axiosCatch(axios.post(`${this.apiUrl}/add-to-list`, data, {}));
  }

  async getList(data: IGetList) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/root-list`, {
        params: data,
      }),
    );
  }

  async createNewRootFolder(data: IPayloadItemById) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/new/${data.id}`, {
        params: data,
      }),
    );
  }

  async getOne(payload: IPayloadItemById) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/root-folders/${payload.id}`, {}),
    );
  }

  // async getAllByBase(data: IGetAllByBase) {
  //   return axiosCatch(
  //     axios.get(`${this.apiUrl}/get-all`, {
  //       params: data,
  //     }),
  //   );
  // }
  //
  // async create(payload: IKnowledgeTutorialNew) {
  //   return axiosCatch(axios.post(`${this.apiUrl}`, payload, {}));
  // }
  //
  // async update(payload: IKnowledgeTutorial) {
  //   const { _id: tutorialId, base: baseId, ...data } = payload;
  //   return axiosCatch(axios.patch(`${this.apiUrl}/${tutorialId}`, data, {}));
  // }
  //
  // async updatePositions(payload: any) {
  //   return axiosCatch(
  //     axios.patch(`${this.apiUrl}/update-positions`, payload, {}),
  //   );
  // }
  //
  // async checkPublish({ id }: { id: string }) {
  //   return axiosCatch(axios.get(`${this.apiUrl}/check-publish/${id}`));
  // }
  //
  // async publish(id: string) {
  //   return axiosCatch(axios.patch(`${this.apiUrl}/publish/${id}`));
  // }
  //
  // async unpublish(id: string) {
  //   return axiosCatch(axios.patch(`${this.apiUrl}/unpublish/${id}`));
  // }
  //
  // async delete({ id, parentId }: { id: string; parentId: string }) {
  //   return axiosCatch(
  //     axios.delete(`${this.apiUrl}/${id}`, {
  //       params: {
  //         parentId: parentId,
  //       },
  //     }),
  //   );
  // }
}
