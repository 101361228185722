import { ApiServiceCommon } from '@platform/front-lib';
import { IGetList } from '../../../../service/interfaces';

interface IGetListBuild extends IGetList {
  rootFolders?: boolean;
  complianceFolders?: boolean;
}

export class WorkZoneVirtualFoldersService extends ApiServiceCommon {
  getList(data: IGetListBuild) {
    return this.axiosCatch(
      this.axios.get(`${this.apiUrl}/work-zone/virtual-folders`, {
        params: data,
      }),
    );
  }

  getVirtualEntities(params: Record<string, any>) {
    return this.axiosCatch(
      this.axios.get(`${this.apiUrl}/work-zone/virtual-folders/particular`, {
        params,
      }),
    );
  }
}
