import React from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';

import { AnvIcon, AnvTooltip } from '@platform/front-lib';

import type { ISZObjectFolder } from 'src/models/storage-zone';

import useClickPreventionOnDoubleClick from 'src/utils/hook-useDoubleClick';
import { useMoveFolderStyles } from './styles';

interface IFolderMoveListProps {
  currentFolderEntity: ISZObjectFolder & { name?: string };
  folder: ISZObjectFolder & { name?: string };
  activeFolderId: string;
  handleChangeParentId: (folderId: string) => void;
  setActiveFolderId: React.Dispatch<React.SetStateAction<string>>;
}

export const FolderMoveListItem: React.FC<IFolderMoveListProps> = ({
  currentFolderEntity,
  folder,
  activeFolderId,
  handleChangeParentId,
  setActiveFolderId,
}) => {
  const { formatMessage } = useIntl();

  const classes = useMoveFolderStyles();

  const isDisabledItem = currentFolderEntity._id === folder._id;

  const onClick = () => {
    if (isDisabledItem) {
      return;
    }

    setActiveFolderId(folder._id);
  };

  const onDoubleClick = () => {
    if (isDisabledItem) {
      return;
    }

    handleChangeParentId(folder._id);
  };

  const handlePreventDefault = (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    // prevent text selection on double click
    ev.preventDefault();
  };

  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
    onClick,
    onDoubleClick,
  );

  const isActive = activeFolderId === folder._id;

  const content = (
    <div
      onMouseDown={handlePreventDefault}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      className={clsx(classes.foldersListItem, {
        [classes.foldersListItemActive]: isActive,
        [classes.foldersListItemDisabled]: isDisabledItem,
      })}
    >
      <Grid container alignItems={'center'} spacing={1}>
        <Grid item>
          <AnvIcon icon={'folderBig'} size={40} />
        </Grid>
        <Grid item className={classes.foldersListName}>
          {folder.folderName}
        </Grid>
      </Grid>
    </div>
  );

  return (
    <>
      {isDisabledItem ? (
        <AnvTooltip
          title={formatMessage(
            {
              id: 'STORAGE_ZONE.FOLDERS.MOVE.WARNING.TO_ITSELF',
              defaultMessage:
                'Unable to move folder "{folderName}" into itself',
            },
            {
              folderName:
                currentFolderEntity?.folderName || currentFolderEntity?.name,
            },
          )}
        >
          {content}
        </AnvTooltip>
      ) : (
        content
      )}
    </>
  );
};
