import {
  AnvPaginationMui,
  AnvTable,
  Constants,
  TextBody,
  useCheckIsTableView,
  useCommonTable,
} from '@platform/front-lib';
import { IFile } from '@platform/front-lib/dist/models';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useStores } from '../../../../../../stores';
import { useFilesViewStyles } from '../../../../../common/files/styles';

import { prepareColumns } from './common';

interface IComplianceFiles {}

export const ComplianceReviewFiles: React.FC<IComplianceFiles> = observer(
  () => {
    const classes = useFilesViewStyles();

    const { schoolId, annualReportId, month } = useParams<{
      organizationId: string;
      schoolId: string;
      annualReportId: string;
      month: string;
    }>();

    const { formatMessage, locale } = useIntl();

    const filesRequestId = `${schoolId}_${annualReportId}_${month}`;

    const {
      complianceFilesStore: {
        getList: getListFiles,

        getListMetaDataByRequestId: getListMetaDataByRequestIdFiles,
        setSort,
        setPage,
        setFilter,
      },
      fileExplorerStore: { setActiveFileId, activeFileId },
    } = useStores();

    const {
      dataList,
      pagination,
      storeFilter,
      storeSorting,
      isLoadedList,
    } = getListMetaDataByRequestIdFiles(filesRequestId);

    const {
      sorting = '',
      page,
      filterState,
      filterMemo,
      memoQueryFilter,

      handleChangeFilterDate,
      handleChangeFilter,
      handleChangePhone,
      handleChangePage,
    } = useCommonTable({
      defaultFilterState: {},
      pagination,
      setPage,
      setFilter,
      setSort,
      storeFilter,
      storeSorting,
    });

    React.useEffect(() => {
      return () => {
        setActiveFileId(null);
      };
    }, [setActiveFileId]);

    React.useEffect(() => {
      getListFiles(
        {
          page: +page,
          sort: sorting,
          filter: filterMemo,
          limit: Constants.PAGINATION_LIMIT,
          schoolId,
          annualReportId,
          month,
          module: 'report',
        },
        { requestId: filesRequestId },
      );
    }, [
      getListFiles,
      filesRequestId,
      schoolId,
      annualReportId,
      month,
      page,
      sorting,
      filterMemo,
    ]);

    const handleFileClick = (file: IFile & { _id: string }) => {
      setActiveFileId(file._id);
    };

    const columns = React.useMemo(() => {
      return prepareColumns(locale, formatMessage);
    }, [formatMessage, locale]);

    const { isShowTable } = useCheckIsTableView({
      tableDataIsLoaded: isLoadedList,
      tableDataLength: dataList?.length || 0,
      memoQueryFilter: memoQueryFilter,
    });

    return (
      <div className={classes.root}>
        <div className={classes.tableCaption}>
          <TextBody semiBold>
            <FormattedMessage
              id="STORAGE_ZONE.FILES.LIST.TITLE"
              defaultMessage="Files"
            />
          </TextBody>
        </div>

        <div className={classes.tableWrapper}>
          <AnvTable
            size={'small'}
            filterMemo={filterMemo}
            setFilter={setFilter}
            isShowTable={isShowTable}
            isLoadedList={isLoadedList}
            filterState={filterState}
            handleChangeFilterDate={handleChangeFilterDate}
            handleChangeFilter={handleChangeFilter}
            handleChangePhone={handleChangePhone}
            //common
            data={dataList}
            columns={columns}
            querySort={sorting}
            setSort={setSort}
            TableActionComponent={null}
            onRowClick={handleFileClick}
            selectedRow={activeFileId}
          />

          {isLoadedList && dataList.length === 0 && (
            <div>
              <TextBody color={'textSecondary'}>
                <FormattedMessage
                  id="STORAGE_ZONE.FILES.LIST.EMPTY"
                  defaultMessage="There is no files yet"
                />
              </TextBody>
            </div>
          )}

          <AnvPaginationMui
            pagination={pagination}
            totalPages={pagination?.totalPages}
            handleChangePage={handleChangePage}
            page={+page}
          />
        </div>
      </div>
    );
  },
);
