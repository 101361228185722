export const TOOL_CODE = 'STORAGE_ZONE';

export const TOOL_MODULES = {
  FILE_EXPLORER: 'FILE_EXPLORER',
  FILE_ORGANIZER: 'FILE_ORGANIZER',
  ACCESS: 'ACCESS',
  TRASH: 'TRASH',
};

export const FILE_EXPLORER_SUB_MODULES = {
  WORK_ZONE: 'WORK_ZONE',
  PERSONAL_ZONE: 'PERSONAL_ZONE',
} as const;

export type TFILE_EXPLORER_SUB_MODULES = typeof FILE_EXPLORER_SUB_MODULES[keyof typeof FILE_EXPLORER_SUB_MODULES];

export const blacklistSegment = ['form', 'list', 'chapters'];

export const blacklistSegmentTopLevel = [
  'build',
  'preview',
  'view',
  'access',
  'subscriptions',
];

export const ROOT_VIRTUAL_FOLDER_ID = 'ROOT';

export const pzRootVirtualFolders = 'pz_root-virtual_folders';

export const wzRootVirtualFolders = 'wz_root-virtual_folders';

export const wzRootRequestId = 'wz_root';

export const wzComplianceVirtualFolders = 'wz_compliance-virtual_folders';

export const wzComplianceReportModuleVirtualFolders =
  'wz_compliance_report_module-virtual_folders';
export const wzComplianceReviewModuleVirtualFolders =
  'wz_compliance_review_module-virtual_folders';

export const wzComplianceEduEntitiesVirtualFolders =
  'wz_compliance_edu_entities-virtual_folders';
export const wzComplianceAnnualReportsVirtualFolders =
  'wz_compliance_annual_reports-virtual_folders';
export const wzComplianceAnnualReportsMonthsVirtualFolders =
  'wz_compliance_annual_reports_months-virtual_folders';

export const EMPTY_FILE_SIZE_PLACEHOLDER = '-';

export const SZ_ENTITY_KIND = {
  FOLDER: 'folder',
  FILE: 'file',
};

export const SZ_ENTITY_TYPE = {
  EDUCATION_ENTITY: 'educationEntity',
  SYSTEM: 'system',
  USER: 'user',
};

export type TSZ_ENTITY_TYPE = typeof SZ_ENTITY_TYPE[keyof typeof SZ_ENTITY_TYPE];
export type TSZ_ENTITY_KIND = typeof SZ_ENTITY_KIND[keyof typeof SZ_ENTITY_KIND];
