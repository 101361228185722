import { axiosInstance } from '@platform/front-lib';

import { axiosCatch } from 'src/service/utils';

import { IGetList, IPayloadItemById } from '../../../service/interfaces';
import {
  ICreateFolderPayload,
  IDeleteFolderPayload,
  IMoveFolderPayload,
  IUpdateFolderPayload,
} from '../file-organizer-store/folder-store';

const axios = axiosInstance;

export interface IGetAllChain {
  rootId: string;
  folders?: string[];
}

export interface IGetChainToEntity {
  id: string;
  path: boolean;
}

export class FolderCommonService {
  private readonly apiUrl: string;

  constructor({ isView }: { isView: boolean }) {
    this.apiUrl = isView
      ? process.env.REACT_APP_API_TOOL_ENDPOINT + '/file-explorer'
      : process.env.REACT_APP_API_TOOL_ENDPOINT + '/file-organizer';
  }

  async getOne(payload: IPayloadItemById) {
    return axiosCatch(axios.get(`${this.apiUrl}/folders/${payload.id}`, {}));
  }

  async getList(data: IGetList) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/folders/${data.id}/children`, {
        params: data,
      }),
    );
  }

  async getAllChain(data: IGetAllChain) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/folders-chain`, {
        params: data,
      }),
    );
  }

  async getChainToEntity(data: IGetChainToEntity) {
    return axiosCatch(
      axios.get(`${this.apiUrl}/folders-chain/${data.id}`, {
        params: data,
      }),
    );
  }

  async createFolder(data: ICreateFolderPayload) {
    return axiosCatch(axios.post(`${this.apiUrl}/folders`, data));
  }

  async updateFolder(data: IUpdateFolderPayload) {
    const { id, ...payload } = data;
    return axiosCatch(axios.patch(`${this.apiUrl}/folders/${id}`, payload));
  }

  async moveFolder(data: IMoveFolderPayload) {
    const { id, ...payload } = data;
    return axiosCatch(
      axios.patch(`${this.apiUrl}/folders/${id}/move`, payload),
    );
  }
  async deleteFolder(data: IDeleteFolderPayload) {
    const { id } = data;
    return axiosCatch(axios.delete(`${this.apiUrl}/folders/${id}`));
  }
}
